import styled from "styled-components";

import * as ReactBootstrap from "react-bootstrap";
import { X } from "react-feather";


const Modal = styled(ReactBootstrap.Modal)`
    & .modal-dialog.modal-dialog-centered > .modal-content {
        display: flex;
        width: 560px;
        padding: 16px;
        flex-direction: column;
        gap: 16px;
    }
    svg {
     cursor: pointer;
   }
`

/**
 * show: Bool
 * description: String
 * onHide: function
 */
const DescriptionModal = (props) => {
    const { show, description, onHide } = props;
    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            animation={true}
        >
            <div className="d-flex justify-content-between align-items-center align-self-stretch">
                <h4 className="h4">
                    Description
                </h4>
                <X onClick={onHide} />
            </div>
            <div>
                <span className="body-xs">
                    {description}
                </span>
            </div>
        </Modal>
    )
};


export default DescriptionModal;