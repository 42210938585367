import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useProperty } from "../../../context/PropertyContext";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useGeneral } from "../../../context/GeneralContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Text, Center } from "@mantine/core";
import LoaderRing from "../../../components/LoaderRing";

const Users = () => {
  const { property } = useProperty();
  const [users, setUsers] = useState();
  const [isLoading, setLoading] = useState(false);
  const { setAlertModal } = useGeneral();

  const navigate = useNavigate();
  const getPropertiesUsers = () => {
    setLoading(true);
    Auth.currentSession()
      .then((token) => {
        axios
          .get(
            `${process.env.REACT_APP_NODE_API_URL}/api/property/${property?._id}/users`,
            {
              headers: {
                Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
              },
            }
          )
          .then((response) => {
            setLoading(false);
            if (response.status === 200) {
              setUsers(response.data.agents);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            if (error?.response?.status === 401) {
              Auth.signOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  console.log("Error signing out: ", error);
                });
            }
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              console.log("Error signing out: ", error);
            });
        } else {
          setAlertModal({
            show: true,
            title: "Error",
            content: "Error getting users",
          });
        }
      });
  };

  useEffect(() => {
    property?._id && getPropertiesUsers();
  }, [property?._id, navigate]);

  return (
    <div className="content-div my-3">
      <Card>
        <Card.Section p={10}>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <LoaderRing height="100" width="100" />
            </div>
          ) : users?.length > 0 ? (
            <Table responsive className="hardware-kit-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users?.map((element, index) => (
                    <tr key={index}>
                      <td>{element.firstName}</td>
                      <td>{element.email}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            <>
              <Center m={50}>
                <Text>There are no records to display</Text>
              </Center>
            </>
          )}
        </Card.Section>
      </Card>
    </div>
  );
};

export default Users;
