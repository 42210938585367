import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border-color: #BDBEBF;
  padding: 8px 24px 8px 8px;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' fill='%23000'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 24px 24px;
  

  &:disabled {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: #EAEAEB;
    background-color: #EAEAEB;
    color: #bdbfbf;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' fill='%23bdbfbf'/%3E%3C/svg%3E");

  }
`;

const StyledLabel = styled.label`
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-weight: ${props =>
    props.variantType === 'primary' ? '700' : props.variantType === 'secondary' ? '400' : '700'};
  line-height: 140%;
  letter-spacing: 0.16px;
  padding-bottom: 0px;

  /* disabled styles */
  ${props =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const AutocompleteSelect = ({
  disabled = false,
  name,
  values,
  options,
  selectedOption,
  placeholder,
  inputRef,
  error = {},
  errorMessage = "This field is required",
  ...props
}) => {

  return (
    <fieldset className='w-100'>
      <StyledLabel
        variantType={props?.variantType}
        htmlFor={props?.name}
        disabled={disabled}
      >
        {props?.label}
      </StyledLabel>
      <StyledSelect
        ref={inputRef}
        {...props}
      >
        <option value="">
          {placeholder}
        </option>
        {options.map((value, index) => (
          <option key={index} value={
            typeof value === 'string' ? value : value.id
          }>
            {typeof value === 'string' ? value : value.description}</option>
        ))}
      </StyledSelect>
      {error[name] && <p className="error">{errorMessage}</p>}
    </fieldset>
  );
};

export default AutocompleteSelect;
