import { Auth } from "aws-amplify";
import axios from "axios";

export const getOrders = async () => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/order`
  try {
    const token = await Auth.currentSession()
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    })
    return res.data
  } catch (error) {
    
  }
}

export const adminGetOrders = async () => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/order/admin/list`
  try {
    const token = await Auth.currentSession()
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    })
    return res.data
  } catch (error) {
    
  }
}