import React from 'react';
import { Button, Container, Modal, Row } from 'react-bootstrap';
import { CiExport } from 'react-icons/ci';

const ExportModal = ({show, handleClose, handleExport, filters}) => {
    return (
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          dialogClassName="modal-dialog"
        >
          <Modal.Header>
            <Modal.Title className="d-flex align-items-center modal-title p-1 ">
              <CiExport size="22px" /> <span>Export</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>            
              <Row>
              {filters?.map((column) => column)}            
          </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleExport}>
              Export
            </Button>
          </Modal.Footer>
        </Modal>
    );
};

export default ExportModal;