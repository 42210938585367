import './Leads.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import copy from 'copy-to-clipboard';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Sidebar from '../../components/SideBar';
import getImageUrl from '../../helper/checkImageFormat';
import { Center, Loader } from '@mantine/core';

const LeadsDetails = () => {
  // alert modal
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Alert');
  const [alertContent, setAlertContent] = useState('');

  const [timezone, setTimezone] = useState(0);

  const [loading, setLoader] = useState(false);

  const [list, setList] = useState([]);
  const [leadImage, setLeadImage] = useState('');

  const navigate = useNavigate();
  const params = useParams();


  useEffect(() => {
    async function getTimezone() {
      try {
        const token = await Auth.currentSession()
        const response = await axios
          .get(`${process.env.REACT_APP_NODE_API_URL}/api/users/timezone`, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
        if (response.status === 200) {
          setTimezone(response.data.timezone);
        } else {
          setShowAlert(true);
          setAlertTitle('Error');
          setAlertContent('Error getting timezone');
        }
      } catch (error) {
        console.log(error);
        setShowAlert(true);
        setAlertTitle('Error');
        setAlertContent('Error getting timezone');
      }
    }
    getTimezone();
  }, [])

  useEffect(() => {
    if (timezone) {
      getPropertyList()
    }
  }, [timezone])

  const copyToClipboard = () => {
    let userId = list ? list.user: ''
    let link = `${process.env.REACT_APP_URL}/book/list/${userId}`
    copy(link)
    setShowAlert(true);
    setAlertTitle('Copied');
    setAlertContent('Calendar link copied. You can send this link to leads, so that they can create appointments.');
  }

  const getPropertyList = () => {
    Auth.currentSession()
      .then((token) => {
        axios
          .get(
            `${process.env.REACT_APP_NODE_API_URL}/api/contact/${params.id}`,
            { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.document) {
                setLeadImage(response.data.document);
              }
              setList(response.data);
              setLoader(true);
            } else {
              setShowAlert(true);
              setAlertTitle('Alert');
              setAlertContent('Error getting Lead');
            }
          })
          .catch((error) => {
            setShowAlert(true);
            setAlertTitle('Alert');
            setAlertContent('Error getting Lead');
          })
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/')
          }).catch((error) => {
            console.log('Error signing out: ', error);
          })
        } else {
          setShowAlert(true);
          setAlertTitle('Alert');
          setAlertContent('Error getting Lead');
        }
      })
  }

  const setOutcome = (e) => {
    let outcomeValue = e.currentTarget.dataset.value
    let id = e.currentTarget.dataset.id
    let data = {
      outcome: outcomeValue,
      tags: ['new', 'user selected'],
      status: 'active',
    }
    Auth.currentSession()
      .then((token) => {
        axios
          .patch(
            `${process.env.REACT_APP_NODE_API_URL}/api/booking/${id}`,
            data,
            { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
          .then((response) => {
            if (response.status === 200) {
              setShowAlert(true);
              setAlertTitle('Success');
              setAlertContent('Lead Update Successfully')
            } else {
              setShowAlert(true);
              setAlertTitle('Alert');
              setAlertContent('Error Updating Lead');
            }
          })
          .catch((error) => {
            setShowAlert(true);
            setAlertTitle('Alert');
            setAlertContent('Error Updating Lead');
          })
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/')
          }).catch((error) => {
            console.log('Error signing out: ', error);
          })
        } else {
          setShowAlert(true);
          setAlertTitle('Alert');
          setAlertContent('Error Updating Lead');
        }
      })
  }
  return (
    <Sidebar>
      <div className="page-main-head align-items-center p-0 property-head">
        <Row className="align-items-center">
          <Col >
            <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-start">
              <span className="position-relative d-inline-block">
                <img
                  src={getImageUrl(leadImage)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = require('../../assests/images/aljnfn.png');
                  }}
                  alt="ID document"
                  width={160}
                  height={90}

                  className="border-radius-0"

                />
              </span>
              <div className="ml-20">
                <h6>
                  {list.firstName} {list.lastName}
                </h6>
                {/* <div className="d-flex">
                  <Link
                    to={`/edit-leads/${params.id}`}
                    size="sm"
                    className="btn btn-primary btn-sm edit-property-btn mr-30"
                  >
                    Edit lead details
                  </Link>
                </div> */}
              </div>
            </div>
          </Col>
          {/* <Col xl={6} lg={12} className="mt-2 mb-2 m-xl-0 d-flex justify-content-center justify-content-lg-end">
            <Button
              size="sm"
              className="light-btn btn btn-wicon btn-sm ml-auto mr-10"
              onClick={copyToClipboard}
            >
              Share calendar with {list.firstName}
            </Button>
          </Col> */}
        </Row>
      </div>
      {loading === false ? (
        <Center h={100}>
          <Loader/>
        </Center>
      ) : (
        <>
          <Row className="mt-30">
            <Col xl={3} lg={12}>
              <Card>
                <Card.Header as="h5">ID document</Card.Header>
                <Card.Body>
                  <div className="id-img-wrap">
                    <img
                      src={getImageUrl(leadImage)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = require('../../assests/images/aljnfn.png');
                      }}
                      alt="ID document"
                      width="100%"
                    />
                  </div>
                  <p>
                    <em>
                      *Always check and confirm that the ID provided is correct and
                      matches the other given details
                    </em>
                  </p>
                </Card.Body>
              </Card>
            </Col>

            <Col xl={9} lg={12}>
              <Card>
                <Card.Header as="h5">Personal information</Card.Header>
                <Card.Body>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Full-name"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Full name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.firstName}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Mobile"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Last name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.lastName}</p>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Email"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Mobile</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <a
                          href={`tel:${list.phone}`}
                          className="table-phone phonenumber-w-ico link-w-ico color-black"
                        >
                          {list.phone}
                        </a>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Email</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <a
                          href={`mailo:${list.email}`}
                          className="table-mail mail-w-ico link-w-ico"
                        >
                          {list.email}
                        </a>
                      </Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}

      {showAlert ?
        <Modal show={showAlert}>
          <Modal.Header>
            <Modal.Title>{alertTitle}</Modal.Title>
            <button className="btn-close" aria-label="Close" onClick={() => setShowAlert(false)}></button>
          </Modal.Header>
          <Modal.Body>{alertContent}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAlert(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        : ''
      }
    </Sidebar>
  )
}

export default LeadsDetails
