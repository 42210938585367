import { ReactSVG } from 'react-svg';
import propertyDummyImage from '../../../../assests/images/6-thumb.png';
import style from './style.module.scss';
import PrevArrow from '../../../../assests/images/PrevChevron.svg';
import NextArrow from '../../../../assests/images/NextChevron.svg';
import Slider from 'react-slick';
import { Image } from '../../../../designSystem/Card/Image';
import { format } from 'date-fns';

type Props = {
  property: {
    shortAddress: string;
    address: string;
    images: string[];
    amenitiesAndFeatures: {
      [key: string]: string[];
    };
    pets: {
      allowed?: boolean;
      cats?: {
        weight?: string | number;
        maxAllowed?: number;
        allowed?: boolean;
      };
      dogs?: {
        weight?: string | number;
        maxAllowed?: number;
        allowed?: boolean;
      };
      deposit?: number;
      monthlyPetRent?: number;
      notes?: string;
    };
    utilities: {
      [key: string]: 'tenant' | 'included';
    };
    parking: {
      spacesNumber: number;
      type: string;
    };
    dateAvailableTs: EpochTimeStamp;
    leaseTermOptions: string[];
    rentalAplicationForm: {
      enable?: boolean;
      url: string;
      fee: number;
    };
  };
};

const camelCaseToWords = (camelCase: string): string => {
  if (!camelCase) return '';
  const words: string[] = [];
  let currentWord = '';
  for (let i = 0; i < camelCase.length; i++) {
    if (camelCase[i] === camelCase[i].toUpperCase()) {
      words.push(currentWord);
      currentWord = camelCase[i].toLowerCase();
      continue;
    }
    currentWord += camelCase[i];
  }
  words.push(currentWord);
  return words.join(' ');
};

export const PropertyDetail = ({ property }: Props) => {
  const amenitiesEntries =
    typeof property.amenitiesAndFeatures === 'object'
      ? Object.entries(property?.amenitiesAndFeatures)
      : [];
  const includedUtilities =
    typeof property.utilities === 'object'
      ? Object.entries(property?.utilities).reduce<string[]>(
          (acc, [key, value]) => {
            if (value === 'included') {
              acc.push(key);
            }
            return acc;
          },
          []
        )
      : [];
  const setSVGAttributes = (svg: SVGElement) => {
    svg.setAttribute('viewbox', '0 0 12 24');
    svg.setAttribute('width', '12');
    svg.setAttribute('height', '24');
    const path = svg.querySelector('path');

    if (path) {
      path.setAttribute('fill', '#979797');
      path.setAttribute('stroke', '#979797');
    }
  };
  const config = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    adaptiveHeight: true,
    variableHeight: false,
    prevArrow: (
      <ReactSVG
        beforeInjection={setSVGAttributes}
        src={PrevArrow}
      />
    ),
    nextArrow: (
      <ReactSVG
        beforeInjection={setSVGAttributes}
        src={NextArrow}
      />
    ),
  };
  const availablePetsString = (pets: Props['property']['pets']) => {
    if (!pets?.allowed) {
      return 'No pets allowed';
    } else {
      if (pets.cats?.allowed && pets.dogs?.allowed) {
        return 'All pets allowed';
      } else if (pets.cats?.allowed) {
        return 'Only cats allowed';
      } else {
        return 'Only dogs allowed';
      }
    }
  };
  return (
    <article className={style.container}>
      <header>
        <h2 className={style.shortAddress}>{property?.shortAddress}</h2>
        <p className={style.address}>{property?.address}</p>
      </header>
      {property?.images.length ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'minmax(0, 1fr)',
            paddingInline: '1rem',
          }}
        >
          <div className='slider-container'>
            <Slider {...config}>
              {property?.images.map((src) => (
                <Image
                  key={src}
                  src={src}
                  alt=''
                />
              ))}
            </Slider>
          </div>
        </div>
      ) : (
        <Image
          src={propertyDummyImage}
          alt=''
        />
      )}
      <section className={style.descriptionsGroup}>
        {amenitiesEntries.some((amenity) => !!amenity[1].length) ? (
          <div>
            <h3 className={style.h3}>Features & Amenities</h3>
            {amenitiesEntries.map(([key, values]) => {
              return (
                <div key={key}>
                  <h4 className={style.h4}>
                    {camelCaseToWords(key).toUpperCase()}
                  </h4>
                  <ul className={style.description}>
                    {values.map((value) => {
                      return <li key={value}>{value}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        ) : null}
        <div>
          <div>
            <h3 className={style.h3}>Pet policies</h3>
            <p className={style.description}>
              {availablePetsString(property?.pets)}
            </p>
          </div>
          <div>
            <h3 className={style.h3}>Utilities Included</h3>
            <p className={style.description}>
              {includedUtilities.length
                ? includedUtilities.join(', ')
                : 'No included utilities'}
            </p>
          </div>
          <div>
            <h3 className={style.h3}>Parking</h3>
            <p className={`${style.description} ${style.parking}`}>
              {`${camelCaseToWords(property?.parking?.type)} ${
                property?.parking?.spacesNumber
                  ? `, ${property?.parking?.spacesNumber} spaces`
                  : ''
              }`}
            </p>
          </div>
        </div>
      </section>
      {property?.leaseTermOptions?.length ||
      property?.dateAvailableTs ||
      property?.rentalAplicationForm?.enable ? (
        <section className={style.leaseOptions}>
          <h3 className={style.h3}>Lease Options</h3>

          <p className={style.desciption}>
            Lease Terms{'(s)'}: {property?.leaseTermOptions[0]}
          </p>
          <p className={style.desciption}>
            Available:{' '}
            {format(new Date(property?.dateAvailableTs), 'MM/dd/yyyy')}
          </p>
          {property?.rentalAplicationForm?.enable ? (
            <p className={style.desciption}>
              Application Fee: ${property?.rentalAplicationForm.fee}
            </p>
          ) : null}
        </section>
      ) : null}
    </article>
  );
};
