import React, { useState } from 'react';

import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Sidebar from '../components/AdminSideBar';

const AddUser = () => {
  const navigate = useNavigate()
  const [selectValue, setSelectValue] = useState('')
  const [show, setShow] = useState(false)
  const [error, setError] = useState('')
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    description: '',
    role: selectValue,
    company_name: '',
  })
  const onFormInputChange = (event) => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }
  const handleChange = (e) => {
    setSelectValue(e)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    var pattern = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
    )
    var data = {
      firstName: form.firstName,
      lastName: form.lastName,
      phone: form.phone,
      email: form.email,
      password: form.password,
      description: form.description,
      role: selectValue ? selectValue : 'admin',
      company: {
        name: form.company_name,
      },
      active: true,
    }
    if (form.firstName === '') {
      setError('Please Enter FirstName')
      setShow(true)
    } else if (form.lastName === '') {
      setError('Please Enter LastName')
      setShow(true)
    } else if (form.phone === '') {
      setError('Please Enter PhoneNumber')
      setShow(true)
    } else if (!pattern.test(form.phone)) {
      setError('Please enter a valid phone number')
      setShow(true)
    } else if (form.phone.length !== 10) {
      setError('Please enter a valid phone number')
      setShow(true)
    } else if (form.email === '') {
      setError('Please Enter Email')
      setShow(true)
    } else if (!emailRegex.test(form.email)) {
      setError('Please enter a valid email address')
      setShow(true)
    } else if (form.password === '') {
      setError('Please Enter Password')
      setShow(true)
    } else if (form.company_name === '') {
      setError('Please Enter Company Name')
      setShow(true)
    } else {
      try {
        const url = process.env.REACT_APP_NODE_API_URL + '/api/users/admin/create'
        const token = await Auth.currentSession();

        const response = await axios.post(url, data, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
        if (response.status === 200 || response.status === 201) {
          if (response.data) {
            setShow(true)
            setError('')
          }
        } else {
          setError('Error Creating User')
          setShow(true)
        }

      } catch (error) {
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/admin/login')
          }).catch((error) => {
            console.log('Error signing out: ', error);
          })
        } else {
          console.log(error);
          setError('Error Creating User');
          setShow(true);
        }
      }
    }
  }
  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">Create User</h1>
        </div>
        <Form>
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <Card>
                <Card.Header as="h5">Users Details</Card.Header>
                <Card.Body>
                  <Form method="post" onSubmit={onSubmit}>
                    <Form.Group className="mb-20" controlId="Full-name">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>First Name</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="firstName"
                            type="text"
                            value={form.firstName}
                            onChange={onFormInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-20" controlId="Full-name">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Last Name</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="lastName"
                            type="text"
                            value={form.lastName}
                            onChange={onFormInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-20" controlId="Mobile">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Mobile</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            type="tel"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            maxLength={10}
                            minLength={10}
                            name="phone"
                            value={form.phone}
                            onChange={onFormInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Email">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Email</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="email"
                            type="email"
                            value={form.email}
                            onChange={onFormInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Building-manager">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Password</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="password"
                            type="password"
                            value={form.password}
                            onChange={onFormInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Buzzer-code">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Company Name</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="company_name"
                            type="text"
                            value={form.company_name}
                            onChange={onFormInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-20" controlId="Lockbox-code">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Description</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            name="description"
                            type="text"
                            value={form.description}
                            onChange={onFormInputChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-20" controlId="Lockbox-code">
                      <Row className="align-items-center">
                        <Col lg={2}>
                          <Form.Label>Role</Form.Label>
                        </Col>
                        <Col lg={10}>
                          <Form.Select
                            onChange={(event) =>
                              handleChange(event.target.value)
                            }
                            value={selectValue}
                          >
                            <option
                              selected="true"
                              disabled="disabled"
                              value="select"
                            >
                              Select Role
                            </option>

                            <option value="admin">Admin</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                    <div className="col-lg-12">
                      <div className="text-center">
                        <Button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                          onClick={onSubmit}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      </Sidebar>
      {show ? (
        <Modal show={show}>
          {error !== '' ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Saved</Modal.Title>
              </Modal.Header>

              <Modal.Body>Create User Successfully </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigate('/admin/user/list')
                    setShow(false)
                  }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}

export default AddUser
