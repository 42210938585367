import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

const InvoiceForm = ({
  handleInputChange,
  invoice,
  users,
  handleUserChange,
  items = [],
  subscription,
  orders,
}) => {
  return (
    <Form>
      <Form.Group className="mb-20" controlId="user">
        <Row className="align-items-center">
          <Col lg={2}>
            <Form.Label>Allocate User</Form.Label>
          </Col>
          <Col lg={10}>
            <Form.Select
              name="user"
              onChange={(e) => handleUserChange(e.target.value)}
              required
            >
              <option selected={true} disabled="disabled">
                Select user
              </option>
              {users?.map((user, index) => (
                <>
                  <option id={user._id} value={index}>
                    {user.firstName} {user.lastName}| {user.company?.name}
                  </option>
                </>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>

      <Form.Group className="mb-20" controlId="subsAndOrders">
        <Row className="align-items-center">
          <Col lg={2}>
            <Form.Label>Pick order/subscription</Form.Label>
          </Col>
          <Col lg={10}>
            <Form.Select
              name="subsAndOrders"
              onChange={(e) => handleInputChange("service", JSON.parse(e.target.value))}
              required
            >
              <option selected={true} disabled="disabled">
                Select order or subscription
              </option>
              {subscription && (
                <option
                  id={subscription._id}
                  value={JSON.stringify({ type: "subscription", id: subscription._id })}
                >
                  Subscription {subscription.paymentMethod}
                </option>
              )}
              {orders?.map((order) => (
                <option
                  key={order._id}
                  value={JSON.stringify({ type: "order", id: order._id })}
                >
                  Order {order.code} {order._id} | {order.state}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>

      <Row>
        <Form.Group
          className="mb-3 d-flex align-items-center"
          controlId="checkNumber"
        >
          <Form.Label className="col-2">Check number</Form.Label>
          <Form.Control
            className="col"
            value={invoice.checkNumber}
            type="text"
            placeholder="Enter check number"
            onChange={(e) => handleInputChange(e.target.id, e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="paymentDate" as={Col}>
          <Form.Label>Payment date</Form.Label>
          <Form.Control
            type="date"
            value={invoice.paymentDate}
            onChange={(e) => handleInputChange(e.target.id, e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="amount" as={Col}>
          <Form.Label>Amount paid</Form.Label>
          <Form.Control
            type="number"
            value={invoice.amount}
            onChange={(e) => handleInputChange(e.target.id, e.target.value)}
          />
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
        <h3 className="h6">Period</h3>
        <Row>
          <Form.Group as={Col} className="mb-3" controlId="fromDate">
            <Form.Label>From</Form.Label>
            <Form.Control
              type="date"
              value={invoice.fromDate}
              onChange={(e) => handleInputChange(e.target.id, e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} className="mb-3" controlId="toDate">
            <Form.Label>To</Form.Label>
            <Form.Control
              type="date"
              value={invoice.toDate}
              onChange={(e) => handleInputChange(e.target.id, e.target.value)}
            />
          </Form.Group>
        </Row>

        {invoice.service?.type === "order" && (
          <Form.Group className="mb-20" controlId="items">
            <Row className="align-items-center">
              <Col lg={2}>
                <Form.Label>Choose items</Form.Label>
              </Col>
              <Col lg={10}>
                <Select
                  options={items}
                  onChange={(newItems) => handleInputChange("items", newItems)}
                  getOptionValue={(detail) =>
                    detail.amount + " " + detail.serviceType
                  }
                  getOptionLabel={(item) =>
                    item.name + " " + item.state
                  }
                  value={invoice.services}
                  isMulti
                ></Select>
              </Col>
            </Row>
          </Form.Group>
        )}
      </Row>
    </Form>
  );
};

export default InvoiceForm;
