import React from 'react';
import PublicLayout from '../../layouts/public';
import StepsContainer from './components/Steps';
import { RescheduleSteps } from './components/RescheduleSteps';

function BookingPage({ reschedule }) {
  return (
    <PublicLayout footerClassname={'d-none d-sm-flex'}>
      <div className='px-3'>
        {reschedule ? <RescheduleSteps /> : <StepsContainer />}
      </div>
    </PublicLayout>
  );
}

export default BookingPage;
