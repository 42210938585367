import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useForm, FormProvider } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { usePlacesWidget } from "react-google-autocomplete";
import { Auth } from "aws-amplify";

import Sidebar from "../../components/SideBar";
import Button from "../../components/Button";
import InputSelectComponent from "../../components/InputSelectComponent";
import InputImages from "../../components/InputImages";
import InputTextComponent from "../../components/InputTextComponent";
import PageHeader from "../../components/PageHeader";
import InputCheckbox from "../../components/InputCheckbox";
import InputRadio from "../../components/InputRadio";
import InputSwitchComponent from "../../components/InputSwitchComponent";
import InputSubmit from "../../components/InputSubmit";
import InputTextAreaComponent from "../../components/InputTextAreaComponent";
import LableComponent from "../../components/Lable";
import InputRadioHorizontal from "../../components/InputRadioHorizontal";
import {
  amenities,
  states,
  category,
  propertyType,
  parkingType,
  pets,
  LeaseTermOptions,
} from "../../static/Data";
import AutocompleteInput from "../../components/AutocompleteInput";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import InputDateComponent from "../../components/InputDateComponent";
import { useGeneral } from "../../context/GeneralContext";
import { useProperty } from "../../context/PropertyContext";
import {
  convertFileToSrc,
  createFileFromUrl,
  str2bool,
} from "../../utils/utils";
import getImageUrl from "../../helper/checkImageFormat";
import removeUndefinedWord from "../../helper/updateString";
import { Loader } from "@mantine/core";
import SecurityLevelOption from "./components/securityLevelOption";
import { IoShield, IoShieldHalf, IoShieldOutline } from "react-icons/io5";
import { FiShieldOff } from "react-icons/fi";
import UnstyledButton from "../../components/UnstyledButton";

const Container = styled.div({
  // display: "flex",
  // justifyContent: "space-between",
});

const LeftSidebar = styled.div`
  display: flex;
  flex-direction: column;
  text-align: "justify";
  gap: 16px;
`;

const LeftSidebarTitle = styled.div``;

const PageContent = styled.div({
  position: "relative",
});

const FormContainer = styled.div`
  padding: 32px 0px 32px 0px;
`;

const Card = styled.div({
  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25);",
  borderRadius: "5px",
  margin: "20px",
  padding: "15px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

const CardList = styled.div({
  flexDirection: "column",
});

const FormTitle = styled.h4({
  color: "#5d5fef",
});

const FormSubTitle = styled.span({
  color: "#66676B",
  textTransform: "none",
  fontFamily: "MulishRegular",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "140%",
  letterSpacing: "0.14px",
});

const PropertiesForm = () => {
  const { setAlertModal } = useGeneral();
  const { setProperty, property } = useProperty();

  const [zipCodeError, setZipCodeError] = useState("");
  const [propertyImages, setPropertyImages] = useState([]);
  const [kits, setkits] = useState([]);
  const [kitsLoaded, setKitsLoaded] = useState(false);
  const [categoryDisabled, setCategoryDisabled] = useState(true);
  const [categoryState, setCategoryState] = useState(false);
  const [parkingTandem, setParkingTandem] = useState(false);
  const [parkingSelected, setParkingSelected] = useState(null);
  const [petsAllowedStatus, setPetsAllowedStatus] = useState({
    no: false,
    dogs: false,
    cats: false,
  });
  const [parkingIncluded, setParkingIncluded] = useState(false);
  const [switchState, setSwitchState] = useState({
    maintenance: false,
    property: false,
    leasing: false,
    company: false,
    doorUnlock: false,
    propertyAccess: false,
    idVerification: false,
    rentalApplication: false,
  });

  const [creatingProperty, setCreatingProperty] = useState(false);

  const cityRef = useRef();
  const zipCodeRef = useRef();
  const stateRef = useRef();

  const section1Ref = useRef();
  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const isEditPage = location.pathname.includes("edit");

  const formMethods = useForm({
    defaultValues: {
      amenitiesAndFeatures: {
        floorPlanHighlights: [],
        kitchenFeatures: [],
        buildingFeatures: [],
      },
    },
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    watch,
    reset,
  } = formMethods;

  const defaultValues = (form) => {
    if (form.propertyType === null) {
      form.propertyType = "apartment";
    }

    if (form.utilities.water === null) {
      form.utilities.water = "tenant";
    }
    if (form.utilities.electricity === null) {
      form.utilities.electricity = "tenant";
    }
    if (form.utilities.gas === null) {
      form.utilities.gas = "tenant";
    }
    if (form.utilities.trash === null) {
      form.utilities.trash = "tenant";
    }
    if (form.utilities.sewage === null) {
      form.utilities.sewage = "tenant";
    }
    if (form.parking.type === null) {
      form.parking.type = "streetResidential";
    }
    if (form.kit === "") {
      form.kit = null;
    }
    if (form.maintenanceManager.onSite === null) {
      form.maintenanceManager.onSite = false;
    }
    if (form.propertyManager.onSite === null) {
      form.propertyManager.onSite = false;
    }
    if (form.timeInstructions === null || form.timeInstructions === "") {
      form.timeInstructions = 900000;
    }
    return form;
  };

  const onSubmit = async (form) => {
    let images = [];

    for (let i = 0; i < propertyImages.length; i++) {
      if (typeof propertyImages[i] === "string") {
        images.push(propertyImages[i]);
      } else {
        const image = await convertFileToSrc(propertyImages[i]);
        images.push(image);
      }
    }

    console.log("---------------------------", images);

    // return

    form.shortAddress = autoCompleteRef.current.value;
    form.city = cityRef.current.value;
    form.zipCode = zipCodeRef.current.value;
    form.state = stateRef.current.value;

    let addressParts = [];

    if (form.shortAddress) {
      addressParts.push(form.shortAddress);
    }
    if (form.city) {
      addressParts.push(form.city);
    }
    if (form.state) {
      addressParts.push(form.state);
    }
    if (form.zipCode) {
      addressParts.push(form.zipCode);
    }
    form.address = addressParts.join(", ");

    delete form.parking["tandem"];
    form.parking.type = parkingTandem
      ? `${form.parking.type}, tandem`
      : form.parking.type;

    form.dateAvailableTs = new Date(form.dateAvailableTs).getTime();

    try {
      form.images = images;
    } catch (error) {
      console.error("Error reading file:", error);
    }

    if (form.pets.allowed === "no") {
      form.pets.allowed = false;
    } else {
      form.pets.allowed = true;
    }
    if (form.pets.dogs.allowed === "dogs") {
      form.pets.dogs.allowed = true;
    }
    if (form.pets.cats.allowed === "cats") {
      form.pets.cats.allowed = true;
    }

    form.idVerification = switchState.idVerification;

    form.doorUnlockLink = switchState.doorUnlock;
    form.propertyAccessCodes = {
      ...form.propertyAccessCodes,
      enable: switchState.propertyAccess,
    };
    form.rentalApplicationForm = {
      ...form.rentalApplicationForm,
      enable: switchState.rentalApplication,
    };

    form.company = { ...form.company, contactInformation: switchState.company };
    form.leasingAgent = {
      ...form.leasingAgent,
      contactInformation: switchState.leasing,
    };
    form.propertyManager = {
      ...form.propertyManager,
      contactInformation: switchState.property,
      onSite: str2bool(form.propertyManager.onSite),
    };
    form.maintenanceManager = {
      ...form.maintenanceManager,
      contactInformation: switchState.maintenance,
      onSite: str2bool(form.maintenanceManager.onSite),
    };

    defaultValues(form);

    let methodForm = "";
    let url = "";

    if (isEditPage) {
      methodForm = `put`;
      url = `${process.env.REACT_APP_NODE_API_URL}/api/property/${params.id}`;
    } else {
      methodForm = `post`;
      url = `${process.env.REACT_APP_NODE_API_URL}/api/property`;
    }
    Auth.currentSession()
      .then((token) => {
        setCreatingProperty(true);
        axios({
          method: methodForm,
          url: url,
          data: form,
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
          .then((response) => {
            if (isEditPage) {
              setAlertModal({
                show: true,
                title: "Success",
                content: "Property Edited Successfully",
              });
              navigate(`/property/${params.id}`);
            } else {
              setAlertModal({
                show: true,
                title: "Success",
                content: "Property Created Successfully",
              });
              navigate("/property/list");
            }
          })
          .catch((error) => {
            console.log(error);
            if (isEditPage) {
              setAlertModal({
                show: true,
                title: "Error",
                content: "Error Editing Property",
              });
            } else {
              setAlertModal({
                show: true,
                title: "Error",
                content: "Error Creating Property",
              });
            }
          })
          .finally(() => {
            setCreatingProperty(false);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const unitOnChange = (e) => {
    // eslint-disable-next-line no-useless-escape
    if (e.target.value.match("^[a-zA-Z0-9/-]*$") === null) {
      setError("unit", {
        type: "error",
        message:
          "Please enter alphanumeric values only. No special characters (i.e., # ! * $ ^ &).",
      });
    } else {
      setError("unit");
    }
  };

  const zipCodeOnChange = (e) => {
    // eslint-disable-next-line no-useless-escape
    if (e.target.value.length !== 5) {
      setZipCodeError("Please enter a valid 5-digit US ZIP Code");
    } else {
      setZipCodeError("");
    }
  };

  const handleAutocomplete = (place) => {
    let address = "",
      roadNumber = "",
      road = "",
      postalCode = "",
      city = "",
      state = "";
    // Get each component of the address from the place details,
    for (const component of place.address_components) {
      const componentType = component.types[0];

      if (componentType === "street_number") {
        roadNumber = component.long_name;
      }
      if (componentType === "route") {
        road = component.long_name;
      }
      if (componentType === "postal_code") {
        postalCode = component.long_name;
      }

      if (componentType === "sublocality_level_1") {
        if (city === "") {
          city = component.long_name;
        }
      }
      if (componentType === "locality") {
        city = component.long_name;
      }
      if (componentType === "administrative_area_level_1") {
        state = component.short_name;
      }
      if (roadNumber !== "" || road !== "") {
        address = `${roadNumber} ${road}`;
      }
    }
    autoCompleteRef.current.value = address || "";
    cityRef.current.value = city || "";
    zipCodeRef.current.value = postalCode || "";
    stateRef.current.value = state || "";
  };

  const { ref: autoCompleteRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_MAPS_KEY,
    options: {
      componentRestrictions: { country: "us" },
      fields: ["address_component", "geometry"],
      types: ["address"],
    },
    onPlaceSelected: handleAutocomplete,
  });

  useEffect(() => {
    const { propertyType, category } = watch();
    if (propertyType !== null) {
      setCategoryDisabled(false);
    }
    if (category === "rent") {
      setCategoryState(true);
    } else {
      setCategoryState(false);
    }
  }, [watch()]);

  const getProperty = async () => {
    try {
      const token = await Auth.currentSession();
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_API_URL}/api/property/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        }
      );

      response.data.dateAvailableTs = response.data.dateAvailableTs
        ? new Date(response.data.dateAvailableTs).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10);

      autoCompleteRef.current.value = removeUndefinedWord(
        response.data.shortAddress
      );
      cityRef.current.value = removeUndefinedWord(response.data.city);
      zipCodeRef.current.value = removeUndefinedWord(response.data.zipCode);
      stateRef.current.value = removeUndefinedWord(response.data.state);

      response.data.maintenanceManager.onSite = String(
        response.data.maintenanceManager.onSite
      );
      response.data.propertyManager.onSite = String(
        response.data.propertyManager.onSite
      );

      setPropertyImages(response.data.images);
      console.log({data: response.data})
      const parkingTypeArray = response.data.parking?.type.split(", ");
      const initialParkingType = parkingTypeArray?.[0];
      const isTandemChecked = parkingTypeArray?.length === 2;

      setParkingSelected(initialParkingType);
      setParkingTandem(isTandemChecked);

      setProperty(response.data);
      reset(
        {
          ...response.data,
          kit: response.data.kit ? response.data.kit._id : null,
        },
        {
          keepErrors: true,
          keepDirty: true,
        }
      );
      setSwitchState({
        idVerification: response.data.idVerification,
        doorUnlock: response.data.doorUnlockLink,
        propertyAccess: response.data.propertyAccessCodes.enable,
        rentalApplication: response.data.rentalApplicationForm.enable,
        company: response.data.company.contactInformation,
        leasing: response.data.leasingAgent.contactInformation,
        property: response.data.propertyManager.contactInformation,
        maintenance: response.data.maintenanceManager.contactInformation,
      });
      // const propImages = await Promise.all(
      //   response.data.images.map(async (image) => await createFileFromUrl(getImageUrl(image)))
      // );
      // setPropertyImages(propImages);
    } catch (error) {
      console.log(error);
      setAlertModal({
        show: true,
        title: "Error",
        content: "Error Getting property",
      });
    }
  };

  useEffect(() => {
    getHardwareKits();
    if (isEditPage) {
      getProperty();
    }
  }, [navigate, kitsLoaded]);

  function getHardwareKits() {
    Auth.currentSession().then((token) => {
      axios
        .get(`${process.env.REACT_APP_NODE_API_URL}/api/kits/user`, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setkits(response.data);
            setKitsLoaded(true);
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error Getting Kits",
            });
          }
        })
        .catch((error) => {
          console.log("ERROR...");
          if (error.response === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/");
              })
              .catch((error) => {
                console.log("error signing out: ", error);
              });
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error Getting Kits",
            });
          }
        });
    });
  }

  return (
    <>
      <Sidebar>
        <Container>
          <PageContent>
            <PageHeader
              isEditPage={isEditPage}
              refs={{
                section1Ref,
                section2Ref,
                section3Ref,
                section4Ref,
                section5Ref,
              }}
            />
            <div className="content-div">
              <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <FormContainer>
                    <Row>
                      <Col xl={4}>
                        <LeftSidebar ref={section1Ref}>
                          <LeftSidebarTitle>
                            <span className="tags">Section 1 of 5</span>
                          </LeftSidebarTitle>
                          <LeftSidebarTitle>
                            <h3>Property Details</h3>
                          </LeftSidebarTitle>
                          <LeftSidebarTitle>
                            <span className="body-s">
                              This information will be visible to prospects on
                              the Prospect Booking page.
                            </span>
                          </LeftSidebarTitle>
                        </LeftSidebar>
                      </Col>
                      <Col xl={8}>
                        <CardList>
                          <Card id="location">
                            <FormTitle>LOCATION</FormTitle>
                            <AutocompleteInput
                              labelType="primary"
                              label="Address"
                              name="shortAddress"
                              error={errors}
                              inputRef={autoCompleteRef}
                              placeholder="Street Address"
                            />
                            <InputTextComponent
                              labelType="secondary"
                              label="Apt / Suite # (Optional)"
                              name="unit"
                              register={register}
                              error={errors}
                              onChange={unitOnChange}
                              placeholder="(i.e. 102, 5B, etc.)"
                            />
                            <AutocompleteInput
                              label="City"
                              name="city"
                              inputRef={cityRef}
                              error={errors}
                              placeholder="(i.e. Atlanta)"
                            />
                            <AutocompleteSelect
                              label="State"
                              name="state"
                              placeholder="Select"
                              selectedOption="Select"
                              options={states.map((state) => state.abbreviated)}
                              error={errors}
                              inputRef={stateRef}
                            />
                            <AutocompleteInput
                              label="ZIP Code"
                              name="zipcode"
                              inputRef={zipCodeRef}
                              errorMessage={zipCodeError}
                              onChange={zipCodeOnChange}
                              placeholder="5-digit postal code"
                            />
                          </Card>
                          <Card id="listingDetails">
                            <FormTitle>LISTING DETAILS</FormTitle>
                            <LableComponent
                              variantType="primary"
                              label="Select property type:"
                              name="classification"
                            />
                            <InputRadio
                              name="classification"
                              register={register}
                              error={errors}
                              options={propertyType.slice(1).map((item) => ({
                                name: item.label,
                                value: item.type,
                              }))}
                              registerFields={{ required: true }}
                            />
                            <InputRadio
                              label="Is this property available for rent or for sale?"
                              name="category"
                              register={register}
                              error={errors}
                              options={category}
                              disabled={categoryDisabled}
                              registerFields={{ required: true }}
                            />
                            {isEditPage || categoryState ? (
                              <>
                                <InputTextComponent
                                  textAtBeginning="$"
                                  labelType="primary"
                                  label="Monthly Rent ($)"
                                  name="price"
                                  type="number"
                                  register={register}
                                  error={errors}
                                  placeholder=""
                                />
                                <InputTextComponent
                                  textAtBeginning="$"
                                  labelType="primary"
                                  label="Security Deposit ($)"
                                  name="deposit"
                                  type="number"
                                  register={register}
                                  error={errors}
                                  placeholder=""
                                />
                                <InputDateComponent
                                  labelType="primary"
                                  label="Available Move-In Date"
                                  name="dateAvailableTs"
                                  register={register}
                                  error={errors}
                                  placeholder=""
                                />
                                <LableComponent
                                  variantType="primary"
                                  label="Lease Term Options"
                                  name="LeaseTermOptions"
                                />

                                <InputCheckbox
                                  name="leaseTermOptions"
                                  register={register}
                                  options={LeaseTermOptions.map((option) => ({
                                    name: option.value,
                                    value: option.value,
                                  }))}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Card>
                          <Card id="propertyImages">
                            <FormTitle>PROPERTY IMAGES</FormTitle>
                            <InputImages
                              description="Upload images of your property"
                              files={propertyImages}
                              onFilesChange={(files) =>
                                setPropertyImages(files)
                              }
                            />
                          </Card>
                        </CardList>
                      </Col>
                    </Row>
                  </FormContainer>
                  <FormContainer>
                    <Row>
                      <Col xl={4}>
                        <LeftSidebar ref={section2Ref}>
                          <LeftSidebarTitle>
                            <span className="tags">Section 2 of 5</span>
                          </LeftSidebarTitle>
                          <LeftSidebarTitle>
                            <h3>Specifications & Fees</h3>
                          </LeftSidebarTitle>
                          <LeftSidebarTitle>
                            <span className="body-s">
                              This information will be visible to prospects on
                              the Prospect Booking page.
                            </span>
                          </LeftSidebarTitle>
                        </LeftSidebar>
                      </Col>

                      <Col xl={8}>
                        <CardList>
                          <Card id="propertySize">
                            <FormTitle>Property Size & Description</FormTitle>
                            <InputTextComponent
                              textAtEnd="sqft"
                              labelType="primary"
                              label="Sqft"
                              name="sqft"
                              type="number"
                              register={register}
                              error={errors}
                              placeholder=""
                            />

                            <InputSelectComponent
                              label="# of Bedrooms"
                              name="bedrooms"
                              register={register}
                              error={errors}
                              options={Array.from({ length: 6 }, (_, i) =>
                                (i + 1).toString()
                              )}
                              selectedOption="Select"
                              placeholder="Select"
                            />
                            <InputSelectComponent
                              label="# of Bathrooms"
                              name="bathrooms"
                              register={register}
                              error={errors}
                              options={Array.from({ length: 14 }, (_, index) =>
                                (index === 0 ? 0 : 0.5 * index).toString()
                              )}
                              selectedOption="Select"
                              placeholder="Select"
                            />

                            <InputTextAreaComponent
                              labelType="secondary"
                              label="Property Description (Optional)"
                              name="propertyDescription"
                              register={register}
                              placeholder="Enter property description here..."
                            />
                          </Card>
                          <Card id="utilities">
                            <FormTitle>UTILITIES</FormTitle>
                            <Row>
                              <Col xl={5} md={5}>
                                <span className="body-s-bold">Water</span>
                              </Col>
                              <Col
                                xl={7}
                                md={7}
                                className="d-flex justify-content-start justify-content-lg-end"
                              >
                                <InputRadioHorizontal
                                  name="utilities.water"
                                  register={register}
                                  error={errors}
                                  options={["included", "tenant"]}
                                  labels={["Included in Rent", "Tenant Pays"]}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={5} md={5}>
                                <span className="body-s-bold">Electricity</span>
                              </Col>
                              <Col
                                xl={7}
                                md={7}
                                className="d-flex justify-content-start justify-content-lg-end"
                              >
                                <InputRadioHorizontal
                                  name="utilities.electricity"
                                  register={register}
                                  error={errors}
                                  options={["included", "tenant"]}
                                  labels={["Included in Rent", "Tenant Pays"]}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={5} md={5}>
                                <span className="body-s-bold">Gas</span>
                              </Col>
                              <Col
                                xl={7}
                                md={7}
                                className="d-flex justify-content-start justify-content-lg-end"
                              >
                                <InputRadioHorizontal
                                  name="utilities.gas"
                                  register={register}
                                  error={errors}
                                  options={["included", "tenant"]}
                                  labels={["Included in Rent", "Tenant Pays"]}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={5} md={5}>
                                <span className="body-s-bold">Trash</span>
                              </Col>
                              <Col
                                xl={7}
                                md={7}
                                className="d-flex justify-content-start justify-content-lg-end"
                              >
                                <InputRadioHorizontal
                                  name="utilities.trash"
                                  register={register}
                                  error={errors}
                                  options={["included", "tenant"]}
                                  labels={["Included in Rent", "Tenant Pays"]}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={5} md={5}>
                                <span className="body-s-bold">Sewage</span>
                              </Col>
                              <Col
                                xl={7}
                                md={7}
                                className="d-flex justify-content-start justify-content-lg-end"
                              >
                                <InputRadioHorizontal
                                  name="utilities.sewage"
                                  register={register}
                                  error={errors}
                                  options={["included", "tenant"]}
                                  labels={["Included in Rent", "Tenant Pays"]}
                                />
                              </Col>
                            </Row>
                            <InputTextAreaComponent
                              labelType="secondary"
                              label="Utilities Notes (Optional)"
                              name="utilities.notes"
                              register={register}
                              placeholder="Enter any additional notes here..."
                            />
                          </Card>
                          <Card id="parking">
                            <FormTitle>PARKING</FormTitle>
                            <LableComponent
                              variantType="primary"
                              label="Parking Type"
                              name="parking.type"
                            />
                            {parkingType.map((type) => (
                              <Row key={type.primary.value}>
                                <Col
                                  xl={8}
                                  className="d-flex align-items-center justify-content-between"
                                >
                                  <InputRadio
                                    name="parking.type"
                                    register={register}
                                    options={[type.primary]}
                                    onChange={(e) => {
                                      setParkingSelected(e.target.value);
                                      setParkingTandem(false);
                                    }}
                                  />
                                </Col>
                                {type.extra && (
                                  <Col
                                    xl={4}
                                    className="d-flex gap-2 justify-content-start justify-content-xl-end flex-column flex-lg-row pt-3 pt-xl-0"
                                  >
                                    <InputCheckbox
                                      name="parking.tandem"
                                      register={register}
                                      options={[{ name: type.extra.name }]}
                                      onChange={(e) =>
                                        setParkingTandem(e.target.checked)
                                      }
                                      checked={parkingTandem}
                                      disabled={
                                        !(
                                          parkingSelected === type.primary.value
                                        )
                                      }
                                    />
                                  </Col>
                                )}
                              </Row>
                            ))}
                            <InputSelectComponent
                              label="# of Parking Spaces"
                              name="parking.spacesNumber"
                              register={register}
                              error={errors}
                              options={Array.from({ length: 6 }, (_, i) =>
                                (i + 1).toString()
                              )}
                              selectedOption="Select"
                              placeholder="Select"
                              disabled={
                                !(
                                  parkingSelected === "secured" ||
                                  parkingSelected === "covered" ||
                                  parkingSelected === "uncovered"
                                )
                              }
                            />
                            <Row>
                              <Col xl={8}>
                                <InputTextComponent
                                  label="Monthly Cost Per Space"
                                  type="number"
                                  name="parking.monthlyCostPerSpace"
                                  register={register}
                                  placeholder="$ Enter dollar amount"
                                  disabled={
                                    !(
                                      parkingSelected === "secured" ||
                                      parkingSelected === "covered" ||
                                      parkingSelected === "uncovered"
                                    ) || parkingIncluded
                                  }
                                />
                              </Col>
                              <Col
                                xl={4}
                                className="d-flex align-items-end justify-content-start justify-content-lg-end pb-2 pt-3 pt-xl-0"
                              >
                                <InputCheckbox
                                  name="parking.included"
                                  register={register}
                                  options={[{ name: "Included in Rent" }]}
                                  disabled={
                                    !(
                                      parkingSelected === "secured" ||
                                      parkingSelected === "covered" ||
                                      parkingSelected === "uncovered"
                                    )
                                  }
                                  changedValue={(value, checked) => {
                                    setParkingIncluded(checked);
                                  }}
                                />
                              </Col>
                            </Row>
                            <InputTextAreaComponent
                              labelType="secondary"
                              label="Parking Notes (Optional)"
                              name="parking.notes"
                              register={register}
                              placeholder="Enter any additional notes here..."
                            />
                          </Card>
                          <Card id="petPolicies">
                            <FormTitle>PET POLICIES</FormTitle>
                            <LableComponent
                              variantType="primary"
                              label="Pets Allowed?"
                              name="petsAllowed"
                            />
                            <InputCheckbox
                              name="pets.allowed"
                              register={register}
                              options={[pets[0]]}
                              error={errors}
                              disabled={
                                petsAllowedStatus.dogs || petsAllowedStatus.cats
                              }
                              onChange={(e) => {
                                setPetsAllowedStatus({
                                  ...petsAllowedStatus,
                                  no: e.target.checked,
                                });
                              }}
                            />
                            <InputCheckbox
                              name="pets.dogs.allowed"
                              register={register}
                              options={[pets[1]]}
                              error={errors}
                              disabled={petsAllowedStatus.no}
                              onChange={(e) => {
                                setPetsAllowedStatus({
                                  ...petsAllowedStatus,
                                  dogs: e.target.checked,
                                });
                              }}
                            />
                            <InputCheckbox
                              name="pets.cats.allowed"
                              register={register}
                              options={[pets[2]]}
                              error={errors}
                              disabled={petsAllowedStatus.no}
                              onChange={(e) => {
                                setPetsAllowedStatus({
                                  ...petsAllowedStatus,
                                  cats: e.target.checked,
                                });
                              }}
                            />

                            <InputTextComponent
                              label="Pet Deposit"
                              name="pets.deposit"
                              type="number"
                              register={register}
                              placeholder="$ Enter dollar amount"
                              disabled={petsAllowedStatus.no}
                            />
                            <InputTextComponent
                              label="Monthly Pet Rent"
                              name="pets.monthlyPetRent"
                              type="number"
                              register={register}
                              placeholder="$ Enter dollar amount"
                              disabled={petsAllowedStatus.no}
                            />

                            <InputTextAreaComponent
                              labelType="secondary"
                              label="Pet Policy Notes (Optional)"
                              name="pets.notes"
                              register={register}
                              placeholder="Enter any additional notes here..."
                            />
                            <div
                              className={
                                petsAllowedStatus.dogs
                                  ? "d-flex flex-column gap-3"
                                  : "d-none"
                              }
                            >
                              <h5 className="d-flex pb-2 align-items-start border-bottom border-dark">
                                Dogs
                              </h5>
                              <div
                                className="d-flex flex-column align-items-start"
                                style={{ gap: "32px" }}
                              >
                                <InputTextComponent
                                  textAtEnd="lbs"
                                  label="Weight Limit (Optional)"
                                  name="pets.dogs.weight"
                                  type="number"
                                  register={register}
                                  placeholder="Enter max weight"
                                />
                                <InputTextComponent
                                  label="Max # of dogs Allowed (Optional)"
                                  name="pets.dogs.maxAllowed"
                                  type="number"
                                  register={register}
                                  placeholder="Enter numeric value"
                                />
                              </div>
                            </div>
                            <div
                              className={
                                petsAllowedStatus.cats
                                  ? "d-flex flex-column gap-3"
                                  : "d-none"
                              }
                            >
                              <h5 className="d-flex pb-2 align-items-start border-bottom border-dark">
                                Cats
                              </h5>
                              <div
                                className="d-flex flex-column align-items-start"
                                style={{ gap: "32px" }}
                              >
                                <InputTextComponent
                                  textAtEnd="lbs"
                                  label="Weight Limit (Optional)"
                                  name="pets.cats.weight"
                                  type="number"
                                  register={register}
                                  placeholder="Enter max weight"
                                />
                                <InputTextComponent
                                  label="Max # of dogs Allowed (Optional)"
                                  name="pets.cats.maxAllowed"
                                  type="number"
                                  register={register}
                                  placeholder="Enter numeric value"
                                />
                              </div>
                            </div>
                          </Card>
                        </CardList>
                      </Col>
                    </Row>
                  </FormContainer>
                  <FormContainer>
                    <Row>
                      <Col xl={4}>
                        <LeftSidebar ref={section3Ref}>
                          <LeftSidebarTitle>
                            <span className="tags">Section 3 of 5</span>
                          </LeftSidebarTitle>
                          <LeftSidebarTitle>
                            <h3>Amenities & Features</h3>
                          </LeftSidebarTitle>

                          <LeftSidebarTitle>
                            <span className="body-s">
                              This information will be visible to prospects on
                              the Prospect Booking page.
                            </span>
                          </LeftSidebarTitle>
                        </LeftSidebar>
                      </Col>
                      <Col xl={8}>
                        <CardList>
                          <Card>
                            <FormTitle>AMENITIES & FEATURES</FormTitle>
                            {amenities.map((amenity) => (
                              <InputCheckbox
                                key={amenity.label}
                                label={amenity.label}
                                name={`amenitiesAndFeatures.${amenity.name}`}
                                register={register}
                                options={amenity.options.map((option) => ({
                                  name: option,
                                  value: option,
                                }))}
                                error={errors}
                              />
                            ))}
                          </Card>
                        </CardList>
                      </Col>
                    </Row>
                  </FormContainer>
                  <FormContainer>
                    <Row>
                      <Col xl={4}>
                        <LeftSidebar ref={section4Ref}>
                          <LeftSidebarTitle>
                            <span className="tags">Section 4 of 5</span>
                          </LeftSidebarTitle>
                          <LeftSidebarTitle>
                            <h3>Delet System Settings</h3>
                          </LeftSidebarTitle>

                          <LeftSidebarTitle>
                            <span className="body-s">
                              These settings allow you to specify the Delet
                              Hardware Kit used for this property and what kinds
                              of information they receive in automated email and
                              SMS communications prior to their self-guided tour
                              appointments.
                            </span>
                          </LeftSidebarTitle>
                        </LeftSidebar>
                      </Col>
                      <Col xl={8}>
                        <CardList>
                          <Card id="petPolicies">
                            <FormTitle>DELET HARDWARE KIT</FormTitle>
                            <FormSubTitle>
                              NOTE: Skip this item if you’re not ready to select
                              a Hardware Kit at this time. You can always change
                              or modify your Hardware Kit settings after
                              submitting this form.
                            </FormSubTitle>
                            {kitsLoaded ? (
                              <InputSelectComponent
                                variantType="secondary"
                                label={`Select Hardware Kit used for this property (Optional)`}
                                name="kit"
                                register={register}
                                error={errors}
                                options={
                                  kits &&
                                  kits.map((kit) => {
                                    return {
                                      description: kit.name,
                                      id: kit._id,
                                    };
                                  })
                                }
                                placeholder="Select"
                              />
                            ) : (
                              <div className="d-flex justify-content-start align-items-center gap-2">
                                <Loader />
                                <span className="body-s-bold ms-2">
                                  Loading kits...
                                </span>
                              </div>
                            )}
                          </Card>
                          <Card>
                            <FormTitle>
                              PROSPECT IDENTITY VERIFICATION
                            </FormTitle>
                            <FormSubTitle>
                              Prospect identity verification is highly
                              recommended and enabled by default as an enhanced
                              security measure.{" "}
                              <span className="fw-bold">Disabling</span> this
                              setting will allow prospects to book appointments
                              without submitting a photo ID.
                            </FormSubTitle>
                            <LableComponent
                              variantType="primary"
                              label="ID/Driver’s License Verification"
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="body col-10">
                                Require prospects to submit a photo ID or driver’s license for verification prior to booking an appointment.
                              </span>
                              <InputSwitchComponent
                                id="idVerification.active"
                                checked={switchState.idVerification.active}
                                onChange={(e) =>
                                  setSwitchState((prev) => ({
                                    ...prev,
                                    idVerification: {
                                      ...prev.idVerification,
                                      active: e.target.checked,
                                    },
                                  }))
                                }
                              />
                            </div>
                            <Row>
                              <Col
                                xl={10}
                                className="d-flex gap-2 justify-content-start justify-content-xl-end flex-column flex-lg-row"
                              ></Col>
                              <Col
                                xl={2}
                                className="d-flex align-items-center"
                              ></Col>
                              {switchState.idVerification.active ? (
                                <div className="d-flex flex-column gap-2 pt-2">
                                  <h4>Request</h4>

                                  <div className="d-flex justify-content-between pe-none">
                                    <span className="">
                                      Front of document (Mandatory)
                                    </span>
                                    <InputSwitchComponent
                                      id="front-and-back-id"
                                      checked={true}
                                      disabled={true}
                                    />
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <span className="">
                                      Require selfie photo
                                    </span>
                                    <InputSwitchComponent
                                      id="idVerification.face"
                                      checked={
                                        switchState.idVerification.requireFace
                                      }
                                      onChange={(e) =>
                                        setSwitchState((prev) => ({
                                          ...prev,
                                          idVerification: {
                                            ...prev.idVerification,
                                            requireFace: e.target.checked,
                                          },
                                        }))
                                      }
                                    />
                                  </div>

                                  <h4>Security Level Analysis</h4>

                                  <UnstyledButton
                                  className="d-none"
                                    onClick={(e) =>
                                      setSwitchState((prev) => ({
                                        ...prev,
                                        idVerification: {
                                          ...prev.idVerification,
                                          profile: "high",
                                        },
                                      }))
                                    }
                                  >
                                    <SecurityLevelOption
                                      badge={
                                        <IoShield size={60} color="#5271FF" />
                                      }
                                      description="Performs in-depth analysis, looking for subtle signs of sophisticated editing or forgery, ensuring the highest level of security."
                                      level="Hig"
                                      selected={
                                        switchState.idVerification.profile ===
                                        "high"
                                      }
                                    />
                                  </UnstyledButton>

                                  <UnstyledButton
                                  className="d-none"
                                    onClick={(e) =>
                                      setSwitchState((prev) => ({
                                        ...prev,
                                        idVerification: {
                                          ...prev.idVerification,
                                          profile: "medium",
                                        },
                                      }))
                                    }
                                  >
                                    <SecurityLevelOption
                                      badge={
                                        <IoShieldHalf
                                          size={60}
                                          color="#5271FF"
                                        />
                                      }
                                      description="Includes more thorough checks for common types of alterations and inconsistencies, providing a balanced level of security."
                                      level="Medium"
                                      selected={
                                        switchState.idVerification.profile ===
                                        "medium"
                                      }
                                    />
                                  </UnstyledButton>

                                  <UnstyledButton
                                    onClick={(e) =>
                                      setSwitchState((prev) => ({
                                        ...prev,
                                        idVerification: {
                                          ...prev.idVerification,
                                          profile: "low",
                                        },
                                      }))
                                    }
                                  >
                                    <SecurityLevelOption
                                      badge={
                                        <IoShieldOutline
                                          size={60}
                                          color="#5271FF"
                                        />
                                      }
                                      description="Checks the photo for integrity and signs of tampering or fakes."
                                      level="On"
                                      selected={
                                        switchState.idVerification.profile ===
                                        "low"
                                      }
                                    />
                                  </UnstyledButton>

                                  <UnstyledButton
                                    onClick={(e) =>
                                      setSwitchState((prev) => ({
                                        ...prev,
                                        idVerification: {
                                          ...prev.idVerification,
                                          profile: "none",
                                        },
                                      }))
                                    }
                                  >
                                    <SecurityLevelOption
                                      badge={
                                        <FiShieldOff
                                          size={60}
                                          color="#5271FF"
                                        />
                                      }
                                      description="Doesn't check for fakes, it just makes sure is a US ID, doesn't analyze the face photo."
                                      level="Off"
                                      selected={
                                        switchState.idVerification.profile ===
                                        "none"
                                      }
                                    />
                                  </UnstyledButton>
                                </div>
                              ) : null}
                            </Row>
                          </Card>
                          <Card id="">
                            <FormTitle>
                              Access Controls and Automated Communications
                            </FormTitle>
                            <FormSubTitle>
                              Use the following settings to specify the
                              information prospects receive in automated email
                              and SMS communications prior to their
                              appointments. Please note that prospects will
                              receive all enabled information in a single email
                              and text message.
                            </FormSubTitle>
                            <LableComponent
                              variantType="primary"
                              label="Select the information to include in automated email & SMS communications:"
                              name="accessInstructions"
                            />
                            <Row className="gap-2 gap-xl-0">
                              <Col xl={2} className="d-flex align-items-center">
                                <InputSwitchComponent
                                  id="doorUnlockLink"
                                  checked={
                                    property.kit
                                      ? switchState.doorUnlock
                                      : false
                                  }
                                  disabled={!property.kit}
                                  onChange={(e) =>
                                    setSwitchState((prev) => ({
                                      ...prev,
                                      doorUnlock: e.target.checked,
                                    }))
                                  }
                                />
                              </Col>

                              <Col
                                xl={7}
                                className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-center"
                              >
                                <span className="body">
                                  Door Unlock Link (for Delet SmartLock)
                                </span>
                              </Col>
                              <Col
                                xl={3}
                                className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-center"
                              >
                                <span className="tags">
                                  {property.kit
                                    ? "(Recommended)"
                                    : "(Assign kit to unlock)"}
                                </span>
                              </Col>
                            </Row>
                            <Row className="gap-2 gap-xl-0">
                              <Col xl={2} className="d-flex align-items-center">
                                <InputSwitchComponent
                                  id="propertyAccessCodes.enable"
                                  checked={switchState.propertyAccess}
                                  onChange={(e) =>
                                    setSwitchState((prev) => ({
                                      ...prev,
                                      propertyAccess: e.target.checked,
                                    }))
                                  }
                                />
                              </Col>
                              <Col
                                xl={7}
                                className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-center"
                              >
                                <span className="body">
                                  Property Access Codes & Instructions
                                </span>
                              </Col>
                              <Col
                                xl={3}
                                className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-center"
                              >
                                <span className="tags">(Recommended)</span>
                              </Col>
                            </Row>
                            {switchState.propertyAccess && (
                              <div className="d-flex flex-column gap-2">
                                <span className="body-s-bold">
                                  Do prospects need a security code to access
                                  the front entrance or use the elevators in
                                  this building?
                                </span>
                                <InputTextComponent
                                  label="Building Access Code (Optional)"
                                  name="propertyAccessCodes.buildingCode"
                                  register={register}
                                  error={errors}
                                  placeholder="Security code for the front entrance"
                                />
                                <InputTextComponent
                                  label="Elevator Code (Optional)"
                                  name="propertyAccessCodes.elevatorCode"
                                  register={register}
                                  error={errors}
                                  placeholder="Security code for the elevator"
                                />
                                <InputTextAreaComponent
                                  label="Please provide any additional instructions that prospects may need to find and access the property (Optional)"
                                  name="propertyAccessCodes.instructions"
                                  register={register}
                                  placeholder="Enter additional access informations here"
                                />
                              </div>
                            )}
                            <Row className="gap-2 gap-xl-0">
                              <Col xl={2} className="d-flex align-items-center">
                                <InputSwitchComponent
                                  id="rentalApplicationForm.enable"
                                  checked={switchState.rentalApplication}
                                  onChange={(e) =>
                                    setSwitchState((prev) => ({
                                      ...prev,
                                      rentalApplication: e.target.checked,
                                    }))
                                  }
                                />
                              </Col>
                              <Col
                                xl={7}
                                className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-center"
                              >
                                <span className="body">
                                  Rental Application Form & Instructions
                                </span>
                              </Col>
                              <Col
                                xl={3}
                                className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-center"
                              >
                                <span className="tags">(Recommended)</span>
                              </Col>
                              <div
                                className={
                                  switchState.rentalApplication
                                    ? "d-flex flex-column gap-3 pt-3"
                                    : "d-none"
                                }
                              >
                                <div
                                  className="d-flex flex-column align-items-start"
                                  style={{ gap: "16px" }}
                                >
                                  <InputTextComponent
                                    // textAtEnd='lbs'
                                    label="Application URL"
                                    name="rentalApplicationForm.url"
                                    type="text"
                                    register={register}
                                    placeholder="https://applicationlink.com"
                                  />
                                  <InputTextComponent
                                    textAtBeginning="$"
                                    labelType="secondary"
                                    label="Application Fee (if applicable)"
                                    name="rentalApplicationForm.fee"
                                    type="number"
                                    register={register}
                                    placeholder="Enter application fee"
                                  />
                                  <InputTextAreaComponent
                                    labelType="secondary"
                                    label="Application Instructions (Optional)"
                                    name="rentalApplicationForm.instructions"
                                    register={register}
                                    placeholder="Enter application instructions here..."
                                  />
                                </div>
                              </div>
                            </Row>
                          </Card>
                        </CardList>
                      </Col>
                    </Row>
                  </FormContainer>
                  <FormContainer>
                    <Row>
                      <Col xl={4}>
                        <LeftSidebar ref={section5Ref}>
                          <LeftSidebarTitle>
                            <span className="tags">Section 5 of 5</span>
                          </LeftSidebarTitle>
                          <LeftSidebarTitle>
                            <h3>Property Management Contact Information</h3>
                          </LeftSidebarTitle>

                          <LeftSidebarTitle>
                            <span className="body-s">
                              By default, the information entered in this
                              section will only be visible to you. You have the
                              option to share contact information with prospects
                              via automated communications.
                            </span>
                          </LeftSidebarTitle>
                        </LeftSidebar>
                      </Col>
                      <Col xl={8}>
                        <CardList>
                          <Card id="">
                            <FormTitle>COMPANY CONTACT INFORMATION</FormTitle>
                            <InputTextComponent
                              label="Property Management Group"
                              name="company.name"
                              register={register}
                              error={errors}
                              placeholder="Enter company name"
                            />
                            <InputTextComponent
                              labelType="secondary"
                              label="Company Website (Optional)"
                              name="company.website"
                              register={register}
                              error={errors}
                              placeholder="Enter website URL"
                            />
                            <InputTextComponent
                              labelType="secondary"
                              label="Company Phone Number (Optional)"
                              name="company.phone"
                              register={register}
                              error={errors}
                              placeholder="+1 (555) 555-5555"
                            />
                            <Row>
                              <Col xl={2} className="d-flex align-items-center">
                                <InputSwitchComponent
                                  id="company.contactInformation"
                                  checked={switchState.company}
                                  onChange={(e) =>
                                    setSwitchState((prev) => ({
                                      ...prev,
                                      company: e.target.checked,
                                    }))
                                  }
                                />
                              </Col>
                              <Col
                                xl={10}
                                className="d-flex gap-2 justify-content-start justify-content-xl-end flex-column flex-lg-row"
                              >
                                <span className="body">
                                  Include Company contact information in
                                  automated prospect communications
                                  (RECOMMENDED)
                                </span>
                              </Col>
                            </Row>
                          </Card>
                          <Card>
                            <FormTitle>
                              Leasing Agent Contact Information
                            </FormTitle>
                            <Row className="gap-2 gap-xl-0">
                              <Col xl={6} className="d-flex align-items-center">
                                <InputTextComponent
                                  labelType="secondary"
                                  label="Name (Optional)"
                                  name="leasingAgent.firstName"
                                  register={register}
                                  error={errors}
                                  placeholder="First Name"
                                />
                              </Col>
                              <Col
                                xl={6}
                                className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-end"
                              >
                                <InputTextComponent
                                  name="leasingAgent.lastName"
                                  register={register}
                                  error={errors}
                                  placeholder="Last Name"
                                />
                              </Col>
                            </Row>

                            <InputTextComponent
                              labelType="secondary"
                              label="Phone Number (Optional)"
                              name="leasingAgent.phone"
                              register={register}
                              error={errors}
                              placeholder="+1 (555) 555-5555"
                            />
                            <InputTextComponent
                              labelType="secondary"
                              label="Email (Optional)"
                              name="leasingAgent.email"
                              type="email"
                              register={register}
                              error={errors}
                              placeholder="example@email.com"
                            />
                            <Row>
                              <Col xl={2} className="d-flex align-items-center">
                                <InputSwitchComponent
                                  id="leasingAgent.contactInformation"
                                  checked={switchState.leasing}
                                  onChange={(e) =>
                                    setSwitchState((prev) => ({
                                      ...prev,
                                      leasing: e.target.checked,
                                    }))
                                  }
                                />
                              </Col>
                              <Col
                                xl={10}
                                className="d-flex gap-2 justify-content-start justify-content-xl-end flex-column flex-lg-row"
                              >
                                <span className="body">
                                  Include Leasing Agent contact information in
                                  automated prospect communications
                                  (RECOMMENDED)
                                </span>
                              </Col>
                            </Row>
                          </Card>
                          <Card>
                            <FormTitle>
                              PROPERTY MANAGER CONTACT INFORMATION
                            </FormTitle>
                            <InputRadio
                              label="Property/Building Manager on site?"
                              name="propertyManager.onSite"
                              register={register}
                              error={errors}
                              options={[
                                { name: "Yes", value: "true" },
                                { name: "No", value: "false" },
                              ]}
                            />
                            <Row className="gap-2 gap-xl-0">
                              <Col xl={6} className="d-flex align-items-center">
                                <InputTextComponent
                                  labelType="secondary"
                                  label="Name (Optional)"
                                  name="propertyManager.firstName"
                                  register={register}
                                  error={errors}
                                  placeholder="First Name"
                                />
                              </Col>
                              <Col
                                xl={6}
                                className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-end"
                              >
                                <InputTextComponent
                                  name="propertyManager.lastName"
                                  register={register}
                                  error={errors}
                                  placeholder="Last Name"
                                />
                              </Col>
                            </Row>

                            <InputTextComponent
                              labelType="secondary"
                              label="Phone Number (Optional)"
                              name="propertyManager.phone"
                              register={register}
                              error={errors}
                              placeholder="+1 (555) 555-5555"
                            />
                            <InputTextComponent
                              labelType="secondary"
                              label="Email (Optional)"
                              name="propertyManager.email"
                              type="email"
                              register={register}
                              error={errors}
                              placeholder="example@email.com"
                            />
                            <Row>
                              <Col xl={2} className="d-flex align-items-center">
                                <InputSwitchComponent
                                  id="propertyManager.contactInformation"
                                  checked={switchState.property}
                                  onChange={(e) =>
                                    setSwitchState((prev) => ({
                                      ...prev,
                                      property: e.target.checked,
                                    }))
                                  }
                                />
                              </Col>
                              <Col
                                xl={10}
                                className="d-flex gap-2 justify-content-start justify-content-xl-end flex-column flex-lg-row"
                              >
                                <span className="body">
                                  Include Property Manager contact information
                                  in automated prospect communications
                                </span>
                              </Col>
                            </Row>
                          </Card>
                          <Card>
                            <FormTitle>
                              Maintenance Manager Contact Information
                            </FormTitle>
                            <InputRadio
                              variantType="secondary"
                              label="Maintenance Manager on site?"
                              name="maintenanceManager.onSite"
                              register={register}
                              error={errors}
                              options={[
                                { name: "Yes", value: "true" },
                                { name: "No", value: "false" },
                              ]}
                            />
                            <Row className="gap-2 gap-xl-0">
                              <Col xl={6} className="d-flex align-items-center">
                                <InputTextComponent
                                  labelType="secondary"
                                  label="Name (Optional)"
                                  name="maintenanceManager.firstName"
                                  register={register}
                                  error={errors}
                                  placeholder="First Name"
                                />
                              </Col>
                              <Col
                                xl={6}
                                className="d-flex justify-content-start justify-content-xl-end flex-column flex-lg-row align-items-start align-items-xl-end"
                              >
                                <InputTextComponent
                                  name="maintenanceManager.lastName"
                                  register={register}
                                  error={errors}
                                  placeholder="Last Name"
                                />
                              </Col>
                            </Row>

                            <InputTextComponent
                              labelType="secondary"
                              label="Phone Number (Optional)"
                              name="maintenanceManager.phone"
                              register={register}
                              error={errors}
                              placeholder="+1 (555) 555-5555"
                            />
                            <InputTextComponent
                              labelType="secondary"
                              label="Email (Optional)"
                              name="maintenanceManager.email"
                              type="email"
                              register={register}
                              error={errors}
                              placeholder="example@email.com"
                            />
                            <Row>
                              <Col xl={2} className="d-flex align-items-center">
                                <InputSwitchComponent
                                  id="maintenanceManager.contactInformation"
                                  checked={switchState.maintenance}
                                  onChange={(e) =>
                                    setSwitchState((prev) => ({
                                      ...prev,
                                      maintenance: e.target.checked,
                                    }))
                                  }
                                />
                              </Col>
                              <Col
                                xl={10}
                                className="d-flex gap-2 justify-content-start justify-content-xl-end flex-column flex-lg-row"
                              >
                                <span className="body">
                                  Include Maintenance Manager contact
                                  information in automated prospect
                                  communications
                                </span>
                              </Col>
                            </Row>
                          </Card>
                          <Row className="gap-3 gap-xl-0 m-2">
                            <Col
                              xl={6}
                              className="d-flex gap-2 justify-content-center justify-content-xl-start flex-column flex-lg-row"
                            >
                              <InputSubmit
                                type="submit"
                                value={
                                  isEditPage
                                    ? "Save Changes"
                                    : "Create New Property"
                                }
                                disabled={creatingProperty}
                              />
                            </Col>
                            <Col
                              xl={6}
                              className="d-flex gap-2 justify-content-center justify-content-xl-end flex-column flex-lg-row"
                            >
                              <Button
                                variantType="secondary"
                                to="/property/list"
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </CardList>
                      </Col>
                    </Row>
                  </FormContainer>
                </form>
              </FormProvider>
            </div>
          </PageContent>
        </Container>
      </Sidebar>
    </>
  );
};

export default PropertiesForm;
