import './Cameras.scss';
import React, {
  useEffect,
  useState,
  useRef
} from 'react';

import axios from 'axios';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import Sidebar from '../../components/SideBar';
import ReactPlayer from 'react-player';
import Toast from 'react-bootstrap/Toast';
import PaginatedTable from './PaginatedTable';
import { useProperty } from '../../context/PropertyContext';
import cameraImage from '../../assests/images/camera-1.png';
import fullScreenImage from '../../assests/images/full-screen.png';
import OnlineTag from '../../components/OnlineTag';
import placeholderImage from '../../assests/images/1-placeholder-X2.png';
import removeUndefinedWord from '../../helper/updateString';
import { Center, Loader } from '@mantine/core';

const StyledCard = styled.div`
    display: flex;
    padding: 0;
    flex-direction: column;
    gap: 16px;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    `

const Cameras = () => {
  // alert modal
  const intervalRef = useRef({});
  const [showAlert, setShowAlert] = useState(false)
  const navigate = useNavigate()
  const [ProperyList, setProperyList] = useState([])
  const [cameras, setCameras] = useState([])
  const [camerasAll, setCamerasAll] = useState([])
  const [q, setQ] = useState('')
  const [CameraUrl, setCameraUrl] = useState([])
  const [kits, setkits] = useState([])
  const [KitID, setKitID] = useState('')
  const [PropertyAddress, setPropertyAddress] = useState('')
  const [searchParam] = useState(['name'])
  const [loading, setLoader] = useState(false)
  const [cameraLoading, setCameraLoading] = useState(true);
  const [showToast, setShowToast] = useState({});
  // const { cameras, CameraUrl, cameraLoading, showToast, setShowToast, getStreamUrl, clearAllIntervals } = useProperty();

  useEffect(() => {
    getPropertyList()
    getcamerasList()
    gethardwarekits()
  }, [])

  function getPropertyList() {
    Auth.currentSession()
      .then((token) => {
        axios
          .get(
            `${process.env.REACT_APP_NODE_API_URL}/api/property`,
            { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } }
          )
          .then((response) => {
            if (response.status === 200) {
              setProperyList(response.data.properties)
              setLoader(true)
            } else {
              setShowAlert(true)
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              Auth.signOut().then(() => {
                navigate('/')
              }).catch((error) => {
                console.log('error signing out: ', error);
              })
            } else {
              setShowAlert(true)
            }
          })
      })
      .catch((error) => {
        Auth.signOut()
          .then(() => {
            navigate('/')
          })
          .catch((error) => {
            console.log('error signing out: ', error);
          })
      })
  }
  function gethardwarekits() {
    Auth.currentSession()
      .then((token) => {
        axios
          .get(
            `${process.env.REACT_APP_NODE_API_URL}/api/kits`,
            { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } }
          )
          .then((response) => {
            if (response.status === 200) {
              setkits(response.data)
              setLoader(true)
            } else {
              setShowAlert(true)
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              Auth.signOut().then(() => {
                navigate('/')
              }).catch((error) => {
                console.log('Error signing out: ', error);
              })
            } else {
              setShowAlert(true)
            }
          })
      }).catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/')
          }).catch((error) => {
            console.log('Error signing out: ', error);
          })
        } else {
          setShowAlert(true);
        }
      });
  }

  function getcamerasList() {
    Auth.currentSession()
      .then((token) => {
        axios
          .get(
            `${process.env.REACT_APP_NODE_API_URL}/api/hardware/list/cameras/stream/start`,
            { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } }
          )
          .then((response) => {
            if (response.status === 200) {
              setCameras(response.data)
              setCamerasAll(response.data)
              var cameraresponse = response.data
              const newArray = cameraresponse.map(obj => ({ [obj._id]: "Stream error" }));
              setCameraUrl(() => newArray);
              const toastObj = {};
              cameraresponse.forEach((element) => {
                toastObj[element._id] = false;
              });
              setShowToast(toastObj)
              // cameraresponse.map((element) => getStreamUrl(element))
            } else {
              setShowAlert(true)
            }
          })
          .catch((error) => {
            if (error.response) {
              setShowAlert(true)
            } else {
              setShowAlert(true)
            }
          })
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/')
          }).catch((error) => {
            console.log('Error signing out: ', error);
          })
        } else {
          setShowAlert(true);
        }
      });
  }

  const clearAllIntervals = () => {
    Object.values(intervalRef.current).forEach((interval) => {
      clearInterval(interval);
    });
  };

  useEffect(() => {
    // Starting to poll for camera URLs when this component mounts
    cameras.forEach((element) => getStreamUrl(element));

    // Clearing all intervals when this component unmounts
    return () => {
      console.log('Clearing all intervals');
      clearAllIntervals();
    };
  }, [cameras]);


  function getStreamUrl(element) {
    let intervalCount = 0;
    const maxIntervals = 30;

    const interval = setInterval(() => {
      setCameraLoading(true);
      const url =
        process.env.REACT_APP_NODE_API_URL +
        '/api/hardware/' +
        element._id +
        '/stream/testing';
      Auth.currentSession()
        .then((token) => {
          axios
            .get(url, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
            .then((response) => {
              if (response.status === 200) {
                const { url } = response.data;
                setCameraUrl((prevCameraUrl) => {
                  const updatedCameraUrl = prevCameraUrl.map((item) =>
                    item.hasOwnProperty(element._id) ? { ...item, [element._id]: url } : item
                  );
                  return [...updatedCameraUrl];
                });

                if (url !== 'Stream error') {
                  clearInterval(interval) // Stoping the interval if the URL is not 'Stream error'
                  setCameraLoading(false) // stop the Loading for the camera
                }
              } else {
                setShowAlert(true);
              }
            })
            .catch((error) => {
              if (error?.response?.status === 401) {
                Auth.signOut().then(() => {
                  navigate('/')
                }).catch((error) => {
                  console.log('Error signing out: ', error);
                })
              } else {
                setShowAlert(true)
              }
            });
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut().then(() => {
              navigate('/')
            }).catch((error) => {
              console.log('Error signing out: ', error);
            })
          } else {
            setShowAlert(true)
          }
        })

      intervalCount++; // Incrementing the interval count everytime the response is Stream error

      if (intervalCount === maxIntervals) {
        clearInterval(interval); // Stoping the interval after reaching the maximum intervals
        setCameraLoading(false); // stop the Loading for the camera 
        setShowToast((prevShowToast) => {
          return { ...prevShowToast, [element._id]: true };
        }); // Show the toast

      }

      if (!intervalRef.current) {
        clearInterval(intervalRef.current);
        return;
      }
    }, 2000); // Interval: every 2 seconds

    intervalRef.current[element._id] = interval;
  }


  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        )
      })
    })
  }
  const handleKitId = (e) => {
    setKitID(e.target.value)
    if (e.target.value === 'all') {
      setCameras(camerasAll)
    } else {
      const cameraAllValue = camerasAll.filter(
        (camera) => camera.kit._id === e.target.value,
      )
      // let filteredArray = camerasAll.filter((item) => item === cameraAllValue)
      setCameras(cameraAllValue)
    }
  }
  const handlePropertyId = (e) => {
    setPropertyAddress(e.target.value)
    if (e.target.value === 'all') {
      setCameras(camerasAll)
    } else {
      const cameraAllValue = camerasAll.filter(
        (camera) => camera.kit.property === e.target.value,
      )
      // let filteredArray = camerasAll.filter((item) => item === cameraAllValue)
      setCameras(cameraAllValue)
    }
  }
  const handleFullScreen = (e, index) => {
    const player = document.querySelectorAll('.react-player')[index];
    player?.requestFullscreen();
  };

  return (
    <Sidebar>
      <div className="page-main-head align-items-center">
        <Row className="align-items-center">
          <Col xl={8}>
            <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
              <h2 className="color-black">Cameras</h2>
              {/* <h1 className="h4 color-black mr-20">Cameras</h1> */}

              <Form className="w-100">
                <Form.Group
                  className="search-control-group d-flex "
                  controlId="search-control"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                  <Button className="search-btn"></Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
          <Col xl={4}>
            <div className="d-flex align-items-center justify-content-md-end">
              <div className="d-flex ml-lg-30 w-100">
                <Form.Select
                  className="rounded-select flex-1"
                  onChange={(e) => handleKitId(e)}
                  value={KitID}
                // defaultValue='all'
                >
                  <option value="all">
                    All kits
                  </option>
                  {kits.map((element) => (
                    <option value={element._id} key={element._id}>
                      {element.name}
                    </option>
                  ))}
                </Form.Select>

                <Form.Select
                  className="rounded-select flex-1"
                  onChange={(e) => handlePropertyId(e)}
                  value={PropertyAddress}
                // defaultValue='all'
                >
                  <option value="all">
                    All Property
                  </option>
                  {ProperyList.map((element) => (
                    <option value={element._id} key={element._id}>
                      {removeUndefinedWord(element.address)}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Row className="filter-alert px-4">
        <Col xs="auto">
          <p className="m-0">All cameras ({cameras.length})</p>
        </Col>
      </Row>
      {loading === false ? (
        <Center h={100}>
          <Loader/>
        </Center>
      ) : (
        <div className='content-div mt-3 mb-4'>
          <Row>
            {search(cameras).map((element, index) => {
              const cameraId = element._id;
              const cameraObject = CameraUrl.find(obj => obj.hasOwnProperty(cameraId));
              const theCameraUrl = cameraObject ? cameraObject[cameraId] : 'Stream error';
              const isValidUrl = theCameraUrl && theCameraUrl !== 'Stream error';
              const currentCameraUrl = isValidUrl ? theCameraUrl : 'Stream error';
              return (
                <Col key={element._id} xxl={4} xl={4} lg={6}>
                  <div>
                    <StyledCard className="mb-4">
                      <div className="d-flex justify-content-between w-100 align-items-center p-2"
                        key={element._id}>
                        <div className='d-flex gap-2'>
                          <img
                            src={cameraImage}
                            alt="screen"
                            className=""
                            width={24}
                            height={24}
                          />
                          <h5> {element.name} </h5>
                        </div>
                        <Link to="#" onClick={(e) => handleFullScreen(e, index)}>
                          <img
                            src={fullScreenImage}
                            alt="screen"
                            className=""
                            width={24}
                            height={24}

                          />
                        </Link>
                      </div>
                      <div>
                        {cameraLoading && theCameraUrl === 'Stream error' && (
                          <div className='position-relative'>
                            <img
                              src={placeholderImage}
                              alt="screen"
                              style={{ width: '100%', height: '100%', borderRadius: '0 0 4px 4px' }}
                            />
                            <div className='position-absolute top-50 bottom-50 end-50 start-50'>
                                <Loader/>
                            </div>
                            <OnlineTag
                              status={'offline'}
                            />

                          </div>
                        )}
                        {!cameraLoading && theCameraUrl === 'Stream error' && (

                          <div className='position-relative'>
                            <img
                              src={placeholderImage}
                              alt="screen"
                              style={{ width: '100%', height: '100%', borderRadius: '0 0 4px 4px' }}
                            />
                            <OnlineTag
                              status={'offline'}
                            />
                          </div>
                        )}

                        {isValidUrl && (
                          <div className='position-relative p-0 m-0'>
                            <ReactPlayer
                              className='react-player'
                              url={currentCameraUrl}
                              width="100%"
                              height="100%"
                              style={{
                                margin: '0px',
                                padding: '0px',
                                width: '100%',
                                height: '100%',
                                borderRadius: '0 0 4px 4px'
                              }}
                              playing
                            />
                            <OnlineTag
                              status={'online'}
                            />
                          </div>
                        )}
                      </div>
                    </StyledCard>
                    <Toast key={showToast[element._id]}
                      show={showToast[element._id]}
                      onClose={() => setShowToast((prev) => ({ ...prev, [element._id]: false }))}
                      style={{ width: '100%' }}>
                      <Toast.Header>
                        <strong className="me-auto">Alert</strong>
                        <small><span style={{ color: 'black', fontWeight: 'bold' }}>{element.name}</span> is not available</small>
                      </Toast.Header>
                    </Toast>
                  </div>
                </Col>

              );
            })}
          </Row>
          <Row className="camera-lists">
            <PaginatedTable
              kit={KitID}
            />
          </Row>
        </div>
      )}

      {showAlert ? (
        <Modal show={showAlert}>
          <Modal.Header>
            <Modal.Title>Alert</Modal.Title>
            <button
              className="btn-close"
              aria-label="Close"
              onClick={() => setShowAlert(false)}
            ></button>
          </Modal.Header>
          <Modal.Body>Something Went Wrong</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAlert(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ''
      )}
    </Sidebar>
  )
}

export default Cameras