import awsDevConfig from "./aws-exports-dev";
import awsProdConfig from "./aws-exports-prod";
import awsStagingConfig from "./aws-exports-staging";

const config = !process.env.REACT_APP_ENVIRONMENT
  ? awsProdConfig
  : process.env.REACT_APP_ENVIRONMENT === "development"
  ? awsDevConfig
  : process.env.REACT_APP_ENVIRONMENT === "staging"
  ? awsStagingConfig
  : "invalid";

export default config;