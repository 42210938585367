import {
  useEffect,
  useState,
  useRef
} from 'react';

import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Row,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Auth } from 'aws-amplify';

import Sidebar from '../components/AdminSideBar';
import InputText from '../../../components/InputText';
import InputSelect from '../../../components/InputSelect';

const AccountForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const tokenAugustRef = useRef()

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('Alert');
  const [alertContent, setAlertContent] = useState('');

  const [organizations, setOrganizations] = useState([])
  const [selectVendor, setSelectVendor] = useState('switchbot')


  const [initialValues, setInitialValues] = useState({});

  const [augustCodeSended, setAugustCodeSended] = useState(false);
  const [augustTokenData, setAugustTokenData] = useState({ email: '', token: '' });

  useEffect(() => {
    if (location.pathname.includes('create')) {
      setInitialValues({
        name: '',
        description: '',
        category: 'all',
        vendor: selectVendor,
        notes: '',
      })
    } else if (location.pathname.includes('edit')) {
      const getAccountDetails = async () => {
        try {
          const url = `${process.env.REACT_APP_NODE_API_URL}/api/account/${params.id}`;
          const token = await Auth.currentSession();

          const response = await axios
            .get(url, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })

          if (response.status === 200) {
            setInitialValues(response.data);
            setSelectVendor(response.data.vendor);
          } else {
            setShowAlert(true);
            setAlertTitle('Error');
            setAlertContent('Error getting account');
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            Auth.signOut().then(() => {
              navigate('/admin/login');
            }).catch((error) => {
              console.log('Error signing out: ', error);
            });
          } else {
            setShowAlert(true)
            setAlertTitle('Error');
            setAlertContent('Error getting account')
          }
        }
      }
      getAccountDetails();
    }
    const getOrganization = async () => {
      try {
        const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/orgs`;
        const token = await Auth.currentSession();

        const response = await axios
          .get(url, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } });

        if (response.status === 200) {
          setOrganizations(response.data);
        } else {
          setShowAlert(true);
          setAlertTitle('Error');
          setAlertContent('Error getting organizations');
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/admin/login');
          }).catch((error) => {
            console.log('Error signing out: ', error);
          });
        } else {
          setShowAlert(true);
          setAlertTitle('Error');
          setAlertContent('Error getting organizations');
        }
      }
    }
    getOrganization();
  }, [navigate, params.id, location.pathname])

  useEffect(() => {
    if (selectVendor === 'august') {
      tokenAugustRef.current.value = initialValues.token
    }
  }, [selectVendor]);



  const sendToken = async (data) => {
    try {
      const token = await Auth.currentSession();

      const urlSession = `${process.env.REACT_APP_NODE_API_URL}/api/lock/token/session`;

      const sessionResponse = await axios
        .post(urlSession, data,
          { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } }
        );

      if (sessionResponse.status === 201) {
        const urlValidate = `${process.env.REACT_APP_NODE_API_URL}/api/lock/token/validate`;

        const validateResponse = await axios.post(
          urlValidate,
          {
            username: sessionResponse.data.userId,
            email: data.email,
            token: sessionResponse.data.token,
          },
          { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } }
        )
        setShowAlert(true);
        setAlertTitle('Success');
        setAlertContent('Code sended');
        setAugustCodeSended(true);
        setAugustTokenData({ email: data.email, token: validateResponse.data.token });

      } else {
        setShowAlert(true);
        setAlertTitle('Error');
        setAlertContent('There was an error generating the token');
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        Auth.signOut().then(() => {
          navigate('/admin/login');
        }).catch((error) => {
          console.log('Error signing out: ', error);
        })
      } else {
        setShowAlert(true);
        setAlertTitle('Error');
        setAlertContent('There was an error generating the token');
      }
    }
  }

  const VerifyCode = async (data) => {
    try {
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/lock/token/confirm`;
      const body = {
        email: augustTokenData.email,
        token: augustTokenData.token,
        code: data.code
      }
      const token = await Auth.currentSession();

      const confirmResponse = await axios.post(url, body, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
      if (confirmResponse.status === 201) {
        setShowAlert(true);
        setAlertTitle('success');
        setAlertContent('Token generated');
        setAugustCodeSended(false);
        tokenAugustRef.current.value = confirmResponse.data;
      } else {
        setShowAlert(true);
        setAlertTitle('Error');
        setAlertContent('There was an error generating the token');
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        Auth.signOut().then(() => {
          navigate('/admin/login');
        }).catch((error) => {
          console.log('Error signing out: ', error);
        })
      } else {
        setShowAlert(true);
        setAlertTitle('Error');
        setAlertContent('There was an error generating the token');
      }
    }
  }

  const onSubmit = async (form) => {
    try {
      let methodForm = '';
      let url = '';
      if (location.pathname.includes('create')) {
        methodForm = `post`;
        url = `${process.env.REACT_APP_NODE_API_URL}/api/account`;
      } else if (location.pathname.includes('edit')) {
        methodForm = 'put';
        url = `${process.env.REACT_APP_NODE_API_URL}/api/account/${params.id}`;
      }

      form.vendor = selectVendor;

      if (selectVendor === 'august') {
        form.token = tokenAugustRef.current.value;
      }

      const token = await Auth.currentSession();

      const response = await axios({
        method: methodForm,
        url: url,
        data: form,
        headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` }
      })

      if (response.status === 200) {
        setShowAlert(true);
        setAlertTitle('Saved');
        setAlertContent(response.data);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        Auth.signOut().then(() => {
          navigate('/admin/login');
        }).catch((error) => {
          console.log('Error signing out: ', error);
        })
      } else {
        setShowAlert(true);
        setAlertTitle('Alert');
        setAlertContent('Error on save account')
      }
    }
  }

  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">{location.pathname.includes('edit') ? 'Edit' : 'Create'} Account</h1>
        </div>
        <Row>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <Form>
              <Col xxl={12} xl={12} lg={12}>
                <Card>
                  <Card.Header as="h5">Account Details</Card.Header>
                  <Card.Body>
                    <InputText label='Name' name='name' />
                    <InputText label='Description' name='description' />
                    <InputSelect label='Category' name='category'>
                      <option value="all">All</option>
                      <option value="lock">Lock</option>
                      <option value="button">Button</option>
                      <option value="keypad">Keypad</option>
                    </InputSelect>
                    <InputSelect
                      label='Vendor'
                      name='vendor'
                      onChange={(event) => setSelectVendor(event.target.value)}
                      value={selectVendor}
                    >
                      <option value="switchbot">Switchbot</option>
                      <option value="august">August</option>
                    </InputSelect>
                    <InputText label='Notes' name='notes' />
                    <InputSelect label='Organization' name='org'>
                      <option value="">Select Organization</option>
                      {organizations.map((element, index) => (
                        <option
                          key={index}
                          value={element._id}
                        >
                          {element.name}
                        </option>
                      ))}
                    </InputSelect>
                  </Card.Body>
                </Card>
              </Col>
              {selectVendor === 'switchbot' ?
                <Col xxl={12} xl={12} lg={12}>
                  <Card>
                    <Card.Header as="h5">Switchbot info</Card.Header>
                    <Card.Body>
                      <InputText label='Token' name='token' />
                      <InputText label='Secret' name='secret' />
                    </Card.Body>
                    <div className="col-lg-12">
                      <div className="text-center">
                        <Button
                          className="btn btn-primary waves-effect waves-light m-3"
                          type="submit"
                        >
                          Save Account
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
                : null}
              {selectVendor === 'august' ?
                <Col xxl={12} xl={12} lg={12}>
                  <Card>
                    <Card.Header as="h5">August info</Card.Header>
                    <Card.Body>
                      <Row className="align-items-center mb-20">
                        <Col lg={2}>
                          <label className='form-label'>Token</label>
                        </Col>
                        <Col lg={10}>
                          <input
                            className='form-control'
                            type="text"
                            ref={tokenAugustRef}
                            defaultValue=''
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                    <div className="col-lg-12">
                      <div className="text-center">
                        <Button
                          className="btn btn-primary waves-effect waves-light m-3"
                          type="submit"
                        >
                          Save Account
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
                : null}

            </Form>
          </Formik>
          {(selectVendor === 'august' && augustCodeSended === false) ?
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={sendToken}>
              <Form>
                <Col xxl={12} xl={12} lg={12}>
                  <Card>
                    <Card.Header as="h5">Create Token</Card.Header>
                    <Card.Body>
                      <InputText label='Email' name='email' />
                      <InputText label='Password' name='password' />
                      <Col xxl={12} xl={12} lg={12}>
                        <div className="text-center">
                          <Button
                            className="btn btn-primary waves-effect waves-light m-3"
                            type="submit"
                          >
                            Send Code
                          </Button>
                        </div>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
              </Form>
            </Formik>
            : null}
          {(selectVendor === 'august' && augustCodeSended) ?
            <Formik
              initialValues={{ code: '' }}
              onSubmit={VerifyCode}>
              <Form>
                <Col xxl={12} xl={12} lg={12}>
                  <Card>
                    <Card.Header as="h5">Create Token</Card.Header>
                    <Card.Body>
                      <InputText label='Code' name='code' />
                      <Col xxl={12} xl={12} lg={12}>
                        <div className="text-center">
                          <Button
                            className="btn btn-primary waves-effect waves-light m-3"
                            type="submit"
                          >
                            Verify
                          </Button>
                        </div>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
              </Form>
            </Formik>
            : null}
        </Row>
      </Sidebar >
      {
        showAlert ? (
          <Modal show={showAlert}>
            <Modal.Header>
              <Modal.Title>{alertTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{alertContent}</Modal.Body>
            <Modal.Footer>
              {
                alertTitle === 'Saved' ?
                  <Button variant="secondary" onClick={() => {
                    navigate('/admin/account/list')
                    setShowAlert(false);
                  }}
                  >
                    Okay
                  </Button>
                  :
                  <Button variant="secondary" onClick={() => setShowAlert(false)}>
                    Close
                  </Button>
              }
            </Modal.Footer>
          </Modal>
        ) : (
          null
        )
      }
    </>
  )
}
export default AccountForm;
