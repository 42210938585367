import { format } from "date-fns";
import React from "react";

const codeTypes = {
  timeLimit: "temporary",
  urgent: "emergency",
};

const CodeRow = ({ code, handleSelect, selected, disabled, dailyCode }) => {
  return (
    <div className={`d-flex gap-3 border-bottom ${dailyCode ? 'bg-primary bg-opacity-10' : ''}`} key={code.id}>
      <span className="w-content d-flex align-items-center">
        <input
          type="checkbox"
          value={code.id}
          onChange={(e) => handleSelect(code, e.target.checked)}
          checked={selected.some((selectedCode) => selectedCode.id === code.id)}
          disabled={disabled || dailyCode}
          className={dailyCode ? 'opacity-0' : ''}
        />
      </span>

      <span className={`col text-truncate py-2 d-flex align-items-center ${dailyCode ? 'fw-bold text-delet-blue' : ''}`}>
        {!dailyCode ? code.name : "Today's code"}
      </span>
      <span className="col text-truncate py-2 d-flex align-items-center">
        {code.password}
      </span>
      <div className="col align-items-center d-flex align-items-center">
        <span
          className={`'text-truncate text-capitalize rounded-5 w-content bg-opacity-10 px-3
                ${
                  code.status === "normal"
                    ? "bg-success text-success"
                    : "bg-secondary text-body-tertiary"
                }`}
        >
          {code.status === "normal" ? "Active" : code.status}
        </span>
      </div>
      <span className="col text-truncate py-2 text-capitalize d-flex align-items-center">
        {codeTypes[code.type] ?? code.type}
      </span>
      <span
        className={`col text-truncate py-2 d-flex align-items-center ${
          code.type === "permanent" ? "" : ""
        }`}
      >
        {["permanent", "urgent"].includes(code.type) ? (
          format(new Date(code.createTime), "MM/dd/yyyy")
        ) : ["timeLimit", "disposable"].includes(code.type) ? (
          code.startTime ? (
            <div className="d-flex flex-column">
              {format(new Date(code.startTime), "MM/dd/yyyy")}{" "}
              <span className="fs-8 text-secondary">
                {format(new Date(code.startTime), "hh:mm a")}
              </span>
            </div>
          ) : (
            "Couldn't get start time"
          )
        ) : (
          ""
        )}
      </span>
      <span
        className={`col text-truncate py-2 d-flex align-items-center ${
          code.type === "permanent" ? "" : ""
        }`}
      >
        {["permanent", "urgent"].includes(code.type) ? (
          "∞"
        ) : ["timeLimit", "disposable"].includes(code.type) ? (
          code.endTime ? (
            <div className="d-flex flex-column">
              {format(new Date(code.endTime), "MM/dd/yyyy")}{" "}
              <span className="fs-8 text-secondary">
                {format(new Date(code.endTime), "hh:mm a")}
              </span>
            </div>
          ) : (
            "Couldn't get end time"
          )
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default CodeRow;
