export const encodeURL = (url) => url.replaceAll('-', '~').replaceAll('/', '_').replaceAll(' ', '-');

export const str2bool = (value) => {
    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
    }
    return value;
}

export const createFileFromUrl = async (imageSrc) => {
    const metadata = {
        type: 'image/jpeg'
    };
    if (imageSrc) {
        try {
            // TODO: catch when the image data is a data:image
            const response = await fetch(imageSrc, {
                method: 'GET',
                headers: { "Cache-Control": 'no-cache' },
            });
            const data = await response.blob();
    
            const urlSplitted = imageSrc.split('/');
            const urlName = urlSplitted[urlSplitted.length - 1];
            const file = new File([data], urlName, metadata);
            return file;
        } catch (error) {
        //    console.error(error); 
        }
    } else {

        return new File([], '', metadata);;
    }
}

export const convertFileToSrc = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
        resolve(fileReader.result);
    }
    fileReader.onerror = () => {
        reject(fileReader.error);
    }
    if (file) {
        fileReader.readAsDataURL(file);
    } else {
        resolve('');
    }
})