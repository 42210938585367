import styled from "styled-components";
import Select from "react-dropdown-select";

const StyledFilterDropdown = styled(Select)`
  ${"" /* border: 1px solid; */}
border-radius: 4px;
border-color: #000;
height: 20px;
align-items: center;
justify-content: center;
text-align: center;
${"" /* gap: 4px; */}
flex-shrink: 0;
display: flex;
transition: all 0.5s ease;
white-space: wrap;
color: #000;
${"" /* padding: 2px 2px; */}
  &.react-dropdown-select {
    ${"" /* min-height: 34px; */}
    height: 32px;
    display: flex;
    border-radius: 4px;
    padding: 1px;
    justify-content: center;
    align-items: center;
    ${'' /* height: 26px !important; */}
    width: auto;
    border: 1.7px solid #000;
    box-shadow: none;
    ${"" /* height: 10px; */}
    color: #000;
    white-space: none;
    

    /* Body (Bold) */
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.14px;
    text-decoration: none;
        color: #000;

    &:focus, &:focus-within {
      border: 1px solid;
        box-shadow: none;
    }
  }

& .react-dropdown-select-input {
    /* Body (Bold) */
    text-align: middle;
    font-family: "MulishBold";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    ${"" /* line-height: 140%; */}
    letter-spacing: 0.18px;
    text-align: center;
    color: '#000';
    ${'' /* display: flex;
    width: 100%;   */}
    
    
    
    ${"" /* height: 16px; */}

}

 &.react-dropdown-select-content {
    display: flex;
    align-items: center;
    justify-content: center ;
    text-align: center;
    white-space: wrap;
    text-wrap: wrap;
    
    
    
}

.react-dropdown-select-dropdown-handle {
    margin: 0px 4px 0px 5px;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    color: '#000000';
  }
& .react-dropdown-select-dropdown-position-bottom{
    display: relative;
    padding:0px;
    left:-2%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    border-radius: 4px;
    background: '#FFFFFF';
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.50);
    width: ${(props) => props.modalWidth || 'auto'};

    
    .react-dropdown-select-item {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
        
    }
    
    .react-dropdown-select-item:hover,
    .react-dropdown-select-item:focus,
    .react-dropdown-select-item-selected {
        background: '#BDBEBF';
        color: #1f2327;
    }
    
    span {
        display: flex;
        height: 40px;
        padding: 8px;
        align-items: center;
        gap: 10px;
        align-self: stretch;

        /* Body (Small) */
        font-family: "MulishRegular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.16px;
        
    }
}

@media (max-width: 768px) {
    &.react-dropdown-select {
      width: 100% !important;
    }
  }


`;

export default StyledFilterDropdown;
