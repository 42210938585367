import style from '../style.module.scss';
import { ReactSVG } from 'react-svg';
import ExternalLink from '../../../assests/images/ExternalLink.svg';
import { UnstyledAppButton } from '../../AppButton/unstyled';
import type { Props as TAppButtonProps } from '../../AppButton/unstyled';

type Props = TAppButtonProps;

export const Link = ({ children, className, ...props }: Props) => {
  return (
    <UnstyledAppButton
      {...props}
      className={`${className} ${style.link}`}
    >
      <ReactSVG src={ExternalLink} />
      {children}
    </UnstyledAppButton>
  );
};
