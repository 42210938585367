import React, { useState } from 'react';
import styled from 'styled-components';
const StyledFieldSet = styled.fieldset`
  color: #66676B;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;
const StyledLabel = styled.label`
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-weight: ${props =>
    props.variantType === 'primary' ? '700' : props.variantType === 'secondary' ? '400' : '700'};
  line-height: 140%;
  letter-spacing: 0.16px;
  /* disabled styles */
  ${props =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;
const StyledSpan = styled.span`
  padding-left: 8px;
  /* disabled styles */
  ${props =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;
const StyledCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: #FFF;
  border : 2px solid #66676B;
  margin-top: 1px;
  box-shadow: none;
  &:checked {
    background-color: #5D5FEF;
    border : 2px solid #5D5FEF;
    box-shadow: none;
    //add a background image here with a white checkmark and background color of #5D5FEF 
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1.5 0 23 19'%3E%3Cpath d='M2.0 10.5L6.5 15.0L18 4' stroke='%23fff' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round' fill='none'/%3E%3C/svg%3E");
    
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border : 2px solid #5D5FEF;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #EAEAEB;
    border : 2px solid #EAEAEB;
  }
`;

const InputCheckbox = ({
  disabled = false,
  disabledOptions,
  name,
  register,
  options,
  error = {},
  changedValue,
  ...props
}) => {
  const [checkedItems, setCheckedItems] = useState([]);

  const handleChange = e => {
    const { value, checked } = e.target;
    //this is c callback function from the parent component to update state related to the checkbox
    if (changedValue) {
      changedValue(value, checked);
    }
    const name = ""
    let updatedCheckedItems;
    if (checkedItems.includes("name")) {
      updatedCheckedItems = checkedItems.filter(item => item !== name);
    } else {
      const currentItems = [...checkedItems];
      currentItems.push(name);
      updatedCheckedItems = currentItems;
    }
    setCheckedItems(updatedCheckedItems)
  }
  return (
    <>
      {props.label && <StyledLabel
        variantType={props?.variantType}
        disabled={disabled}
        className='body-s-bold'
        htmlFor={props?.name}
      >
        {props?.label}
      </StyledLabel>}
      {options.map((option, index) => {
        return (
          <label key={index} htmlFor={index}>
            <StyledCheckboxInput
              className='form-check-input'
              type="checkbox"
              value={option.value}
              key={option.value + "checkboxinput"}
              checked={checkedItems[name]}
              {...register(name)}
              onChange={handleChange}
              {...props}
              disabled={disabled}
            />
            <StyledSpan
              key={option.value + "checkboxspan"}
              disabled={disabled}
            >{option.name}</StyledSpan>
          </label>
        )
      })}
      {error[name] ? <span className="error">{props?.error}</span> : null}
    </>
  );
};
export default InputCheckbox;