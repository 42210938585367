import Sidebar from "../../components/SideBar";
import Orders from "./tabs/Orders";
import { Link, useNavigate, useParams } from "react-router-dom";
import KitsTab from "./tabs/KitsTab";

const Kits = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  return (
    <Sidebar>
      <section className="p-4 min-h-100vh d-flex flex-column gap-2">
        <header className="d-flex justify-content-between align-items-center">
          <h4 className="fs-3 fw-bold pt-0">
            Kits
          </h4>

          <Link
            className="bg-delet text-white p-2 fw-bold fs-5 border-0 rounded-3 text-decoration-none d-none"
            to="/order"
          >
            + Order New Kit
          </Link>
        </header>

        <ul class="nav nav-underline">
          <li class="nav-item">
            <button
              className={`nav-link ${
                (!tab || tab === "kits") && "active text-delet-blue"
              }`}
              type="button"
              onClick={() => navigate("/kits/kits")}
            >
              Kits
            </button>
          </li>
          <li class="nav-item d-none">
            <button
              className={`nav-link ${
                tab === "orders" && "active text-delet-blue"
              }`}
              onClick={() => navigate("/kits/orders")}
            >
              Orders
            </button>
          </li>
        </ul>

        {tab === "orders" ? <Orders /> : <KitsTab />}
      </section>
    </Sidebar>
  );
};
export default Kits;
