import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import Sidebar from '../components/AdminSideBar'
import InputText from '../../../components/InputText'
import Alert from '../../../components/Alert'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

import UseHttp from '../../../hooks/UseHttp'
import { Button } from '@mantine/core'

const AddUser = () => {
  const [alertModal, setAlertModal] = useState({
    show: false,
    title: 'Alert',
    content: '',
  })
  const [passwordVisible, setPasswordVisible] = useState(false)

  const params = useParams()
  const navigate = useNavigate()

  const {
    isLoading: loading,
    error: changePasswordError,
    requestData: changePassword,
  } = UseHttp()

  useEffect(() => {
    if (changePasswordError) {
      console.log(changePasswordError)
      if (changePasswordError.content) {
        setAlertModal({
          show: true,
          title: 'Error',
          content: changePasswordError.content,
        })
      } else {
        setAlertModal({
          show: true,
          title: 'Error',
          content: 'Error Changing Password',
        })
      }
    }
  }, [changePasswordError])

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const onSubmit = (data) => {
    const { password, rePassword } = data
    if (password === '' && rePassword === '') {
      setAlertModal({
        show: true,
        title: 'Error',
        content: 'Please Enter The Passwords',
      })
    } else if (password !== rePassword) {
      setAlertModal({
        show: true,
        title: 'Error',
        content: 'Password Do Not Match',
      })
    } else if (password.length < 6) {
      setAlertModal({
        show: true,
        title: 'Error',
        content: 'The password must be longer than 6 characters',
      })
    } else {
      Auth.currentSession()
        .then((token) => {
          changePassword(
            {
              method: 'POST',
              url: `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/${params.id}/reset/`,
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
              },
              body: data,
            },
            (data) => {
              setAlertModal({
                show: true,
                title: data.title,
                content: data.message,
              })
            }
          )
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate('/')
              })
              .catch((error) => {
                console.log('Error signing out: ', error)
              })
          } else {
            setAlertModal({
              show: true,
              title: 'Error',
              content: 'Error Changing Password',
            })
          }
        })
    }
  }
  return (
    <>
      <Sidebar>
        <div className='page-main-head d-flex justify-content-between'>
          <h1 className='h4'>Change Password</h1>
        </div>
        <Row>
          <Col xxl={6} xl={6} lg={6}>
            <Card>
              <Card.Header as='h5'></Card.Header>
              <Card.Body>
                <Formik
                  initialValues={{ password: '', rePassword: '' }}
                  onSubmit={onSubmit}
                >
                  <Form>
                    <InputText
                      label='New Password'
                      name='password'
                      type={passwordVisible ? 'text' : 'password'}
                      labelSize={4}
                      inputSize={8}
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: 'absolute',
                        right: '30px',
                        top: '30%',
                        cursor: 'pointer',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      {passwordVisible ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </span>
                    <InputText
                      label='Confirm Password'
                      name='rePassword'
                      type={passwordVisible ? 'text' : 'password'}
                      labelSize={4}
                      inputSize={8}
                    />
                    <div className='col-lg-12'>
                      <div className='text-center'>
                        <Button type='submit' color='black' loading={loading}>
                          Change
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Sidebar>
      {alertModal.show && (
        <Alert
          show={alertModal.show}
          title={alertModal.title}
          content={alertModal.content}
          navigateTo={alertModal.title === 'Success' && '/admin/user/list'}
          setShow={() => setAlertModal({ ...alertModal, show: false })}
        />
      )}
    </>
  )
}

export default AddUser
