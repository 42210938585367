import './Leads.scss';

import React, {
  useEffect,
  useState,
  useRef,
} from 'react';

import { Formik } from 'formik';
import axios from 'axios';
import {
  Card,
  Col,
  Form,
  Row,
  Modal,
  Button
} from 'react-bootstrap';
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Sidebar from '../../components/SideBar';
import InputText from '../../components/InputText';
import InputImage from '../../components/InputImage';
import getImageUrl from '../../helper/checkImageFormat';

const EditLeads = () => {
  // alert modal
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [file, setFile] = useState()
  const [data, setData] = useState([])
  const formRef = useRef()
  const params = useParams();
  const navigate = useNavigate()


  useEffect(() => {
    Auth.currentSession()
      .then((token) => {
        axios
          .get(
            `${process.env.REACT_APP_NODE_API_URL}/api/contact/${params.id}`,
            { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } }
          )
          .then((response) => {
            if (response.status === 200) {
              setFile(response.data.idImage);
              setData(response.data)
            } else {
              setShowAlert(true);
              setAlertContent('Error getting contact')
            }
          })
          .catch((error) => {
            setShowAlert(true);
            setAlertContent('Error getting contact')
          })
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/')
          }).catch((error) => {
            console.log('Error signing out: ', error);
          })
        } else {
          setShowAlert(true);
          setAlertContent('Error getting contact')
        }
      })
  }, [params.id])

  const onFileChange = (e) => {
    const fileReader = new FileReader()
    if(e.target.files[0]){
      fileReader.readAsDataURL(e.target.files[0])
    }
    fileReader.onload = () => {
      var image = fileReader.result
      setFile(image)
    }
  }

  const onSubmit = (event) => {
    const body = {
      firstName: event.firstName,
      lastName: event.lastName,
      phone: event.phone,
      email: event.email,
      idImage: file
    }
    Auth.currentSession()
      .then((token) => {
        axios
          .put(
            `${process.env.REACT_APP_NODE_API_URL}/api/contact/update/${params.id}`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token.getIdToken().getJwtToken()}`
              }
            }
          )
          .then(() => {
            setShowAlert(true);
            setAlertContent('Contact Updated Successfully')
            navigate(`/leads-details/${params.id}`)
          }).catch(() => {
            setShowAlert(true);
            setAlertContent('Error Updating contact')
          })
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/')
          }).catch((error) => {
            console.log('Error signing out: ', error);
          })
        } else {
          setShowAlert(true);
          setAlertContent('Error Updating contact')
        }
      })
  }

  return (
    <Sidebar>
      <div className="page-main-head align-items-center p-0 property-head">
        <Row className="align-items-center">
          <Col xl={8} lg={12}>
            <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-start">
              <span className="position-relative d-inline-block">
                <img
                  src={getImageUrl(data.idImage)}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = require('../../assests/images/aljnfn.png');
                  }}
                  alt="ID document"
                  width={160}
                  height={90}
                  className="border-radius-0"
                />
              </span>
              <div className="ml-20">
                <h6>{data.firstName} {data.lastName}</h6>
                <div className="d-flex">
                  <Link
                    to="#"
                    size="sm"
                    className="btn btn-primary btn-sm edit-property-btn mr-30"
                  >
                    Editing
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={4} lg={12}>
            <div className="d-flex align-items-center justify-content-center justify-content-xl-end mt-2 mb-2 m-xl-0">
              <button
                className="btn btn-sm btn-primary"
                type='submit'
                onClick={() => formRef.current.handleSubmit()}
              >
                Save changes
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          email: data.email,
          idImage: data.idImage
        }}
        innerRef={formRef}
        onSubmit={onSubmit}
      >
        <Form>
          <Row className="mt-30">
            <Col xl={3} lg={12}>
              <Card>
                <Card.Header as="h5">ID document</Card.Header>
                <Card.Body>
                  <InputImage
                    buttonText='Upload new image'
                    description='To change the image, drag another image into this box or use upload button'
                    defaultImage={file ? getImageUrl(file) : require('../../assests/images/aljnfn.png')}
                    file={getImageUrl(file)}
                    name='image'
                    onChange={onFileChange}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={9} lg={12}>
              <Card>
                <Card.Header as="h5">Personal information</Card.Header>
                <Card.Body>
                  <InputText label='First name' name="firstName"
                  />
                  <InputText label='Last name' name="lastName"
                  />
                  <InputText label='Mobile' name="phone"
                  />
                  <InputText label='Email' name="email"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      </Formik>
      {showAlert ?
        <Modal show={showAlert}>
          <Modal.Header>
            <Modal.Title>Alert</Modal.Title>
            <button className="btn-close" aria-label="Close" onClick={() => setShowAlert(false)}></button>
          </Modal.Header>
          <Modal.Body>{alertContent}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAlert(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        : ''
      }
    </Sidebar>
  )
}

export default EditLeads
