import React from 'react';

function PublicLayout({ children, footerClassname }) {
  return (
    <div className='d-flex flex-column min-h-100vh'>
      <div className='flex-grow-1 d-flex flex-column w-100'>
        <nav class='navbar'>
          <div class='container-fluid px-sm-5 px-4 pt-4'>
            <img
              src='https://developmentapi.delet.com/images/delet-logo.png'
              alt='delet-logo'
              className='logo'
            />
          </div>
        </nav>
        {children}
      </div>
      <footer
        className={`instruction-footer bg-black d-flex justify-content-center align-items-center text-white ${footerClassname}`}
      >
        © Delet 2024. All rights reserved.
      </footer>
    </div>
  );
}

export default PublicLayout;
