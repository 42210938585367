import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import {
  Button,
  Col,
  Form,
  Row,
  Table,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Sidebar from '../components/AdminSideBar';
import { Center, Loader } from '@mantine/core';

const searchParam = ['name', 'description', 'vendor', 'notes']

const AccountList = () => {
  const [list, setList] = useState([]);
  const [q, setQ] = useState('');
  const [loading, setLoader] = useState(false);
  const [modelshow, setModelShow] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getAccountList = async () => {
      try {
        const url = `${process.env.REACT_APP_NODE_API_URL}/api/account`;
        const token = await Auth.currentSession();

        const response = await axios
          .get(url, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } });
          
        if (response.status === 200) {
          setLoader(true);
          setList(response.data);
        } else {
          setError('Error Getting Accounts');
          setModelShow(true);
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/admin/login');
          }).catch((error) => {
            console.log('Error signing out: ', error);
          })
        } else {
          setError('Error Getting Accounts');
          setModelShow(true);
        }
      }
    }
    getAccountList();
  }, [navigate]);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        )
      })
    })
  }

  return (
    <>
      <Sidebar>
        <div className="page-main-head align-items-center">
          <Row className="align-items-center">
            <Col xl={8}>
              <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                <h1 className="h4 color-black mr-20">ACCOUNT LIST</h1>
                <div>
                  <Link
                    to="/admin/account/create"
                    size="sm"
                    className="btn btn-primary btn-sm"
                  >
                    NEW
                  </Link>
                </div>
                <Form className="w-100">
                  <Form.Group
                    className="search-control-group d-flex "
                    controlId="search-control"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                    <Button className="search-btn"></Button>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
        <div className="filter-alert">
          <p>All contacts ({list.length})</p>
        </div>
        <Table responsive className="property-table">
          {loading === false ? (
            <Center mt={20}>
              <Loader/>
            </Center>            
          ) : (
            <tbody style={{ textAlign: 'center' }}>
              {search(list).map((element, index) => (
                <tr key={element._id} onClick={() => navigate(`/admin/account/details/${element._id}`)}>
                  <td>
                    <span className="order">{index + 1}.</span>
                  </td>

                  <td>
                    <Link className="properties-details-link">
                      <p className="table-phone">{element.name}</p>
                    </Link>
                  </td>
                  <td>
                    <p className="table-phone">{element.description}</p>
                  </td>
                  <td>
                    <p>{element.notes}</p>
                  </td>
                  <td>
                    <p className="text-phone">{element.vendor}</p>
                  </td>
                  <td>
                    <p className="text-phone">
                      {element.org ? element.org.email : ''} |{' '}

                      {element.org ? element.org.company.name : ''}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </Sidebar>
      {modelshow &&
        <Modal show={modelshow}>
          <Modal.Header>
            <Modal.Title>Alert</Modal.Title>
            <button
              className="btn-close"
              aria-label="Close"
              onClick={() => setModelShow(false)}
            ></button>
          </Modal.Header>
          <Modal.Body>{error}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModelShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </>
  )
}

export default AccountList
