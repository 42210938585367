import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useProperty } from "../../../context/PropertyContext";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useGeneral } from "../../../context/GeneralContext";
import { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Title } from "@mantine/core";

import GenericModal from "../../../components/Modals";
import ChangeKitForm from "../components/Kit/ChangeKitForm";
import KeypadCodeForm from "../../../components/Keypad";
import CodeHistory from "../components/Keypad/CodeHistory";
import HardwareList from "../components/HardwareList";

import { Toaster } from "react-hot-toast";
import { IoIosAlert, IoMdInformationCircleOutline } from "react-icons/io";
import { FaClockRotateLeft } from "react-icons/fa6";
import { MdOutlineNotificationsNone } from "react-icons/md";
import InfoBanner from "../../../components/Banners/InfoBanner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { fetchKits, unassignKit } from "../../../api/kits";
import SelectKit from "../components/Kit/SelectKit";
import { useMutation, useQuery } from "@tanstack/react-query";
import { updatePropertyKit } from "../../../api/properties";
import { RiArrowDropRightLine } from "react-icons/ri";

const System = () => {
  const MySwal = withReactContent(Swal);
  const [searchParams, setSearchParams] = useSearchParams();
  const { property, setProperty } = useProperty();

  const [modal, setModal] = useState({
    show: false,
  });

  const currentTab = searchParams.get("systemTab");

  const keypadId = property.kit?.hardware.find(
    (hardware) => hardware.category === "keypad"
  )?._id;

  const handleCloseModal = () => {
    setModal({ ...modal, show: false });
  };

  const handleCreateCode = () => {
    setModal({
      show: true,
      body: (
        <KeypadCodeForm handleCancel={handleCloseModal} keypadId={keypadId} />
      ),
      title: "ACCESS CODE CONFIGURATION",
      hideCloseButton: true,
    });
  };

  const updateProperty = (updatedFields) => {
    setProperty({ ...property, ...updatedFields });
  };

  const handleOpenChangeKit = () => {
    setModal({
      show: true,
      body: (
        <SelectKit
          labelText="Select the new kit you want to assign to the property."
          handleCancel={handleCloseModal}
          updateProperty={updateProperty}
          propertyId={property._id}
        />
      ),
      hideCloseButton: true,
    });
  };

  const systemTabUrl = (param, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("activeTab", "system");
    newSearchParams.set(param, value);
    return `?${newSearchParams.toString()}`;
  };

  return (
    <section className="py-3 px-5">
      <Toaster />
      <div className="bg-delet-pink d-flex justify-content-between align-items-center rounded-top w-100 p-3">
        <div className="d-flex align-items-center justify-content-between w-100">
          {property?.kit?.name ? (
            <>
              <Title order={4} tt="uppercase">
                {property.kit.name}
              </Title>

              <div className="d-flex gap-3">
                <Button
                  className="bg-delet border-0 px-4"
                  onClick={handleOpenChangeKit}
                >
                  Change kit
                </Button>

                <Button
                  onClick={() =>
                    MySwal.fire({
                      title:
                        "<h4 class='fs-5'>Are you sure you want to unassign this kit?</h4>",
                      html: "<p class='text-body-tertiary fs-6'>All saved information will be lost.",
                      iconHtml: <IoIosAlert color="#5271ff" />,
                      showCancelButton: true,
                      cancelButtonText: "Close",
                      confirmButtonText: "Unassign",
                      confirmButtonColor: "#5271ff",
                      reverseButtons: true,
                      customClass: {
                        icon: "border-0 my-0 py-2",
                        popup: "px-5 pt-2",
                        title: "pt-0",
                        htmlContainer: "pt-2",
                        actions: "gap-3 w-100 flex-nowrap",
                        confirmButton: "w-100 py-2 rounded-3",
                        cancelButton:
                          "w-100 bg-white text-black border border-secondary-subtle rounded-3",
                      },
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          await unassignKit(property.kit._id);
                          setProperty({ ...property, kit: undefined });
                        } catch (error) {
                          console.log("error unassign kit: ", error);
                        }
                      }
                    })
                  }
                >
                  Unassign Kit
                </Button>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-between align-items-center w-100">
              <p className="d-flex gap-3 align-items-center text-body-secondary">
                <IoIosAlert color="#929292" size={30} /> It looks like you
                haven’t selected a kit for this property yet
              </p>
              <Button
                className="text-white bg-delet border-0 d-flex align-items-center px-4 rounded-3"
                onClick={handleOpenChangeKit}
              >
                Select kit <RiArrowDropRightLine size={30} />
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex">
        <div className="d-flex flex-column col-2 border border-top-0 p-2 gap-3">
          <Link
            to={systemTabUrl("systemTab", "kit")}
            preventScrollReset={true}
            className="btn so-option border-0 bg-white d-flex align-items-center gap-1 fw-medium text-dark-emphasis"
          >
            <IoMdInformationCircleOutline size={20} color="#4C4D52" /> Kit
            Information
          </Link>
          <Link
            to={systemTabUrl("systemTab", "history")}
            preventScrollReset={true}
            className="btn so-option border-0 bg-white d-flex align-items-center gap-1 fw-medium text-dark-emphasis"
          >
            <FaClockRotateLeft size={18} color="#4C4D52" /> Codes History
          </Link>
          <Link
            to={systemTabUrl("systemTab", "notifications")}
            preventScrollReset={true}
            className="btn so-option border-0 bg-white d-flex align-items-center gap-1 fw-medium text-dark-emphasis"
          >
            <MdOutlineNotificationsNone size={20} color="#4C4D52" />{" "}
            Notifications
          </Link>
        </div>

        <div className="pt-3 col">
          {currentTab === "kit" || !currentTab ? (
            <HardwareList property={property} />
          ) : currentTab === "notifications" ? (
            <div className="px-5">
              <InfoBanner>
                <span className="text-secondary fs-7">
                  We’re still working on this feature — coming soon!
                </span>
              </InfoBanner>
            </div>
          ) : currentTab === "history" ? (
            keypadId ? (
              <CodeHistory
                keypadId={keypadId}
                handleCreateCode={handleCreateCode}
              />
            ) : (
              <div className="px-5">
                <InfoBanner>
                  <span className="text-secondary fs-7">No keypad found</span>
                </InfoBanner>
              </div>
            )
          ) : (
            <div>Incorrect route</div>
          )}
        </div>
      </div>

      <GenericModal {...modal} handleClose={handleCloseModal} />
    </section>
  );
};

export default System;
