import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "../Steps/style.module.scss";
import rescheduleStyle from "../RescheduleSteps/style.module.scss";
import check from "../../../../assests/images/check.svg";
import { StepNumberLabel } from "../Steps/StepNumberLabel";
import Step2 from "../Steps/Step2/Step2";
import { useMutation } from "@tanstack/react-query";
import { createMeeting } from "../../../../api/bookings";
import nextArrow from "../../../../assests/images/NextArrow.svg";
import prevArrow from "../../../../assests/images/PrevArrow.svg";
import { AppButton } from "../../../../designSystem/AppButton";
import { PropertyDetail } from "../PropertyDetail";
import { DrawerModalContainer } from "../../../../designSystem/DrawerModalContainer";
import { SelectedPropertyCard } from "../RescheduleSteps/SelectedProperyCard";
import { ReactSVG } from "react-svg";
import Step3 from "../Steps/step3/Step3";

const CircleContentChecked = () => <img src={check} alt="" />;

export const OnlyOnePropertySteps = ({ property }) => {
  const { idVerification } = property;
  const { propertyId, agentId } = useParams();
  const [inputValues, setInputValues] = useState({});
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(2);
  const [alertModal, setAlertModal] = useState({
    show: false,
    title: "",
    content: "",
  });
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setInputValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onPrev = () => {
    if (step > 2) {
      setStep(step - 1);
    }
  };
  const onNext = () => {
    if (step === 3) {
      // TODO:
      handleBook();
      return;
    }
    setStep(step + 1);
  };

  const handleNextDisable = () => {
    if (step === 2 && !inputValues.localTimeRequested) {
      return true;
    } else if (step === 3) {
      if (
        !inputValues.firstName ||
        !inputValues.lastName ||
        !inputValues.phone ||
        !inputValues.email ||
        !inputValues.document ||
        inputValues.privacyPolicy !== "true"
      ) {
        return true;
      }

      if (property.idVerification.active) {
        if (
          inputValues.verified === undefined ||
          inputValues.verified === false
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const handleBook = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );
    if (
      !inputValues.phone ||
      !inputValues.email ||
      !inputValues.firstName ||
      !inputValues.lastName ||
      !inputValues.localTimeRequested
    ) {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Please complete all the information",
      });
      return;
    }

    if (!phoneRegex.test(inputValues.phone)) {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Please enter a valid phone number",
      });
    } else if (!emailRegex.test(inputValues?.email)) {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Please enter a valid email address",
      });
    } else {
      meetingMutation.mutate(inputValues);
    }
  };
  const meetingMutation = useMutation({
    mutationFn: (booking) =>
      createMeeting({ ...booking, propertyId, agent: agentId }),
    onSuccess: (data) => {
      navigate(`/book/confirmation/${data.booking}`);
    },
  });

  return (
    <div className={`${style.layout} ${rescheduleStyle.layout}`}>
      <div className={style.stepLabels}>
        <StepNumberLabel
          className={style.inactive}
          circleContent={<CircleContentChecked />}
          state="default"
          title="Choose A Property"
        />
        <StepNumberLabel
          circleContent={step === 2 ? "2" : <CircleContentChecked />}
          state={step === 2 ? "selected" : step > 2 ? "completed" : "default"}
          title="Choose Time"
        />
        <StepNumberLabel
          className={style.inactive}
          circleContent={3}
          state={step === 3 ? "selected" : step > 3 ? "completed" : "default"}
          title="Identity Verifications"
        />
      </div>

      <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
        {step === 2 ? (
          <SelectedPropertyCard
            className={`${rescheduleStyle.card}`}
            property={property[0] || property}
            onSeeDetails={onOpen}
          />
        ) : null}

        {step === 2 ? (
          <Step2
            className={style.container}
            currentStep={step}
            propertyId={property[0]?._id || property?._id}
            onChange={handleInputChange}
          />
        ) : step === 3 ? (
          <Step3
            document={inputValues.document}
            documentBack={inputValues.documentBack}
            face={inputValues.face}
            className={style.container}
            currentStep={step}
            onChange={handleInputChange}
            idVerification={idVerification}
            verified={inputValues.verified}
          />
        ) : null}

        <div className={`${style.buttons} ${style.container}`}>
          <AppButton
            className={style.button}
            as="button"
            onClick={onPrev}
            disabled={step === 2}
            hierarchy="secondary"
            type="button"
          >
            <ReactSVG
              beforeInjection={(svg) => {
                if (step === 2) {
                  svg
                    .querySelector("path")
                    ?.setAttribute("stroke", "#00000033");
                }
              }}
              src={prevArrow}
            />
            Go Back
          </AppButton>
          <AppButton
            disabled={handleNextDisable()}
            className={style.button}
            as="button"
            type="button"
            onClick={onNext}
          >
            <ReactSVG
              beforeInjection={(svg) => {
                if (handleNextDisable()) {
                  svg
                    .querySelector("path")
                    ?.setAttribute("stroke", "#00000033");
                }
              }}
              src={nextArrow}
            />
            {step !== 3 ? "Confirm and Continue" : "Schedule My Tour"}
          </AppButton>
        </div>
        <DrawerModalContainer onOpen={onOpen} onClose={onClose} isOpen={open}>
          <PropertyDetail property={property[0] || property} />
        </DrawerModalContainer>
      </div>
    </div>
  );
};
