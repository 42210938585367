import "./Leads.scss";

import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Sidebar from "../../components/SideBar";
import PagePagination from "../../components/PagePagination";
import getImageUrl from "../../helper/checkImageFormat";
import removeUndefinedWord from "../../helper/updateString";
import _ from "lodash";
import ButtonDropdown from "../../components/Buttons/ButtonDropdown";
import ExportModal from "../../components/Modals/ExportModal";
import { DateRangeSelect } from "../../components/Selects/DateRangeSelect";
import { MultiselectSearch } from "../../components/Selects/MultiselectSearch";
import { useGeneral } from "../../context/GeneralContext";
import { FiltersModal } from "./components/FiltersModal";
import { CiExport, CiFilter } from "react-icons/ci";
import { Center, Loader } from "@mantine/core";

const Leads = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { setAlertModal } = useGeneral();

  const [timezone, setTimezone] = useState(null);
  const [pageLimit, setPageLimit] = useState(7);
  const [search, setSearch] = useState(true);
  const [searchParam, setSearchParam] = useState("");
  const [propSearchParam, setPropSearchParam] = useState("");
  const [list, setList] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [listCount, setListCount] = useState(0);
  const [rowPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoader] = useState(false);
  const [listOrder, setListOrder] = useState("desc");

  const navigate = useNavigate();

  const options = [
    {
      value: 1,
      label: "Oldest",
    },
    {
      value: 2,
      label: "Most Recent",
    },
  ];

  const numberOfEntriesOptions = [
    {
      value: 7,
      label: "Number of Leads Per Page: 7",
    },
    {
      value: 20,
      label: "Number of Leads Per Page: 20",
    },
    {
      value: 50,
      label: "Number of Leads Per Page: 50",
    },
    {
      value: 100,
      label: "Number of Leads Per Page: 100",
    },
    {
      value: 200,
      label: "Number of Leads Per Page: 200",
    },
    {
      value: 500,
      label: "Number of Leads Per Page: 500",
    },
  ];

  // FILTERS
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedProperties, setSelectedProperties] = useState([]);

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterProperties, setFilterProperties] = useState("");

  const handleShowFiltersModal = () => setShowFiltersModal(true);

  const handleDateRangeChange = (value) => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  };

  const handleSelectedProperties = (value) => {
    setSelectedProperties(value);
  };

  const handleCloseFiltersModal = () => {
    if (!filterStartDate || !filterEndDate) {
      setStartDate("");
      setEndDate("");
    }
    if (!filterProperties) {
      setSelectedProperties([]);
    }
    setShowFiltersModal(false);
  };

  const handleApplyFilters = () => {
    if (startDate) setFilterStartDate(moment(startDate).format("YYYY-MM-DD"));
    if (endDate) setFilterEndDate(moment(endDate).format("YYYY-MM-DD"));
    if (selectedProperties.length)
      setFilterProperties(
        selectedProperties.map((property) => property._id).join()
      );
    if (!startDate) setFilterStartDate("");
    if (!endDate) setFilterEndDate("");
    if (!selectedProperties.length) setFilterProperties("");
    setShowFiltersModal(false);
  };

  const handleResetFilters = () => {
    setStartDate("");
    setEndDate("");
    setSelectedProperties([]);
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterProperties("");
  };

  //Export
  const [show, setShow] = useState(false);
  const [exportStartDate, setExportStartDate] = useState("");
  const [exportEndDate, setExportEndDate] = useState("");
  const [exportProperties, setExportProperties] = useState([]);
  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleExportDateRangeChange = (value) => {
    setExportStartDate(value[0]);
    setExportEndDate(value[1]);
  };

  const handleExport = (e) => {
    e.preventDefault();
    setIsGeneratingCsv(true)
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/contact/toCsv?`;

      if (exportProperties.length) {
        url = `${url}&propertyIds=${exportProperties
          .map((property) => property._id)
          .join()}`;
      }

      if (exportStartDate) {
        url = `${url}&startDate=${moment(exportStartDate).format(
          "YYYY-MM-DD"
        )}`;
      }
      if (exportEndDate) {
        url = `${url}&endDate=${moment(exportEndDate).format("YYYY-MM-DD")}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: "text/csv",
            });

            const a = document.createElement("a");
            a.download = "leads";
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
          }

          setExportStartDate("");
          setExportEndDate("");
          setExportProperties([]);
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          handleClose();

          setAlertModal({
            show: true,
            title: "Error",
            content: "There's no data to export",
          });
        })
        .finally(() => setIsGeneratingCsv(false));
    });
  };

  useEffect(() => {
    async function getTimezone() {
      try {
        const token = await Auth.currentSession();
        const response = await axios.get(
          `${process.env.REACT_APP_NODE_API_URL}/api/users`,
          {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          }
        );
        if (response.status === 200) {
          setTimezone(response.data.settings.bookings.timezone);
        } else {
          setShowAlert(true);
        }
      } catch (error) {
        console.log(error);
        setShowAlert(true);
      }
    }
    getTimezone();
  }, []);

  useEffect(() => {
    if (timezone !== null) {
      getContactList(page);
    }
  }, [
    timezone,
    page,
    search,
    listOrder,
    pageLimit,
    filterProperties,
    filterStartDate,
    filterEndDate,
  ]);

  useEffect(() => {
    filterDataByProperty();
  }, [propSearchParam]);

  const filterDataByProperty = () => {
    if (propSearchParam === "") {
      setTempList(list);
      return;
    }
    const data = [...list];
    const filteredData = data.filter((item) => {
      const property = item.bookings[0]?.property; // Use optional chaining
      const addressMatch = property?.address
        ?.toLowerCase()
        .includes(propSearchParam.toLowerCase() || "");
      const unitMatch = property?.unit
        ?.toLowerCase()
        .includes(propSearchParam.toLowerCase() || "");
      return addressMatch || unitMatch;
    });
    setTempList(filteredData);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const handleReset = () => {
    setPage(1);
    setSearchParam("");
    setSearch(!search);
  };

  const debouncedSearchOnChange = _.debounce((e) => {
    handleSearchOnchange(e);
  }, 1000);

  const handleSearch = () => {
    setPage(1);
    setSearch(!search);
  };

  const handleSearchOnchange = (e) => {
    setPage(1);
    setSearch(!search);
  };

  const handleNextPage = (nextPage) => {
    if (totalPages >= nextPage && nextPage > 0) {
      setPage(nextPage);
    }
  };

  const getContactList = (curretPage) => {
    setLoader(false);
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/contact?page=${curretPage}&order=${listOrder}&pageLimit=${pageLimit}`;

      if (searchParam !== "") {
        url = `${url}&search=${searchParam}`;
      }

      if (filterStartDate.length) {
        url = `${url}&startDate=${filterStartDate}`;
      }
      if (filterEndDate.length) {
        url = `${url}&endDate=${filterEndDate}`;
      }

      if (filterProperties.length) {
        url = `${url}&propertyIds=${filterProperties}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setListCount(response.data.total);
            setRowsPerPage(response.data.rowPerPage);
            setTotalPages(response.data.pages);
            setList(response.data.data);
            setTempList(response.data.data);
            setLoader(true);
          } else {
            setShowAlert(true);
          }
        })
        .catch((error) => {
          console.error(error)
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/");
              })
              .catch((error) => {
                console.log("error signing out: ", error);
              });
          } else {
            setShowAlert(true);
          }
        });
    });
  };
  const handleorder = (e) => {
    setListOrder(e[0].label);
    if (e[0].label === "Oldest") {
      setListOrder("asc");
    } else if (e[0].label === "Most Recent") {
      setListOrder("desc");
    }
  };

  const handlePageLimit = (e) => {
    setPageLimit(e[0].value);
  };

  const [propertiesData, setPropertiesData] = useState([]);

  const getProperties = () => {
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/property`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setPropertiesData(response.data);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {})
              .catch((error) => {
                console.log("error signing out: ", error);
              });
          } else {
          }
        });
    });
  };

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <Sidebar>
      <div className="page-main-head align-items-center">
        <Row className="align-items-center">
          <Col xl={12} className="mb-2">
            <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
              <h2 className="color-black">Leads</h2>
            </div>
          </Col>
          <Row className="flex">
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ width: "50%" }}
            >
              <Form
                className="w-100"
                style={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Form.Group
                  className="search-control-group m-0"
                  controlId="search-control"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search by Name, Email or Phone"
                    style={{ height: "40px" }}
                    value={searchParam}
                    onChange={(e) => {
                      setSearchParam(e.target.value);
                      debouncedSearchOnChange(e);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  {searchParam !== "" ? (
                    <Button
                      className="cancel-filter-button"
                      onClick={handleReset}
                      variant="link"
                    >
                      X
                    </Button>
                  ) : null}
                  <Button
                    className={
                      searchParam !== "" ? "search-btn active" : "search-btn"
                    }
                    onClick={handleSearch}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "end",
                width: "50%",
              }}
              className="m-0"
            >
              <div>
                <Select
                  options={options}
                  size="lg"
                  onChange={(e) => handleorder(e)}
                  className="btn w-100 w-lg-auto"
                  placeholder={options[1].label}
                />
              </div>
              <div>
                <Select
                  options={numberOfEntriesOptions}
                  size="lg"
                  onChange={(e) => handlePageLimit(e)}
                  className="btn w-100 w-lg-auto"
                  placeholder={numberOfEntriesOptions[0].label}
                />
              </div>
            </Col>
          </Row>
        </Row>
      </div>

      <div className="filter-alert ps-4 d-flex flex-row justify-content-between align-items-center">
        <div className="">
          {listCount > 0 ? (
            <p>Total Contacts: {listCount}</p>
          ) : (
            <p>No Records Found</p>
          )}
        </div>
        <div className="d-flex justify-content-end">
          <div className="mr-10">
            <ButtonDropdown
              title={!isGeneratingCsv ? "Export" : "Loading..."}
              icon={<CiExport size="18px" className="mr-10" />}
              items={[
                { name: "Export All Leads", handleClick: handleExport },
                { name: "Filter by Date or Property", handleClick: handleShow },
              ]}
            />
          </div>

          <Button
            variant="primary"
            onClick={handleShowFiltersModal}
            className="d-flex align-items-center mr-10"
          >
            <CiFilter size="22px" className="mr-10" /> <span>Filter</span>
          </Button>
          <Button
            variant="outline-dark"
            onClick={handleResetFilters}
            className="d-flex align-items-center"
          >
            Clear filters
          </Button>
        </div>
        <ExportModal
          show={show}
          handleClose={handleClose}
          handleExport={handleExport}
          filters={[
            <Col>
              <Form.Label htmlFor="dateRangeSelect">Date</Form.Label>
              <DateRangeSelect
                startDate={exportStartDate}
                endDate={exportEndDate}
                handleDateRangeChange={handleExportDateRangeChange}
              />
            </Col>,
            <Col>
              <Form.Label htmlFor="propertySelect">Property</Form.Label>
              <MultiselectSearch
                data={propertiesData?.properties}
                selected={exportProperties}
                setSelected={(value) => setExportProperties(value)}
              />
            </Col>,
          ]}
        />
        <FiltersModal
          show={showFiltersModal}
          handleClose={handleCloseFiltersModal}
          handleDateRangeChange={handleDateRangeChange}
          startDate={startDate}
          endDate={endDate}
          handleSelectedProperties={handleSelectedProperties}
          selectedProperties={selectedProperties}
          handleApplyFilters={handleApplyFilters}
        />
      </div>

      <div className="content-div my-3">
        <Table responsive className="property-table">
          {loading === false ? (
            <Center mt={20}>
              <Loader/>
            </Center>
          ) : (
            <tbody>
              {tempList.map((element, index) => (
                <tr key={element._id} className="cursor-pointer">
                  <td>
                    <span className="order">
                      {" "}
                      {index + 1 + (page - 1) * rowPerPage}.
                    </span>
                    added on
                    <br />
                    {moment(element.createdAt ?? element.createdDate).format("MM/DD/YYYY")}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <Link
                        // to="/leads-details"
                        to={`/leads-details/${element.contact._id}`} //TODO: check
                        state={{ user_id: element.contact._id }}
                        className="properties-details-link"
                      >
                        <span className="position-relative d-inline-block">
                          <img
                            src={getImageUrl(element.contact.document ?? element.contact.idImage)}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = require("../../assests/images/aljnfn.png");
                            }}
                            alt="properties"
                            width={140}
                            height={90}
                          />
                        </span>
                      </Link>
                      <div className="ml-20">
                        <div className="d-block">
                          <Link
                            to="#"
                            className="table-users users-w-ico link-w-ico color-black"
                          >
                            {element.contact.firstName}{" "}
                            {element.contact.lastName}
                          </Link>
                        </div>
                        <div className="d-block">
                          <Link
                            to="tel:202-555-0153"
                            className="table-phone phonenumber-w-ico link-w-ico color-black"
                          >
                            {element.contact.phone}
                          </Link>
                        </div>
                        <div className="d-block">
                          <Link
                            to="mailto:michael.anderson@gmail.com"
                            className="table-mail mail-w-ico link-w-ico"
                          >
                            {element.contact.email}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {/* {element.bookings && element.bookings.length > 0 ? ( // TODO */}
                    <Link
                      to={`/property/${element.property?._id}`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="d-flex align-items-center no-wrap-small-screen">
                        <span className="position-relative d-inline-block">
                          <img
                            width="70"
                            height="70"
                            src={
                              element.property?.images[0]
                                ? element.property.images[0]
                                  : require("../../assests/images/3-thumb.png")
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = require("../../assests/images/3-thumb.png");
                            }}
                            alt="property"
                          />
                        </span>

                        <div className="ml-20">
                          <div className="d-block latest-showing">
                            latest showing
                            <br />
                            {moment(element.startTime * 1000).format(
                              "MMM  DD, yyyy hh:mm a"
                            )}
                          </div>
                          <div className="d-block">
                            {element.property
                              ? `${removeUndefinedWord(
                                  element.property.address
                                )}${
                                  element.property && element.property?.unit
                                    ? `, Unit ${element.property.unit}`
                                    : ""
                                }`
                              : "PROPERTY NOT FOUND"}
                          </div>
                        </div>
                      </div>
                    </Link>
                    {/* )
                    : (
                      <div className="d-flex align-items-center no-wrap-small-screen latest-showing">
                        LATEST SHOWING NOT FOUND
                      </div>
                    )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {totalPages > 1 && loading === true ? (
          <div className="pagination_component">
            <PagePagination
              totalPages={totalPages}
              actualPage={page}
              handlePageChange={handleNextPage}
            />
          </div>
        ) : null}

        {showAlert ? (
          <Modal show={showAlert}>
            <Modal.Header>
              <Modal.Title>Alert</Modal.Title>
              <button
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              ></button>
            </Modal.Header>
            <Modal.Body>Something Went Wrong</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowAlert(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
      </div>
    </Sidebar>
  );
};

export default Leads;
