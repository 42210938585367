import React, {
} from 'react';
import styled from 'styled-components';

const StyledFieldSet = styled.fieldset`
display: flex;
flex-direction: column;
    color: #66676B;
    gap: 8px;
    margin-bottom: 0; 
    padding: 0; 
    border: none;
`;

const StyledRadioLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
`;

const StyledOption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
`;

const StyledRadioInput = styled.input.attrs({ type: 'radio' })`
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: #FFF;
  border : 2px solid #66676B;
  margin-top: 1px;
  box-shadow: none;

  &:checked {
    background-color: #5D5FEF;
    border : 2px solid #5D5FEF;
    box-shadow: none;
    background-color: #000;
    color: #000;
    --bs-check-indeterminate-color: #000;
    --bs-check-indeterminate-bg: #000;
    --bs-check-indeterminate-size: 1.5em;
    --bs-check-indeterminate-box-shadow: inset 0 0 0 0.25em #000;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='11' fill='%235D5FEF' stroke='%23fff' stroke-width='3'/%3E%3C/svg%3E");

  }

  &:focus {
    outline: none;
    box-shadow: none;
    border : 2px solid #5D5FEF;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #EAEAEB;
    border : 2px solid #EAEAEB;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='9' fill='%EAEAEB' stroke='%EAEAEB' stroke-width='3'/%3E%3C/svg%3E");
  }
`;


const StyledLabel = styled.label`
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-weight: ${props =>
    props.variantType === 'primary' ? '700' : props.variantType === 'secondary' ? '400' : '700'};
  line-height: 140%;
  letter-spacing: 0.16px;

  /* disabled styles */
  ${props =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const StyledRadioText = styled.span`
  color: #000;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.18px;

  /* disabled styles */
  ${props =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const InputRadio = ({
  disabled = false,
  name,
  options,
  value,
  initialValue,
  register,
  error = {},
  errorMessage = "This field is required",
  ...props
}) => {

  const registerFields = {};

  if (props?.required)
    registerFields["required"] = props.required;

  if (props?.maxLength)
    registerFields["maxLength"] = props.maxLength;

  return (
    <StyledFieldSet>
      {props?.label &&
        <StyledLabel
          variantType={props?.variantType}
          htmlFor={props?.name}
          disabled={disabled}
          className='body-s-bold'>
          {props?.label}
        </StyledLabel>
      }
      <StyledRadioLabel
        htmlFor={props?.name}
        key={props?.name}
      >
        {options.map((option) => (
          <StyledOption key={option.value}>
            <StyledRadioInput
              className='form-check-input'
              type="radio"
              key={option.value + "RadioInput"}
              value={option.value}
              {...register(name, registerFields)}
              disabled={disabled}
              defaultChecked={value === option.value || initialValue === option.value}
              {...props}
            />
            <StyledRadioText
              key={option.value + "RadioText"}
              disabled={disabled}
            >   {option.name}
            </StyledRadioText>
          </StyledOption>
        ))}
      </StyledRadioLabel>
      {error[name] && <span className="error">{errorMessage}</span>}
    </StyledFieldSet>
  );
};

export default InputRadio;
