import React from "react";

const HardwareList = ({ property }) => {
  return (
    <div className="d-flex flex-column px-5 gap-3">
      <div className="d-flex border-bottom border-secondary py-2 px-1">
        <span className="col-3 fw-semibold">Type</span>
        <span className="col fw-semibold">Name</span>
      </div>
      {property?.kit?.hardware?.map((hardware) => (
        <div className="d-flex py-2 px-1 border-bottom" key={hardware._id}>
          <span className="col-3 text-capitalize">{hardware.category}</span>
          <span className="col text-capitalize">{hardware.name}</span>
        </div>
      ))}
    </div>
  );
};

export default HardwareList;
