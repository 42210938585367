import React from "react";

function YourCart({ orders, totalPrice, step }) {
  const kits = orders[0];

  return (
    <div className="shadow-lg d-flex flex-column gap-2 p-3">
      {step === 2 && kits.amount > 0 ? (
        <div className="card-subtitle mb-2 text-muted fw-bold d-flex justify-content-between">
          <p className="d-flex gap-2 align-items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 15L12 8L19 15"
                stroke="#979797"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Subscription ({kits.amount} kit{kits.amount > 1 ? 's' : ''})
          </p>
          <span>${kits.price} / month</span>
        </div>
      ) : (
        <h4 className="card-subtitle mb-2 text-muted">Your cart</h4>
      )}
      {orders.map((order) => (
        <div className="d-flex justify-content-between">
          <strong className="col">
            {order.amount} {order.name}
          </strong>
          <strong className="col">
            ${order.individualPrice * order.amount}
          </strong>
          <button
            type="button"
            className="border-0 text-delet-blue bg-white fw-bold"
          >
            Add notes
          </button>
        </div>
      ))}
      <div className="d-flex justify-content-between">
        <strong>Total</strong>
        <strong>${totalPrice}</strong>
      </div>
    </div>
  );
}

export default YourCart;
