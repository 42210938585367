import React, { createContext, useContext, useState } from 'react';

const GeneralContext = createContext();

const GeneralProvider = ({ children }) => {
  const [alertModal, setAlertModal] = useState({ show: false, title: 'Alert', content: '' });
  const [timezone, setTimezone] = useState(0);

  return (
    <GeneralContext.Provider value={{ alertModal, setAlertModal, timezone, setTimezone }}>
      {children}
    </GeneralContext.Provider>
  );
};

const useGeneral = () => {
  return useContext(GeneralContext);
};

export { GeneralProvider, useGeneral };