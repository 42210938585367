import "./UserInfo.scss";

import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import Sidebar from "../../components/SideBar";
import InputText from "../../components/InputText";
import InputImage from "../../components/InputImage";
import { Center, Flex, Loader, Title, Modal } from "@mantine/core";
import { MdAdd } from "react-icons/md";
import ChangePassword from "../Auth/ChangePassword";
import { useDisclosure } from "@mantine/hooks";

const UserProfile = () => {
  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Alert");
  const [alertContent, setAlertContent] = useState("");

  const [userData, setUserData] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [companyImage, setCompanyImage] = useState("");

  // initial data fetch loader
  const [loading, setLoader] = useState(false);

  const [companyLogoUpdated, setCompanyLogoUpdated] = useState(false);
  const [profileLogoUpdated, setProfileLogoUpdated] = useState(false);
  const [companyData, setCompanyData] = useState();
  // form submit loader
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    Auth.currentSession()
      .then((token) => {
        axios
          .get(`${process.env.REACT_APP_NODE_API_URL}/api/users/`, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              setUserImage(response.data?.profileImage);
              setCompanyImage(response.data.company?.idImage);
              setUserData(response.data);
              setLoader(true);
            } else {
              setShowAlert(true);
              setAlertTitle("Alert");
              setAlertContent("Error Getting Profile");
            }
          })
          .catch((error) => {
            console.log(error);
            if (error?.response?.status === 401) {
              Auth.signOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  console.log("Error signing out: ", error);
                });
            } else {
              setShowAlert(true);
              setAlertTitle("Alert");
              setAlertContent("Error Getting Profile");
            }
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              console.log("Error signing out: ", error);
            });
        } else {
          setShowAlert(true);
          setAlertTitle("Alert");
          setAlertContent("Error Getting Profile");
        }
      });
  }, [navigate]);
  const getAgentCompany = () => {
    Auth.currentSession()
      .then((token) => {
        axios
          .get(`${process.env.REACT_APP_NODE_API_URL}/api/users/company/agent`, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          })
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              setCompanyData(response?.data?.company)
              setLoader(true);
            } else {
              setShowAlert(true);
              setAlertTitle("Alert");
              setAlertContent("Error Getting Profile");
            }
          })
          .catch((error) => {
            console.log(error);
            if (error?.response?.status === 401) {
              Auth.signOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  console.log("Error signing out: ", error);
                });
            } else {
              setShowAlert(true);
              setAlertTitle("Alert");
              setAlertContent("Error Getting Profile");
            }
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              console.log("Error signing out: ", error);
            });
        } else {
          setShowAlert(true);
          setAlertTitle("Alert");
          setAlertContent("Error Getting Profile");
        }
      });
  }
  useEffect(() => {
    userData?.role === "agent" && getAgentCompany()
  }, [navigate, userData.role]);


  const onUserImageChange = (e) => {
    const fileReader = new FileReader();
    if (e.target.files[0]) {
      fileReader.readAsDataURL(e.target.files[0]);
    }
    fileReader.onload = () => {
      let image = fileReader.result;
      setUserImage(image);
      setProfileLogoUpdated(true);
    };
  };

  const onUserCompanyImageChange = (e) => {
    const fileReader = new FileReader();
    if (e.target.files[0]) {
      fileReader.readAsDataURL(e.target.files[0]);
    }
    fileReader.onload = () => {
      let image = fileReader.result;
      setCompanyImage(image);
      setCompanyLogoUpdated(true);
    };
  };

  const onSubmit = (data) => {
    Auth.currentSession()
      .then((token) => {
        setIsLoading(true);
        axios
          .patch(
            `${process.env.REACT_APP_NODE_API_URL}/api/users/profile`,
            {
              firstName: data.firstName,
              lastName: data.lastName,
              company: {
                name: data.company.name,
                idImage: companyLogoUpdated ? companyImage : "",
              },
              profileImage: profileLogoUpdated ? userImage : "",
            },
            {
              headers: {
                Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
              },
            }
          )
          .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
              setShowAlert(true);
              setAlertTitle("Saved");
              setAlertContent("Settings Updated Successfully");
            } else {
              setShowAlert(true);
              setAlertTitle("Alert");
              setAlertContent("Error Updating Profile");
            }
          })
          .catch((error) => {
            setIsLoading(false);
            setShowAlert(true);
            setAlertTitle("Alert");
            setAlertContent("Error Updating Profile");
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              console.log("Error signing out: ", error);
            });
        } else {
          setShowAlert(true);
          setAlertTitle("Alert");
          setAlertContent("Error Updating Profile");
        }
      });
  };

  const [opened, { open, close }] = useDisclosure(false);

  const handleOpenPasswordModal = () => {
    open();
  };

  const handleChangePassword = (value) => {
    console.log(value);
  };
  return (
    <Sidebar>
      <Flex
        bg={"#eaeaeb"}
        justify={"space-between"}
        align={"center"}
        px={"26px"}
        py={"20px"}
      >
        <Title order={1} fw={900} lh={"45px"}>
          Profile
        </Title>

        <Button color="black" leftSection=<MdAdd /> onClick={() => open()}>
          Change Password
        </Button>
      </Flex>
      {loading === false ? (
        <Center h={100}>
          <Loader />
        </Center>
      ) : (
        <div className="content-div mt-3">
          <Row>
            <Col lg={8}>
              <Card>
                <Card.Header as="h5">User Info</Card.Header>
                <Card.Body>
                  <Formik initialValues={userData} onSubmit={onSubmit}>
                    <Form>
                      <InputText label="First Name" name="firstName" />
                      <InputText label="Last Name" name="lastName" />
                      {userData?.role === "agent" ? (
                        <>
                          <InputText label="Company Name" name="company.name" value={companyData?.name} disabled />
                          <InputImage
                            defaultImage={require("../../assests/images/placeholder.png")}
                            file={companyData?.idImage}
                            name="companyImage"
                            hideUploadBtn={true}
                            disabled
                          />
                        </>
                      ) : (
                        <>
                          <InputText label="Company Name" name="company.name" />
                          <InputImage
                            buttonText="Upload company logo"
                            description="Drag an image into this box or use the upload button."
                            defaultImage={require("../../assests/images/placeholder.png")}
                            file={companyImage}
                            name="companyImage"
                            onChange={onUserCompanyImageChange}
                          />
                        </>
                      )}
                      {isLoading ? (
                        <Button disabled>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Saving...
                        </Button>
                      ) : (
                        <Button type="submit">Save Settings</Button>
                      )}
                    </Form>
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}

      <Modal
        title={alertTitle}
        opened={showAlert}
        onClose={() => setShowAlert(false)}
      >
        {alertContent}
        {alertTitle === "Saved" ? (
          <Flex justify={"end"} mt={10}>
            <Button
              variant="secondary"
              onClick={() => {
                navigate("/property/list");
                setShowAlert(false);
              }}
            >
              Okay
            </Button>
          </Flex>
        ) : (
          <Flex justify={"end"} mt={10}>
            <Button variant="secondary" onClick={() => setShowAlert(false)}>
              Close
            </Button>
          </Flex>
        )}
      </Modal>
      <Modal title="Change password" opened={opened} onClose={close}>
        <ChangePassword close={close} userId={userData._id} />
      </Modal>
    </Sidebar>
  );
};

export default UserProfile;
