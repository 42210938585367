import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { createPopper } from '@popperjs/core';

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
`;

const DropdownMenu = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  width: 260px;
  height: auto;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
  transform: ${(props) => (props.isOpen ? 'translateY(0)' : 'translateY(-10px)')};
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
`;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  text-align: start;
  white-space: normal;
  color: ${(props) => (props.menuItemColor || '#000')};
  font-family: ${(props) => (props.menuItemFontFamily || 'MulishRegular')};
  font-size: ${(props) => (props.menuItemFontSize || '16px')};
  font-style: normal;
  font-weight: ${(props) => (props.menuItemFontWeight || 400)};
  line-height: ${(props) => (props.menuItemLineHeight || '140%')};
  letter-spacing: ${(props) => (props.menuItemLetterSpacing || '0.16px')};
  &:hover {
    background-color: #ececec;
  }
`;

const CustomDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuPopper, setMenuPopper] = useState(null);
  const [menuReference, setMenuReference] = useState(null);
  const menuContainerRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (action) => {
    if (props.onMenuItemClick) {
      props.onMenuItemClick(action);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (menuReference && menuContainerRef.current) {
      const popperInstance = createPopper(menuReference, menuContainerRef.current, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: document.body,
            },
          },
        ],
      });

      setMenuPopper(popperInstance);
    }
  }, [menuReference]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuContainerRef.current && !menuContainerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <MenuContainer>
      <IconButton ref={setMenuReference} onClick={toggleDropdown}>
        {props.icon}
        <DropdownMenu ref={menuContainerRef} isOpen={isOpen}>
          {props.menuItems.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => handleMenuItemClick(item.label)}
              menuItemColor={props.menuItemColor}
              menuItemFontFamily={props.menuItemFontFamily}
              menuItemFontSize={props.menuItemFontSize}
              menuItemFontWeight={props.menuItemFontWeight}
              menuItemLineHeight={props.menuItemLineHeight}
              menuItemLetterSpacing={props.menuItemLetterSpacing}
            >
              {item.label}
            </MenuItem>
          ))}
        </DropdownMenu>
      </IconButton>
    </MenuContainer>
  );
};

export default CustomDropdown;
