import React, { useState } from "react";
import { useForm } from "@mantine/form";
import { CloseButton, TextInput } from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getProperties } from "../../../api/properties";
import Select from "react-select";
import { updateKit } from "../../../api/kits";

type ShippingAddressFormProps = {
  close: () => void,
};

const ShippingAddressForm = ({ product, close }: ShippingAddressFormProps) => {
  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: product.shippingAddress,

    validate: {},
  });

  const {
    data: properties,
    isLoading: loadingProperties,
    isError: errorGettingProperties,
  } = useQuery({
    queryKey: ["properties"],
    queryFn: () => getProperties(),
  });

  const {mutateAsync: updateKitAddress, isPending: updatingKit, isError: errorUpdatingKit } = useMutation({
    mutationFn: () => updateKit(product._id, {
      shippingAddress: form.values
    }),
    onSuccess: (data) => {
      queryClient.setQueryData(['kits-from-order', product.order], (productsArr) => {
        const productIndex = productsArr.findIndex(prod => prod._id === product._id)
        productsArr[productIndex] = data
        return productsArr
      });
      close()
    }
  })

  const [selectedProperty, setSelectedProperty] = useState();

  const selectProperty = (property) => {
    if (!property) {
      setSelectedProperty(null);
    } else {
      setSelectedProperty((prev) => ({ ...prev, ...property }));
      form.setValues({
        street: property.address,
        city: property.city,
        state: property.state,
        zipCode: property.zipCode,
        country: "US",
      });
    }
  };

  return (
    <section className="d-flex flex-wrap pb-5">
      <div className="d-flex justify-content-end col-12">
        {loadingProperties ? (
          <strong>Getting properties...</strong>
        ) : errorGettingProperties ? (
          <strong>
            Error getting properties, close this window and try again
          </strong>
        ) : (
          <Select
            options={properties.properties}
            onChange={(property) => selectProperty(property)}
            getOptionValue={(property) => property}
            getOptionLabel={(property) => property.shortAddress}
            value={selectedProperty}
            className="w-50"
          ></Select>
        )}

        {selectedProperty?._id ? (
          <CloseButton onClick={() => setSelectedProperty(null)} />
        ) : null}
      </div>
      <form
        onSubmit={form.onSubmit((values) => updateKitAddress(values, product))}
        className="col-12"
      >
        <div className="d-flex flex-wrap gap-2">
          <TextInput
            label="Street"
            {...form.getInputProps("street")}
            className="col-12"
            disabled={selectedProperty}
          />
          <TextInput
            label="Zip Code"
            {...form.getInputProps("zipCode")}
            className="col"
            disabled={selectedProperty}
          />
          <TextInput
            label="City"
            {...form.getInputProps("city")}
            className="col"
            disabled={selectedProperty}
          />
          <TextInput
            label="State"
            {...form.getInputProps("state")}
            className="col"
            disabled={selectedProperty}
          />
          <div className="col-12 d-flex justify-content-end">
            <button
              type="submit"
              className="rounded bg-delet text-white border-0 px-2 py-1 fw-semibold"
              disabled={updatingKit}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default ShippingAddressForm;
