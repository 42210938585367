import React from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

const ButtonDropdown = ({title, icon, items}) => {
    return (
        <DropdownButton
        as={ButtonGroup}
        title={
          <>
            {icon}
            <span className="mr-10 ml-10">{title}</span>
          </>
        }
        id="export-dropdown"
      >
      {items?.map((item, i) => 

        <Dropdown.Item key={i} eventKey={1} as="button" onClick={item.handleClick}>
          {item.name}
        </Dropdown.Item>

      )}      
      </DropdownButton>
    );
};

export default ButtonDropdown;