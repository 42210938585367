import React from "react";

const CodeRowSkeleton = () => {
  return (
    <p class="card-text placeholder-glow d-flex gap-2 pe-3">
      <span class="placeholder col placeholder-sm rounded-1 bg-primary"></span>
      <span class="placeholder col placeholder-sm rounded-1 bg-primary"></span>
      <span class="placeholder col placeholder-sm rounded-1 bg-primary"></span>
      <span class="placeholder col placeholder-sm rounded-1 bg-primary"></span>
      <span class="placeholder col placeholder-sm rounded-1 bg-primary"></span>
      <span class="placeholder col placeholder-sm rounded-1 bg-primary"></span>
    </p>
  );
};

export default CodeRowSkeleton;
