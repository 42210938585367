import axios from "axios";

export const analyzeId = async (body: {
  document: string;
  documentBack?: string;
  profile: "security_low" | "security_medium" | "security_high" | "security_none";
  requireFace?: boolean
  face?: string;
}) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/id-analyzer`;

  try {
    const res = await axios.post(url, body);
    return res.data;
  } catch (error) {
    console.log("ERROR WITH ID ANALYZER: ", error);
    throw error;
  }
};
