function removeUndefinedWord(str) {
    const cleanedStr = str?.split(' ').filter(word => word.trim() !== 'undefined').join(' ');
    
    if (cleanedStr && cleanedStr.trim().endsWith(',')) {
        return cleanedStr.slice(0, -1).trim();
    }
    
    return cleanedStr;
}

export default removeUndefinedWord;
