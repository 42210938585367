import React, {
    useEffect,
    useState,
  } from 'react';

  import axios from 'axios';
  import {
    Button,
    Card,
    Col,
    Form,
    Row,
  } from 'react-bootstrap';
  import Modal from 'react-bootstrap/Modal';
  import { ColorRing } from 'react-loader-spinner';
  import {
    useNavigate,
    useParams,
  } from 'react-router-dom';
  import { Auth } from 'aws-amplify';
import Sidebar from '../../../../components/SideBar';
import { MultiselectPropertySearch } from '../../../../components/Selects/MultiselectSearch';


const EditUser = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [Loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [userData, setUserData] = useState({
    description: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    role: "",
    company: "",
    active: "",
  });
  const [propertiesData, setPropertiesData] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [isUpdatingUser, setUpdatingUser] = useState(false);
  useEffect(() => {
    const id = params.id;
    if (id) {
      const getUserDetails = async (id) => {
        try {
          const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/${id}`;
          const token = await Auth.currentSession();

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          });
          if (response.data) {
            setUserData(response.data);
            setLoader(true);
          } else {
            setError("Error getting User");
            setShow(true);
          }
        } catch (error) {
          console.log(error);
          if (error.response) {
            if (error?.response?.status === 401) {
              Auth.signOut()
                .then(() => {
                  navigate("/admin/login");
                })
                .catch((error) => {
                  console.log("Error signing out: ", error);
                });
            } else if (error.response.status === 404) {
              if (error.response.data.message) {
                setError(error.response.data.message);
                setShow(true);
              } else {
                setError(error.message);
                setShow(true);
              }
            } else {
              setError("Error getting User");
              setShow(true);
            }
          } else {
            setError(error);
            setShow(true);
          }
        }
      };
      getUserDetails(id);
    } else {
      navigate("/admin/login");
    }
  }, [navigate, params.id]);

  const getProperties = () => {
    const id = params.id;
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/property/agent`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setPropertiesData(response.data);
            const filteredProperties = response.data.properties.filter((prop) =>
              prop.allowedAgents.includes(id)
            );
            setSelectedProperties(filteredProperties);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {})
              .catch((error) => {
                console.log("error signing out: ", error);
              });
          } else {
          }
        });
    });
  };

  useEffect(() => {
    getProperties();
  }, []);
  const handleChange = (e) => {
    setUserData((userData) => ({
      ...userData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleStatus = (e) => {
    setSelectStatus(e);
  };

  const onSubmit = async (e) => {
    var pattern = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );
    let data = {
      company: {
        name: userData.company.name ? userData.company.name : userData.company,
      },
      description: userData.description,
      lastName: userData.lastName,
      firstName: userData.firstName,
      phone: userData.phone,
      email: userData.email,
      active: selectStatus ? selectStatus : userData.active,
      role: selectValue ? selectValue : userData.role,
      id: params.id,
      selectedProperties,
    };
    if (!pattern.test(userData.phone)) {
      setError("Please enter a valid phone number");
      setShow(true);
    } else if (userData.phone.length !== 10) {
      setError("Please enter a valid phone number");
      setShow(true);
    } else {
      try {
        setUpdatingUser(true)
        const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/account/admin/${params.id}`;
        const token = await Auth.currentSession();

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: url,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
          data: data,
        };

        const response = await axios.request(config);

        if (response.status === 200 || response.status === 201) {
          setError("");
          setUpdatingUser(false)
          setShow(true);
        }
      } catch (error) {
        setUpdatingUser(false)
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/admin/login");
            })
            .catch((error) => {
              console.log("Error signing out: ", error);
            });
        } else {
          console.log(error);
          setError("Error on edit user");
          setShow(true);
        }
      }
    }
  };

  return (
    <>
      <Sidebar>
        <div className="page-main-head align-items-center">
          <Row className="align-items-center">
            <Col xl={8}>
              <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                <h1 className="h4 color-black mr-20">Edit User</h1>
              </div>
            </Col>
            <Col xl={4}></Col>
          </Row>
        </div>
        <Row className="mt-30">
          {Loader === false ? (
            <>
              <div style={{ textAlign: "center" }}>
                <ColorRing
                  visible={true}
                  height="100"
                  width="100"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#1F60AE",
                    "#5C79FF",
                    "#455DC8",
                    "#889DFF",
                    "#5271FF",
                  ]}
                />
              </div>
            </>
          ) : (
            <Col xl={12} lg={12}>
              <Card>
                <Card.Header as="h5">Personal information</Card.Header>
                <Card.Body>
                  <Form.Group className="mb-20" controlId="Full-name">
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>First name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          name="firstName"
                          value={userData.firstName}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-20" controlId="Mobile">
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Last name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          name="lastName"
                          value={userData.lastName}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-20" controlId="Email">
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Email</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          name="email"
                          value={userData.email}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-20" controlId="Building-manager">
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Mobile</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <Form.Control
                          type="tel"
                          pattern="^-?[0-9]\d*\.?\d*$"
                          maxLength={10}
                          minLength={10}
                          onChange={handleChange}
                          name="phone"
                          value={userData.phone}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-20" controlId="Building-manager">
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Company Name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          name="company"
                          value={userData.company ? userData.company.name : ""}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-20" controlId="Building-manager">
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Description</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          name="description"
                          value={userData.description}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-20" controlId="Building-manager">
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Assign Property</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <MultiselectPropertySearch
                          data={propertiesData?.properties}
                          selectedProperties={selectedProperties}
                          handleSelectedProperties={(value) =>
                            setSelectedProperties(value)
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-20" controlId="Building-manager">
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Status</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <Form.Select
                          onChange={(event) => handleStatus(event.target.value)}
                          value={selectStatus ? selectStatus : userData.active}
                        >
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-md-center">
                    <div className="d-flex">
                      <Button
                        className="btn btn-primary waves-effect waves-light  mr-30"
                        type="submit"
                        onClick={onSubmit}
                        disabled={isUpdatingUser}
                      >
                        Edit User
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </Sidebar>
      {show ? (
        <Modal show={show}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Saved</Modal.Title>
              </Modal.Header>

              <Modal.Body>User Updated Successfully</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigate("/account?activeTab=users");
                    setShow(false);
                  }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default EditUser;
