import { ReactNode } from 'react';
import { useWindowValue } from '../../hooks/useWindowValue';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Dialog from '@mui/material/Dialog';
import style from './style.module.scss';
import handle from '../../assests/images/drawer-handle.svg';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  mode?: 'modal' | 'drawer';
};

export const DrawerModalContainer = ({
  children,
  mode,
  isOpen,
  onClose,
  onOpen,
}: Props) => {
  const windowWidth = useWindowValue('innerWidth', 'resize');

  const definedMode: Props['mode'] =
    mode || windowWidth < 650 ? 'drawer' : 'modal';
  if (definedMode === 'drawer') {
    return (
      <SwipeableDrawer
        classes={{ paper: style.drawer }}
        disableSwipeToOpen={true}
        anchor='bottom'
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
      >
        <img
          className={style.handle}
          src={handle}
          alt='handle'
        />
        <div className={style.content}>{children}</div>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Dialog
        transitionDuration={0}
        classes={{ paper: style.modal }}
        open={isOpen}
        onClose={onClose}
      >
        <div className={style.content}>{children}</div>
      </Dialog>
    );
  }
};
