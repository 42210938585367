import Pagination from 'react-bootstrap/Pagination';

const PagePagination = ({ totalPages, actualPage, handlePageChange }) => {
    return (
        <Pagination >
            <Pagination.First
                disabled={actualPage === 1}
                onClick={() => handlePageChange(1)}
            />
            <Pagination.Prev
                disabled={actualPage === 1}
                onClick={() => handlePageChange(actualPage - 1)} />
            <Pagination.Item>{actualPage}</Pagination.Item>
            <Pagination.Next
                disabled={actualPage === totalPages}
                onClick={() => handlePageChange(actualPage + 1)}
            />
            <Pagination.Last
                disabled={actualPage === totalPages}
                onClick={() => handlePageChange(totalPages)}
            />
        </Pagination>
    );
}
export default PagePagination;