import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Sidebar from '../components/AdminSideBar';
import { Center, Loader } from '@mantine/core';

const AccountDetails = () => {
  const [modelshow, setModelShow] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoader] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const id = params.id;
    if (id) {
      const getHardwareList = async (id) => {
        try {
          const url = `${process.env.REACT_APP_NODE_API_URL}/api/account/${id}`;
          const token = await Auth.currentSession();

          const response = await axios
            .get(url, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } });

          if (response.status === 200) {
            setList(response.data);
            setLoader(true);
          } else {
            setError('Error getting account');
            setModelShow(true);
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            Auth.signOut().then(() => {
              navigate('/admin/login');
            }).catch((error) => {
              console.log('Error signing out: ', error);
            })
          } else {
            setError('Error getting account');
            setModelShow(true);
          }
        }
      }
      getHardwareList(id);
    } else {
      navigate('/admin/login');
    }
  }, [navigate, params.id])

  const handleDelete = async (e) => {
    try {
      e.preventDefault()

      const url = `${process.env.REACT_APP_NODE_API_URL}/api/account/${params.id}`
      const token = await Auth.currentSession();

      const response = await axios
        .delete(url, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } });

      if (response.status === 200) {
        setModelShow(false)
        setDelete(true)
      } else {
        setError('Error deleting account')
        setModelShow(true)
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        Auth.signOut().then(() => {
          navigate('/admin/login');
        }).catch((error) => {
          console.log('Error signing out: ', error);
        });
      } else {
        setError('Error deleting account');
        setModelShow(true);
      }
    }
  }
  return (
    <>
      <Sidebar>
        <div className="page-main-head align-items-center">
          <Row className="align-items-center">
            <Col xl={8}>
              <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                <h1 className="h4 color-black mr-20">Account Details</h1>
              </div>
            </Col>
            <Col xl={2}>
              <div className="d-flex align-items-center justify-content-md-end">
                <div className="d-flex">
                  <Link
                    to={`/admin/account/edit/${list._id}`}
                    size="sm"
                    className="btn btn-primary btn-sm mr-30"
                  >
                    Edit Account
                  </Link>
                </div>
              </div>
            </Col>
            <Col xl={2}>
              <div className="d-flex align-items-center justify-content-md-end">
                <div className="d-flex">
                  <Link
                    onClick={() => setModelShow(true)}
                    className="btn btn-danger  mr-30"
                  >
                    Delete Account
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="mt-30">
          <Col xl={12} lg={12}>
            <Card>
              <Card.Header as="h5">Personal information</Card.Header>
              {!loading ? (
                <Center mt={20}>
                  <Loader/>
                </Center>
              ) : (
                <Card.Body>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Full-name"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Name</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.name}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Mobile"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Description</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p>{list.description}</p>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Notes</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p>{list.notes}</p>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Category</Form.Label>
                      </Col>
                      <Col lg={10}>
                        <p> {list.category}</p>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group
                    className="mb-20 form-group-border pb-8"
                    controlId="Building-manager"
                  >
                    <Row className="align-items-center">
                      <Col lg={2}>
                        <Form.Label>Vendor</Form.Label>
                      </Col>

                      <Col lg={10}>
                        <p> {list.vendor}</p>
                      </Col>
                    </Row>
                  </Form.Group>
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </Sidebar>
      {modelshow &&
        <Modal show={modelshow}>
          {error !== '' ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModelShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Delete Account</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>

              <Modal.Body>Are you sure you want to delete account?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      }
      {Delete &&
        <Modal show={Delete}>
          <>
            <Modal.Header>
              <Modal.Title>Deleted</Modal.Title>
            </Modal.Header>

            <Modal.Body>Account Successfully</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  navigate('/admin/account/list')
                  setDelete(false)
                }}
              >
                Okay
              </Button>
            </Modal.Footer>
          </>
        </Modal>
      }
    </>
  );
}

export default AccountDetails;
