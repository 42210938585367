import React, { ButtonHTMLAttributes } from 'react';
import style from './style.module.scss';
import { LinkProps } from 'react-router-dom';
import { UnstyledAppButton } from './unstyled';

export type BaseProps = {
  children?: React.ReactNode;
  hierarchy?: 'primary' | 'secondary' | 'link' | 'text';
  size?: 'medium' | 'large';
  className?: string;
  disabled?: boolean;
};

export type AsButtonProps = {
  as: 'button';
} & ButtonHTMLAttributes<HTMLButtonElement> &
  BaseProps;

type AsLinkProps = {
  as: 'link';
} & LinkProps &
  BaseProps;

export type Props = AsButtonProps | AsLinkProps;

export const AppButton = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  Props
>(
  (
    { children, hierarchy = 'primary', size = 'medium', className, ...props },
    ref
  ) => {
    const structuredClassName = `${style.button} ${style[hierarchy]} ${
      style[size]
    } ${props.disabled && style.disabled}  ${className}`;
    return (
      <UnstyledAppButton
        ref={ref}
        className={structuredClassName}
        {...props}
      >
        {children}
      </UnstyledAppButton>
    );
  }
);
