import { Box, Flex, Grid, Stack } from "@mantine/core";
import React from "react";
import {
  AreaChart,
  BarChart,
  DonutChart,
  PieChart,
  RadarChart,
} from "@mantine/charts";
import { AreaData, BarData, DonutData, PieData, RadarData } from "./data";
import Sidebar from "../../components/SideBar";
import InfoCard from "./InfoCard";

function DashboardPage() {
  return (
    <Sidebar>
      <Box p={20}>
        <Stack gap='lg'>
          <Flex direction="row" justify='space-between' pb={20}>
            <InfoCard title="Weekly Sales" info="15235" signBeforeNumber="$"></InfoCard>
            <InfoCard title="Weekly Bookings"info="1223"></InfoCard>
            <InfoCard title="Weekly sales"></InfoCard>
          </Flex>
          <Grid>
            <Grid.Col span={6}>
              <DonutChart
                withLabelsLine
                withLabels
                data={DonutData}
                chartLabel="Label"
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <AreaChart
                h={300}
                data={AreaData}
                dataKey="date"
                series={[
                  { name: "Apples", color: "indigo.6" },
                  { name: "Oranges", color: "blue.6" },
                  { name: "Tomatoes", color: "teal.6" },
                ]}
                curveType="linear"
              />
            </Grid.Col>

            <Grid.Col span={4}>
              <PieChart
                data={PieData}
                withTooltip
                tooltipDataSource="segment"
                mx="auto"
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <BarChart
                h={300}
                data={BarData}
                dataKey="month"
                series={[
                  { name: "Smartphones", color: "violet.6" },
                  { name: "Laptops", color: "blue.6" },
                  { name: "Tablets", color: "teal.6" },
                ]}
                tickLine="y"
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <RadarChart
                h={300}
                data={RadarData}
                dataKey="product"
                withPolarRadiusAxis
                series={[
                  { name: "sales_january", color: "lime.4", opacity: 0.1 },
                  { name: "sales_february", color: "cyan.4", opacity: 0.1 },
                ]}
              />
            </Grid.Col>
          </Grid>
        </Stack>
      </Box>
    </Sidebar>
  );
}

export default DashboardPage;
