import moment from "moment";
import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import getImageUrl from "../../../../helper/checkImageFormat";
import ProperyPlaceholderImage from '../../../../assests/images/6-thumb.png';

const BookingInfo = ({ show, handleClose, booking}) => {
  const { contact, property} = booking

  const [showReschedule, setShowReschedule] = useState(false)
  const [showRemove, setShowRemove] = useState(false)
  
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      name="end"
      className="showing-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Booking</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="date-time-panel mb-30 offcanvas-panel">
          <p className="mb-0">
            <em>Date and time</em>
          </p>
          <p className="mb-0">
            {`${moment(booking.startTime * 1000).format("dddd")} ${moment(booking.startTime * 1000).format("MMMM")} ${moment(booking.startTime * 1000).format("DD")}`}
            <br />
            {`${moment(booking.startTime * 1000).format("h:mm a")}`}
          </p>
        </div>

        <div className="name-panel mb-30 offcanvas-panel">
          <p className="mb-0">
            <em>Lead</em>
          </p>
          <h5 className="mb-20">
            {contact?.firstName}{" "}
            {contact?.lastName}
          </h5>
          <div className="d-block">
            <Link
              to="tel:202-555-0153"
              className="table-phone phonenumber-w-ico link-w-ico color-black"
            >
              {contact?.phone}
            </Link>
          </div>
          <div className="d-block mb-2">
            <Link
              to={`mailto:${contact?.email}`}
              className="table-mail mail-w-ico link-w-ico"
            >
              {contact?.email}
            </Link>
          </div>
        </div>

        <div className="property-panel mb-30 offcanvas-panel">
          <p>
            <em>Property</em>
          </p>
          <div className="properties-details-link">
            <span className="position-relative d-inline-block d-flex">
              <div>
                <img
                  src={getImageUrl(property?.images[0])}
                  alt="properties"
                  width={"100px"}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = ProperyPlaceholderImage;
                    e.target.style.width = "100px";
                  }}
                />
              </div>
              <div className="ml-20 position-relative">
                <p className="lead-name d-block">
                  {property?.address}
                </p>
                <Link
                  to={`/property/${[property?._id]}`}
                  className="color-primary"
                >
                  View property
                </Link>
              </div>
            </span>
          </div>
        </div>
      </Offcanvas.Body>
      {/* <div className="offcanvas-footer">
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-outline-secondary"
            onClick={() => setShowReschedule(true)}
          >
            Reschedule showing
          </button>
          <button
            className="btn btn-outline-secondary color-red"
            onClick={() => setShowRemove(true)}
          >
            Remove
          </button>
        </div>
      </div> */}
    </Offcanvas>
  );
};

export default BookingInfo;
