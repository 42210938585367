import { Card } from '../../../../../designSystem/Card';
import placeHolderImage from '../../../../../assests/images/1-placeholder.png';
import style from './style.module.scss';

export const SelectedPropertyCard = ({ property, className, onSeeDetails }) => {
  return (
    <Card className={`${style.card} ${className}`}>
      <div className={style.infoGroup}>
        <img
          src={property?.images[0] ?? placeHolderImage}
          alt=''
          className={style.img}
        />
        <div className='d-flex flex-column'>
          <Card.SubTitle>Booking for</Card.SubTitle>
          <Card.Title>{property?.shortAddress}</Card.Title>
          <Card.SubTitle>{property?.longAddress}</Card.SubTitle>
        </div>
      </div>
      <Card.Link
        className={style.link}
        onClick={onSeeDetails}
        style={{ marginLeft: 'auto' }}
        as='button'
      >
        See details
      </Card.Link>
    </Card>
  );
};
