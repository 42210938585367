import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import {
  Badge,
  Button,
  Col,
  Form,
  Row,
  Table,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-dropdown-select';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Sidebar from '../components/AdminSideBar';
import { Center, Loader } from '@mantine/core';

const options = [
  {
    value: 1,
    label: 'tablet',
  },
  {
    value: 2,
    label: 'camera',
  },
  {
    value: 3,
    label: 'router',
  },
  {
    value: 4,
    label: 'lock',
  },
]

const HardwareList = () => {
  const [list, setList] = useState([]);
  const [q, setQ] = useState('');
  const [modelshow, setModelShow] = useState(false);
  const [selectValue, setselectValue] = useState([]);
  const [Value, setValue] = useState(true);
  const [searchParam] = useState(['name', 'category', 'status', 'description']);
  const [error, setError] = useState('');
  const [loading, setLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getHardwareList = async () => {
      try {
        const url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware?format=list`;
        const token = await Auth.currentSession();
        const response = await axios
          .get(url, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } });
        if (response.status === 200) {
          setList(response.data)
          setLoader(true)
        } else {
          setError('Error Getting hardware')
          setModelShow(true)
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          Auth.signOut().then(() => {
            navigate('/admin/login');
          }).catch((error) => {
            console.log('Error signing out: ', error);
          });
        } else {
          setError('Error Getting hardware');
          setModelShow(true);
        }
      }
    }
    getHardwareList();
  }, [navigate]);


  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        )
      })
    })
  }

  function handleorder(e) {
    if (e[0].label === '') {
    } else {
      setselectValue(e[0].label)
      setValue(false)
    }
  }
  return (
    <>
      <Sidebar>
        <div className="page-main-head align-items-center">
          <Row className="align-items-center">
            <Col xl={8}>
              <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                <h1 className="h4 color-black mr-20">HARDWARE LIST</h1>
                <div>
                  <Link
                    to="/admin/hardware/create"
                    size="sm"
                    className="btn btn-primary btn-sm"
                  >
                    NEW
                  </Link>
                </div>
                <Form className="w-100">
                  <Form.Group
                    className="search-control-group d-flex "
                    controlId="search-control"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                    <Button className="search-btn"></Button>
                  </Form.Group>
                </Form>
              </div>
            </Col>
            <Col xl={4}>
              <div className="d-flex align-items-center justify-content-md-end">
                <Select
                  options={options}
                  size="sm"
                  className="btn date-btn"
                  onChange={(e) => handleorder(e)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="filter-alert">
          <p>All contacts ({list.length})</p>
        </div>
        <Table responsive className="property-table">
          {loading === false ? (
            <Center h={100}>
              <Loader/>
            </Center>
          ) : (
            <tbody>
              {search(
                list.filter((list) => {
                  if (Value === false) {
                    return list.category === selectValue
                  } else {
                    return true
                  }
                }),
              ).map((element, index) => (
                <tr
                  key={element._id}
                  onClick={() => navigate(`/admin/hardware/details/${element._id}`)}
                >
                  <td>
                    <span className="order">{index + 1}.</span>
                  </td>
                  <td>
                    <Link
                      to={`/property/${element._id}`}
                      className="properties-details-link"
                    >
                      <span className="position-relative d-inline-block">
                      </span>
                    </Link>
                  </td>
                  <td>
                    <Link
                      className="properties-details-link"
                    >
                      <p className="table-phone">{element.name}</p>
                    </Link>
                  </td>
                  <td>
                    <span className="table-phone">{element.description}</span>
                  </td>
                  <td>
                    <span>{element.notes}</span>
                  </td>

                  <td>
                    <span className="text-nowrap">
                      <Badge className="rent">{element.status}</Badge>
                      {/* <span>{element.status}</span> */}
                    </span>
                  </td>
                  <td>
                    <span className="text-nowrap">
                      <span>{element.category}</span>
                    </span>
                  </td>

                  <td>
                    <span className="text-nowrap">
                      <span className="">
                        {element.kit ? element.kit.name : ''}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span className="text-nowrap">
                      <span className="">
                        {element.user ? element.user.firstName : ''}
                        {element.user ? element.user.lastName : ''}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span className="text-nowrap">
                      <span className="">
                        {element.property ? element.property.name : ''}
                      </span>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </Sidebar>
      {modelshow ? (
        <Modal show={modelshow}>
          {error !== '' ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModelShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            ''
          )}
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}

export default HardwareList
