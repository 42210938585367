import "./book.scss";
import "react-datepicker/dist/react-datepicker.css";

import { useEffect, useState } from "react";

import moment from "moment";
import { Card } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Compressor from "compressorjs";

import useGaTracker from "../../hooks/useGaTracker";
import UseHttp from "../../hooks/UseHttp";

import LoaderRing from "../../components/LoaderRing";
import Alert from "../../components/Alert";
import getImageUrl from "../../helper/checkImageFormat";
import removeUndefinedWord from "../../helper/updateString";
import Stepper from "./Stepper";
import axios from "axios";

const Book = () => {
  const [alertModal, setAlertModal] = useState({
    show: false,
    title: "Alert",
    content: "",
  });

  const [timezone, setTimezone] = useState(0);

  const [company, setCompany] = useState("");
  const [success, setsuccess] = useState(false);
  const [available, setAvailable] = useState(true);
  const [isverification, setVerification] = useState(false);
  const [verificationURL, setVerificationURL] = useState("");
  const [VerificationReference, setVerificationReference] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [selectdate, setselectdate] = useState("");
  const [showTime, setShowTime] = useState(false);
  const [list, setList] = useState([]);
  const [property, setproperty] = useState();
  const [propertyIndex, setPropertyIndex] = useState();
  const [file, setFile] = useState();
  const [response, setReponse] = useState();
  const [meetingLoader, setMeetingLoader] = useState(false);
  const [meetingData, setMeetingData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const agent = queryParams.get("agent") ?? params.agentId;

  const {
    isLoading: getPublicPropertiesLoading,
    error: getPublicPropertiesError,
    requestData: getPublicProperties,
  } = UseHttp();

  const { error: calcAvailabilityError, requestData: calcAvailability } =
    UseHttp();

  const { error: loadValidateIdError, requestData: loadValidateId } = UseHttp();

  const {
    error: checkContactVerifiedError,
    requestData: checkContactVerified,
  } = UseHttp();

  const { error: makeBookError, requestData: makeBook } = UseHttp();

  const {
    isLoading: sendConfirmationEmailLoading,
    error: sendConfirmationEmailError,
    requestData: sendConfirmationEmail,
  } = UseHttp();

  const {
    isLoading: sendConfirmationSMSLoading,
    error: sendConfirmationSMSError,
    requestData: sendConfirmationSMS,
  } = UseHttp();

  useGaTracker();
  const [minTime, setMinTime] = useState(new Date());
  const [maxTime, setMaxTime] = useState(new Date());

  useEffect(() => {
    const now = new Date();
    setMinTime(now);
    const startOfDay = new Date(now);
    startOfDay.setHours(0, 0, 0, 0);

    if (startDate.toDateString() === now.toDateString()) {
      setMinTime(now);
    } else {
      setMinTime(startOfDay);
    }

    const endOfDay = new Date(now);
    endOfDay.setHours(23, 59, 59, 999);
    setMaxTime(endOfDay);
  }, [startDate]);

  useEffect(() => {
    let url;
    if (params.id) {
      setMeetingLoader(true);
      axios
        .get(
          `${process.env.REACT_APP_NODE_API_URL}/api/booking/${params.id}?cancelled=true`
        )
        .then((response) => {
          setMeetingLoader(false);
          setMeetingData(response.data);
          if (response.data?.localDateTime) {
            const parsedDate = moment(
              response.data?.localDateTime,
              "MM-DD-YYYY h:mm a"
            );
            const startDate = parsedDate.toDate();
            setStartDate(startDate);
          }
        })
        .catch((error) => {
          setMeetingLoader(false);
          console.log(error);
        });
    }
    if (params.propertyId) {
      url = `${process.env.REACT_APP_NODE_API_URL}/api/property/public/${params.propertyId}`;
    } else if (params.state && params.city && params.address) {
      const propertyAddress = `${params.state}/${params.city}/${params.address}`;
      const unit = params.unit ? params.unit : "";
      url = `${process.env.REACT_APP_NODE_API_URL}/api/property/public/${propertyAddress}/${unit}`;
    } else if (params.agentId) {
      url = `${process.env.REACT_APP_NODE_API_URL}/api/property/public/agent/${params.agentId}`;
    }

    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const properties = queryParams.get("properties");

      if (properties) {
        url = `${
          process.env.REACT_APP_NODE_API_URL
        }/api/property/public/properties?${properties.toString()}`;
      }
    }

    getPublicProperties({ url: url }, (data) => {
      setPropertyIndex(data.list[0]._id);
      setCompany(data.list[0].user.company.name);
      setTimezone(data.list[0].user.settings.bookings.timezone);
      setAvailable(data.available);
      setList(data.list);
    });
  }, [params, getPublicProperties]);

  useEffect(() => {
    if (VerificationReference !== "") {
      setInterval(() => {
        checkContactVerified(
          {
            url: `${process.env.REACT_APP_NODE_API_URL}/api/contact-verified/${VerificationReference}`,
          },
          (data) => {
            if (data.verified) {
              // add idImage
              setFile(data.idImage);
            }
          }
        );
      }, 2000);
    }
  }, [VerificationReference]);

  useEffect(() => {
    if (success) {
      sendConfirmationSMS(
        {
          method: "POST",
          url: `${process.env.REACT_APP_NODE_API_URL}/api/notification/confirmation/sms`,
          body: response,
        },
        (data) => {
          ReactGA.event({
            action: "book",
            category: "booking",
            label: "success",
          });
        }
      );
      sendConfirmationEmail(
        {
          method: "POST",
          url: `${process.env.REACT_APP_NODE_API_URL}/api/notification/confirmation/email`,
          body: response,
        },
        (data) => {}
      );
    }
  }, [success]);

  useEffect(() => {
    if (getPublicPropertiesError) {
      console.log(getPublicPropertiesError);
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Error Getting Property Info",
      });
    }
  }, [getPublicPropertiesError]);

  useEffect(() => {
    if (calcAvailabilityError) {
      console.log(calcAvailabilityError);
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Error Calculating Availability",
      });
    }
  }, [calcAvailabilityError]);

  useEffect(() => {
    if (loadValidateIdError) {
      console.log(loadValidateIdError);
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Error Loading ID analyzer",
      });
    }
  }, [loadValidateIdError]);

  useEffect(() => {
    if (checkContactVerifiedError) {
      console.log(checkContactVerifiedError);
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Error Checking Verification Reference",
      });
    }
  }, [checkContactVerifiedError]);

  useEffect(() => {
    if (sendConfirmationSMSError) {
      console.log(sendConfirmationSMSError);
      if (sendConfirmationSMSError.content) {
        setAlertModal({
          show: true,
          title: "Alert",
          content:
            "An error occured while the booking sms notification was sent!",
        });
      } else {
        setAlertModal({
          show: true,
          title: "Alert",
          content: "Couldn't send confirmation sms",
        });
      }
    }
  }, [sendConfirmationSMSError]);

  useEffect(() => {
    if (sendConfirmationEmailError) {
      console.log(sendConfirmationEmailError);
      if (sendConfirmationEmailError.content) {
        setAlertModal({
          show: true,
          title: "Alert",
          content:
            "An error occured while the booking email notification was sent!",
        });
      } else {
        setAlertModal({
          show: true,
          title: "Alert",
          content: "Couldn't send confirmation email",
        });
      }
    }
  }, [sendConfirmationEmailError]);

  useEffect(() => {
    if (makeBookError) {
      console.log(makeBookError);
      if (makeBookError.code === 413) {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Request Entity Too Large",
        });
      } else if (makeBookError.content) {
        setAlertModal({
          show: true,
          title: "Error",
          content: makeBookError.content,
        });
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error Making Book",
        });
      }
    }
  }, [makeBookError]);

  const onFileChange = (e) => {
    new Compressor(e.target.files[0], {
      quality: 0.6,
      success(result) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(result);
        fileReader.onload = () => {
          console.log({ result: fileReader.result });
          setFile(fileReader.result);
        };
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const onDateChange = (date) => {
    setselectdate(date.getDate());
    setShowTime(!showTime);
    const month = date.getMonth();
    const year = date.getFullYear();
    if (propertyIndex) {
      setStartDate(date);
      calcAvailability(
        {
          url: `${process.env.REACT_APP_NODE_API_URL}/api/property/${propertyIndex}/month-availability?month=${month}&year=${year}&agent=${agent}`,
        },
        (availability) => {
          setproperty(availability);
        }
      );
    } else {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Please select a property",
      });
    }
  };

  const selectProperty = (e) => {
    setPropertyIndex(e);
    if (e !== "") {
      let newDate = new Date();
      let month = newDate.getMonth();
      let year = newDate.getFullYear();
      calcAvailability(
        {
          url: `${process.env.REACT_APP_NODE_API_URL}/api/property/${e}/month-availability?month=${month}&year=${year}&agent=${agent}`,
        },
        (data) => {
          setproperty(data);
          if (data.idVerification) {
            let start = Math.floor(startDate.getTime());
            let end = Math.floor(startDate.getTime() + 15 * 60 * 1000);

            let localDateTime = moment(startDate.getTime()).format(
              "MM-DD-YYYY h:mm a"
            );

            let postData = {
              contact: {},
              timeslot: {
                start: start,
                duration: 15,
                end: end,
                available: true,
              },
              localDateTime: localDateTime,
            };
            loadValidateId(
              {
                method: "POST",
                url: `${process.env.REACT_APP_NODE_API_URL}/api/contact/validateid/load`,
                body: postData,
              },
              (data) => {
                setVerificationURL(data.url);
                setVerificationReference(data.reference);
                setVerification(true);
              }
            );
          } else {
            setVerification(false);
          }
        }
      );
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );
    const formData = new FormData(e.target);
    let frm = Object.fromEntries(formData.entries());
    var id;
    if (frm.firstName === "") {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Enter First Name",
      });
    } else if (frm.lastName === "") {
      setAlertModal({ show: true, title: "Alert", content: "Enter Last Name" });
    } else if (frm.phone === "") {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Enter Phone Number",
      });
    } else if (!phoneRegex.test(frm.phone)) {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Please enter a valid phone number",
      });
    } else if (frm.email === "") {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Enter Email Address",
      });
    } else if (!emailRegex.test(frm.email)) {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Please enter a valid email address",
      });
    } else if (file === undefined) {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Please Upload Image",
      });
    } else if (selectdate === "") {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Please Select Date",
      });
    } else if (!propertyIndex) {
      setAlertModal({
        show: true,
        title: "Alert",
        content: "Please Select Property",
      });
    } else {
      id = propertyIndex;

      const utcDiff = moment().utcOffset() / 60;
      const timeDiff = timezone - utcDiff;

      var start = Math.floor(startDate.getTime());
      var end = Math.floor(startDate.getTime() + 15 * 60 * 1000);
      var localDateTime = moment(startDate.getTime()).format(
        "MM-DD-YYYY h:mm a"
      );
      var data = {
        contact: {
          firstName: frm.firstName ? frm.firstName : "",
          lastName: frm.lastName ? frm.lastName : "",
          phone: frm.phone ? frm.phone : "",
          email: frm.email ? frm.email : "",
          idImage: file ? file : "",
        },
        timeslot: {
          start: start,
          duration: 15,
          end: end,
          available: true,
        },
        localDateTime: localDateTime,
      };

      setIsLoading(true);
      makeBook(
        {
          method: "POST",
          url: `${process.env.REACT_APP_NODE_API_URL}/api/property/${id}/book?agent=${agent}`,
          headers: { "Content-Type": "application/json" },
          body: data,
        },
        (data) => {
          setReponse(data);
          setsuccess(true);
          setIsLoading(false);
        }
      );
    }
  };

  const onReschedule = async (e) => {
    e.preventDefault();

    let start = Math.floor(startDate.getTime());
    let end = Math.floor(startDate.getTime() + 15 * 60 * 1000);

    let localDateTime = moment(startDate.getTime()).format("MM-DD-YYYY h:mm a");

    var data = {
      start: start,
      duration: 15,
      end: end,
      available: true,
      localDateTime: localDateTime,
      status: "active",
    };

    if (params.propertyId && params.id && !meetingLoader)
      // Auth.currentSession().then((token) => {
      setIsLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_NODE_API_URL}/api/booking/reschedule/${params.id}`,
        data,
        {
          headers: {
            // Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setAlertModal({
            show: true,
            title: "Success",
            content: "Metting Rescheduled Successfully",
          });
          navigate(`/book/${params.propertyId}`);
        } else {
          setAlertModal({
            show: true,
            title: "Error",
            content: "Error Rescheduling Metting",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setAlertModal({
          show: true,
          title: "Error",
          content: "Error Rescheduling Metting",
        });
      });
  };
  return (
    <div className="page-main-head-book">
      <div className="container d-flex justify-content-center">
        {available ? (
          !success ? (
            <div className="row">
              <div className="d-flex justify-content-center book-head-logo">
                <h2 className="company-name"></h2>
                {list[0]?.user?.company?.idImage ? (
                  <img
                    className="book-head-logo"
                    // src={'https://s3.us-east-2.amazonsdsdaws.com/delet.ai/public/images/company/f825f169-501d-420f-b0e9-1eb6887323d4-company.jpeg'}
                    src={getImageUrl(list[0]?.user?.company?.idImage)}
                    onError={({ currentTarget }) => {
                      if (currentTarget) {
                        currentTarget.onerror = null;
                        currentTarget.style.display = "none";
                        const companyNameElement =
                          document.querySelector(".company-name");
                        if (companyNameElement) {
                          companyNameElement.innerText =
                            list[0]?.user?.company?.name || "";
                          companyNameElement.style.display = "block";
                        }
                      }
                    }}
                    onLoad={({ currentTarget }) => {
                      const companyNameElement =
                        document.querySelector(".company-name");
                      if (companyNameElement) {
                        companyNameElement.style.display = "none";
                      }
                    }}
                    alt={
                      list[0]?.user?.company?.name ||
                      "Company Name Not Available"
                    }
                  />
                ) : (
                  <h2 className="company-name">
                    {list[0]?.user?.company?.name || ""}
                  </h2>
                )}
                {/*{list.length > 1 && (
                  <h5 className="mb-10">Please select one unit and Time</h5>
                )}
                */}
                {property?.selfGuide && (
                  <h5 className="mb-10">Self guided tour</h5>
                )}
              </div>
              <div>
                <div className="book-stpper">
                  <Stepper
                    list={list}
                    selectProperty={selectProperty}
                    propertyIndex={propertyIndex}
                    onDateChange={onDateChange}
                    startDate={startDate}
                    minTime={minTime}
                    maxTime={maxTime}
                    excludeDates={property?.excludedDates}
                    isverification={isverification}
                    verificationURL={verificationURL}
                    onFileChange={onFileChange}
                    file={file}
                    onSubmit={onSubmit}
                    onReschedule={onReschedule}
                    success={success}
                    currentSelectedPropertyIndex={propertyIndex}
                    selectDate={selectdate}
                    isLoading={isLoading}
                    meetingId={params.id}
                    meetingLoader={meetingLoader}
                    meetingData={meetingData}
                    settings={property?.settings}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <h5 className="card-header">Booking Created</h5>
              <div className="card-body">
                <h6>Well done! Your booking was created successfully!</h6>
                {property?.selfGuide && (
                  <h6 className="mb-10">Self guided tour</h6>
                )}
                <table className="table table-nowrap mb-0">
                  <tbody>
                    <tr>
                      <th scope="row">Booking with :</th>
                      <td className="ng-binding">
                        {property ? property.agent.name : " "}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Agency :</th>
                      <td className="ng-binding">
                        {property ? property.company.name : " "}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Location :</th>
                      <td className="ng-binding">
                        {property ? removeUndefinedWord(property.address) : ""}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Start :</th>
                      <td className="ng-binding">
                        {response
                          ? moment(response.startTime).format(
                              "MM-DD-YYYY h:mm a"
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">End :</th>
                      <td className="ng-binding">
                        {response
                          ? moment(response.stopTime).format(
                              "MM-DD-YYYY h:mm a"
                            )
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="offcanvas-footer">
                  <div className="d-flex justify-content-between">
                    {sendConfirmationSMSLoading ? (
                      <div className="d-flex">
                        <LoaderRing height="25" width="25" />
                        <p>Sending sms</p>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <p>SMS sent</p>
                      </div>
                    )}
                    {sendConfirmationEmailLoading ? (
                      <div className="d-flex">
                        <LoaderRing height="25" width="25" />
                        <p>Sending email</p>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <p>Email sent</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <Card>
            <Card.Header as="h5">We're sorry</Card.Header>
            <Card.Body>
              <center>
                <p>
                  Unfortunately the property{" "}
                  {removeUndefinedWord(list[0].address)} is not available at
                  this time.
                </p>
              </center>
            </Card.Body>
          </Card>
        )}
      </div>
      {alertModal.show && (
        <Alert
          show={alertModal.show}
          title={alertModal.title}
          content={alertModal.content}
          setShow={() => setAlertModal({ ...alertModal, show: false })}
        />
      )}
    </div>
  );
};
export default Book;
