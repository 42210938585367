import React, { useEffect, useState } from "react";

import axios from "axios";
import { Badge, Button, Col, Form, Row, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Select from "react-dropdown-select";
import { ColorRing } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";
import Sidebar from "../../../components/SideBar";

const Users = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [selectValue, setselectValue] = useState([]);
  const [Value, setValue] = useState(true);
  const [error, setError] = useState("");
  const [modelshow, setModelShow] = useState(false);
  const [q, setQ] = useState("");
  const [Loader, setLoader] = useState(false);
  const [searchParam] = useState([
    "email",
    "firstName",
    "lastName",
    "role",
    "active",
  ]);
  const options = [
    {
      value: 1,
      label: "admin",
    },
    {
      value: 2,
      label: "agent",
    },
  ];

  useEffect(() => {
    async function getTokenForUserList() {
      try {
        const token = await Auth.currentSession();
        if (!token) {
          setError(error);
          navigate("/admin/login");
        }
        getUserList(token.getIdToken().getJwtToken());
      } catch (error) {
        setError(error);
        navigate("/admin/login");
      }
    }
    getTokenForUserList();
  }, []);

  function getUserList(token) {
    var url = process.env.REACT_APP_NODE_API_URL + "/api/users/account/admin/list";
    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.status === 200) {
          setList(response.data);
          setLoader(true);
        } else {
          setError("Error Getting Users");
          setModelShow(true);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/admin/login");
            })
            .catch((error) => {
              console.log("Error signing out: ", error);
            });
        } else {
          setError("Error Getting Users");
          setModelShow(true);
        }
      });
  }
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }
  function handleorder(e) {
    if (e[0].label === "") {
    } else {
      setselectValue(e[0].label);
      setValue(false);
    }
  }
  return (
    <>
      {/* <Sidebar> */}
      <div className="page-main-head align-items-center">
        <Row className="align-items-center">
          <Col xl={8}>
            <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
              <h1 className="h4 color-black mr-20">Users List</h1>
              <div>
                <Link
                  to="/account/user/create"
                  size="sm"
                  className="btn btn-primary btn-sm"
                >
                  NEW
                </Link>
              </div>
              <Form className="w-100">
                <Form.Group
                  className="search-control-group d-flex "
                  controlId="search-control"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                  <Button className="search-btn"></Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
          <Col xl={4}>
            <div className="d-flex align-items-center justify-content-md-end">
              <Select
                options={options}
                size="sm"
                className="btn date-btn"
                onChange={(e) => handleorder(e)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Table responsive className="property-table">
        {Loader === false ? (
          <>
            <div style={{ textAlign: "center" }}>
              <ColorRing
                visible={true}
                height="100"
                width="100"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#1F60AE", "#5C79FF", "#455DC8", "#889DFF", "#5271FF"]}
              />
            </div>
          </>
        ) : (
          <tbody>
            {search(
              list.filter((list) => {
                if (Value === false) {
                  return list.role === selectValue;
                } else {
                  return true;
                }
              })
            ).map((element, index) => (
              <tr
                key={element._id}
                onClick={() => navigate(`/account/user/details/${element._id}`)}
              >
                <td>
                  <Link
                    to={`/account/user/details/${element._id}`}
                    className="user-details-link"
                  >
                    <span className="position-relative d-inline-block">
                      <img
                        width={80}
                        height={50}
                        src={require("../../../assests/images/img-6.png")}
                        alt=""
                      />
                    </span>
                  </Link>
                </td>
                <td>
                  <Link className="properties-details-link">
                    <p className="addreess">{element.firstName}</p>
                  </Link>
                </td>
                <td>
                  <span className="addreess">{element.lastName}</span>
                </td>
                <td>
                  <span className="table-phone phonenumber-w-ico link-w-ico color-black">
                    {element.phone}
                  </span>
                </td>

                <td>
                  <span className="text-nowrap">
                    <span className="table-mail mail-w-ico link-w-ico">
                      {element.email}
                    </span>
                  </span>
                </td>

                <td>
                  <span className="text-nowrap">
                    <span className="">
                      {element.company ? element.company.name : ""}
                    </span>
                  </span>
                </td>
                <td>
                  <Badge className="rent">{element.role}</Badge>
                </td>
                <td>
                  {element.active === false ? (
                    <span
                      className="badge"
                      style={{ backgroundColor: "#ffcccb " }}
                    >
                      Inactive()
                    </span>
                  ) : (
                    <span
                      className="badge"
                      style={{ backgroundColor: "#90EE90" }}
                    >
                      Active()
                    </span>
                  )}
                </td>
                <td>
                  <span className=" text-nowrap">{element.description}</span>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      {/* </Sidebar> */}
      {modelshow ? (
        <Modal show={modelshow}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setModelShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModelShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            ""
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default Users;
