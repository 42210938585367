export const statusClassMap = {
  "checking stock": "pending",
  "awaiting stock": "pending",
  "assembling kit": "pending",
  "ready to ship": "in-progress",
  "awaiting confirmation": "assigned",
  "received": "received",
  "confirmed": "received",
  "returned": "returned",
  undefined: "received"
};

export const orderStatusDic = {
  "waiting for payment information": "in-progress",
  "assembling kits": "in-progress",
  "complete": "completed",
  "paid": "completed",
};