import React from "react";
import { AppDropzone } from "../../../designSystem/Dropzone";
import picture from "../../../assests/images/picture.svg";
import { ReactSVG } from "react-svg";
import { AppButton } from "../../../designSystem/AppButton";
import { Card } from "../../../designSystem/Card";
import Compressor from "compressorjs";

const DragAndDropImg = ({ onChange, img, name, placeholder }) => {
  const handleDeleteFile = (e) => {
    e.stopPropagation();
    onChange({ target: { name, value: null } });
  };

  const onDrop = (files) => {
    new Compressor(files[0], {
      quality: 0.6,
      success(result) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(result);
        fileReader.onload = () => {
          onChange({ target: { name, value: fileReader.result } });
        };
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  return (
    <AppDropzone onDrop={onDrop}>
      {img ? (
        <>
          <Card.Image style={{ maxWidth: "40%" }} src={img} />
          <AppButton hierarchy="link" onClick={handleDeleteFile}>
            Remove
          </AppButton>
        </>
      ) : (
        <>
          <ReactSVG src={picture} />
          <p className="text-center">{placeholder ?? <>Files <span>or</span></>} </p>
          <AppButton as="button">+ Browse</AppButton>
        </>
      )}
    </AppDropzone>
  );
};

export default DragAndDropImg;
