import {
  Accordion,
  Center,
  Flex,
  NumberFormatter,
  ScrollArea,
  SimpleGrid,
  Text,
  Title,
  Stack,
  Divider,
} from "@mantine/core";
import React, { useEffect, useState } from "react";

const OrderConfirmation = ({
  totalInstallation,
  totalRemoval,
  totalPriceInstallation,
  totalPriceRemoval,
  totalKits,
  totalPriceKits,
  discount,
  orders,
  removalKits,
}) => {
  const [suscription, setSuscription] = useState([]);
  const [installation, setInstallation] = useState([]);
  const [removal, setRemoval] = useState([]);

  useEffect(() => {
    console.log(orders);
    console.log({ suscription });
    orders.forEach((order) => {
      if (order.serviceType === "kit") {
        const n = order.amount; // Number of elements
        const defaultValue = {
          price: order.individualPrice,
        }; // Default value for each element
        const array = Array(n).fill(defaultValue); // Creates an array of n elements, initialized with defaultValue
        setSuscription((prev) => array);
      } else if (order.serviceType === "installation") {
        const n = order.amount;
        const defaultValue = {
          price: order.individualPrice,
        };
        const array = Array(n)
          .fill(defaultValue)
          .map((kit, index) => ({ ...kit, name: "Kit " + (index + 1) }));

        if (order.kits?.length) {
          order.kits.forEach((kit, index) => {
            array[index] = { ...array[index], name: kit.name };
          });
        }
        setInstallation(array);
      } else if (order.serviceType === "removal") {
        const n = order.amount;
        const defaultValue = {
          price: order.individualPrice,
        };
        const array = Array(n)
          .fill(defaultValue)
          .map((kit, index) => ({ ...kit, name: "Kit " + (index + 1) }));

        if (order.kits?.length) {
          order.kits.forEach((kit, index) => {
            array[index] = { ...array[index], name: kit.name };
          });
        }
        setRemoval(array);
      }
    });
  }, [JSON.stringify(orders)]);
  return (
    <>
      <Title order={4} mt={10} mb={20}>
        Order detail
      </Title>
      {/* <Accordion chevronPosition="left" maw={400} mx="auto" variant="filled">
        <Accordion.Item
          value="suscription"
          display={!totalKits ? "none" : undefined}
        >
          <Accordion.Control>
            <Flex justify={"space-between"}>
              <Text size="sm" c={"var(--mantine-color-blue-6)"} fw={800}>
                Subscription (
                {totalKits > 1 ? `${totalKits} kits` : `${totalKits} kit`})
              </Text>
              <Text
                size="sm"
                ml={20}
                c={"var(--mantine-color-blue-6)"}
                fw={800}
              >
                <NumberFormatter
                  prefix="$ "
                  value={totalPriceKits}
                  thousandSeparator
                />{" "}
                / month{" "}
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <ScrollArea.Autosize mah={150} scrollbars="y">
              {suscription.map((el, index) => (
                <Center key={index} mx={10}>
                  <SimpleGrid cols={2}>
                    <Text size="sm"> Kit {index + 1}</Text>
                    <Text size="sm">$ {el.price} / month</Text>
                  </SimpleGrid>
                </Center>
              ))}
            </ScrollArea.Autosize>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item
          value="installation"
          display={!totalInstallation ? "none" : undefined}
        >
          <Accordion.Control>
            <Flex justify={"space-between"}>
              <Text size="sm">
                Installation (
                {totalInstallation > 1
                  ? `${totalInstallation} kits`
                  : `${totalInstallation} kit`}
                )
              </Text>
              <Text size="sm" ml={20}>
                <NumberFormatter
                  prefix="$ "
                  value={totalPriceInstallation}
                  thousandSeparator
                />
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <ScrollArea.Autosize mah={200} scrollbars="y">
              {installation.map((el, index) => (
                <Center key={index} mx={10}>
                  <SimpleGrid cols={2}>
                    <Text size="sm"> {el.name}</Text>
                    <Text size="sm">$ {el.price}</Text>
                  </SimpleGrid>
                </Center>
              ))}
            </ScrollArea.Autosize>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item
          value="removal"
          display={!totalRemoval ? "none" : undefined}
        >
          <Accordion.Control>
            <Flex justify={"space-between"}>
              <Text size="sm">
                Removal (
                {totalRemoval > 1
                  ? `${totalRemoval} kits`
                  : `${totalRemoval} kit`}
                )
              </Text>
              <Text size="sm" ml={20}>
                <NumberFormatter
                  prefix="$ "
                  value={totalPriceRemoval}
                  thousandSeparator
                />
              </Text>
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <ScrollArea.Autosize mah={200} scrollbars="y">
              {removal?.map((el, index) => (
                <Center key={index} mx={10}>
                  <SimpleGrid cols={2}>
                    <Text size="sm"> {el.name}</Text>
                    <Text size="sm">$ {el.price}</Text>
                  </SimpleGrid>
                </Center>
              ))}
            </ScrollArea.Autosize>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Divider mx={150} my={15} />
      <Center>
        <Stack w={370}>
          <Text
            c={"var(--mantine-color-blue-6)"}
            size="sm"
            pl={35}
            fw={800}
            display={!totalKits ? "none" : undefined}
          >
            Your suscription for each kit will start once you receive the
            product
          </Text>
          {discount?.amount ? (
            <SimpleGrid cols={2}>
              <Text size="sm" c={"dimmed"} pl={35}>
                Discount
              </Text>
              <Text size="sm" ml={11} c={"dimmed"} ta={"end"}>
                <NumberFormatter
                  prefix="$ "
                  value={
                    discount?.type === "percentage"
                      ? (totalPriceKits * discount?.amount) / 100
                      : discount?.amount
                  }
                  thousandSeparator
                />
                <Text span> off first payment</Text>
              </Text>
            </SimpleGrid>
          ) : null}

          <SimpleGrid cols={2} display={totalInstallation || totalRemoval ? undefined : "none"}>
            <Text size="sm" fw={800} pl={35}>
              Total due today
            </Text>
            <Text size="sm" ml={20} fw={800} ta={"end"}>
              <NumberFormatter
                prefix="$ "
                value={totalPriceInstallation + totalPriceRemoval}
                thousandSeparator
              />
            </Text>
          </SimpleGrid>
        </Stack>
      </Center> */}
    </>
  );
};

export default OrderConfirmation;
