import { Auth } from "aws-amplify";
import axios from "axios";

export const getUsersList = async () => {
  const url = process.env.REACT_APP_NODE_API_URL + "/api/users/admin/list";

  try {
    const token = await Auth.currentSession()

    const res = await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      },
    })
  
    return res.data

  } catch (error) {
    console.log("ERROR GETTING USERS: ", error)
  }
}