import { useEffect, useState, useRef } from "react";

import { Formik, Form, setIn, Field } from 'formik'
import axios from 'axios'
import { Button, Card, Col, Row, Modal } from 'react-bootstrap'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import Sidebar from "../components/AdminSideBar";
import InputText from "../../../components/InputText";
import InputSelect from "../../../components/InputSelect";

import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { getUsersList } from "../../../api/users";
import { getAccountsList } from "../../../api/accounts";

const customSelect = ({ field, form, options, isLoading, placeholder }) => (
  <Select
    {...field}
    value={
      options ? options.find((option) => option.value === field.value) : ""
    }
    onChange={(option) => form.setFieldValue(field.name, option.value)}
    options={options}
    isDisabled={isLoading}
    placeholder={placeholder}
    className="w-100"
  />
);

const HardwareForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Alert");
  const [alertContent, setAlertContent] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [useSync, setUseSync] = useState({ code1: "", code2: "", mode: "" });

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: errorGettingUsers,
  } = useQuery({
    queryKey: ["users"],
    queryFn: getUsersList,
  });

  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    isError: errorGettingAccounts,
  } = useQuery({
    queryKey: ["accounts"],
    queryFn: getAccountsList,
  });

  useEffect(() => {
    if (location.pathname.includes("create")) {
      setInitialValues({
        name: "",
        description: "",
        inventoryId: "",
        tags: "",
        category: selectCategory,
        serial: "",
        status: "active",
        zm: { status: "disabled" },
        user: "",
        account: "",
      });
    } else if (location.pathname.includes("edit")) {
      const getHardwareDetails = async () => {
        try {
          const url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware/${params.id}`;
          const token = await Auth.currentSession();

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          });

          if (response.status === 200) {
            setInitialValues(response.data);
            setSelectCategory(response.data.category);
            if (response.data.category === "tablet") {
              generateSyncToken();
            }
          } else {
            setShowAlert(true);
            setAlertTitle("Alert");
            setAlertContent("Error Getting Hardware");
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
                navigate("/admin/login");
              })
              .catch((error) => {
                console.log("Error signing out: ", error);
              });
          } else {
            setShowAlert(true);
            setAlertTitle("Alert");
            setAlertContent("Error Getting Hardware");
          }
        }
      };
      getHardwareDetails();
    }
  }, [location.pathname]);

  const handleChangeCategory = (event) => {
    const category = event.target.value;
    const fomrValues = formRef.current.values;
    const basicValues = {
      name: fomrValues.name,
      description: fomrValues.description,
      inventoryId: fomrValues.inventoryId,
      tags: fomrValues.tags,
      category: category,
      serial: fomrValues.serial,
      status: fomrValues.status,
    };
    setSelectCategory(category);

    if (category === "camera") {
      setInitialValues({
        ...basicValues,
        make: fomrValues.make,
        model: fomrValues.model,
        port: fomrValues.port,
        protocol: fomrValues.protocol,
        username: fomrValues.username,
        password: fomrValues.password,
        hostname: fomrValues.hostname,
        link: fomrValues.link,
        path: fomrValues.path,
        recordPath: fomrValues.recordPath,
        zm: { status: "disabled" },
      });
    } else if (category === "tablet") {
      setInitialValues({
        ...basicValues,
        make: fomrValues.make,
        model: fomrValues.model,
        tablet: { dropin: "agora" },
      });
    } else if (category === "router") {
      setInitialValues({
        ...basicValues,
        make: fomrValues.make,
        model: fomrValues.model,
        port: fomrValues.port,
        username: fomrValues.username,
        password: fomrValues.password,
        hostname: fomrValues.hostname,
      });
    } else if (category === "lock") {
      setInitialValues({
        ...basicValues,
        lock: { id: "", vendor: "switchbot" },
      });
    } else if (category === "button") {
      setInitialValues({
        ...basicValues,
        button: { id: '', vendor: 'switchbot', time: '0' },
      })
    } else {
      setInitialValues(basicValues)
    }
  };

  const generateSyncToken = async () => {
    try {
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware/${params.id}/admin/sync`;
      const token = await Auth.currentSession();

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });
      if (response.status === 200) {
        setUseSync(response.data);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/admin/login");
          })
          .catch((error) => {
            console.log("Error signing out: ", error);
          });
      } else {
        setShowAlert(true);
        setAlertTitle("Error");
        setAlertContent("Error Getting Sync Token");
      }
    }
  };

  const onSubmit = async (form) => {
    try {
      let methodForm = "";
      let url = "";
      if (location.pathname.includes("create")) {
        methodForm = `post`;
        url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware`;
      } else if (location.pathname.includes("edit")) {
        methodForm = "post";
        url = `${process.env.REACT_APP_NODE_API_URL}/api/hardware/${params.id}`;
      }
      const token = await Auth.currentSession();

      const response = await axios({
        method: methodForm,
        url: url,
        data: form,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });
      if (response.status === 200) {
        setShowAlert(true);
        setAlertTitle("Saved");
        setAlertContent(response.data);
      }
    } catch (error) {
      if (error?.response?.statuse === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/admin/login");
          })
          .catch((error) => {
            console.log("Error signing out: ", error);
          });
      } else {
        setShowAlert(true);
        setAlertTitle("Alert");
        setAlertContent("Error");
      }
    }
  };
  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">Create Hardware</h1>
        </div>
        <Row>
          <Formik
            innerRef={formRef}
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            <Form>
              <Col xxl={12} xl={12} lg={12}>
                <Card style={{ height: "auto" }}>
                  <Card.Header as="h5">Hardware Details</Card.Header>
                  <Card.Body>
                    <InputText
                      label="Name"
                      name="name"
                      labelSize={1}
                      inputSize={11}
                    />
                    <InputText
                      label="Description"
                      name="description"
                      labelSize={1}
                      inputSize={11}
                    />
                    <InputText
                      label="Notes"
                      name="notes"
                      labelSize={1}
                      inputSize={11}
                    />
                    <InputText
                      label="InventoryId"
                      name="inventoryId"
                      labelSize={1}
                      inputSize={11}
                    />
                    <InputText
                      label="Tags"
                      name="tags"
                      labelSize={1}
                      inputSize={11}
                    />

                    <Row>
                      <Col lg={4}>
                        <InputSelect
                          labelSize={3}
                          inputSize={9}
                          label="Category"
                          name="category"
                          onChange={handleChangeCategory}
                          value={selectCategory}
                        >
                          <option value="camera">Camera</option>
                          <option value="tablet">Tablet</option>
                          <option value="router">Router</option>
                          <option value="lock">Lock</option>
                          <option value="button">Button</option>
                          <option value="keypad">Keypad</option>
                        </InputSelect>
                      </Col>
                      <Col lg={5}>
                        <InputText
                          label="Serial Number"
                          name="serial"
                          labelSize={4}
                          inputSize={8}
                        />
                      </Col>
                      <Col lg={3}>
                        <InputSelect
                          labelSize={3}
                          inputSize={9}
                          label="Status"
                          name="status"
                        >
                          <option value="active">Active</option>
                          <option value="disabled">Disabled</option>
                        </InputSelect>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6} className="d-flex gap-2 align-items-center">
                        <label>User</label>
                        <Field
                          name="user"
                          component={customSelect}
                          options={users?.map((user) => ({
                            value: user._id,
                            label: `${user.firstName} ${user.lastName}`,
                          }))}
                          isLoading={isLoadingUsers}
                          placeholder={
                            isLoadingUsers ? "Getting users..." : "Select user"
                          }
                        />
                      </Col>

                      <Col lg={6} className="d-flex gap-2 align-items-center">
                        <label>Account</label>
                        <Field
                          name="account"
                          component={customSelect}
                          options={accounts?.map((account) => ({
                            value: account._id,
                            label: `${account.name} ${account.vendor} ${account.category}`,
                          }))}
                          isLoading={isLoadingAccounts}
                          placeholder={
                            isLoadingAccounts ? "Getting accounts..." : "Select account"
                          }
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {selectCategory === "camera" && (
                  <Col xxl={12} xl={12} lg={12}>
                    <Card>
                      <Card.Header as="h5">Recording Preferences</Card.Header>
                      <Card.Body>
                        <InputSelect
                          labelSize={2}
                          inputSize={10}
                          label="Enable/Disable (default disabled)"
                          name="zm.status"
                        >
                          <option value="disabled">Disabled</option>
                          <option value="active">Active</option>
                        </InputSelect>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {selectCategory === "tablet" &&
                  location.pathname.includes("edit") && (
                    <Col xxl={12} xl={12} lg={12}>
                      <Card>
                        <Card.Header as="h5">Tablet Configuration</Card.Header>
                        <Card.Body>
                          <Card.Text as="h6">
                            Tap the Antler icon 8 times on the app to open the
                            sync form. Add code1 + code2 to the form.
                          </Card.Text>
                          <Row>
                            <Col lg={3}>
                              <InputText
                                label="Code 1"
                                labelSize={3}
                                inputSize={9}
                                disabled
                                value={useSync.code1}
                              />
                            </Col>
                            <Col lg={3}>
                              <InputText
                                label="Code 2"
                                labelSize={3}
                                inputSize={9}
                                disabled
                                value={useSync.code2}
                              />
                            </Col>
                            <Col lg={3}>
                              <InputText
                                label="Sync Status"
                                labelSize={3}
                                inputSize={9}
                                value={useSync.token ? "Synced" : "Not synced"}
                                disabled
                              />
                            </Col>
                            <Col lg={3}>
                              <InputSelect
                                label="Sync Mode"
                                name="sync.mode"
                                labelSize={3}
                                inputSize={9}
                              >
                                <option value="push">Push Notification</option>
                                <option value="poll">Polling</option>
                              </InputSelect>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  )}
                <Col xxl={12} xl={12} lg={12}>
                  <Card>
                    <Card.Header as="h5">Hardware Configuration</Card.Header>
                    {selectCategory === "camera" && (
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <InputText
                              label="Make"
                              name="make"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Model"
                              name="model"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Port"
                              name="port"
                              labelSize={4}
                              inputSize={8}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputSelect
                              label="Protocol"
                              name="protocol"
                              labelSize={4}
                              inputSize={8}
                            >
                              <option value="rtsp">rtsp</option>
                              <option value="https">https</option>
                            </InputSelect>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={3}>
                            <InputText
                              label="Username"
                              name="username"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Password"
                              name="password"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={6}>
                            <InputText label="Video path" name="path" />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={3}>
                            <InputText
                              label="Hostname / Static IP"
                              name="hostname"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Link"
                              name="link"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={6}>
                            <InputText label="Record path" name="recordPath" />
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                    {selectCategory === "tablet" && (
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <InputText
                              label="Make"
                              name="make"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Model"
                              name="model"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputSelect
                              labelSize={3}
                              inputSize={9}
                              label="Provider"
                              name="tablet.dropin"
                            >
                              <option value="agora">Agora</option>
                              <option value="twilio">Twilio</option>
                            </InputSelect>
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                    {selectCategory === "router" && (
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <InputText
                              label="Make"
                              name="make"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Model"
                              name="model"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Hostname / Static IP"
                              name="hostname"
                              labelSize={4}
                              inputSize={8}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Port"
                              name="port"
                              labelSize={4}
                              inputSize={8}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={3}>
                            <InputText
                              label="Username"
                              name="username"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Password"
                              name="password"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                    {selectCategory === "lock" && (
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <InputSelect
                              label="Vendor"
                              name="lock.vendor"
                              labelSize={3}
                              inputSize={9}
                            >
                              <option value="switchbot">Switchbot</option>
                              <option value="august">August</option>
                            </InputSelect>
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="ID"
                              name="lock.id"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                    {selectCategory === "button" && (
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <InputSelect
                              label="Vendor"
                              name="button.vendor"
                              labelSize={3}
                              inputSize={9}
                            >
                              <option value="switchbot">Switchbot</option>
                            </InputSelect>
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="ID"
                              name="button.id"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="Delay Time"
                              name="button.time"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                    {selectCategory === "keypad" && (
                      <Card.Body>
                        <Row>
                          <Col lg={3}>
                            <InputSelect
                              label="Vendor"
                              name="keypad.vendor"
                              labelSize={3}
                              inputSize={9}
                            >
                              <option value="switchbot">Switchbot</option>
                            </InputSelect>
                          </Col>
                          <Col lg={3}>
                            <InputText
                              label="ID"
                              name="keypad.id"
                              labelSize={3}
                              inputSize={9}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    )}
                    <div className="col-lg-12">
                      <div className="text-center">
                        <Button
                          className="btn btn-primary waves-effect waves-light m-3"
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Col>
            </Form>
          </Formik>
          {/* TODO enable this */}
          {selectCategory === "camera" && (
            <Formik
              initialValues={{ cameraip: "" }}
              onSubmit={(e) => console.log(e)}
            >
              <Form>
                <Col xxl={12} xl={12} lg={12}>
                  <Card>
                    <Card.Header as="h5">Hardware Testing</Card.Header>
                    <Card.Body>
                      <InputText label="Static IP" name="cameraip" />
                      <div className="col-lg-12">
                        <div className="text-center">
                          <Button
                            className="btn btn-primary waves-effect waves-light m-3"
                            type="submit"
                          >
                            Test Camera
                          </Button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Form>
            </Formik>
          )}
        </Row>
      </Sidebar>
      {showAlert && (
        <Modal show={showAlert}>
          <Modal.Header>
            <Modal.Title>{alertTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{alertContent}</Modal.Body>
          <Modal.Footer>
            {alertTitle === "Saved" ? (
              <Button
                variant="secondary"
                onClick={() => {
                  navigate("/admin/hardware/list");
                  setShowAlert(false);
                }}
              >
                Okay
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => setShowAlert(false)}>
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default HardwareForm;
