import UnstyledButton from "../../components/UnstyledButton";
import { Warning } from "../../types/id-analyzer";
import toast from "react-hot-toast";

export const validationWarning = (warning: Warning) =>
  toast.error((t) =>
    <UnstyledButton onClick={() => toast.dismiss(t.id)} className="text-start fw-semibold">
      {warning.description}
    </UnstyledButton>, {
    position: "top-right",
    duration: Infinity,
    className: "me-3"
  }
  );
