import React from 'react';
import styled from 'styled-components';

const StyledInputText = styled.input`
  color: #000000;
  font-family: 'Mulish';
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.16px;
  border: 1px solid #BDBEBF;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  ${props =>
    props.textAtBeginning &&
    ` padding-left: 25px;
    `}
  box-sizing: border-box;
  &:focus {
     border: 1px solid #5D5FEF;
     box-shadow: 0px 0px 4px rgba(93, 95, 239, 0.25);
  }
  /* disabled styles */
  &:disabled {
    background-color: #EAEAEB;
    border: 1px solid #EAEAEB;
  }
  /* disabled placeholder */
  &:disabled::placeholder {
    color: #BDBEBF;
  }
`;

const StyledLabel = styled.label`
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-weight: ${props =>
    props.labelType === 'primary' ? '700' : props.labelType === 'secondary' ? '400' : '700'};
  line-height: 140%;
  letter-spacing: 0.16px;


  ${props =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const InputContainer = styled.div`

  position: relative;
  display: flex;
  flex-direction: column;
`;

const TextAtBeginning = styled.span`

position: absolute;
left: 10px; 
top: 50px;
transform: translateY(-50%);
color: #66676B; 
font-family: Mulish;
font-size: 14px;
font-weight: 500;
line-height: 140%;
letter-spacing: 0.28px;
text-transform: uppercase;


input:disabled + & {
  color: #bdbfbf;
}
`;

const TextAtEnd = styled.span`

  position: absolute;
  right: 10px; 
  top: 50px;
  transform: translateY(-50%);
  color: #66676B; 
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: uppercase;


  input:disabled + & {
    color: #bdbfbf;
  }
`;

const InputTextComponent = ({
  name,
  register,
  disabled = false,
  textAtBeginning,
  textAtEnd,
  registerFields,
  error = {},
  ...props
}) => {
  return (
    <InputContainer className='w-100'>
      <StyledLabel
        labelType={props?.labelType}
        className='pb-1'
        htmlFor={props?.name}
        disabled={disabled}
      >
        {props?.label}
      </StyledLabel>
      <StyledInputText
        className='form-control'
        type="text"
        textAtBeginning={textAtBeginning}
        {...register(name, registerFields)}
        {...props}
        disabled={disabled}
      />
      {textAtBeginning && <TextAtBeginning>{textAtBeginning}</TextAtBeginning>}
      {textAtEnd && <TextAtEnd>{textAtEnd}</TextAtEnd>}
      {error[name] && error[name].message && <span className="error">{error[name].message}</span>}
    </InputContainer>
  );
};

export default InputTextComponent;