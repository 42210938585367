import React from 'react';

/**
 * status: 'online', 'offline'
 */
const OnlineTag = ({ status }) => {
    return (
      <p className='d-flex gap-2 align-items-center'>
        Camera: 
        <span className={`text-uppercase properties-kit-status properties-${status}`}>
            {status}
        </span >
      </p>
        
    )
}

export default OnlineTag;