import {
  Center,
  CloseButton,
  Group,
  Loader,
  ScrollArea,
  Table,
  Text,
  TextInput,
  UnstyledButton,
  keys,
  rem,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import React, { useState } from "react";
import { IoMdSearch } from "react-icons/io";
import {
  HiMiniChevronUp,
  HiMiniChevronDown,
  HiMiniChevronUpDown,
} from "react-icons/hi2";

function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => {
      if (typeof item[key] === "string") {
        return item[key].toLowerCase().includes(query);
      } else if (typeof item[key] === "object" && item[key] !== null) {
        return keys(item[key]).some((key2) => {
          if (typeof item[key][key2] === "string") {
            return item[key][key2].toLowerCase().includes(query);
          } else {
            return false;
          }
        });
      }
    })
  );
}
function sortData(data, payload) {
  const { sortBy, sortProperty } = payload;
  if (!sortBy) {
    return filterData(data, payload.search);
  }
  if (sortProperty) {
    return filterData(
      [...data].sort((a, b) => {
        const elA = a[sortBy] ? a[sortBy][sortProperty] : "";
        const elB = b[sortBy] ? b[sortBy][sortProperty] : "";

        if (typeof elA !== "string") {
          if (payload.reversed) {
            return elB - elA;
          } else {
            return elA - elB;
          }
        } else {
          if (payload.reversed) {
            return elB?.localeCompare(elA);
          }
          return elA?.localeCompare(elB);
        }
      }),
      payload.search
    );
  }
  return filterData(
    [...data].sort((a, b) => {
      if (typeof a[sortBy] !== "string") {
        if (payload.reversed) {
          return b[sortBy] - a[sortBy];
        } else {
          return a[sortBy] - b[sortBy];
        }
      } else {
        if (payload.reversed) {
          return b[sortBy]?.localeCompare(a[sortBy]);
        }
        return a[sortBy]?.localeCompare(b[sortBy]);
      }
    }),
    payload.search
  );
}
const TableCell = ({ children, title, ...props }) => (
  <Table.Td title={title} {...props}>
    {children}
  </Table.Td>
);
function Th({ children, reversed, sorted, onSort }) {
  const { hovered, ref } = useHover();
  const Icon = sorted
    ? reversed
      ? HiMiniChevronUp
      : HiMiniChevronDown
    : HiMiniChevronUpDown;
  return (
    <Table.Th p={0}>
      <UnstyledButton
        ref={ref}
        onClick={onSort}
        w={"100%"}
        style={{
          backgroundColor: hovered ? "var(--mantine-color-gray-0)" : "",
        }}
      >
        <Group gap={3} align="center">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center style={{ borderRadius: rem(21) }} w={20} h={20}>
            <Icon style={{ width: 15, height: 15 }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}
const BasicTable = ({
  isLoading,
  data,
  columns,
  setSortedData,
  sortedData,
  button,
}) => {
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const setSorting = (field, property) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(
      sortData(data, {
        sortBy: field,
        sortProperty: property,
        reversed,
        search,
      })
    );
  };
  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(data, { sortBy, reversed: reverseSortDirection, search: value })
    );
  };
  const handleResetSearch = () => {
    setSearch("");
    setSortedData(
      sortData(data, { sortBy, reversed: reverseSortDirection, search: "" })
    );
  };
  const rows = sortedData.map((row) => (
    <Table.Tr key={row._id}>
      {columns?.map((col, index) => (
        <TableCell key={index} title={col.title ? col.title(row) : undefined}>
          {col.cell ? col.cell(row) : undefined}
        </TableCell>
      ))}
    </Table.Tr>
  ));

  return (
    <div className="d-flex flex-column gap-3 max-h-80vh mw-100">
      <div className="d-flex gap-3">
        <TextInput
          placeholder="Search..."
          leftSection={
            <IoMdSearch
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
          value={search}
          onChange={handleSearchChange}
          rightSection={<CloseButton onClick={handleResetSearch} />}
        />
        {button}
      </div>
      <ScrollArea h={"100%"}>
        {isLoading ? (
          <Center h={100}>
            <Loader />
          </Center>
        ) : (
          <Table stickyHeader>
            <Table.Thead>
              <Table.Tr>
                {columns?.map((col, index) => {
                  return col.sortBy ? (
                    <Th
                      key={index}
                      sorted={sortBy === col.sortBy}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting(...col.sorting)}
                    >
                      {col.header}
                    </Th>
                  ) : (
                    <Table.Td key={index}>{col.header}</Table.Td>
                  );
                })}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {rows.length > 0 ? (
                rows
              ) : (
                <Table.Tr>
                  <Table.Td colSpan={5}>
                    <Text fw={500} ta="center">
                      Nothing found
                    </Text>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table>
        )}
      </ScrollArea>
    </div>
  );
};
export default BasicTable;
