import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import style from './style.module.scss';
import './react-datepicker.scss';
import clock from '../../../../../assests/images/clock.svg';
import { ReactSVG } from 'react-svg';
import { useGetMonthAvailability } from '../../../../../api/useQueryHooks/useGetMonthAvailability';
import { useWindowValue } from '../../../../../hooks/useWindowValue';
import { toZonedTime, format } from 'date-fns-tz';

const setSVGAttributes = (svg) => {
  svg.setAttribute('height', '20');
  svg.setAttribute('width', '20');
  const path = svg.querySelector('path');
  if (!path) return;
  path.setAttribute('stroke-width', '3.7');
};

function Step2({ currentStep, propertyId, onChange, className, agent }) {
  const { agentId } = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const selectedMonth = selectedDate.getMonth();
  const selectedYear = selectedDate.getFullYear();

  const { data, isLoading, isError } = useGetMonthAvailability(
    propertyId,
    selectedMonth,
    selectedYear,
    agent ?? agentId
  );

  const windowWidth = useWindowValue('innerWidth', 'resize');

  if (!propertyId) return <div>No property selected</div>;

  if (isLoading)
    return (
      <div className={`date-picker-wrapper`}>
        {windowWidth > 750 ? (
          <DatePicker
            disabled
            inline
            showTimeSelect
            timeFormat='p'
            timeIntervals={15}
            dateFormat='Pp'
            minDate={new Date()}
          />
        ) : (
          <>
            <DatePicker
              disabled
              inline
              minDate={new Date()}
            />
            <div className={style.myTimePickerContainer}>
              <DatePicker
                minDate={new Date()}
                disabled
                className={style.myTimePicker}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat='h:mm aa'
              />
              <ReactSVG
                src={clock}
                className={style.clock}
                beforeInjection={setSVGAttributes}
              />
            </div>
          </>
        )}
      </div>
    );

  if (isError)
    return (
      <div>
        There was an error loading the data, please reload and try again
      </div>
    );

  const { excludedDates, settings } = data;

  const todayDate = toZonedTime(new Date(), "America/Los_Angeles");

  const now = {
    time: todayDate.getTime(),
    month: todayDate.getMonth(),
    year: todayDate.getFullYear(),
    day: todayDate.getDay(),
  };

  const filterDates = (date) => {
    if (!settings) {
      return false;
    }

    const dayNames = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    const dayOfWeek = date.getDay();
    const dayConfig = settings.bookings.dow[dayNames[dayOfWeek]];

    return dayConfig.available;
  };
  const filterTimes = (date) => {
    if (date.getTime() < now.time) {
      return false;
    }

    if (!settings) {
      return false;
    }

    const dayNames = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    const dayOfWeek = date.getDay();
    const {availability} = settings.bookings.dow[dayNames[dayOfWeek]];

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const totalMinutes = hours * 60 + minutes;

    const isAvailable = availability.some((schedule) => {
      return totalMinutes >= schedule.startTime && totalMinutes <= schedule.stopTime;
    });

    return isAvailable;
  };

  const filterExactDates = (selectedDate, excludeDates) => {
    return excludeDates?.filter((excludeDate) => {
      return (
        excludeDate.getDay() === selectedDate.getDay() &&
        excludeDate.getMonth() === selectedMonth &&
        excludeDate.getFullYear() === selectedYear
      );
    });
  };

  const excludeTimes = (selectedDate) => {
    const excludeDates = excludedDates?.map((unixDate) => new Date(unixDate));
    const filteredDates = filterExactDates(selectedDate, excludeDates);
    return filteredDates;
  };

  const handleChange = (date) => {
    setSelectedDate(date);
    onChange({
      target: {
        name: 'localTimeRequested',
        value: format(date, 'yyyy-MM-dd HH:mm'),
      },
    });
  };

  return (
    <div className={` ${currentStep !== 2 ? 'd-none' : ''} ${className}`}>
      <div className='calendar-wrapper'>
        <div className={`date-picker-wrapper ${style.container}`}>
          {windowWidth > 750 ? (
            <DatePicker
              selected={selectedDate}
              onChange={handleChange}
              inline
              showTimeSelect
              timeFormat='p'
              timeIntervals={15}
              dateFormat='Pp'
              minDate={new Date()}
              excludeTimes={excludeTimes(selectedDate)}
              filterDate={filterDates}
              filterTime={filterTimes}
            />
          ) : (
            <>
              <DatePicker
                filterDate={filterDates}
                selected={selectedDate}
                inline
                minDate={new Date()}
                onChange={handleChange}
              />
              <div className={style.myTimePickerContainer}>
                <DatePicker
                  className={style.myTimePicker}
                  filterTime={filterTimes}
                  excludeTimes={excludeTimes(selectedDate)}
                  onChange={handleChange}
                  selected={selectedDate}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  dateFormat='h:mm aa'
                  customInput={
                    <input
                      readOnly
                      inputMode='none'
                      style={{ position: 'relative', zIndex: '1000' }}
                    />
                  }
                  //   onFocus={(e) => [e.target.blur()]}
                />
                <ReactSVG
                  src={clock}
                  className={style.clock}
                  beforeInjection={setSVGAttributes}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Step2;
