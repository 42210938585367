import { useQuery} from "@tanstack/react-query";
import {  useSearchParams } from "react-router-dom";
import ShowInstructionsCard from "./components/ShowInstructionsCard";

function InstructionsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token")

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["tour-instructions", token],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_NODE_API_URL}/api/booking/instructions?token=${token}`
      );
      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error || 'An error occurred');
      }
      const instructions = await res.json();
      return instructions;
    },
    refetchOnWindowFocus: false,
  });

  if (error) {
    console.log(error.message);
  }

  return (
    <div className="full-screen-container d-flex flex-column">
      <nav class="navbar">
        <div class="container-fluid px-5 py-2">
          <img
            src="https://developmentapi.delet.com/images/delet-logo.png"
            alt="delet-logo"
            className="logo"
          />
        </div>
      </nav>
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        {isLoading ? (
          "...loading"
        ) : error ? (
          error.message
        ) : (
          <ShowInstructionsCard booking={data} refetch={refetch} token={token} />
        )}
      </div>
      <footer className="instruction-footer bg-black d-flex justify-content-center align-items-center text-white">
        © Delet 2024. All rights reserved.
      </footer>
    </div>
  );
}

export default InstructionsPage;
