import {
  Box,
  Button,
  Center,
  Flex,
  Group,
  PasswordInput,
  Progress,
  Stack,
  Text,
} from "@mantine/core";
import { isNotEmpty, matchesField, useForm } from "@mantine/form";
import React, { useEffect } from "react";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import UseHttp from "../../hooks/UseHttp";
import { Auth } from "aws-amplify";
import { useGeneral } from "../../context/GeneralContext";

const ChangePassword = ({ close,userId }) => {
  const { isLoading, error, requestData: changePassword } = UseHttp();

  const requirements = [
    { re: /[0-9]/, label: "Includes number" },
    { re: /[a-z]/, label: "Includes lowercase letter" },
    { re: /[A-Z]/, label: "Includes uppercase letter" },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
  ];

  const isValidPassword = (password) =>
    requirements.find((regex) => !regex.re.test(password)) === undefined;

  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validate: {
      password: (value) => (isValidPassword(value) ? null : "Invalid password"),
      confirmPassword:
        isNotEmpty("Confirm password") &&
        matchesField("password", "Passwords are not the same"),
    },
  });
  function PasswordRequirement({ meets, label }) {
    return (
      <Text component="div" c={meets ? "teal" : "red"} mt={5} size="sm">
        <Center inline>
          {meets ? (
            <CiCircleCheck size="0.9rem" stroke={1.5} />
          ) : (
            <CiCircleRemove size="0.9rem" stroke={1.5} />
          )}
          <Box ml={7}>{label}</Box>
        </Center>
      </Text>
    );
  }

  function getStrength(password) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
  }

  const strength = getStrength(form.values.password);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(form.values.password)}
    />
  ));
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: "0ms" } }}
        value={
          form.values.password.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  const handleSubmit = (e) => {
    e.preventDefault();

    Auth.currentSession().then((token) => {
      changePassword(
        {
          method: "POST",
          url: `${process.env.REACT_APP_NODE_API_URL}/api/users/change-password/${userId}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
          body: {
            password: form.values.password,
          },
        },
        (response) => {
          close();
          setAlertModal({
            show: true,
            title: "Success",
            content: "Your password was changed",
          });
        }
      );
    });
  };
  const { setAlertModal } = useGeneral();

  useEffect(() => {
    if (error) {
      setAlertModal({
        show: true,
        title: "Error",
        content: "There was an error when trying to change your password",
      });
    }
  }, [error]);

  return (
    <Stack gap={"md"}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <PasswordInput
          withAsterisk
          label="New password"
          placeholder="Secure password"
          size="md"
          {...form.getInputProps("password")}
        />
        {form.values.password.length &&
        !isValidPassword(form.values.password) ? (
          <>
            <Group gap={5} grow mt="xs" mb="md">
              {bars}
            </Group>

            <PasswordRequirement
              label="Has at least 6 characters"
              meets={form.values.password.length > 5}
            />
            {checks}
          </>
        ) : null}

        <PasswordInput
          mt={10}
          withAsterisk
          label="Confirm password"
          placeholder="Repeat your password"
          size="md"
          {...form.getInputProps("confirmPassword")}
        />
        <Flex justify={"end"} mt={10}>
          <Button
            type="submit"
            mt={"md"}
            disabled={!form.isValid()}
            loading={isLoading}
          >
            Change password
          </Button>
        </Flex>
      </form>
    </Stack>
  );
};

export default ChangePassword;
