import React, { useEffect, useState } from "react";
import {
  Button,
  CloseButton,
  Container,
  Flex,
  Text,
} from "@mantine/core";
import SearchSelect from "../../../../components/Selects/SearchSelect";
import { useListState } from "@mantine/hooks";
import { fetchKitsWithQuery } from "../../../../api/kits";

const AssignKitsModal = ({ assignKits }) => {
  const [data, setData] = useState([]);

  const fetchAvailableKits = async (query) => {
    try {
     const kits = await fetchKitsWithQuery(query)
     setData(kits)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAvailableKits({user: {$eq: null}});
  }, []);

  const [selectedKits, handlers] = useListState([]);
  const addKit = (el) => handlers.append(el);
  const removeKit = (index) => handlers.remove(index);

  const selectKit = (kit) => {
    const find = selectedKits.find((item) => item._id === kit._id);
    if (!find) addKit(kit);
  };

  const handleClick = (e) => {
    e.preventDefault();

    assignKits(selectedKits);
  };

  return (
    <>
      <SearchSelect
        data={data}
        label={"Select kits"}
        selectBy={"name"}
        handleSelect={(val) => selectKit(val)}
        placeholder={"Select available kits to assign to order"}
      />
      <Container mt={10}>
        {selectedKits?.map((kit, index) => (
          <Flex key={index} align={"center"}>
            <Text size="md">{kit.name}</Text>
            <CloseButton size="xs" onClick={() => removeKit(index)} />
          </Flex>
        ))}
      </Container>
      <Flex justify={"flex-end"}>
        <Button color="black" onClick={handleClick}>
          Submit
        </Button>
      </Flex>
    </>
  );
};

export default AssignKitsModal;
