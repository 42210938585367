import { Button } from 'react-bootstrap';

const ButtonIcon = ({ ...props }) => {
    const { icon, color, borderColor, children } = props;
    return (
        <Button {...props} style={{ backgroundColor: color, border: `1px solid ${borderColor}` }} >
            <img src={icon} alt={children} className='m-2' />
            {children}
        </Button>
    );
}

export default ButtonIcon;