import React from 'react';
import { HTMLAttributes, forwardRef } from 'react';
import style from './style.module.scss';
import { Image } from './Image';
import { Title } from './Title';
import { SubTitle } from './SubTitle';
import { Link } from './Link';

type Props = {
  active?: boolean;
  disabled?: boolean;
  interactive?: boolean;
} & HTMLAttributes<HTMLDivElement>;

interface ICard extends React.ForwardRefExoticComponent<Props> {
  Image: typeof Image;
  Title: typeof Title;
  SubTitle: typeof SubTitle;
  Link: typeof Link;
}

const ref = forwardRef<HTMLDivElement>(
  ({ active, disabled, interactive, className, ...props }: Props, ref) => {
    return (
      <div
        ref={ref}
        className={`
              ${style.card}
              ${interactive && style.interactive}
              ${active && style.active}
              ${disabled && style.disabled}
              ${className} 
          `}
        {...props}
      >
        {props.children}
      </div>
    );
  }
);

export const Card = {
  ...ref,
  Image: Image,
  Title: Title,
  SubTitle: SubTitle,
  Link: Link,
} as ICard;
