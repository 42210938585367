import {
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Card,
} from 'react-bootstrap';

import LogoGraph from '../../components/LogoGraph/LogoGraph';
import useQuery from '../../hooks/useQuery';
import UseHttp from '../../hooks/UseHttp';
import Alert from '../../components/Alert';
import LoaderRing from '../../components/LoaderRing';


const Subscription = () => {
    const [alertModal, setAlertModal] = useState({ show: false, title: 'Alert', content: '' })

    const [subscription, setSubscription] = useState(null);

    const query = useQuery();

    const contactID = query.get('contact');

    const {
        isLoading: contactLoading,
        error: contactError,
        requestData: getContact,
    } = UseHttp();

    const {
        isLoading: updateContactSubLoading,
        error: updateContactSubError,
        requestData: updateContactSub,
    } = UseHttp();

    useEffect(() => {
        if (contactID) {
            getContact(
                { url: `${process.env.REACT_APP_NODE_API_URL}/api/contact/subscription/${contactID}` },
                (contact) => {
                    setSubscription(contact.subscription);
                }
            )
        }
    }, [contactID, getContact]);

    useEffect(() => {
        if (contactError) {
            setAlertModal({ show: true, title: contactError.title, content: contactError.content })
        }
    }, [contactError]);

    useEffect(() => {
        if (updateContactSubError) {
            setAlertModal({ show: true, title: updateContactSubError.title, content: updateContactSubError.content });
        }
    }, [updateContactSubError]);

    const handleUpdateSub = () => {
        updateContactSub(
            {
                method: 'PUT',
                url: `${process.env.REACT_APP_NODE_API_URL}/api/contact/subscription/${contactID}`,
                body: { subscription: !subscription }
            },
            (response) => {
                setAlertModal({ show: true, title: response.title, content: response.message });
                setSubscription(response.body.subscription);
            }
        );
    }

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center position-relative" style={{ top: -150 }}>
                <LogoGraph />
                {(contactLoading || updateContactSubLoading)
                    ?
                    <LoaderRing />
                    :
                    (subscription !== null) && <Card style={{ height: 'auto', width: 344, backgroundColor: '#FFFFFF', border: '1px solid #D9D9D9', borderRadius: '16px' }}>
                        <Card.Header as='h5' style={{ backgroundColor: '#FBFBFB', borderBottom: '1px solid #EDEDED', borderRadius: '16px 16px 0 0' }}>{subscription ? 'Unsubscribe' : 'Subscribe'}</Card.Header>
                        <Card.Body>
                            <div className='d-flex align-items-center my-2'>
                                <Card.Text>We will {subscription ? 'remove' : 'add'} you from our message list, do you want to confirm?</Card.Text>
                            </div>
                            <div className='d-flex align-items-center justify-content-center my-2'>
                                <Button onClick={handleUpdateSub}>Confirm</Button>
                            </div>
                        </Card.Body>
                    </Card>
                }
            </div>
            {alertModal.show
                &&
                <Alert
                    show={alertModal.show}
                    title={alertModal.title}
                    content={alertModal.content}
                    setShow={() => setAlertModal({ ...alertModal, show: false })}
                />
            }
        </>
    );
}

export default Subscription;
