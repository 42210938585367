import React from "react";
import { IoIosAlert } from "react-icons/io";

const FieldError = ({ children }) => {
  return (
    <p className="bg-danger bg-opacity-25 d-flex gap-2 align-items-center justify-content-center rounded-3 text-body-secondary py-2">
      <IoIosAlert color="red" size={25} />
      {children}
    </p>
  );
};

export default FieldError;
