export const updateKitsInCache = (queryClient, newKit, existingKit) => {

  if (existingKit) {
    queryClient.setQueryData(["kits"], (prev) =>
      prev.map((kit) => (kit._id === existingKit._id ? existingKit : kit))
    );
  }

  queryClient.setQueryData(["kits"], (prev) =>
    prev.map((kit) =>
      kit._id === newKit._id ? { ...kit, ...newKit } : kit
    )
  );

};