import React, { useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGeneral } from "../../context/GeneralContext";
import "tailwindcss/tailwind.css";
import "../../index.css";

const ForgotPassword = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { setAlertModal } = useGeneral();

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        if (email === "") {
            setAlertModal({
                show: true,
                title: "Error",
                content: "Please Enter Email Address",
            });
        } else if (!emailRegex.test(email)) {
            setAlertModal({
                show: true,
                title: "Error",
                content: "Please enter a valid email address",
            });
        } else {
            try {
                setIsLoading(true);
                const response = await axios.post(
                    `${process.env.REACT_APP_NODE_API_URL}/api/users/forgot-password`,
                    { email }
                );
                setIsLoading(false);
                setAlertModal({
                    show: true,
                    title: "Success",
                    content: response.data.message,
                });
            } catch (error) {
                setIsLoading(false);
                setAlertModal({
                    show: true,
                    title: "Error",
                    content: error.response.data.message,
                });
            }
        }
    };

    return (
        <div className="bg-image">
            <div className="center-container">
                <div className="main-login">
                    <form>
                        <img
                            alt="Brand logo"
                            className="mx-auto w-50 h-10 brand-logo"
                            src={require("../../assests/images/delet-logo.png")}
                        />
                        <div className="form-group pb-4">
                            <label htmlFor="exampleInputEmail1" className="text-start">
                                Email address
                            </label>
                            <input
                                type="email"
                                className="form-control login-input"
                                id="exampleInputEmail1"
                                value={email}
                                onChange={onEmailChange}
                                name="email"
                                placeholder="Name@company.com"
                            />
                        </div>
                        <button
                            type="submit"
                            onClick={onSubmit}
                            className="login-btn btn w-90 px-3 my-3 py-2"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="visually-hidden">Loading...</span>
                                </>
                            ) : (
                                <span className="login-text">Submit</span>
                            )}
                        </button>
                    </form>
                    <p>
                        Remembered your password? <Link to={"/login"}>Login</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
