import { Auth } from 'aws-amplify';
import axios from 'axios';

export const getBookings = async () => {
  const token = await Auth.currentSession();

  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking`
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      }
    });
    const bookings = response.data
    return bookings
  } catch (error) {
    console.error("Error getting bookings: ", error)
  }
};

export const getMeeting = async (meetingId) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/${meetingId}`;
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.log('ERROR GETTING MEETING: ', error);
  }
};

export const getMeetingWithToken = async (token) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/reschedule?token=${token}`;
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    console.log('ERROR GETTING MEETING: ', error);
  }
};

export const getMonthAvailability = async (id, agent, month, year) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/property/${id}/month-availability?month=${month}&year=${year}&agent=${agent}`;
  const res = await fetch(url);
  const bookingInfo = await res.json();

  return bookingInfo;
};

export const rescheduleMeeting = async (token, startTime) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/reschedule?token=${token}`;
  const body = {
    startTime,
  };
  const res = await axios.patch(url, body);
  return res.data;
};

export const createMeeting = async (booking) => {
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking/public`;
  const res = await axios.post(url, booking);
  return res.data;
};

export const postBooking = async (booking) => {
  const token = await Auth.currentSession();
  const url = `${process.env.REACT_APP_NODE_API_URL}/api/booking`;
  const res = await axios.post(url, booking, {
    headers: {
      Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
    }
  });
  return res.data;
};
