import React from "react";

import { useField } from "formik";
import { Switch } from "@mantine/core";

const InputSwitch = ({
  label,
  labelSize = 2,
  inputSize = 10,
  ...props
}) => {

  const [field] = useField(props);
  return <Switch {...field} {...props} checked={field.value} label={label} color="black" className="text-capitalize"/>;
};

export default InputSwitch;
