import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Alert = ({ show = false, title = '', content = '', setShow, navigateTo }) => {
    const navigate = useNavigate()

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{content}</Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={navigateTo ? () => navigate(navigateTo) : setShow}
                >
                    {navigateTo ? 'Okay' : 'Close'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default Alert;
