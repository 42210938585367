import { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import axios from "axios";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import Sidebar from "../components/AdminSideBar";

const OrderFormRow = ({
  shipment,
  index,
  shipments,
  handleKitSelect,
  selectedKits,
  handleInputChange,
}) => (
  <Row>
    <Col xxl={12} xl={12} lg={12}>
      <Card>
        <Card.Header as="h5">Shipment Details</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Tracking code</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="code"
                    type="text"
                    value={shipment.code}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value, index)
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Shipping Company</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="company"
                    type="text"
                    value={shipment.company}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value, index)
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Tracking url</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="trackingUrl"
                    type="text"
                    value={shipment.trackingUrl}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value, index)
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Notes</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="notes"
                    type="text"
                    value={shipment.notes}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value, index)
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center gap-2">
                <Col lg={2}>
                  <Form.Label>Address</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="street"
                    type="text"
                    placeholder="Street"
                    value={shipment.street}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value, index)
                    }
                  />
                </Col>

                <Col lg={10}>
                  <Form.Control
                    name="city"
                    type="text"
                    placeholder="City"
                    value={shipment.city}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value, index)
                    }
                  />
                </Col>

                <Col lg={10}>
                  <Form.Control
                    name="state"
                    type="text"
                    placeholder="State"
                    value={shipment.state}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value, index)
                    }
                  />
                </Col>

                <Col lg={10}>
                  <Form.Control
                    name="zipCode"
                    type="text"
                    placeholder="Zip code"
                    value={shipment.zip}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value, index)
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Buzzer-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Ready to ship Kits</Form.Label>
                </Col>

                <Col lg={10}>
                  <Select
                    options={shipments}
                    onChange={(kit) => handleKitSelect(kit, index)}
                    getOptionValue={(kit) => kit.name}
                    getOptionLabel={(kit) => kit.name}
                    value={selectedKits}
                    isMulti
                  ></Select>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

const CreateShipment = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [shipment, setShipment] = useState({});
  const [selectedKits, setSelectedKits] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [updatedFields, setUpdatedFields] = useState({})

  const handleInputChange = (field, newValue) => {
    setShipment((prev) => {
      const newForm = {...prev};
      newForm[field] = newValue;
      return newForm;
    });

    setUpdatedFields((prev) => {
      const newForm = {...prev};
      newForm[field] = newValue;
      return newForm;
    });
  };

  const handleKitSelect = (kits, index) => {
    setSelectedKits((prev) => {
      const newSelected = [...prev];
      newSelected[index] = kits;
      return newSelected;
    });

    setUpdatedFields((prev) => {
      const newForm = {...prev};
      newForm.kits = kits.map(kit => kit._id);
      return newForm;
    });
  };

  useEffect(() => {
    getKitList();
    getShipment();
  }, []);

  async function getShipment() {
    try {
      var url =
        process.env.REACT_APP_NODE_API_URL +
        `/api/shipments/${id}`;
      const token = await Auth.currentSession();
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          setShipment(response.data)
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut();
            navigate("/admin/login");
          } else {
            setError("Error Getting Kit");
          }
        });
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  }

  async function getKitList() {
    try {
      var url =
        process.env.REACT_APP_NODE_API_URL +
        `/api/shipments?format=list&state=${encodeURIComponent(
          "ready to ship"
        )}`;
      const token = await Auth.currentSession();
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => setShipment(response.data))
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut();
            navigate("/admin/login");
          } else {
            setError("Error Getting Kit");
          }
        });
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = updatedFields;

    const updateShipment = async () => {
      try {
        var url = process.env.REACT_APP_NODE_API_URL + "/api/shipment/" + id;
        const token = await Auth.currentSession();
        const response = await axios.patch(url, data, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          setError("");
          setShow(true);
          return response.data;
        }
      } catch (error) {
        setError("Error Creating Kit");
        setShow(true);
        return null;
      }
    };

    const stripeLink = await updateShipment();
    if (stripeLink.url) {
      console.log(stripeLink.url);
    } else {
      navigate("/orders");
    }
  };

  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">Update Shipment</h1>
        </div>
        <Form method="post" onSubmit={onSubmit}>

            <OrderFormRow
              shipment={shipment}
              handleKitSelect={handleKitSelect}
              selectedKits={selectedKits}
              handleInputChange={handleInputChange}
            />

          <div className="d-flex gap-5 justify-content-center">
            <Button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        </Form>
      </Sidebar>
      {show ? (
        <Modal show={show}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Saved</Modal.Title>
              </Modal.Header>

              <Modal.Body>Shipment created successfully </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default CreateShipment;
