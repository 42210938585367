import React from 'react';

import { useField } from "formik";
import {
    Col,
    Form,
    Row,
} from 'react-bootstrap';


const InputImage = ({ buttonText = 'Upload', description = '', defaultImage, hideUploadBtn=false , file, ...props }) => {
    const [field] = useField(props);
    let srcImage = process.env.REACT_APP_IMAGE_PATH + file;
    if (file !== undefined) {
        if (file.includes('data:image/png')||file.includes('data:image/jpeg')||file.includes('data:image/jpg')) {
            srcImage = file;
        }
    }
    return (
        <Form.Group className="mb-20 formFile-control" controlId={field.name}>
            <Row className="align-items-center">
                <Col lg={12}>
                    <Form.Label>
                        <img
                            className="img-drop-area"
                            src={srcImage}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = defaultImage;
                            }}
                            alt=''
                        />
                        <em>{description}</em>
                        <span className={`btn btn-primary d-inline-block ${hideUploadBtn &&  'd-none'}`}>
                            {buttonText}
                        </span>
                    </Form.Label>
                    <Form.Control
                        type="file"
                        {...field}
                        {...props}
                    />
                </Col>
            </Row>
        </Form.Group>
    )
}

export default InputImage;