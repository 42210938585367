import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useGeneral } from "../../context/GeneralContext";

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const { setAlertModal } = useGeneral();

    const [form, setForm] = useState({
        password: "",
        confirmPassword: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const onFormInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        if (form.password !== form.confirmPassword) {
            setAlertModal({
                show: true,
                title: "Error",
                content: "Passwords do not match",
            });
            return;
        }

        try {
            setIsLoading(true);
            await axios.post(`${process.env.REACT_APP_NODE_API_URL}/api/users/reset-password/${token}`, {
                password: form.password,
            });
            setIsLoading(false);
            setAlertModal({
                show: true,
                title: "Success",
                content: "Password reset successfully. Please login with your new password.",
            });
            navigate("/login");
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            setAlertModal({
                show: true,
                title: "Error",
                content: "Error resetting password",
            });
        }
    };

    return (
        <div className="bg-image">
            <div className="center-container">
                <div className="main-login">
                    <form onSubmit={onSubmit}>
                        <h2>Reset Password</h2>
                        <div className="form-group pb-4">
                            <label htmlFor="newPassword">New Password</label>
                            <input
                                type="password"
                                className="form-control login-input"
                                id="newPassword"
                                value={form.password}
                                onChange={onFormInputChange}
                                name="password"
                                placeholder="Enter your new password"
                            />
                        </div>
                        <div className="form-group pb-4">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <input
                                type="password"
                                className="form-control login-input"
                                id="confirmPassword"
                                value={form.confirmPassword}
                                onChange={onFormInputChange}
                                name="confirmPassword"
                                placeholder="Confirm your new password"
                            />
                        </div>
                        <button
                            type="submit"
                            className="login-btn btn w-90 px-3 my-3 py-2"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="visually-hidden">Loading...</span>
                                </>
                            ) : (
                                <span className="login-text">Reset Password</span>
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
