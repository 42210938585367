import "./Settings.scss";

import React, { useEffect, useState } from "react";

import axios from "axios";
import moment from "moment";
import { Button, Card, Col, Modal, Row, Tab } from "react-bootstrap";

import { Formik, Form, FieldArray, getIn } from "formik";
import { ColorRing } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

import Sidebar from "../../components/SideBar";
import InputText from "../../components/InputText";
import InputSelect from "../../components/InputSelect";
import InputSwitch from "../../components/InputSwitch";
import InputTextarea from "../../components/InputTextarea";
import Tabs from "../../components/Tabs";
import styled from "styled-components";
import Billing from "./Tabs/Billing";
import Users from "./Tabs/Users";
import UnstyledButton from "../../components/UnstyledButton";
import { FaRegCopy } from "react-icons/fa6";

const calcDatesOptions = (firstValue, length, step, minValue) => {
  let options = [];
  let value = firstValue;
  for (let i = 0; i < length; i++) {
    if (value > minValue || minValue === undefined)
      options.push({
        valueDate: value,
        labelDate: moment()
          .startOf("day")
          .add(value, "minutes")
          .format("hh:mm a"),
      });

    value = value + step;
  }
  return options;
};

const RowContainer = styled(Row)`
  .tab-content {
    padding: 0;
  }
`;
const Account = () => {
  // Alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Alert");
  const [alertContent, setAlertContent] = useState("");

  const [settingData, setSettingData] = useState(false);
  const [dow, setDow] = useState({});
  const [timezone, setTimezone] = useState(0);
  const [Loader, setLoader] = useState(false);

  const [currentRole, setCurrentRole] = useState("");
  const navigate = useNavigate();

  const getSettings = async () => {
    try {
      const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/`;
      const token = await Auth.currentSession();

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      });
      if (response.status === 200) {
        setTimezone(response.data.settings.bookings.timezone);
        setSettingData({ ...response.data.settings });
        setCurrentRole(response.data.role);
        setDow(response.data.settings.bookings.dow);
        setLoader(true);
      } else {
        setShowAlert(true);
        setAlertTitle("Alert");
        setAlertContent("Error Getting Settings");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        Auth.signOut()
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            console.log("error signing out: ", error);
          });
      } else {
        setShowAlert(true);
        setAlertTitle("Alert");
        setAlertContent("Error Getting Settings");
      }
    }
  };

  useEffect(() => {
    if (currentRole == "agent") {
      navigate("/");
    }
    getSettings();
    // getStripeSub();
  }, [currentRole]);

  const onSubmit = (data) => {
    delete data.bookings["timezone"];
    Auth.currentSession()
      .then((token) => {
        axios
          .patch(
            `${process.env.REACT_APP_NODE_API_URL}/api/users/settings`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              setTimezone(() => {
                switch (response.data.bookings.timezoneLocation){
                  case 'America/Los_Angeles':
                  case 'America/Phoenix':
                    return -7;
                  case 'US/Alaska':
                    return -8;
                  case 'US/Hawaii':
                    return -10;
                  case 'America/Chicago':
                    return -5;
                  case 'America/Detroit':
                  case 'America/New_York':
                    return -4;
                  default: 
                    return -6;
                }

                
              });
              console.log(response.data.bookings)
              setShowAlert(true);
              setAlertTitle("Saved");
              setAlertContent("Settings Updated Successfully");
            } else {
              setShowAlert(true);
              setAlertTitle("Alert");
              setAlertContent("Error Updating Settings");
            }
          })
          .catch((error) => {
            setShowAlert(true);
            setAlertTitle("Alert");
            setAlertContent("Error Updating Settings");
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          Auth.signOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              console.log("error signing out: ", error);
            });
        } else {
          setShowAlert(true);
          setAlertTitle("Alert");
          setAlertContent("Error Updating Settings");
        }
      });
  };

  const [activeTab, setActiveTab] = useState("");

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("activeTab");

    setActiveTab(query ?? "settings");
  }, []);

  const handleTabChange = (eventKey) => {
    // Update the URL with the active tab query parameter
    window.history.pushState({}, "", `/account?activeTab=${eventKey}`);
    setActiveTab(eventKey);
  };
  return (
    <Sidebar>
      <div className="page-main-head align-items-center">
        <Row className="align-items-center">
          <Col xl={8}>
            <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
              <h2 className="color-black">Account</h2>
            </div>
          </Col>
        </Row>
      </div>
      {Loader === false ? (
        <>
          <div style={{ textAlign: "center" }}>
            <ColorRing
              visible={true}
              height="100"
              width="100"
              ariaLabel="blocks-loading"
              wrapperClass="blocks-wrapper"
              colors={["#1F60AE", "#5C79FF", "#455DC8", "#889DFF", "#5271FF"]}
            />
          </div>
        </>
      ) : (
        <RowContainer className=" mx-0" style={{}}>
          <Tabs
            defaultActiveKey={activeTab}
            onSelect={handleTabChange}
            style={{ paddingLeft: "32px", backgroundColor: "#EAEAEB" }}
          >
            <Tab eventKey="settings" title="Settings">
              <div className="px-5 py-3">
                <Row>
                  <Col lg={8}>
                    <Card>
                      <Card.Header as="h5">General information</Card.Header>
                      <Card.Body>
                        <Formik initialValues={settingData} onSubmit={onSubmit}>
                          {({ values }) => (
                            <Form>
                              <InputText
                                label="Customer Support Phone"
                                name="customerSupportPhone"
                                labelSize={6}
                                inputSize={6}
                              />
                              <hr />
                              <h5>Select Timezone</h5>
                              <div className="d-flex gap-4">
                                <div className="col-6">
                                  <InputSelect
                                    label="Location"
                                    name="bookings.timezoneLocation"
                                    labelSize={6}
                                    inputSize={6}
                                  >
                                    <option value="US/Hawaii">
                                      (Hawaii Time) Hawaii
                                    </option>
                                    <option value="US/Alaska">
                                      (Alaska Time) Alaska
                                    </option>
                                    <option value="America/Los_Angeles">
                                      (Pacific Time) Los Angeles
                                    </option>
                                    <option value="America/Phoenix">
                                      (Mountain Time) Denver
                                    </option>
                                    <option value="America/Chicago">
                                      (Central Time) Canada/Pacific
                                    </option>
                                    <option value="America/Detroit">
                                      (Eastern Time) Detroit
                                    </option>
                                    <option value="America/New_York">
                                      (Eastern Time) New York / Florida /
                                      Georgia
                                    </option>
                                  </InputSelect>
                                </div>
                                {/* <div col-3>
                                  <InputSelect
                                    label="Gap Between Meetings"
                                    name="bookings.padding"
                                    labelSize={6}
                                    inputSize={6}
                                  >
                                    <option value="0">0</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="45">45</option>
                                    <option value="60">60</option>
                                  </InputSelect>
                                </div> */}
                              </div>
                              <hr />
                              <h5>Timezone</h5>
                              <Row className="align-items-center">
                                <Col lg={6}>
                                  <label>GMT {timezone}</label>
                                </Col>
                              </Row>
                              <hr />
                              <h5>Availability</h5>

                              {Object.keys(values.bookings.dow).map((day) => (
                                <div className="row gap-3">
                                  <InputSwitch
                                    label={day}
                                    name={`bookings.dow.${day}.available`}
                                  />

                                  <div className="col-12 d-flex flex-column gap-3">
                                    {!values.bookings.dow[day].available ? (
                                      <span className="fw-semibold text-secondary">
                                        Unavailable
                                      </span>
                                    ) : (
                                      <FieldArray
                                        name={`bookings.dow.${day}.availability`}
                                      >
                                        {({ form, remove, push }) => {
                                          const { values } = form;
                                          return values.bookings.dow[
                                            day
                                          ].availability.map((range, index) => {
                                            const startTimePath = `bookings.dow.${day}.availability.${index}.startTime`;
                                            const stopTimePath = `bookings.dow.${day}.availability.${index}.stopTime`;
                                            const startTime = getIn(
                                              values,
                                              startTimePath
                                            );
                                            return (
                                              <div className="row align-items-center">
                                                <div className="col">
                                                  <InputSelect
                                                    name={`bookings.dow.${day}.availability.${index}.startTime`}
                                                    labelSize={6}
                                                    inputSize={6}
                                                  >
                                                    {calcDatesOptions(
                                                      0,
                                                      96,
                                                      15
                                                    ).map((option, index) => (
                                                      <option
                                                        key={index}
                                                        value={option.valueDate}
                                                      >
                                                        {option.labelDate}
                                                      </option>
                                                    ))}
                                                  </InputSelect>
                                                </div>
                                                <span className="col-1 fw-bold fs-4 text-center">
                                                  -
                                                </span>
                                                <div className="col">
                                                  <InputSelect
                                                    name={`bookings.dow.${day}.availability.${index}.stopTime`}
                                                    labelSize={6}
                                                    inputSize={6}
                                                  >
                                                    {calcDatesOptions(
                                                      0,
                                                      96,
                                                      15,
                                                      startTime
                                                    ).map((option, index) => (
                                                      <option
                                                        key={index}
                                                        value={option.valueDate}
                                                      >
                                                        {option.labelDate}
                                                      </option>
                                                    ))}
                                                  </InputSelect>
                                                </div>
                                                <UnstyledButton
                                                  type="button"
                                                  className="col-1 fw-bold fs-4"
                                                  onClick={() =>
                                                    push({
                                                      startTime: 540,
                                                      stopTime: 1020,
                                                    })
                                                  }
                                                >
                                                  +
                                                </UnstyledButton>

                                                <UnstyledButton
                                                  className="col-1 fw-bold"
                                                  onClick={() => remove(index)}
                                                  disabled={
                                                    values.bookings.dow[day]
                                                      .availability.length === 1
                                                  }
                                                >
                                                  X
                                                </UnstyledButton>
                                              </div>
                                            );
                                          });
                                        }}
                                      </FieldArray>
                                    )}
                                  </div>
                                  <hr />
                                </div>
                              ))}

                              <div className="d-flex flex-column gap-3">
                                <h5>Notifications</h5>

                                <InputSwitch
                                  label="Appointment confirmation"
                                  name="notifications.confirmation.status"
                                />
                                <InputTextarea
                                  label=""
                                  name="notifications.confirmation.text"
                                  labelSize={0}
                                  inputSize={12}
                                  id="confirmation"
                                />

                                <InputSwitch
                                  label="Reminder before booking:"
                                  name="notifications.reminder.status"
                                />
                                <InputSelect
                                  label="Time"
                                  name="notifications.reminder.time"
                                  labelSize={1}
                                  inputSize={3}
                                >
                                  <option value={3600000}>1 hour</option>
                                  <option value={7200000}>2 hour</option>
                                  <option value={10800000}>3 hour</option>
                                  <option value={14400000}>4 hour</option>
                                  <option value={18000000}>5 hour</option>
                                  <option value={21600000}>6 hour</option>
                                </InputSelect>
                                <InputTextarea
                                  label=""
                                  name="notifications.reminder.text"
                                  labelSize={0}
                                  inputSize={12}
                                  id="reminder"
                                />
                              </div>

                              {/* <InputSwitch
                                label="Follow up:"
                                name="notifications.followUp.status"
                              />
                              <InputSelect
                                label="Time"
                                name="notifications.followUp.time"
                                labelSize={1}
                                inputSize={3}
                              >
                                <option value={3600000}>1 hour</option>
                                <option value={7200000}>2 hour</option>
                                <option value={10800000}>3 hour</option>
                                <option value={14400000}>4 hour</option>
                                <option value={18000000}>5 hour</option>
                                <option value={21600000}>6 hour</option>
                                <option value={43200000}>12 hour</option>
                                <option value={86400000}>1 day</option>
                                <option value={172800000}>2 day</option>
                                <option value={259200000}>3 day</option>
                                <option value={345600000}>4 day</option>
                                <option value={432000000}>5 day</option>
                                <option value={518400000}>6 day</option>
                                <option value={604800000}>1 week</option>
                              </InputSelect>
                              <InputTextarea
                                label=""
                                name="notifications.followUp.text"
                                labelSize={0}
                                inputSize={12}
                              /> */}
                              <hr />

                              {/* <h5>Stripe</h5>
                                              {(Object.keys(stripeData).length === 0) ?
                                                  <StripePricingTable />
                                                  :
                                                  <Card className='m-3' style={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)' }}>
                                                      <Card.Body className='d-flex flex-column'>
                                                          <div className='d-flex justify-content-between'>
                                                              <h3>{stripeData.name} - {stripeData.interval} ${stripeData.amount}</h3>
                                                              <span className='tags'>{stripeData.status}</span>
                                                          </div>
                                                          <span className='body-s py-2'>{stripeData.description}</span>
                                                          <span className='body-s py-2'>It will resume: {moment(stripeData.period_end * 1000).format('MM/DD/yyyy')}</span>
                                                          <Link
                                                              onClick={() => window.location.href = 'https://billing.stripe.com/p/login/test_28oeYE0OZfJc492eUU'}
                                                              className='btn btn-primary btn-sm edit-property-btn'
                                                          >
                                                              Manage
                                                          </Link>
                                                      </Card.Body>
                                                  </Card>
                                              } */}
                              <Button type="submit">Save Settings</Button>
                            </Form>
                          )}
                        </Formik>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Tab>
            <Tab eventKey="billing" title="Billing">
              {activeTab === "billing" && <Billing />}
            </Tab>
            <Tab eventKey="users" title="Users">
              {activeTab === "users" && <Users />}
            </Tab>
          </Tabs>
        </RowContainer>
      )}
      {showAlert ? (
        <Modal show={showAlert}>
          <Modal.Header>
            <Modal.Title>{alertTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{alertContent}</Modal.Body>
          <Modal.Footer>
            {alertTitle === "Saved" ? (
              <Button
                variant="secondary"
                onClick={() => {
                  // navigate('/')
                  setShowAlert(false);
                }}
              >
                Okay
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => setShowAlert(false)}>
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </Sidebar>
  );
};

export default Account;
