
import styled from 'styled-components';

const StyledLabel = styled.label`
  color: #000;
  font-family: Mulish;
  font-size: 16px;
  font-weight: ${props =>
    props.variantType === 'primary' ? '700' : props.variantType === 'secondary' ? '400' : '700'};
  line-height: 140%;
  letter-spacing: 0.16px;

  /* disabled styles */
  ${props =>
    props.disabled &&
    ` color: #bdbfbf;
    `}
`;

const LableComponent = ({
  disabled = false,
  name,
  ...props
}) => {
  //make this component a only return a label

  return (
    <StyledLabel
      variantType={props?.variantType}
      htmlFor={props?.name}
      disabled={disabled}
      className='body-s-bold'>
      {props?.label}
    </StyledLabel>
  )



};

// export this component
export default LableComponent;

