import React from "react";

type SecurityLevelOptionProps = {
  badge: React.ReactElement;
  description: string;
  level: string;
  isDefault?: boolean;
  selected: true;
}


const SecurityLevelOption = ({ badge, description, level, isDefault = false, selected }: SecurityLevelOptionProps) => {
  return (
      <div className={`border-2 rounded p-3 d-flex gap-2 align-items-center text-start ${selected ? 'border-delet' : 'border'}`}>
        {badge}
        <div className="gap-2">
          <h4 className="text-delet-blue">Security {level}</h4>
          {isDefault && <span className="default-badge">DEFAULT</span>}
          <p className="fw-semibold">{description}</p>
        </div>
      </div>
  );
};

export default SecurityLevelOption