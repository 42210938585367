import React from "react";

import { useField, useFormikContext } from "formik";
import { Button, Flex, Textarea } from "@mantine/core";

const InputTextarea = ({
  label,
  id,
  labelSize = 2,
  inputSize = 10,
  ...props
}) => {
  const [field] = useField(props);
  const defaultMessages = {
    confirmation:
      "We are looking forward to assisting you! If you have any special requests or need directions, please don't hesitate to contact us.",
    reminder:
      "Please ensure to carry any required documents or items necessary for your appointment. Let us know if you need any special accommodations!",
    followUp:
      "We hope your visit was informative and met your expectations. We're here to help with any further inquiries or future appointments!",
  };

  const { setFieldValue } = useFormikContext();
  const handleResetMessage = () => {
    setFieldValue(`notifications.${id}.text`, defaultMessages[id]);
  };
  return (
    <>
      <Textarea {...field} {...props} />
      <Flex justify={"flex-end"}>
        <Button
          variant="subtle"
          size="compact-sm"
          onClick={() => handleResetMessage("confirmation")}
        >
          Reset message
        </Button>
      </Flex>
    </>
  );
};

export default InputTextarea;
