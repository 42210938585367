import React, { ButtonHTMLAttributes, ForwardedRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type BaseProps = {
  children?: React.ReactNode;
  className?: string;
};

type AsButtonProps = {
  as: 'button';
} & ButtonHTMLAttributes<HTMLButtonElement> &
  BaseProps;

type AsLinkProps = {
  as: 'link';
} & LinkProps &
  BaseProps;

export type Props = AsButtonProps | AsLinkProps;

export const UnstyledAppButton = React.forwardRef<HTMLElement, Props>(
  ({ children, className, ...props }, ref) => {
    if (props.as === 'link') {
      return (
        <Link
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          className={className}
          {...props}
        >
          {children}
        </Link>
      );
    }
    return (
      <button
        ref={ref as ForwardedRef<HTMLButtonElement>}
        className={className}
        {...props}
      >
        {children}
      </button>
    );
  }
);
