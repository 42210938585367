import React, { useState } from "react";
import {
  Button,
  Combobox,
  useCombobox,
  CheckIcon,
  Group,
  Flex,
  ActionIcon,
  Center,
  Loader,
} from "@mantine/core";
import { FaRegEdit } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";

const SelectDropdown = ({ search, setSearch, options, isLoading, placeholder }) => {
    return (
      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder={placeholder}
          leftSection={<IoMdSearch/>}
        />
        {isLoading ? (
          <Center m={20}>
            <Loader size={"sm"} />
          </Center>
        ) : (
          <Combobox.Options>
            {options?.length > 0 ? (
              options
            ) : (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}
          </Combobox.Options>
        )}
      </Combobox.Dropdown>
    );
  };

const SearchSelect = ({data, selectBy, selected, handleSelect, loadingAssign, label, icon, placeholder}) => {
    
    const [search, setSearch] = useState("");
    const combobox = useCombobox({
        onDropdownClose: () => {
          combobox.resetSelectedOption();
          setSearch("");
        },
    
        onDropdownOpen: () => {
          combobox.focusSearchInput();
          combobox.updateSelectedOptionIndex("active");
        },
      });
      const handleValueSelect = async (val) => {
        try{
          await handleSelect(val);
          combobox.closeDropdown();
    
        } catch(error) {
          console.log(error)
        }
      };

      const options = data
      ?.filter((item) =>
        item[selectBy]?.toLowerCase().includes(search?.toLowerCase().trim())
      )
      .map((item) => (
        <Combobox.Option value={item} key={item._id} active={selected}>
          <Group gap="sm">
            {selected?._id === item._id ? <CheckIcon size={12} /> : null}
            <span>{item[selectBy]}</span>
          </Group>
        </Combobox.Option>
      ));
    return (
        <>
      {selected?._id ? (
        <Flex align={"center"}>
          {selected[selectBy]}
          <Combobox
            store={combobox}
            width={250}
            position="bottom-start"
            withArrow
            onOptionSubmit={handleValueSelect}
          >
            <Combobox.Target withAriaAttributes={false}>
              <ActionIcon
                ml={5}
                color="black"
                variant="transparent"
                onClick={() => combobox.toggleDropdown()}
                loading={loadingAssign}
              >
                <FaRegEdit style={{ width: "60%", height: "60%" }} />
              </ActionIcon>
              
            </Combobox.Target>
            <SelectDropdown
              search={search}
              setSearch={setSearch}
              options={options}
              placeholder={placeholder}
            />
          </Combobox>

        </Flex>
      ) : (
        <Combobox
          store={combobox}
          width={250}
          position="bottom-start"
          withArrow
          onOptionSubmit={handleValueSelect}
        >
          <Combobox.Target withAriaAttributes={false}>
            <Button
              onClick={() => combobox.toggleDropdown()}
              variant="transparent"
              size="compact-sm"
              leftSection={icon}
              color="black"
              loading={loadingAssign}
            >              
              {label}
            </Button>
          </Combobox.Target>
          <SelectDropdown
            search={search}
            setSearch={setSearch}
            options={options}
          />
        </Combobox>
      )}
    </>
    );
};

export default SearchSelect;

