import axios from "axios";
const { Auth } = require("aws-amplify");

export const createInvoiceInfo = async (info, kits) => {
  const url = process.env.REACT_APP_NODE_API_URL + "/api/invoiceInfo";

  try {
    const token = await Auth.currentSession();
    const res = await axios.post(url, { info: {
      name: info.name,
      address: {
        state: info.state,
        city: info.city,
        street: info.street,
        zipCode: info.zipCode,
      },
      details: info.details,
    }, kits }, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        "Content-Type": "application/json",
      },
    })
    return res.data
  } catch (error) {
    throw new Error(error)
  }
}

export const getUserInvoicesInfo = async () => {
  const url = process.env.REACT_APP_NODE_API_URL + "/api/invoiceInfo";

  try {
    const token = await Auth.currentSession();  
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
      }
    })
    return res.data    
  } catch (error) {
    
  }
}