import React from "react";
import { IoIosAlert } from "react-icons/io";

const InfoBanner = ({ children }) => {
  return (
    <div className="bg-primary bg-opacity-10 px-5 py-3 d-flex gap-4 align-items-center rounded-3">
      <IoIosAlert color="#5271FF" size={25} />
      {children}
    </div>
  );
};

export default InfoBanner;
