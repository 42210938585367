import React, { MouseEventHandler } from 'react'

type UnstyledButtonProps = {
  children: React.ReactElement | string,
  type?: "button" | "submit" | "reset";
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean 
}

function UnstyledButton({ children, type = 'button', className, onClick, disabled = false }: UnstyledButtonProps) {
  return (
    <button type={type} className={"border-0 bg-white " + className} onClick={onClick} disabled={disabled}>{children}</button>
  )
}

export default UnstyledButton