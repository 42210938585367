import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { fetchKits } from "../../../../api/kits";
import { updatePropertyKit } from "../../../../api/properties";

const SelectKit = ({ labelText, handleCancel, updateProperty, propertyId }) => {
  const [selectedKit, setSelectedKit] = useState(null);

  const {
    data: kits,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["user-kits-list"],
    queryFn: fetchKits,
  });

  const { mutateAsync: handleChangeKit, isPending } = useMutation({
    mutationFn: async (kitId) => await updatePropertyKit(propertyId, kitId),
    onSuccess: (data) => {
      updateProperty({ kit: data.newKit });
      handleCancel();
    },
    onError: (error) => console.error(error),
  });

  return (
    <div className="d-flex flex-column gap-3 pb-5 pt-4">
      <h4 className="text-secondary text-center fs-4">Change Property kit</h4>
      {labelText ? (
        <span className="text-body-tertiary fs-7 text-nowrap text-center">
          {labelText}
        </span>
      ) : null}
      <Select
        options={kits}
        onChange={(kit) => setSelectedKit(kit)}
        getOptionValue={(kit) => kit.name}
        getOptionLabel={(kit) => kit.name}
        isDisabled={isFetching || isPending}
        value={selectedKit}
      />
      <div className="d-flex gap-2">
        <Button
          onClick={handleCancel}
          variant="light"
          className="border border-secondary-subtle w-100 py-2"
          disabled={isPending}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleChangeKit(selectedKit._id)}
          className="w-100 bg-delet border-0 py-2"
          disabled={isPending}
        >
          Change property kit
        </Button>
      </div>
    </div>
  );
};

export default SelectKit;
