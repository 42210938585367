import { useEffect, useState } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { useGeneral } from "../context/GeneralContext";
import { useNavigate } from "react-router-dom";

const useUser = () => {
  const [userData, setUserData] = useState()
  const { setAlertModal } = useGeneral();
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = await Auth.currentSession();
        try {
          const response = await axios.get(`${process.env.REACT_APP_NODE_API_URL}/api/users/`, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          });
          if (response.status === 200) {
            setUserData(response.data);
          } else {
            setAlertModal({ show: true, title: 'Error', content: 'Something went wrong' });
          }
        } catch (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            try {
              await Auth.signOut();
              navigate("/");
            } catch (signOutError) {
              console.log("Error signing out: ", signOutError);
            }
          } else {
            setAlertModal({ show: true, title: 'Error', content: 'Something went wrong' });
          }
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          try {
            await Auth.signOut();
            navigate("/");
          } catch (signOutError) {
            console.log("Error signing out: ", signOutError);
          }
        } else {
          setAlertModal({ show: true, title: 'Error', content: 'Error getting user' });
        }
      }
    };

    fetchUserData();
  }, [setAlertModal, navigate]);
  return { userData};
};

export default useUser;