import styled from "styled-components";

const StyledSubmit = styled.input.attrs({ 
    type: 'submit'
  })`
    display: flex;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    
    white-space: nowrap;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.16px;
    text-decoration: none;
    color:${(props) => {
        if (props.variantType === 'primary') return '#FFFFFF';
        if (props.variantType === 'secondary') return '#000000';
        return '#FFFFFF';
    }};

    background-color: ${(props) => {
        if (props.variantType === 'primary') return '#5D5FEF';
        if (props.variantType === 'secondary') return 'none';
        return '#5D5FEF';

    }};
    border: 1px solid;
    border-radius: 4px;
    border-color: ${(props) => {
        if (props.variantType === 'primary') return '#5D5FEF';
        if (props.variantType === 'secondary') return '#000000';
        return '#5D5FEF';
    }};

    &:hover,
    &:focus {
        background-color: ${(props) => {
        if (props.variantType === 'primary') return '#4143A7'
        if (props.variantType === 'secondary') return 'none';
        return '#4143A7';
    }};
        border-color: ${(props) => {
        if (props.variantType === 'primary') return '#4143A7'
        if (props.variantType === 'secondary') return '#919295'
        return '#4143A7';
    }};
        color:${(props) => {
        if (props.variantType === 'primary') return '#FFFFFF';
        if (props.variantType === 'secondary') return '#919295';
        return '#FFFFFF';
    }};
    }
    &:disabled {
        color: #BDBEBF;
        background-color: ${(props) => {
        if (props.variantType === 'primary') return '#EAEAEB';
        if (props.variantType === 'secondary') return '#FFFFFF';
        return '#EAEAEB';
    }};
        border-color: ${(props) => {
        if (props.variantType === 'primary') return '#EAEAEB'
        if (props.variantType === 'secondary') return '#BDBEBF'
        return '#EAEAEB';
    }};
    }
    & > * {
        & svg {
            height: 24px;
            width: 24px;
        }
    }
`


/**
 * variantType: primary, secondary
 * icon: ReactElement
 * disable: Bool
 */
const InputSubmit = (props) => {
    const { variantType } = props;
    const { value = "Submit" } = props;

    return (
        <StyledSubmit 
            variantType={variantType}
            value={value}
            {...props}
        />
    );
}

export default InputSubmit;