import { Hr } from '../../../../../designSystem/Hr';
import style from './style.module.scss';

type Props = {
  circleContent: React.ReactNode;
  title: string;
  state: 'default' | 'selected' | 'completed';
  className?: string;
};

export const StepNumberLabel = ({
  state,
  circleContent,
  title,
  className,
}: Props) => {
  return (
    <div className={`${style.container} ${style[state]} ${className}`}>
      <div>
        <span className={style.circle}>{circleContent}</span>
        <span className={style.title}>{title}</span>
      </div>
      <Hr className={style.hr} />
    </div>
  );
};
