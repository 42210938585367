import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { Badge, Button, Col, Form, Modal, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useGeneral } from "../context/GeneralContext";
import { useProperty } from "../context/PropertyContext";
import getImageUrl from "../helper/checkImageFormat";
import { DateRangeSelect } from "./Selects/DateRangeSelect";
import ButtonDropdown from "./Buttons/ButtonDropdown";
import ExportModal from "./Modals/ExportModal";
import { CiExport } from "react-icons/ci";

const Container = styled.div`
  width: 100%;
  table {
    tbody {
      tr {
        td,
        td:first-child {
          text-align: left;
          padding-left: 20px;

          a {
            color: #1f2327;
            text-decoration: none;

            &[href^="mailto:"] {
              color: #5271ff;
            }
          }

          .future {
            background-color: #dbffcc !important;
          }

          .past {
            background-color: #fff8cc !important;
          }

          .custom-dropdown {
            background-color: rgba(146, 147, 153, 0.3);
            font-size: 11px;
            letter-spacing: 0.8px;
            line-height: 15px;
            color: #2d2f36;
            border-radius: 3px;
            padding: 2px 28px 2px 7px;
            display: inline-block;
            text-transform: uppercase;
            background-image: url("../../assests/images/downarrow.png");
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) center;
            cursor: pointer;

            &:after {
              content: "";
              height: 10px;
              position: absolute;
              bottom: -8px;
              width: 100%;
              left: 0;
            }

            ul {
              min-width: 190px;
              background-color: #fff;
              border-radius: 3px;
              padding: 8px;
              position: absolute;
              right: 0;
              top: 28px;
              margin-bottom: 0;
              display: none;
              padding-left: 10px;
              list-style: none;
              z-index: 1;

              li {
                text-transform: uppercase;
                font-size: 11px;
                letter-spacing: 0.8px;
                line-height: 15px;
                color: #2d2f36;
                margin-bottom: 10px;
                cursor: pointer;
                text-align: left;
                display: flex;

                & .w-square {
                  padding-left: 36px;

                  &:before {
                    content: "";
                    height: 20px;
                    width: 20px;
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: -35px;
                    margin-right: 8px;
                    border-radius: 3px;
                  }

                  & .interested {
                    &:before {
                      background-color: #52ff58;
                      opacity: 0.3;
                    }
                  }

                  & .no-interest {
                    &:before {
                      background-color: #ff7277;
                      opacity: 0.3;
                    }
                  }

                  & .never-arrived {
                    &:before {
                      background-color: #e9d62a;
                      opacity: 0.3;
                    }
                  }

                  & .sent-application {
                    &:before {
                      background-color: #5271ff;
                      opacity: 0.3;
                    }
                  }

                  & .rescheduled {
                    &:before {
                      background-color: #929399;
                      opacity: 0.3;
                    }
                  }

                  & .leave-this {
                    &:before {
                      background-color: rgba(146, 147, 153, 0.3);
                      content: "x";
                      color: #858588;
                      text-align: center;
                      line-height: 20px;
                    }
                  }
                }

                button {
                  background-color: rgba(82, 113, 255, 0.3);
                  font-size: 11px;
                  letter-spacing: 0.8px;
                  line-height: 15px;
                  border-radius: 3px;
                  border: none;
                  text-transform: uppercase;
                  color: #2d2f36;
                  width: 100%;
                  text-align: center;
                }
              }
            }

            &:hover {
              ul {
                display: block;
              }
            }
          }

          & .notes-btn {
            letter-spacing: 0.8px;
            line-height: 15px;
            display: block;
            text-transform: uppercase;
            position: absolute;
            bottom: 10px;
            left: 180px;
            background-color: transparent;
            padding: 0;
            border: none;
          }
        }

        td:last-child {
          text-align: right;
        }
      }
    }
  }
`;

const LeadList = () => {
  const [outcomeId, setoutcomeId] = useState();
  const [outcomevalue, setoutcomeValue] = useState("");
  const [shownotes, setShownotes] = useState(false);
  const { leads } = useProperty();
  const { setAlertModal, timezone } = useGeneral();
  const setOutcome = (e) => {
    var outcomeValue = e.currentTarget.dataset.value;
    var id = e.currentTarget.dataset.id;
    var data = {
      outcome: outcomeValue,
      tags: ["new", "user selected"],
      status: "active",
    };

    Auth.currentSession().then((token) => {
      axios
        .patch(
          `${process.env.REACT_APP_NODE_API_URL}/api/booking/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setoutcomeId(id);
            setoutcomeValue(outcomeValue);
            setAlertModal({
              show: true,
              title: "Success",
              content: "Outcome Updated Successfully",
            });
          } else {
            setAlertModal({
              show: true,
              title: "Error",
              content: "Error Updating Outcome",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setAlertModal({
            show: true,
            title: "Error",
            content: "Error Updating Outcome",
          });
        });
    });
  };

  const params = useParams();

  const [show, setShow] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleClose = () => {
    setStartDate("");
    setEndDate("");
    setShow(false);
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleDateRangeChange = (value) => {
    setStartDate(value[0]);
    setEndDate(value[1]);
  };

  const handleExport = (e) => {
    e.preventDefault();
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/contact/toCsv?propertyIds=${params.id}`;
      if (startDate) {
        url = `${url}&startDate=${moment(startDate).format("YYYY-MM-DD")}`;
      }
      if (endDate) {
        url = `${url}&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
      }

      console.log(url);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var blob = new Blob([response.data], {
              type: "text/csv",
            });

            const a = document.createElement("a");
            a.download = "leads";
            a.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(clickEvt);
            a.remove();
          }

          setStartDate("");
          setEndDate("");
          handleClose();
        })

        .catch((error) => {
          console.log(error);
          handleClose();

          setAlertModal({
            show: true,
            title: "Error",
            content: "There's no data to export",
          });
        });
    });
  };

  return (
    <>
      <Container>
        <div className="d-flex justify-content-end">
          <ButtonDropdown
            title={"Export"}
            icon={<CiExport size="18px" className="mr-10" />}
            items={[
                { name: "Export All Leads", handleClick: handleExport },
                { name: "Filter By Date", handleClick: handleShow },
              ]}
          />
        </div>

        <ExportModal
          show={show}
          handleClose={handleClose}
          handleExport={handleExport}
          filters={[
            <Col>
              <Form.Label htmlFor="dateRangeSelect">Date</Form.Label>
              <DateRangeSelect
                startDate={startDate}
                endDate={endDate}
                handleDateRangeChange={handleDateRangeChange}
              />
            </Col>,
          ]}
        />
        <Table responsive className="property-table">
          <tbody>
            {leads?.length > 0 &&
              leads
                ?.sort((a, b) => b.createdDate - a.createdDate)
                .map((element, index) => (
                  <tr key={index}>
                    <td>
                      <span className="order">{index + 1}.</span>
                      {element.startTime
                        ? moment(element.startTime * 1000).format("MMM  DD, yyyy ")
                        : ""}
                      <br />
                      {element.startTime
                        ? moment(element.startTime * 1000).format("hh:mm a ")
                        : ""}
                      <div>
                        <Badge className="future">
                          {element.tags?.join(" - ")}
                        </Badge>
                      </div>
                    </td>
                    <td>
                      <Link to="" className="properties-details-link">
                        <span className="position-relative d-inline-block">
                          <img
                            src={getImageUrl(element.contact.document ?? element.contact.idImage)}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = require("../assests/images/wepik-202216-65753.png");
                            }}
                            alt="properties"
                            width={140}
                            height={90}
                          />
                          <p className="lead-name d-inline-block ml-20 position-relative">
                            {element.contact.firstName
                              ? element.contact.firstName
                              : ""}{" "}
                            {element.contact.lastName
                              ? element.contact.lastName
                              : ""}
                          </p>
                        </span>
                      </Link>
                      <Button
                        className="notes-btn"
                        onClick={() => setShownotes(true)}
                      >
                        <img
                          src={require("../assests/images/notes-icon.png")}
                          alt="properties"
                        />
                      </Button>
                    </td>

                    <td>
                      <span className="d-block">
                        <Link
                          to={`tel:${
                            element.contact.phone ? element.contact.phone : ""
                          }`}
                          className="table-phone phonenumber-w-ico link-w-ico"
                        >
                          {element.contact.phone ? element.contact.phone : ""}
                        </Link>
                      </span>
                      <span className="d-block">
                        <Link
                          to={`mailto:${
                            element.contact.email ? element.contact.email : ""
                          }`}
                          className="table-mail mail-w-ico link-w-ico"
                        >
                          {element.contact.email ? element.contact.email : ""}
                        </Link>
                      </span>
                    </td>
                    <td>
                      <div className="custom-dropdown position-relative">
                        <span
                          className={`selected ${
                            outcomeId === element._id
                              ? outcomevalue
                              : element.outcome
                              ? element.outcome
                              : "outcome"
                          }`}
                        >
                          {outcomeId === element._id
                            ? outcomevalue
                            : element.outcome
                            ? element.outcome
                            : "outcome"}
                        </span>
                        <ul className="list-unstyled">
                          <li
                            className="w-square interested"
                            data-id={element._id}
                            data-value="Interested"
                            onClick={setOutcome}
                          >
                            Interested
                          </li>
                          <li
                            className="w-square no-interest"
                            data-id={element._id}
                            data-value=" No Interest"
                            onClick={setOutcome}
                          >
                            No Interest
                          </li>
                          <li
                            className="w-square never-arrived"
                            data-id={element._id}
                            data-value="Never arrived"
                            onClick={setOutcome}
                          >
                            Never arrived
                          </li>
                          <li
                            className="w-square sent-application"
                            data-id={element._id}
                            data-value="Sent Application"
                            onClick={setOutcome}
                          >
                            Sent Application
                          </li>
                          <li
                            className="w-square sent-application"
                            data-id={element._id}
                            data-value="Applied"
                            onClick={setOutcome}
                          >
                            Applied
                          </li>
                          <li
                            className="w-square rescheduled"
                            data-id={element._id}
                            data-value="Rescheduled"
                            onClick={setOutcome}
                          >
                            Rescheduled
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </Container>
      <Modal
        centered
        show={shownotes}
        onHide={() => setShownotes(false)}
        animation={true}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="notes-modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Showing notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div className="notes-img me-2">
              <img
                src={require("../assests/images/wepik-202216-7056.png")}
                alt="properties"
              />
            </div>
            <div className="notes-address">
              {/* <h6> {data.contacts[0].firstName}  {data.contacts[0].lastName}</h6> */}

              <p>1249 W 39th Pl, unit 310, Los Angeles, CA, USA</p>
            </div>
          </div>
          <p>
            <em>
              She is interested in this apartment, with the conditions that the
              living room couch would be changed to a much bigger one and new
              drapes would be installed in the bedroom.
            </em>
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-end">
          {/* <Button className="btn btn-perple ml-auto">Edit notes</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LeadList;
