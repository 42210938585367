import React from 'react';
import ButtonUnderlineText from '../../../components/ButtonUnderlineText';
import { Copy } from 'react-feather';
import styled from 'styled-components';

const CopyIcon = styled(Copy)`
    display: inline-flex;
    color: #5D5FEF;
    border: 1px solid;
    border-radius: 4px;
    padding: 4px;
    & svg {
        height: 16px;
        width: 16px;
    }
`

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    &:hover {
        ${CopyIcon} {
            color: #FFFFFF;
            background-color: #4143A7;
        }
        a {
            color: #4143A7;
        }
  }
`



const CopyLink = ({ onClick, text }) => {
    return (
        <Container onClick={onClick}>
            <ButtonUnderlineText variantType='primary'>
                {text}
            </ButtonUnderlineText>
            <CopyIcon />
        </Container>
    );
};

export default CopyLink;