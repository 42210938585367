import { useQuery } from '@tanstack/react-query';
import { getMonthAvailability } from '../bookings';

export const useGetMonthAvailability = (
  propertyId: string,
  selectedMonth: number,
  selectedYear: number,
  agentId: string
) => {
  return useQuery({
    queryKey: ['month-availability', propertyId, selectedMonth, selectedYear],
    queryFn: () =>
      getMonthAvailability(propertyId, agentId, selectedMonth, selectedYear),
    enabled: !!propertyId,
  });
};
