import { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

import Sidebar from "../components/AdminSideBar";
import ShipmentForm from "./Form";
import { fetchKitsWithQuery } from "../../../api/kits";

const initialState = {
  state: "initiated",
  deliveryAddress: {},
  kits: [],
}

const CreateShipment = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const [userKits, setUserKits] = useState([]);
  const [shipment, setShipment] = useState(initialState);
  const [validated, setValidated] = useState(false);

  const handleUserChange = (index) => {
    index = parseInt(index);
    setShipment((prev) => {
      const updatedShipment = { ...prev };
      updatedShipment.user = users[index]._id;
      updatedShipment.kits = []
      updatedShipment.address = {
        state: '',
        city: '',
        street: '',
        zipCode: ''
      }
      return updatedShipment;
    });
  };

  const handleInputChange = (field, newValue) => {
    setShipment((prev) => {
      const updatedShipment = { ...prev };
      updatedShipment[field] = newValue;
      return updatedShipment;
    });
  };

  const handleAddressChange = (field, newValue) => {
    setShipment((prev) => {
      const updatedShipment = { ...prev };
      updatedShipment.address[field] = newValue;
      return updatedShipment;
    });
  };

  const handleKitSelect = (kits) => {
    setShipment((prev) => {
      const updatedShipment = { ...prev };
      updatedShipment.kits = kits;
      return updatedShipment;
    });
  };

  useEffect(() => {
    async function getUsersList() {
      try {
        const url =
          process.env.REACT_APP_NODE_API_URL + "/api/users/admin/list";
        const token = await Auth.currentSession();
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setUsers(response.data);
            } else {
              setError("Error Getting Users");
              setShow(true);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              Auth.signOut();
              navigate("/admin/login");
            } else {
              setError("Error Getting Users");
              setShow(true);
            }
          });
      } catch (eror) {
        console.log(error);
        navigate("/");
      }
    }

    getUsersList();
  }, [error, navigate]);

  useEffect(() => {
    async function getKitList() {
      try {
          const kits = await fetchKitsWithQuery({
            user: shipment.user,
            state: { $eq: "ready to ship" },
          })
          
          setUserKits(kits)
      } catch (error) {
        console.log(error);
        navigate("/");
      }
    }

    shipment.user && getKitList();
  }, [navigate, shipment.user]);

  const onSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
      const postShipments = async () => {
        const data = { ...shipment };
        data.kits = data.kits.map((kit) => kit._id);
        try {
          var url = process.env.REACT_APP_NODE_API_URL + "/api/shipments";
          const token = await Auth.currentSession();
          const response = await axios.post(url, data, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
              "Content-Type": "application/json",
            },
          });

          if (response.status === 201) {
            setError("");
            setShow(true);
            setShipment(initialState)
            setValidated(true);
            navigate('/admin/shipment/list')
          }
        } catch (error) {
          setError("Error Creating Shipment");
          setShow(true);
          return null;
        }
      };
      postShipments()
    }
    e.preventDefault();
    e.stopPropagation();

    setValidated(true);
    setError("Missing some required fields");
    setShow(true);

  };

  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">Create Shipment</h1>
        </div>
        <Form
          method="post"
          onSubmit={onSubmit}
          noValidate
          validated={validated}
        >
          <ShipmentForm
            shipment={shipment}
            handleKitSelect={handleKitSelect}
            kits={userKits}
            handleInputChange={handleInputChange}
            handleUserChange={handleUserChange}
            users={users}
            handleAddressChange={handleAddressChange}
          />
          <div className="d-flex gap-5 justify-content-center">
            <Button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
            >
              Create shipment
            </Button>
          </div>
        </Form>
      </Sidebar>
      {show ? (
        <Modal show={show}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Saved</Modal.Title>
              </Modal.Header>

              <Modal.Body>Shipment created successfully </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default CreateShipment;
