import React, { useEffect, useState } from "react";
import Step1 from "./Steps/Step1/Step1";
import Step2 from "./Steps/Step2/Step2";
import Step3 from "./Steps/Step3/Step3";
import Vector from "../../assests/images/Vector.svg";
import { useGeneral } from "../../context/GeneralContext";

export default function Stepper({
  list,
  selectProperty,
  propertyIndex,
  onDateChange,
  startDate,
  minTime,
  maxTime,
  excludeDates,
  isverification,
  verificationURL,
  onFileChange,
  file,
  currentSelectedPropertyIndex,
  onSubmit,
  onReschedule,
  selectDate,
  isLoading,
  meetingId,
  meetingLoader,
  meetingData,
  settings,
}) {
  const steps = [
    {
      id: 1,
      title: "Step 1",
      emailInputId: "exampleFormControlInput1",
    },
    {
      id: 2,
      title: "Step 2",
      emailInputId: "exampleFormControlInput2",
    },
    {
      id: 3,
      title: "Step 3",
      emailInputId: "exampleFormControlInput3",
    },
  ];

  const [pageEnable, setPageEnable] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { setAlertModal } = useGeneral();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleActiveStep = (step) => {
    if (step.id < pageEnable) {
      if (step.id === 1 && currentSelectedPropertyIndex) {
        return true;
      } else if (step.id === 2 && selectDate) {
        return true;
      }
    }
    return false;
  };
  const handleActiveBeforeStep = (step) => {
    if (step.id === pageEnable) {
      if (currentSelectedPropertyIndex) {
        return true;
      } else if (selectDate) {
        return true;
      }
    }
    return false;
  };

  const handleStepClick = (stepId) => {
    if (screenWidth > 1200) return;
    if (stepId < pageEnable) {
      setPageEnable(stepId);
    } else if (stepId > pageEnable) {
      if (stepId === 2 && currentSelectedPropertyIndex) {
        setPageEnable(stepId);
      } else if (stepId === 3 && selectDate && currentSelectedPropertyIndex) {
        setPageEnable(stepId);
      } else if (stepId === 3 && selectDate && currentSelectedPropertyIndex) {
        setPageEnable(stepId);
      } else {
        setAlertModal({
          show: true,
          title: "Error",
          content: "Please fill in all required information before proceeding.",
        });
      }
    }
  };

  const handleStepClickNext = (stepId) => {
    if (stepId === 1 && currentSelectedPropertyIndex) {
      setPageEnable(stepId + 1);
    } else if (stepId === 2 && selectDate && currentSelectedPropertyIndex) {
      setPageEnable(stepId + 1);
    } else {
      setAlertModal({
        show: true,
        title: "Error",
        content: "Please fill in all required information before proceeding.",
      });
    }
  };
  return (
    <>
      <div className="row steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
        {steps?.map((step, index) => (
          <div
            key={index}
            className={`col-4 step ${
              handleActiveStep(step) ? "completed" : ""
            } ${handleActiveBeforeStep(step) ? "beforecompleted" : ""}`}
            onClick={() => handleStepClick(step.id)}
          >
            <div className="step-icon-wrap">
              <div className="step-icon">
                {handleActiveStep(step) ? (
                  <img
                    src={Vector}
                    alt="vector"
                    srcSet=""
                    className="Vector-icon"
                    style={{}}
                  />
                ) : (
                  <span className="step-count">{index + 1}</span>
                )}
                <i className="pe-7s-cart"></i>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row justify-content-center">
        <div
          className={`${screenWidth > 1200 ? "col-4" : "col-12"} ${
            screenWidth < 1200 && pageEnable !== 1 ? "step-hide" : ""
          } step-container`}
        >
          <div className="step">
            <Step1
              list={list}
              selectProperty={selectProperty}
              propertyIndex={propertyIndex}
              setPageEnable={setPageEnable}
              screenWidth={screenWidth}
              handleStepClickNext={handleStepClickNext}
            />
          </div>
        </div>
        <div
          className={`${
            screenWidth > 1200 ? "col-4" : "col-12 step-container"
          } ${screenWidth < 1200 && pageEnable !== 2 ? "step-hide" : ""} `}
        >
          <div className="step">
            <div style={{ position: "relative" }}>
              <Step2
                onDateChange={onDateChange}
                startDate={startDate}
                minTime={minTime}
                maxTime={maxTime}
                excludeDates={excludeDates}
                setPageEnable={setPageEnable}
                currentStep={pageEnable}
                screenWidth={screenWidth}
                handleStepClickNext={handleStepClickNext}
                settings={settings}
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            screenWidth > 1200 ? "col-4" : "col-12 step-container"
          } ${screenWidth < 1200 && pageEnable !== 3 ? "step-hide" : ""}`}
        >
          <div className="step">
            <div style={{ position: "relative" }}>
              <Step3
                isverification={isverification}
                currentStep={pageEnable}
                verificationURL={verificationURL}
                onFileChange={onFileChange}
                file={file}
                onSubmit={onSubmit}
                isLoading={isLoading}
                screenWidth={screenWidth}
                onReschedule={onReschedule}
                meetingId={meetingId}
                meetingLoader={meetingLoader}
                meetingData={meetingData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
