import propertyDummyImage from '../../../../../assests/images/6-thumb.png';
import style from './style.module.scss';
import { Card } from '../../../../../designSystem/Card';

function PropertyCard({ property, onChange, selectedId, onSeeDetails }) {
  const { address, shortAddress, images } = property;

  return (
      <Card
        active={selectedId === property._id}
        interactive={true}
      >
        <label
          className='d-flex flex-column align-items-center gap-2 pb-2 h-100'
          htmlFor={property._id}
        >
          <input
            onChange={onChange}
            name='propertyId'
            id={property._id}
            type='radio'
            value={property._id}
            checked={selectedId === property._id}
            className='d-none'
          />
          <Card.Image
            src={images?.[0] ?? propertyDummyImage}
            alt='property'
            className={style.img}
          />
          <Card.Title
            title={shortAddress}
            className={style.title}
          >
            {shortAddress}
          </Card.Title>
          <Card.SubTitle
            title={address}
            className={style.subtitle}
          >
            {address}
          </Card.SubTitle>
          <Card.Link
            as='button '
            style={{ marginInline: 'auto' }}
            onClick={() => onSeeDetails(property)}
          >
            View Details
          </Card.Link>
        </label>
      </Card>
  );
}

export default PropertyCard;
