import '../pages/Properties/Properties.scss';

import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import { useProperty } from '../context/PropertyContext';
import { useGeneral } from '../context/GeneralContext';
import ReactDatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import removeUndefinedWord from '../helper/updateString';
import getImageUrl from '../helper/checkImageFormat';

const LeadSidebar = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [weekName, setweekName] = useState('')
    const [monthName, setmonthName] = useState('')
    const [timeName, settimeName] = useState('')
    const [selectdate, setselectdate] = useState('')
    const [file, setFile] = useState();

    const { setAlertModal, timezone } = useGeneral();
    const { property, showcanvas, setShowcanvas } = useProperty();
    const params = useParams();

    const onDateChange = (e) => {
        setStartDate(e)
        setweekName(e.toLocaleString('default', { weekday: 'long' }))
        setmonthName(e.toLocaleString('default', { month: 'long' }))
        settimeName(e.toLocaleTimeString('en-US'))
        setselectdate(e.getDate())
    }

    const onFileChange = (e) => {
        const fileReader = new FileReader()
        if (e.target.files[0]) {
            fileReader.readAsDataURL(e.target.files[0])
        }
        fileReader.onload = () => {
            var image = fileReader.result
            setFile(image)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        let frm = Object.fromEntries(formData.entries())
        var start = Math.floor(startDate.getTime())
        var end = Math.floor(startDate.getTime() + 15 * 60 * 1000)
        var localDateTime = moment.utc(startDate.getTime() + (timezone * 3600000)).format('MM-DD-YYYY h:mm a')
        var data = {
            contact: {
                firstName: frm.firstName ? frm.firstName : '',
                lastName: frm.lastName ? frm.lastName : '',
                phone: frm.phone ? frm.phone : '',
                email: frm.email ? frm.email : '',
                idImage: file ? file : '',
            },
            timeslot: {
                start: start,
                duration: 15,
                end: end,
                available: true,
            },
            localDateTime: localDateTime,
        }
        var url =
            process.env.REACT_APP_NODE_API_URL +
            '/api/property/' +
            params.id +
            '/book'
        Auth.currentSession().then((token) => {
            axios
                .post(url, data, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data) {
                            setShowcanvas(false)
                            setAlertModal({ show: true, title: 'Success', content: 'Lead added Successfully' });
                        }
                    } else {
                        setAlertModal({ show: true, title: 'Error', content: 'Error Adding Lead' });
                    }
                })
                .catch((error) => {
                    setAlertModal({ show: true, title: 'Error', content: 'Error Adding Lead' });
                })
        })
    }

    return (
        <Offcanvas
            show={showcanvas}
            onHide={() => setShowcanvas(false)}
            placement="end"
            name="end"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>New lead</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form onSubmit={onSubmit} encType="multipart/form-data">
                    <Row>
                        <Col xl={6} lg={12}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    placeholder="First name"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    placeholder="Last name"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="text"
                                    name="phone"
                                    placeholder="Phone number"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Email address"
                                />
                            </Form.Group>
                        </Col>

                        <Col xl={6} lg={12}>
                            <Form.Group
                                className="mb-20 formFile-control"
                                controlId="formFile"
                            >
                                <div className="upload-id-copy">
                                    <Form.Label>
                                        <img className="img-drop-area" src={file} alt='' />
                                        <span className="btn btn-primary d-inline-block">
                                            Upload ID copy
                                        </span>
                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="image"
                                        onChange={onFileChange}
                                    />
                                </div>
                                <em className="upload-id-copy-em">
                                    To add a copy of the ID, use the Upload button, or drag the
                                    image into this box.
                                </em>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="d-flex align-items-center">
                                <div className="notes-img me-2 ">
                                    <img
                                        src={
                                            property.primaryImage
                                                ? getImageUrl(property.primaryImage)
                                                : require('../assests/images/1-thumb.png')
                                        }
                                        alt=""
                                        className="border-r-5"
                                        width={140}
                                        height={90}
                                    />
                                </div>
                                <div className="notes-address">
                                    <h6>{removeUndefinedWord(property.address)}</h6>
                                </div>
                            </div>

                            <div className="offcanvas-calander-wrapper">
                                {/* https://reactdatepicker.com/ */}
                                <div className="d-flex flex-wrap flex-md-nowrap">
                                    <div className="date-picker-wrapper">
                                        <ReactDatePicker
                                            selected={startDate}
                                            //onChange={(date) => setStartDate(date)}
                                            onChange={onDateChange}
                                            inline
                                            // locale="pt-BR"
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={15}
                                            dateFormat="Pp"
                                        />
                                    </div>
                                    <div className="selected-day-wrapper">
                                        <div className="d-flex justify-content-center flex-column h-100 align-items-center">
                                            <h4 className="text-uppercase">
                                                {weekName ? 'you selected' : 'Select Date'}
                                            </h4>
                                            <h2 className="text-uppercase">
                                                {weekName ? weekName : ''}
                                                {weekName ? ',' : ''}
                                                <br /> {monthName ? monthName : ''}{' '}
                                                {selectdate ? selectdate : ''}
                                                {selectdate ? ',' : ''}
                                                <br /> {timeName ? timeName : ''}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="offcanvas-footer">
                        <div className="d-flex justify-content-between">
                            <Button
                                className="btn btn-outline-secondary"
                                onClick={() => setShowcanvas(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className="btn light-btn btn-no-icon btn btn-primary"
                            >
                                Create showing
                            </Button>
                        </div>
                    </div>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    )
};

export default LeadSidebar;
