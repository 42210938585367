import "./book.scss";

import { useEffect, useState } from "react";

import axios from "axios";
import { Button, Card } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";

import LogoGraph from "../../components/LogoGraph/LogoGraph";

import Calendar from "../../assests/images/calendar-day.svg";
import Clock from "../../assests/images/clock.svg";
import Lock from "../../assests/images/lock-ico-fill.svg";
import Unlock from "../../assests/images/unlock-ico-fill.svg";
import DoorBell from "../../assests/images/door-bell.svg";
import ButtonIcon from "../../components/ButtonIcon";

const MeetingUnlock = () => {
  // alert modal
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [allowed, setAllowed] = useState(true);
  const [lockStatus, setLockStatus] = useState("Locked");
  const [booking, setMeeting] = useState({});
  const params = useParams();

  useEffect(() => {
    const meetingid = params.meetingid;
    const lockKey = params.lockKey;
    axios
      .get(
        `${process.env.REACT_APP_NODE_API_URL}/api/booking/${meetingid}/${lockKey}/info`
      )
      .then((response) => {
        if (response.status === 200) {
          setMeeting(response.data);
          getLockStatus(response.data.hardware);
          const startTime = response.data.startTime;
          const stopTime = response.data.stopTime;
          const currentTime = new Date().getTime();
          if (startTime < currentTime && stopTime > currentTime) {
            setAllowed(true);
          }
        }
      })
      .catch((error) => {
        setMeeting(error.response.data);
        setShow(true);
        setError(error.response.data.message);
      });
  }, [params]);

  const getLockStatus = (lockId) => {
    axios
      .get(`${process.env.REACT_APP_NODE_API_URL}/api/lock/${lockId}/status`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            setLockStatus(response.data);
          }
        }
      })
      .catch((error) => {
        setShow(true);
        setError("No connection to lock");
      });
  };

  const unlockDoor = () => {
    const meetingid = params.meetingid;
    const lockKey = params.lockKey;
    axios
      .get(
        `${process.env.REACT_APP_NODE_API_URL}/api/booking/${meetingid}/${lockKey}/unlock`
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            if (response.data === "unlock") {
              setShow(true);
              setError("Unlocked");
              setLockStatus("Unlocked");
            } else {
              setShow(true);
              setError("Error Unlocking door");
            }
          }
        }
      })
      .catch((error) => {
        setShow(true);
        setError(error.response.data.message);
      });
  };

  const pressBell = () => {
    const meetingid = params.meetingid;
    const lockKey = params.lockKey;
    axios
      .get(
        `${process.env.REACT_APP_NODE_API_URL}/api/booking/${meetingid}/${lockKey}/push`
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            setShow(true);
            setError("Pressed");
          } else {
            setShow(true);
            setError("Error Pressing Bell");
          }
        }
      })
      .catch((error) => {
        setShow(true);
        setError(error.response.data.message);
      });
  };

  const lockDoor = () => {
    const meetingid = params.meetingid;
    const lockKey = params.lockKey;
    axios
      .get(
        `${process.env.REACT_APP_NODE_API_URL}/api/booking/${meetingid}/${lockKey}/lock`
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            if (response.data === "lock") {
              setShow(true);
              setError("Locked");
              setLockStatus("Locked");
            } else {
              setShow(true);
              setError("Error Locking door");
            }
          }
        }
      })
      .catch((error) => {
        setShow(true);
        setError(error.response.data.message);
      });
  };
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center position-relative"
        style={{ top: -150 }}
      >
        <LogoGraph />
        <Card
          key={booking._id}
          style={{
            height: "auto",
            width: 344,
            backgroundColor: "#FFFFFF",
            border: "1px solid #D9D9D9",
            borderRadius: "16px",
          }}
        >
          <Card.Header
            as="h5"
            style={{
              backgroundColor: "#FBFBFB",
              borderBottom: "1px solid #EDEDED",
              borderRadius: "16px 16px 0 0",
            }}
          >
            Your tour is scheduld for...
          </Card.Header>
          <Card.Body>
            <div className="d-flex align-items-center my-2">
              <img src={Calendar} alt="Calendar" className="m-1" />
              <Card.Text>{booking.date}</Card.Text>
            </div>
            <div className="d-flex align-items-center my-2">
              <img src={Clock} alt="Clock" className="m-1" />
              <Card.Text>{booking.time}</Card.Text>
            </div>
          </Card.Body>
        </Card>
        {allowed ? (
          <div
            className="d-flex justify-content-between"
            style={{ width: 344 }}
          >
            {lockStatus === "Locked" ? (
              <ButtonIcon
                icon={Lock}
                color="#0ABA08"
                onClick={unlockDoor}
                borderColor="#D9D9D9"
              >
                Unlock door
              </ButtonIcon>
            ) : (
              <ButtonIcon
                icon={Unlock}
                color="#FF5258"
                onClick={lockDoor}
                borderColor="#FF5258"
              >
                Lock the door
              </ButtonIcon>
            )}
            <ButtonIcon
              icon={DoorBell}
              color="#1F2327"
              onClick={pressBell}
              borderColor="#1F2327"
            >
              Door bell
            </ButtonIcon>
          </div>
        ) : (
          <p>Time period not allowed</p>
        )}
      </div>
      {show ? (
        <Modal show={show}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <></>
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default MeetingUnlock;
