import { Button, Stack, Text, Title } from "@mantine/core";
import React from "react";
import { LuCheckCircle } from "react-icons/lu";
import Sidebar from "../../components/SideBar";
import { useNavigate } from "react-router-dom";

const OrderSuccess = () => {
  const navigate = useNavigate();
  const handleSeeOrders = () => {
    navigate("/kits/orders");
  };
  return (
    <Sidebar>
      <Stack align="center" m={100}>
        <Title c={"green"} order={2}>
          Order Placed Sucessfully
        </Title>
        <LuCheckCircle
          style={{
            width: 60,
            height: 60,
            color: "var(--mantine-color-green-filled)",
          }}
        />
        <Text w="60%" ta="center" size="lg" mt={"lg"}>
          Once your order is confirmed, you will be able to select the shipping
          address for each kit
        </Text>
        <Button color={"black"} mt={50} onClick={handleSeeOrders}>
          See orders
        </Button>
      </Stack>
    </Sidebar>
  );
};

export default OrderSuccess;
