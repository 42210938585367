import React from "react";

const CountdownTimer = ({ children, timeRemaining, reload, showButton }) => {
  return (
    <div className="d-flex flex-column gap-3">
      {children}
      {showButton && (
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <strong className="text-center">{timeRemaining}</strong>
          {timeRemaining.includes("The unlock button will be active at ") && (
            <button
              className="btn bg-delet border-0 d-flex align-items-center gap-3 justify-content-center text-white rounded"
              onClick={reload}
            >
              reload
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;
