import React, { createContext, useContext, useState, useRef } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useGeneral } from './GeneralContext';

const PropertyContext = createContext();

const PropertyProvider = ({ children }) => {
  const intervalRef = useRef({});
  const [property, setProperty] = useState({});
  const [cameras, setCameras] = useState([]);
  const [CameraUrl, setCameraUrl] = useState([]);
  const [cameraLoading, setCameraLoading] = useState(true);
  const [showToast, setShowToast] = useState({});
  const [showcanvas, setShowcanvas] = useState(false);
  const [leads, setLeads] = useState([]);
  const [leadFilters, setLeadFilters] = useState({ outcome: 'All', time: null });
  const [leadsCount, setLeadsCount] = useState({
    next: {
      week: 0,
      month: 0,
      all: 0
    },
    past: {
      week: 0,
      month: 0,
      all: 0
    },
    all: 0
  });

  const [cameraStatus, setCameraStatus] = useState({});

  const { setAlertModal } = useGeneral();

  // Function to clear intervals for all cameras to prevent memory leaks
  const clearAllIntervals = () => {
    Object.values(intervalRef.current).forEach((interval) => {
      clearInterval(interval);
    });
  };

  function getStreamUrl(element) {
    let intervalCount = 0;
    const maxIntervals = 30;

    const interval = setInterval(() => {
      setCameraLoading(true);
      const url =
        process.env.REACT_APP_NODE_API_URL +
        '/api/hardware/' +
        element._id +
        '/stream/testing';
      Auth.currentSession().then((token) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${token.getIdToken().getJwtToken()}` } })
          .then((response) => {
            if (response.status === 200) {
              const { url } = response.data;
              setCameraUrl((prevCameraUrl) => {
                const updatedCameraUrl = prevCameraUrl.map((item) =>
                  item.hasOwnProperty(element._id) ? { ...item, [element._id]: url } : item
                );
                return [...updatedCameraUrl];
              });

              setCameraStatus(prevCameraStatus => ({
                ...prevCameraStatus,
                [element._id]: url !== 'Stream error' ? 'online' : 'offline'
              }));

              if (url !== 'Stream error') {

                clearInterval(interval)
                setCameraLoading(false)
              }
            } else {
              setAlertModal({ show: true, title: 'Error', content: 'Something went wrong' });
              clearInterval(interval);
              setCameraLoading(false);
              setShowToast((prevShowToast) => {
                return { ...prevShowToast, [element._id]: true };
              });
            }
          })
          .catch((error) => {
            if (error?.statusCode === 401) {
              setAlertModal({ show: true, title: 'Error', content: 'Something went wrong' });
              clearInterval(interval);
              setCameraLoading(false);
              setShowToast((prevShowToast) => {
                return { ...prevShowToast, [element._id]: true };
              });
            } else {
              setAlertModal({ show: true, title: 'Error', content: 'Something went wrong' });
              clearInterval(interval);
              setCameraLoading(false);
              setShowToast((prevShowToast) => {
                return { ...prevShowToast, [element._id]: true };
              });
            }
          });
      })

      intervalCount++; // Incrementing the interval count everytime the response is Stream error

      if (intervalCount === maxIntervals) {
        clearInterval(interval); // Stoping the interval after reaching the maximum intervals
        setCameraLoading(false); // stop the Loading for the camera 
        setShowToast((prevShowToast) => {
          return { ...prevShowToast, [element._id]: true };
        }); // Show the toast

      }

      if (!intervalRef.current) {
        clearInterval(intervalRef.current);
        return;
      }
    }, 7000); // Interval: every 7 seconds

    intervalRef.current[element._id] = interval;
  }

  return (
    <PropertyContext.Provider value={{
      property, setProperty,
      cameras, setCameras, CameraUrl, setCameraUrl, cameraLoading, showToast, setShowToast, getStreamUrl, showcanvas, setShowcanvas, leads, setLeads, leadFilters, setLeadFilters, leadsCount, setLeadsCount, clearAllIntervals, cameraStatus
    }}>
      {children}
    </PropertyContext.Provider>
  );
};

const useProperty = () => {
  return useContext(PropertyContext);
};

export { PropertyProvider, useProperty };