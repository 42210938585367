import {
  Modal,
} from '@mantine/core'

import React from 'react'
import PaymentMethod from './PaymentMethod'
import { LiaMoneyCheckSolid } from 'react-icons/lia'
import { VscCreditCard } from 'react-icons/vsc'
import TermsPDF from '../../assests/Saas_agreement.pdf'

const OrderPayment = ({
  paymentMethod,
  setPaymentMethod,
  setOpenPDF,
  openPDF
}) => {
  const paymentMethods = [
    {
      value: 'check',
      name: 'Check',
      description: '',
      icon: <LiaMoneyCheckSolid />,
    },
    {
      value: 'credit card',
      name: 'Credit Card',
      description:
        'You’ll be redirected to Stripe to complete this transaction',
      icon: <VscCreditCard />,
    },    
  ]

  
  return (
    <div>
      <div className='d-flex flex-column'>
        <h4 className='fw-bold pt-2 pb-5'>
          Please, choose a payment method
        </h4>
        <div className='d-flex justify-content-center gap-3'>
          {' '}
          {paymentMethods.map((method, index) => (
            <PaymentMethod
              key={index}
              onChange={(value) => setPaymentMethod(value)}
              method={method}
              selectedMethod={paymentMethod}
            />
          ))}
        </div>
      </div>
      <Modal
        opened={openPDF}
        onClose={() => setOpenPDF(false)}
        title='Terms and Conditions'
        fullScreen
        radius={0}
        transitionProps={{ transition: 'fade', duration: 200 }}
      >
        <iframe
          title='Terms and Conditions'
          src={TermsPDF}
          width='100%'
          height='600px'
        />
      </Modal>
    </div>
  )
}

export default OrderPayment
