import React from 'react';
import DatePicker from 'react-datepicker';

const todayDate = new Date();

const now = {
  time: todayDate.getTime(),
  month: todayDate.getMonth(),
  year: todayDate.getFullYear(),
  day: todayDate.getDay(),
};

const isToday = (date) => {
  return (
    date.getDay() === now.day &&
    date.getMonth() === now.month &&
    date.getFullYear() === now.year
  );
};

export default function Step2({
  onDateChange,
  startDate,
  excludeDates,
  setPageEnable,
  currentStep,
  screenWidth,
  handleStepClickNext,
  settings,
}) {
  const handleOnDateChange = (date) => {
    onDateChange(date);
    screenWidth > 1200 && setPageEnable(3);
  };

  const filterDates = (date) => {
    if (!settings) {
      return false;
    }

    const dayNames = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    const dayOfWeek = date.getDay();
    const dayConfig = settings.bookings.dow[dayNames[dayOfWeek]];

    return dayConfig.available;
  };

  const filterTimes = (date) => {
    if (isToday) {
      if (date.getTime() < now.time) {
        return false;
      }
    }

    if (!settings) {
      return false;
    }

    const dayNames = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    const dayOfWeek = date.getDay();
    const dayConfig = settings.bookings.dow[dayNames[dayOfWeek]];

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const totalMinutes = hours * 60 + minutes;

    if (
      totalMinutes < dayConfig.startTime ||
      totalMinutes > dayConfig.stopTime
    ) {
      return false;
    }
    return true;
  };

  const filterExactDates = (selectedDate, excludeDates) => {
    return excludeDates?.filter((excludeDate) => {
      return (
        excludeDate.getDay() === selectedDate.getDay() &&
        excludeDate.getMonth() === selectedDate.getMonth() &&
        excludeDate.getFullYear() === selectedDate.getFullYear()
      );
    });
  };

  const excludeTimes = (selectedDate) => {
    const excludedDates = excludeDates?.map((unixDate) => new Date(unixDate));
    const filteredDates = filterExactDates(selectedDate, excludedDates);
    return filteredDates;
  };

  return (
    <>
      {screenWidth > 1200 ? (
        <div>
          <span className='step-title'>{'Step 2'}</span>
          <h4>Choose time</h4>
        </div>
      ) : (
        <h5 className='text-capitalize'>Step 2: Pick Your Listing</h5>
      )}
      <div
        className={`${
          currentStep !== 2 && currentStep !== 3 ? 'overlay-div' : ''
        }`}
      >
        <div
          className='steps-container pt-3'
          id='scroll'
        >
          <div className='calendar-wrapper px-3'>
            <div className='date-picker-wrapper'>
              <DatePicker
                selected={startDate}
                onChange={handleOnDateChange}
                inline
                showTimeSelect
                timeFormat='p'
                timeIntervals={15}
                dateFormat='Pp'
                minDate={new Date()}
                excludeTimes={excludeTimes(startDate)}
                filterDate={filterDates}
                // filterTime={filterTimes}
              />
            </div>
          </div>
          {screenWidth < 1200 && (
            <button
              className='btn btn-md btn-block'
              onClick={() => {
                handleStepClickNext(2);
              }}
            >
              Next Step &gt;&gt;
            </button>
          )}
        </div>
      </div>
    </>
  );
}
