import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Center,
  Text,
  Flex,
  Loader,
  Title,
  Stack,
  Select,
  Modal,
} from "@mantine/core";
import { GoDotFill } from "react-icons/go";
import classes from "../../Kits/tabs/Orders.module.css";
import moment from "moment";
import { Auth } from "aws-amplify";
import OrderTable from "../../Kits/components/ProductsTable";
import Sidebar from "../components/AdminSideBar";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import OrderDetails from "../../Kits/components/OrderDetails";
import AssignKitsModal from "./components/AssignKitsModal";
import { useQuery } from "@tanstack/react-query";
import { adminGetOrders } from "../../../api/orders";

const Orders = () => {
  const navigate = useNavigate();
  const stateColors = {
    "waiting for payment information": "var(--mantine-color-blue-filled)",
    "assembling kits": "var(--mantine-color-blue-filled)",
    complete: "var(--mantine-color-green-filled)",
    paid: "var(--mantine-color-blue-filled)",
    cancelled: "var(--mantine-color-red-filled)",
  };

  const orderStates = [
    {
      value: "waiting for payment information",
      label: "Waiting for payment information",
    },
    { value: "assembling kits", label: "Assembling kits" },
    { value: "complete", label: "Complete" },
    { value: "confirmed", label: "Confirmed" },
    { value: "cancelled", label: "Cancelled" },
  ];

  const { data, isLoading, isError } = useQuery({
    queryKey: ["orders"],
    queryFn: () => adminGetOrders(),
  });

  const [opened, { open, close }] = useDisclosure(false);

  const handleAssignKits = (kits) => {
    close();
  };

  const items = data?.map((order, index) => (
    <Accordion.Item key={order._id} value={order._id}>
      <Accordion.Control icon={<GoDotFill color={stateColors[order.state]} />}>
        <Flex justify={"space-around"} align={"center"}>
          <Text>Order {order.code}</Text>
          <Text>
            {order?.user?.firstName} {order?.user?.lastName}
          </Text>
          <Text c={"dimmed"} size="sm">
            {moment(order.createdAt).format("MMMM Do, YYYY")}
          </Text>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel mx={20} onClick={(e) => e.stopPropagation()}>
        <Flex my={10} justify={"space-between"} align={"center"} gap={20}>
          <OrderDetails
            order={{
              user: order.user,
              paymentMethod: order.paymentMethod,
              notes: order.notes,
              details: order.details,
            }}
          />
          <Stack>
            <Button onClick={open} color="black">
              Assign kits
            </Button>
            {/* <LoadingOverlay
                  visible={isLoadingState}
                  zIndex={1000}
                  overlayProps={{ radius: "sm", blur: 2 }}
                /> */}
            <Select
              label="Order status"
              data={orderStates}
              value={order.state}
              onChange={(value) => console.log(value)}
            />
          </Stack>
        </Flex>

        <OrderTable orderId={order._id} isAdmin={true} />
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Sidebar>
      <Flex
        bg={"#eaeaeb"}
        justify={"space-between"}
        align={"center"}
        px={"26px"}
        py={"20px"}
      >
        <Title order={1} fw={900} lh={"45px"}>
          Orders
        </Title>
        <Button
          onClick={() => navigate("/admin/discount/create")}
          color="black"
        >
          Add discount codes
        </Button>
      </Flex>

      {isLoading ? (
        <Center m={50}>
          <Loader color="blue" />
        </Center>
      ) : isError ? <strong>Error getting the orders</strong> : (
        <>
          {!data?.length ? (
            <Center m={50}>
              <Text>There are no orders to display</Text>
            </Center>
          ) : (
            <>
              <Accordion m={30} classNames={classes}>
                {items}
              </Accordion>
              <Modal
                opened={opened}
                onClose={close}
                title={`Assign kits`}
                size={"md"}
              >
                <AssignKitsModal
                  opened={opened}
                  close={close}
                  assignKits={handleAssignKits}
                />
              </Modal>
            </>
          )}
        </>
      )}
    </Sidebar>
  );
};
export default Orders;
