import {
  Col,
  Row,
  Nav
} from 'react-bootstrap';
import styled from 'styled-components';
import { ChevronRight } from 'react-feather';
import Button from './Button';
import { useProperty } from '../context/PropertyContext';
import removeUndefinedWord from '../helper/updateString';
import { useNavigate } from "react-router-dom";

const StyledPageHeader = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  z-index: 100;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #eaeaeb;
  margin-bottom: 0;
  padding: 24px 32px 15px 32px;

  @media (max-width: 400px) {
    padding: 5px 0px 20px 5px;
  }
  @media (max-width: 480px) {
    padding: 5px 26px 20px 26px;
  }

  @media (max-width: 480px) {
    display: none;
  }

  .cta-xs {
    font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 1%;
  text-decoration-line: underline;
  color: #000000;
  }

  .list-item {
    &:before {
    content: "";
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    background-color: #000;
    border-radius: 50%;
    margin-right: 1rem;

}
  }
`;

const PageHeader = (props) => {
  const { property } = useProperty();
  const isEditPage = props?.isEditPage;
  return (
    <StyledPageHeader className="align-items-center">
      <Row className="">
        <Col xxl={8} xl={8} lg={8} md={8}>
          <div className="d-flex align-items-center flex-wrap flex-md-nowrap justify-content-center justify-content-md-start">
            {isEditPage
              ?
              <div className="d-flex align-items-center flex-wrap flex-xl-nowrap justify-content-center justify-content-xl-start">
                <h2>Edit Property</h2>
                <ChevronRight className='mx-2' />
                <div className='d-flex align-items-center'>
                  <span className='body-bold py-1'>
                    {removeUndefinedWord(property?.shortAddress)}{(property?.unit && property?.unit !== '') && `, Unit ${property.unit}`}
                  </span>
                </div>
              </div>
              :
              <h2>Add New Property</h2>
            }
          </div>
        </Col>
        <Col xxl={4} xl={4} lg={4} md={4}>
          <div className="d-flex align-items-center justify-content-center justify-content-md-end pt-2 pt-md-0">
            <Button
              variantType="secondary"
              to={isEditPage ? `/property/${property?._id}` : '/property/list'}
            >
              {isEditPage ? 'Back to Property Details' : 'Back to All Properties'}
            </Button>
          </div>
        </Col>
      </Row>
      <Row className='d-none d-lg-flex'>
        <Col className='pt-3 d-flex align-items-center'>
          <Nav className='d-flex align-items-center justify-content-start justify-content-xl-between w-100'>
            <span className='d-flex align-items-center tags'>Jump to :</span>
           
            <Nav.Item>
              <Nav.Link className='cta-xs list-item' onClick={() => props.refs.section5Ref.current.scrollIntoView({ behavior: "smooth", block: "center" })}>
                Property Management Contact Information
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </StyledPageHeader>
  );
}

export default PageHeader;