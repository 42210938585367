import React from 'react'
import { Text } from '@mantine/core';
import { Card } from 'react-bootstrap';
import ProductsTable from '../ProductsTable';
import { Order } from '../../../../types/orders';

function OrderCard({ order }: { order: Order }) {
  const { _id, paymentMethod, notes, discount } = order

  return (
    <Card className="shadow-lg rounded-4 h-100 w-100 pt-3">
      <h4 className='text-body-secondary fw-bold px-3'>
        Order ID:{" "}
        <span className=" text-secondary fw-bold text-opacity-50">
          {_id}
        </span>
      </h4>

      <h4 >
        <span className=' text-secondary px-3 text-opacity-50'>
          PAYMENT DETAILS
        </span>
      </h4>

      <div className='px-3'>
        <Text size="14px" fw={700} lh={"21px"}>
          Payment method: {paymentMethod}
        </Text>
        <Text size="14px" fw={700} lh={"21px"}>
          {discount && "Discount Code: " + discount.code}
        </Text>
        {notes?.length ? (
          <Text size="14px" fw={700} lh={"21px"}>
            {notes && 'Notes: ' + notes}
          </Text>
        ) : null}
      </div>
      <div className="pt-4">
        <ProductsTable orderId={_id} />
      </div>


    </Card>
  );
}

export default OrderCard