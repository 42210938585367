import { HTMLAttributes } from 'react';
import style from '../style.module.scss';

type Props = {
  disabled?: boolean;
} & HTMLAttributes<HTMLParagraphElement>;

export const SubTitle = ({
  children,
  disabled,
  className,
  ...props
}: Props) => {
  return (
    <p
      className={`${disabled && style.disabled} ${style.subtitle} ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};
