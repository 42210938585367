import React, { useMemo } from "react";
import propertyDummyImage from "../../../assests/images/6-thumb.png";
import locationIcon from "../../../assests/images/location.svg";
import calendarIcon from "../../../assests/images/calendar.svg";
import clockIcon from "../../../assests/images/clock.svg";
import lockIcon from "../../../assests/images/lock-ico-fill.svg";
import unlockedIcon from "../../../assests/images/unlock-ico-fill.svg";
import infoIcon from "../../../assests/images/info.svg";

function InstructionsCard({
  property,
  handleLock,
  locked,
  startTime,
  isButtonDisabled,
  loading,
  timeRemaining,
  passCode
}) {
  const { propertyAccessCodes, doorUnlockLink, rentalApplicationForm } =
    property;

  const startDate = useMemo(() => new Date(startTime * 1000), [startTime]);

  return (
    <div className="p-3 rounded shadow-lg d-flex flex-column gap-2 max-w-650">
      <h4 className="text-delet-blue fs-5 pb-2">
        Everything is ready for your tour
      </h4>
      <div className="d-flex gap-2 flex-column">
        <img
          src={
            property.images?.[0] || property.primaryImage || propertyDummyImage
          }
          alt="property"
          className="w-100 rounded"
        />
        <div className="d-flex flex-column col-8 justify-content-between py-2 w-100">
          <div className="d-flex gap-2 align-items-center">
            <img src={locationIcon} alt="location-icon" />
            <strong className=" text-wrap">{property?.address}</strong>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <img src={calendarIcon} alt="calendar-icon" />
            <strong>{`${startDate.getDate()} ${startDate.toLocaleString(
              "default",
              {
                month: "long",
              }
            )}, ${startDate.getFullYear()}`}</strong>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <img src={clockIcon} alt="clock-icon" />
            <strong>
              {startDate.toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </strong>
          </div>
        </div>
      </div>

      {passCode && (
        <span className="text-uppercase bg-black text-white fw-bold ps-2 py-1 rounded">passCode: {passCode}</span>
      )}

      {propertyAccessCodes?.enable && (
        <div className="d-flex flex-column">
          <h6 className="mb-2">
            <strong>Access Instructions</strong>
          </h6>
          {propertyAccessCodes.instructions && (
            <p>
              <strong>Instructions: </strong>
              {propertyAccessCodes.instructions}
            </p>
          )}
          {propertyAccessCodes.buildingCode && (
            <strong>Building access: {propertyAccessCodes.buildingCode}</strong>
          )}
          {propertyAccessCodes.elevatorCode && (
            <strong>Elevator access: {propertyAccessCodes.elevatorCode}</strong>
          )}
        </div>
      )}

      {rentalApplicationForm?.enable && (
        <div className="d-flex flex-column">
          <h6 className="mb-2">
            <strong>Apply for this property</strong>
          </h6>
          {rentalApplicationForm.url && (
            <strong>
              URL:{" "}
              <a
                href={rentalApplicationForm.url}
                target="_blank"
                rel="noreferrer"
              >
                Open form
              </a>
            </strong>
          )}
          {rentalApplicationForm.fee && (
            <strong>Fee: {rentalApplicationForm.fee}</strong>
          )}
          {rentalApplicationForm.instructions && (
            <p>
              <strong>Instructions: </strong>
              {rentalApplicationForm.instructions}
            </p>
          )}
        </div>
      )}

      {(doorUnlockLink && timeRemaining !== "The lock/unlock button is no longer available") && (
        <button
          className="btn bg-delet border-0 d-flex align-items-center gap-3 justify-content-center py-3 text-white rounded text-wrap"
          onClick={handleLock}
          disabled={isButtonDisabled || loading}
        >
          {loading ? (
            <strong>Loading... </strong>
          ) : (
            <>
              <img src={locked ? lockIcon : unlockedIcon} alt="lock" />
              {isButtonDisabled ? (
                <strong>
                  Button disabled for the next 2 min, door is{" "}
                  {locked ? "locked" : "unlocked"}
                </strong>
              ) : (
                <strong>
                  Door is {locked ? "locked" : "unlocked"}, click to{" "}
                  {locked ? "unlock" : "lock"}
                </strong>
              )}
            </>
          )}
        </button>
      )}

      <p className="d-flex gap-2 align-items-center text-body-secondary">
        <img src={infoIcon} alt="info" />
        If you're having difficulty entering the unit, you can reach us at
        310-926-7313
      </p>
    </div>
  );
}

export default InstructionsCard;
