import { useSyncExternalStore } from 'react';

export const useWindowValue = (value: keyof Window, event: string) => {
  function getSnapShot() {
    return window[value];
  }
  function subscribe(callback: any) {
    window.addEventListener(event, callback);
    return () => {
      window.removeEventListener(event, callback);
    };
  }
  return useSyncExternalStore(subscribe, getSnapShot);
};
