import React, { useState } from "react";
import { Center, Text, Loader } from "@mantine/core";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { getOrders } from "../../../api/orders";
import OrderCard from "../components/orders/OrderCard";
import UnstyledButton from "../../../components/UnstyledButton";
import StatusText from "../components/StatusText";
import { orderStatusDic } from "../../../helper/kits";

const Orders = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["orders"],
    queryFn: () => getOrders(),
  });

  const rows = data?.map((order) => (
    <UnstyledButton
      className="d-flex border-bottom px-2 py-3"
      key={order?._id}
      onClick={() => setSelectedOrder(order)}
    >
      <span className="text-truncate col-4 pe-3">{order?._id}</span>
      <span className="col-4 text-start">
        {format(order?.createdAt, "do LLL, yyyy")}
      </span>
      <StatusText status={orderStatusDic[order.state]} />
    </UnstyledButton>
  ));

  return isLoading ? (
    <Center m={50}>
      <Loader color="blue" />
    </Center>
  ) : isError ? (
    <p>Error getting orders, please try again</p>
  ) : (
    <section className="d-flex flex-grow-1">
      <div className="col-5 d-flex flex-column gap-1 pt-4">
        <div className="d-flex px-2 border-bottom py-1">
          <strong className="col-4">ID</strong>
          <strong className="col-4">Date</strong>
          <strong className="col-4">Status</strong>
        </div>

        <div className="d-flex flex-column orders-list-container">
          {!data?.length ? (
            <Center m={50}>
              <Text>You haven't made any orders yet</Text>
            </Center>
          ) : [1, 2, 3, 4, 5].map(() => (
            rows
          ))}
        </div>
      </div>
      
      <div className="bg-secondary p-4 bg-opacity-10 col-7">
        {selectedOrder ? <OrderCard order={selectedOrder} /> : null}
      </div>
    </section>
  );
};
export default Orders;
