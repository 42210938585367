import { Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";

const ShipmentForm = ({
  shipment,
  handleKitSelect,
  handleInputChange,
  users,
  handleUserChange,
  kits,
  handleAddressChange,
}) => {
  return (
    <Row>
      <Col xxl={12} xl={12} lg={12}>
        <Card>
          <Card.Header as="h5">Shipment Details</Card.Header>
          <Card.Body>
            <Form.Group className="mb-20" controlId="code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Tracking code</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="code"
                    type="text"
                    value={shipment.code}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    required
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Shipping Company</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="company"
                    type="text"
                    value={shipment.company}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    required
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Tracking url</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="trackingUrl"
                    type="text"
                    value={shipment.trackingUrl}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    required
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Notes</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Control
                    name="notes"
                    type="text"
                    value={shipment.notes}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-20" controlId="Email">
              <Row className="align-items-center">
                <Col lg={2}>
                  <Form.Label>Allocate User</Form.Label>
                </Col>
                <Col lg={10}>
                  <Form.Select
                    name="user"
                    onChange={(e) => handleUserChange(e.target.value)}
                    required
                  >
                    <option selected={true} disabled="disabled">
                      Select user
                    </option>
                    {users?.map((user, index) => (
                      <>
                        <option id={user._id} value={index}>
                          {user.firstName} {user.lastName}| {user.company?.name}
                        </option>
                      </>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>

            {shipment.user && (
              <Form.Group className="mb-20" controlId="Buzzer-code">
                <Row className="align-items-center">
                  <Col lg={2}>
                    <Form.Label>Kits Ready to ship</Form.Label>
                  </Col>

                  <Col lg={10}>
                    <Select
                      options={kits}
                      onChange={(kits) => handleKitSelect(kits)}
                      getOptionValue={(kit) => `${kit.name} ${kit.state}`}
                      getOptionLabel={(kit) => `${kit.name} ${kit.state}`}
                      value={shipment.kits}
                      isMulti
                      required
                    ></Select>
                  </Col>
                </Row>
              </Form.Group>
            )}

            <Form.Group className="mb-20" controlId="Lockbox-code">
              <div className="d-flex justify-content-start gap-4 align-content-center mb-2">
                <Form.Label>Address</Form.Label>

                {shipment.kits?.length > 0 && (
                    <Select
                    className="w-100"
                      options={shipment.kits.filter(
                        (kit) => kit.shippingAddress
                      )}
                      onChange={(kit) =>
                        handleInputChange("deliveryAddress", kit.shippingAddress)
                      }
                      getOptionLabel={(kit) =>
                        kit.shippingAddress &&
                        `${kit.name} ${kit.shippingAddress.state} ${kit.shippingAddress.city} ${kit.shippingAddress.street} ${kit.shippingAddress.zipCode}`
                      }
                      value={`${shipment.deliveryAddress.state} ${shipment.deliveryAddress.city} ${shipment.deliveryAddress.street} ${shipment.deliveryAddress.zipCode}`}
                      placeholder="Select from kits"
                    ></Select>
                )}
              </div>
              <Row className="align-items-center gap-2">
                <Col lg={12}>
                  <Form.Control
                    name="street"
                    type="text"
                    placeholder="Street"
                    value={shipment.deliveryAddress.street}
                    onChange={(e) =>
                      handleAddressChange(e.target.name, e.target.value)
                    }
                    required
                  />
                </Col>

                <Col lg={12}>
                  <Form.Control
                    name="city"
                    type="text"
                    placeholder="City"
                    value={shipment.deliveryAddress.city}
                    onChange={(e) =>
                      handleAddressChange(e.target.name, e.target.value)
                    }
                    required
                  />
                </Col>

                <Col lg={12}>
                  <Form.Control
                    name="state"
                    type="text"
                    placeholder="State"
                    value={shipment.deliveryAddress.state}
                    onChange={(e) =>
                      handleAddressChange(e.target.name, e.target.value)
                    }
                    required
                  />
                </Col>

                <Col lg={12}>
                  <Form.Control
                    name="zipCode"
                    type="text"
                    placeholder="Zip code"
                    value={shipment.deliveryAddress.zipCode}
                    onChange={(e) =>
                      handleAddressChange(e.target.name, e.target.value)
                    }
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ShipmentForm;
