import { HTMLAttributes } from 'react';
import style from '../style.module.scss';

type Props = {
  disabled?: boolean;
} & HTMLAttributes<HTMLParagraphElement>;

export const Title = ({ disabled, className, children, ...props }: Props) => {
  return (
    <p
      className={`${disabled && style.disabled} ${style.title} ${className}`}
      {...props}
    >
      {children}
    </p>
  );
};
