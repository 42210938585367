import Pagination from 'react-bootstrap/Pagination';

const PagePagination = ({totalPages,actualPage, handlePageChange}) => {
    const prevAndFirstDisabled=actualPage == 1? true:false
    const nextAndLastDisabled=actualPage == totalPages? true:false
    return (
        <Pagination >
            <Pagination.First onClick={()=>handlePageChange(1)} disabled={prevAndFirstDisabled}/>
            <Pagination.Prev onClick={()=>handlePageChange(actualPage -1)} disabled={prevAndFirstDisabled}/>
            <Pagination.Item>{actualPage}</Pagination.Item>
            <Pagination.Next onClick={()=>handlePageChange(actualPage +1)} disabled={nextAndLastDisabled}/>
            <Pagination.Last onClick={()=>handlePageChange(totalPages)} disabled={nextAndLastDisabled}/>
        </Pagination>
    );
}

export default PagePagination;
