import React, { createContext, useContext, useState } from 'react';
import { PubSub, Hub } from 'aws-amplify';

const PubSubContext = createContext();

const PubSubProvider = ({ children }) => {
  const [connection, setConnection] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  Hub.listen('pubsub', ({ payload }) => {
    if (payload.event === 'ConnectionStateChange') {
      if (payload.data.connectionState === 'Connected') {
        setConnection(true);
      } else {
        setConnection(false);
      }
    }
  });

  const subscribeToPubSub = (topics) => {
    PubSub.subscribe(topics).subscribe({
      next: (data) => {
        const topic = data.value[Object.getOwnPropertySymbols(data.value)[0]]

        const topicParts = topic.split('/');

        const thingType = topicParts[0];
        const device = topicParts[1];
        const action = topicParts[2];

        if (thingType === 'devices') {
          const deviceParts = device.split('-');

          const deviceType = deviceParts[0];
          const deviceId = deviceParts[1];

          if (deviceType === 'devicehub') {
            if (action === 'test') {
              setSubscriptions((prevSubscriptions) => ({
                ...prevSubscriptions,
                [deviceId]: data.value,
              }));
            }
          }
        }
      },
      error: (error) => {
        console.error('PubSub Error:', error);
      },
      complete: () => {
        console.log('PubSub Complete');
      },
    });
  };

  return (
    <PubSubContext.Provider value={{ subscribeToPubSub, subscriptions, connection }}>
      {children}
    </PubSubContext.Provider>
  );
};

const usePubSub = () => {
  return useContext(PubSubContext);
};

export { PubSubProvider, usePubSub };