import React from "react";
import styled from "styled-components";

import { Row, Col } from "react-bootstrap";

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const SwitchLabel = styled.label`
  display: flex;
  width: 60px;
  height: 32px;
  padding: 3px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  background: #EAEAEB;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s;

  /* Active Styles */
  ${SwitchInput}:checked + & {
    background: #5D5FEF;
    justify-content: flex-end;
  }

  /* Disabled Styles */
  ${SwitchInput}:disabled + & {
    opacity: 0.6;
  }
`;

const SwitchButton = styled.span`
  content: "";
  position: absolute;
  left: 2px;
  margin: 1px;
  width: 24px;
  height: 24px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  /* Active Styles */
  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 4px);
    transform: translateX(-100%);
    
  }

  /* Active Clicked Styles */
  ${SwitchLabel}:active & {
    width: 45px;
  }
`;

const Label = styled.label({
  fontWeight: "bold",
});

const InputSwitchComponent = ({
  id,
  label,
  onChange,
  checked,
  disabled
}) => {
  return (
    <>
      <Label>{label}</Label>
      <SwitchInput
        className="switch-checkbox"
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <SwitchLabel className="switch-label" htmlFor={id}>
        <SwitchButton className="switch-button" />
      </SwitchLabel>
    </>
  );
};

export default InputSwitchComponent;