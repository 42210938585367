import { Box, Text } from "@mantine/core";
import React from "react";

function InfoCard({title, info, signBeforeNumber}) {
  return (
    <Box c="#fff" p={20} w={200} style={{ background: 'linear-gradient(to right, rgba(102, 126, 234, 0.5), rgba(118, 75, 162, 0.5))' }}>
      <Text fw={700}>{title}</Text>
      <Text fw={700}>{signBeforeNumber} {info}</Text>
    </Box>
  );
}

export default InfoCard;
