import React, { useEffect, useMemo, useRef, useState } from 'react';
import CountdownTimer from './CountdownTimer';
import InstructionsCard from './InstructionsCard';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getPublicLockStatus, updatePublicLockStatus } from '../../../api/lock';

function ShowInstructionsCard({ booking, token }) {
  const queryClient = useQueryClient();

  const { property, startTime, passCode } = booking;
  const [timeRemaining, setTimeRemaining] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const intervalIdRef = useRef(null);

  const start = useMemo(() => new Date((startTime * 1000) - 900000), [startTime]);
  const end = useMemo(() => new Date((startTime * 1000) + 900000), [startTime]);

  const {
    data: locked,
    isLoading: loadingStatus,
    isError,
  } = useQuery({
    queryKey: ['public', 'lock', booking._id],
    queryFn: () => getPublicLockStatus(token, property.kit),
  });

  const { mutateAsync: updateLockStatus, isPending: updatingStatus } =
    useMutation({
      mutationFn: () =>
        updatePublicLockStatus(token, property.kit, locked ? 'unlock' : 'lock'),
      onSuccess: (data) => {
        queryClient.setQueryData(['public', 'lock', booking._id], () => data);
      },
    });

  const handleLock = async () => {
    await updateLockStatus();

    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000 * 120);
  };

  const calculateTimeRemaining = () => {
    const now = new Date();
    const diff = end - now;

    if (diff <= 0) {
      setTimeRemaining('The lock/unlock button is no longer available');
      setIsButtonDisabled(true);
      stopTimer();
      return;
    }

    const minutes = Math.floor(diff / 1000 / 60);
    const seconds = Math.floor((diff / 1000) % 60);
    setTimeRemaining(
      `The button will be unavailable in ${String(minutes).padStart(2, '0')}:${String(
        seconds
      ).padStart(2, '0')}`
    );
  };

  const startTimer = () => {
    const now = new Date();
    const activePeriod = {
      start: new Date((startTime * 1000) - 7200000),
      end: new Date((startTime * 1000) + 7200000),
    };
    let intervalId;

    if (now >= activePeriod.start && now < activePeriod.end) {
      if (now >= start) {
        calculateTimeRemaining();
        intervalId = setInterval(calculateTimeRemaining, 1000);
      } else {
        setTimeRemaining(
          `The unlock button will be active at ${start.toLocaleTimeString(
            undefined,
            {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            }
          )}`
        );
      }
      setIsActive(true);
      return;
    }

    if (now > activePeriod.end) {
      setTimeRemaining('The link expired');
      setIsActive(false);
      return;
    }

    return () => clearInterval(intervalId);
  };

  const stopTimer = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const handleReload = () => {
    stopTimer();
    startTimer();
  };

  useEffect(() => {
    startTimer();
    return () => stopTimer();
  }, [startTime]);

  return (
    <CountdownTimer
      timeRemaining={timeRemaining}
      isActive={isActive}
      reload={handleReload}
      showButton={property.doorUnlockLink}
    >
      {isActive && (
        <InstructionsCard
          property={property}
          start={start}
          locked={locked}
          handleLock={handleLock}
          end={end}
          startTime={startTime}
          isButtonDisabled={isButtonDisabled}
          loading={updatingStatus || loadingStatus}
          timeRemaining={timeRemaining}
          passCode={passCode}
        />
      )}
    </CountdownTimer>
  );
}

export default ShowInstructionsCard;
