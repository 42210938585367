import "../Leads.scss";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Container } from "react-bootstrap";
import { CiFilter } from "react-icons/ci";
import { DateRangeSelect } from "../../../components/Selects/DateRangeSelect";
import { MultiselectSearch } from "../../../components/Selects/MultiselectSearch";
import { Auth } from "aws-amplify";
import axios from "axios";

export const FiltersModal = ({
  show,
  handleClose,
  startDate,
  endDate,
  handleDateRangeChange,
  selectedProperties,
  handleSelectedProperties,
  handleApplyFilters,
}) => {
  const [data, setData] = useState([])

  const getProperties = () =>{
    Auth.currentSession().then((token) => {
      let url = `${process.env.REACT_APP_NODE_API_URL}/api/property`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
           setData(response.data)
          } 
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            Auth.signOut()
              .then(() => {
              })
              .catch((error) => {
                console.log("error signing out: ", error);
              });
          } else {
          }
        });
    });
  }

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="modal-dialog"
    >
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center modal-title p-1 ">
          <CiFilter size="22px" /> <span>Filter</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Form.Label htmlFor="dateRangeSelect">Date</Form.Label>
              <DateRangeSelect
                startDate={startDate}
                endDate={endDate}
                handleDateRangeChange={handleDateRangeChange}
              />
            </Col>
            <Col>
              <Form.Label htmlFor="propertySelect">Property</Form.Label>
              <MultiselectSearch
                data={data?.properties}
                selected={selectedProperties}
                handleSelectedProperties={handleSelectedProperties}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleApplyFilters}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
