import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import PublicLayout from "../../layouts/public";
import cancelledIcon from "../../assests/images/cancelled.svg";

function CancelBook() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [loading, setLoading] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [rescheduleToken, setRescheduleToken] = useState(null)
  const [error, setError] = useState(null);

  const handleCancel = async (e) => {
    e.preventDefault();
    if (!token) {
      setError("No valid token found. Cannot proceed with cancellation.");
      return;
    }
    setLoading(true);
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_NODE_API_URL}/api/booking/public/cancel?token=${token}`
      );
      setCancelled(true);
      setRescheduleToken(res.data.rescheduleToken)
    } catch (error) {
      console.log("ERROR CANCELLING BOOKING: ", error);
      setError("An error occurred while cancelling the booking. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      setError("No valid token found. Cannot proceed with cancellation.");
    }
  }, [token]);

  return (
    <PublicLayout>
      <div className="flex-grow-1 d-flex flex-column gap-4 justify-content-center align-items-center text-center px-3">
        {error ? (
          <>
            <h4 className="text-center fs-4">Error</h4>
            <p>{error}</p>
            <Link
              to="/property/list"
              className="text-decoration-none bg-primary rounded-3 text-white fw-bold px-3 py-2"
            >
              Return to Home
            </Link>
          </>
        ) : loading ? (
          <strong>Cancelling booking...</strong>
        ) : cancelled ? (
          <>
            <img src={cancelledIcon} alt="cancelled" />
            <h4 className="text-center fs-1">Booking Cancelled</h4>
            <p className="fw-semibold">
              Your booking has been cancelled. Any questions please contact us.
            </p>
            <Link
              to={`/book/reschedule?token=${rescheduleToken}`}
              className="text-decoration-none bg-delet rounded-3 text-white fw-bold px-3 py-2"
            >
              Reschedule
            </Link>
          </>
        ) : (
          <form
            onSubmit={handleCancel}
            className="d-flex flex-column gap-5 align-items-center px-3"
          >
            <h4 className="text-center fs-4">
              Click to confirm the cancellation of your booking
            </h4>
            <button
              type="submit"
              className="btn btn-danger fw-bold fs-2 p-3"
              disabled={loading || cancelled}
            >
              Confirm cancel
            </button>
          </form>
        )}
      </div>
    </PublicLayout>
  );
}

export default CancelBook;
