import { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

import Sidebar from "../components/AdminSideBar";
import InvoiceForm from "../../../components/invoice/Form";

const initialState = {
  items: [],
};

const CreateInvoice = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const [subsAndOrders, setSubsAndOrders] = useState({
    subscription: null,
    orders: []
  });
  const [invoice, setInvoice] = useState(initialState);
  const [validated, setValidated] = useState(false);
  const [items, setItems] = useState([])

  const handleUserChange = (index) => {
    index = parseInt(index);
    setInvoice((prev) => {
      const updatedInvoice = { ...prev };
      updatedInvoice.user = users[index]._id;
      return updatedInvoice;
    });
  };

  const handleInputChange = (field, newValue) => {
    setInvoice((prev) => {
      const updatedInvoice = { ...prev };
      updatedInvoice[field] = newValue;
      return updatedInvoice;
    });
  };

  const handleKitSelect = (kits) => {
    setInvoice((prev) => {
      const updatedInvoice = { ...prev };
      updatedInvoice.kits = kits;
      return updatedInvoice;
    });
  };

  const onSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
      const postShipments = async () => {
        const data = { ...invoice };
        data.kits = data.kits.map((kit) => kit._id);
        try {
          var url = process.env.REACT_APP_NODE_API_URL + "/api/shipments";
          const token = await Auth.currentSession();
          const response = await axios.post(url, data, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
              "Content-Type": "application/json",
            },
          });

          if (response.status === 201) {
            setError("");
            setShow(true);
            setInvoice(initialState);
            setValidated(true);
            navigate("/admin/invoice/list");
          }
        } catch (error) {
          setError("Error Creating Shipment");
          setShow(true);
          return null;
        }
      };
      postShipments();
    }
    e.preventDefault();
    e.stopPropagation();

    setValidated(true);
    setError("Missing some required fields");
    setShow(true);
  };

  useEffect(() => {
    async function getUsersList() {
      try {
        const url =
          process.env.REACT_APP_NODE_API_URL + "/api/users/admin/list";
        const token = await Auth.currentSession();
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setUsers(response.data);
            } else {
              setError("Error Getting Users");
              setShow(true);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              Auth.signOut();
              navigate("/admin/login");
            } else {
              setError("Error Getting Users");
              setShow(true);
            }
          });
      } catch (eror) {
        console.log(error);
        navigate("/");
      }
    }

    getUsersList();
  }, [error, navigate]);

  useEffect(() => {
    async function getUserSubsAndOrders() {
      try {
        const url = `${process.env.REACT_APP_NODE_API_URL}/api/users/${invoice.user}/orders-and-subs`;
        const token = await Auth.currentSession();
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          })
          .then((response) => {
            console.log("RESPONSE: ", response.data)
            setSubsAndOrders(response.data)
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              Auth.signOut();
              navigate("/admin/login");
            } else {
              setError("Error Getting Kit");
            }
          });
      } catch (error) {
        console.log(error);
        navigate("/");
      }
    }
    setSubsAndOrders({
      subscription: null,
      orders: []
    })
    invoice.user && getUserSubsAndOrders();
  }, [navigate, invoice.user]);

  useEffect(() => {
    async function getServiceItems() {
      try {
        const url = `${process.env.REACT_APP_NODE_API_URL}/api/${invoice.service.type}/${invoice.service.id}/items`;
        const token = await Auth.currentSession();
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
            },
          })
          .then((response) => {
            setItems(response.data)
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              Auth.signOut();
              navigate("/admin/login");
            } else {
              setError("Error Getting Kit");
            }
          });
      } catch (error) {
        console.log(error);
        navigate("/");
      }
    }
    setItems([])
    invoice.service && getServiceItems();
  }, [navigate, invoice.service]);

  return (
    <>
      <Sidebar>
        <div className="page-main-head d-flex justify-content-between">
          <h1 className="h4">Create Invoice</h1>
        </div>
        <Form
          method="post"
          onSubmit={onSubmit}
          noValidate
          validated={validated}
          className="p-4"
        >
          <InvoiceForm
            invoice={invoice}
            handleKitSelect={handleKitSelect}
            kits={subsAndOrders}
            handleInputChange={handleInputChange}
            handleUserChange={handleUserChange}
            subsAndOrders={subsAndOrders}
            users={users}
            subscription={subsAndOrders.subscription}
            orders={subsAndOrders.orders}
            items={items}
          />
          <div className="d-flex gap-5 justify-content-center">
            <Button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
            >
              Create invoice
            </Button>
          </div>
        </Form>
      </Sidebar>
      {show ? (
        <Modal show={show}>
          {error !== "" ? (
            <>
              <Modal.Header>
                <Modal.Title>Alert</Modal.Title>
                <button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShow(false)}
                ></button>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title>Saved</Modal.Title>
              </Modal.Header>

              <Modal.Body>Shipment created successfully </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default CreateInvoice;
