import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { deletePassCodes, getCodeHistory } from "../../../../api/keypad";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { TfiReload } from "react-icons/tfi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { IoIosAlert } from "react-icons/io";
import CodeRow from "./CodeRow";
import Loading from "../../../../components/Banners/Loading";
import CodeRowSkeleton from "./CodeRowSkeleton";

const sortByInitState = {
  name: "",
  type: "asc",
};

const CodeHistory = ({ keypadId, handleCreateCode }) => {
  const queryClient = useQueryClient();
  const [filters, setFilters] = useState({
    status: [],
    type: [],
    text: "",
  });

  const [sortBy, setSortBy] = useState(sortByInitState);

  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const MySwal = withReactContent(Swal);

  const {
    data: codesList = [],
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery({
    queryKey: ["keypad", keypadId, "code-history"],
    queryFn: async () => await getCodeHistory({keypadId, filter: 'booking_codes'}),
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: deleteCodes, isPending: deletingCodes } = useMutation({
    mutationFn: () => deletePassCodes(selected, keypadId),
    onSuccess: () => {
      queryClient.setQueryData(["keypad", keypadId, "code-history"], (prev) =>
        prev.filter(
          (code) =>
            !selected.some((selectedCode) => selectedCode.id === code.id)
        )
      );
      setSelected([]);
      toast.success("Selected codes have been removed successfully");
    },

    onError: (err) => {
      toast.error("Something went wrong. Please, try again");
      console.log("Error deleting codes: ", err);
    },
  });

  if (error) {
    toast.error("Error getting the code history");
    return (
      <div>
        An error occurred trying to get your code history{" "}
        <Button onClick={refetch} disabled={deletingCodes}>
          retry
        </Button>
      </div>
    );
  }

  const handleFilter = (name, value, type) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      if (type) {
        newFilters[name].push(value);
        return newFilters;
      } else {
        const index = newFilters[name].indexOf(value);
        newFilters[name].splice(index, 1);
        return newFilters;
      }
    });
  };

  const filteredCodes = codesList.filter((code) => {
    const { status, type, text } = filters;
    const { status: codeStatus, type: codeType } = code;
    if (
      !code.name.includes(text) &&
      !code.password.includes(text) &&
      !format(
        new Date(code.startTime ?? code.createTime),
        "MM/dd/yyyy"
      ).includes(text) &&
      (code.endTime
        ? !format(new Date(code.endTime), "MM/dd/yyyy").includes(text)
        : true)
    )
      return false;
    if (status.length > 0 && !status.includes(codeStatus)) return false;

    if (type.length > 0 && !type.includes(codeType)) return false;

    return true;
  });

  const sortedCodes = sortBy.name
    ? filteredCodes.sort((a, b) => {
        const { name, type } = sortBy;
        const dateA =
          name === "start"
            ? a.startTime ?? a.createTime
            : a.endTime || Infinity;
        const dateB =
          name === "start"
            ? b.startTime ?? b.createTime
            : b.endTime || Infinity;

        if (type === "asc") {
          return dateA - dateB;
        } else if (type === "desc") {
          return dateB - dateA;
        }
        return 0;
      })
    : filteredCodes;

  const handleSelect = (value, checked) => {
    if (checked) {
      setSelected([...selected, value]);
    } else {
      setSelected((prev) => {
        const index = selected.findIndex((code) => code.id === value.id);
        const newSelected = [...prev];
        newSelected.splice(index, 1);
        return newSelected;
      });
    }
  };

  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      setSelected(codesList);
    } else {
      setSelected([]);
    }
  };

  const dailyCode = codesList.find(
    (code) => code.name === new Date().toISOString().split("T")[0]
  );

  return (
    <section className="rounded d-flex flex-column px-4">
      <div className="d-flex align-items-center pt-2 pb-4 gap-3">
        <div class="input-group">
          <input
            type="text"
            class="form-control py-1"
            placeholder="Search by name or code"
            value={filters.text}
            onChange={(e) => setFilters({ ...filters, text: e.target.value })}
          />
        </div>

        <Button
          className="px-4 btn-outline-dark"
          onClick={refetch}
          disabled={isLoading || isFetching}
        >
          <TfiReload />
        </Button>
        <Button
          className="px-4 btn-outline-dark"
          variant="light"
          onClick={handleCreateCode}
          disabled={deletingCodes}
        >
          Create new code
        </Button>
        <Button
          className="px-4 btn-outline-dark"
          disabled={selected.length === 0 || deletingCodes}
          onClick={() =>
            MySwal.fire({
              title: "<h4 class='fs-5'>You're About to Remove This Code</h4>",
              html: "<p class='text-body-tertiary fs-6'>Are you sure you want to proceed?<br>Please note that this action cannot be undone.</p>",
              iconHtml: <IoIosAlert color="#5271ff" />,
              showCancelButton: true,
              cancelButtonText: "Close",
              confirmButtonText: "Remove",
              confirmButtonColor: "#5271ff",
              reverseButtons: true,
              customClass: {
                icon: "border-0 my-0 py-2",
                popup: "px-5 pt-2",
                title: "pt-0",
                htmlContainer: "pt-2",
                actions: "gap-3 w-100 flex-nowrap",
                confirmButton: "w-100 py-2 rounded-3",
                cancelButton:
                  "w-100 bg-white text-black border border-secondary-subtle rounded-3",
              },
            }).then((result) => {
              if (result.isConfirmed) deleteCodes();
            })
          }
        >
          Delete
        </Button>
      </div>

      <div className="code-history-list d-flex flex-column gap-3">
        <div className="d-flex gap-3 border-bottom py-2">
          <span className="w-content p-0 d-flex align-items-center">
            <input
              type="checkbox"
              value={selectAll}
              onChange={(e) => handleSelectAll(e.target.checked)}
              checked={selected.length === codesList.length}
            />
          </span>
          <strong className="col">Name</strong>
          <strong className="col">Code</strong>

          <Dropdown className="col">
            <Dropdown.Toggle
              className="bg-white border-0 text-dark fw-bold fs-6 p-0"
              id="status-dropdown"
            >
              Status
            </Dropdown.Toggle>

            <Dropdown.Menu className="ps-2">
              <div className="d-flex gap-2">
                <input
                  type="checkbox"
                  value="normal"
                  id="active"
                  onChange={(e) =>
                    handleFilter("status", e.target.value, e.target.checked)
                  }
                />
                <label htmlFor="active">Active</label>
              </div>

              <div className="d-flex gap-2">
                <input
                  type="checkbox"
                  value="expired"
                  id="expired"
                  onChange={(e) =>
                    handleFilter("status", e.target.value, e.target.checked)
                  }
                />
                <label className="form-check-label" htmlFor="expired">
                  Expired
                </label>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="col">
            <Dropdown.Toggle
              className="bg-white border-0 text-dark fw-bold fs-6 p-0"
              id="status-dropdown"
            >
              Type
            </Dropdown.Toggle>

            <Dropdown.Menu className="ps-2">
              <div className="d-flex gap-2">
                <input
                  type="checkbox"
                  value="timeLimit"
                  id="temporary"
                  onChange={(e) =>
                    handleFilter("type", e.target.value, e.target.checked)
                  }
                />
                <label htmlFor="temporary">Temporary</label>
              </div>

              <div className="d-flex gap-2">
                <input
                  type="checkbox"
                  value="permanent"
                  id="permanent"
                  onChange={(e) =>
                    handleFilter("type", e.target.value, e.target.checked)
                  }
                />
                <label className="form-check-label" htmlFor="permanent">
                  Permanent
                </label>
              </div>

              <div className="d-flex gap-2">
                <input
                  type="checkbox"
                  value="urgent"
                  id="urgent"
                  onChange={(e) =>
                    handleFilter("type", e.target.value, e.target.checked)
                  }
                />
                <label htmlFor="urgent">Emergency</label>
              </div>

              <div className="d-flex gap-2">
                <input
                  type="checkbox"
                  value="disposable"
                  id="disposable"
                  onChange={(e) =>
                    handleFilter("type", e.target.value, e.target.checked)
                  }
                />
                <label htmlFor="disposable">One-time</label>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="col">
            <Dropdown.Toggle
              className="bg-white border-0 text-dark fw-bold fs-6 p-0"
              id="status-dropdown"
              onDoubleClick={() => setSortBy(sortByInitState)}
            >
              Start
            </Dropdown.Toggle>

            <Dropdown.Menu className="ps-2">
              <div className="d-flex gap-2">
                <input
                  type="radio"
                  id="startLatest"
                  onChange={() => setSortBy({ name: "start", type: "desc" })}
                  checked={sortBy.name === "start" && sortBy.type === "desc"}
                />
                <label htmlFor="startLatest">Latest first</label>
              </div>

              <div className="d-flex gap-2">
                <input
                  type="radio"
                  id="startRecent"
                  onChange={() => setSortBy({ name: "start", type: "asc" })}
                  checked={sortBy.name === "start" && sortBy.type === "asc"}
                />
                <label htmlFor="startRecent">Earliest first</label>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="col">
            <Dropdown.Toggle
              className="bg-white border-0 text-dark fw-bold fs-6 p-0"
              id="status-dropdown"
              onDoubleClick={() => setSortBy(sortByInitState)}
            >
              End
            </Dropdown.Toggle>

            <Dropdown.Menu className="ps-2">
              <div className="d-flex gap-2">
                <input
                  type="radio"
                  id="endLatest"
                  onChange={() => setSortBy({ name: "end", type: "desc" })}
                  checked={sortBy.name === "end" && sortBy.type === "desc"}
                />
                <label htmlFor="endLatest">Latest first</label>
              </div>

              <div className="d-flex gap-2">
                <input
                  type="radio"
                  id="endRecent"
                  onChange={() => setSortBy({ name: "end", type: "asc" })}
                  checked={sortBy.name === "end" && sortBy.type === "asc"}
                />
                <label htmlFor="endRecent">Earliest first</label>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {isLoading ? (
          <div className="d-flex flex-column gap-4">
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
            <CodeRowSkeleton />
          </div>
        ) : (
          <>
            {dailyCode && (
              <CodeRow
                code={dailyCode}
                handleSelect={handleSelect}
                selected={selected}
                disabled={deletingCodes}
                dailyCode
              />
            )}
            {sortedCodes.map((code) => (
              <CodeRow
                code={code}
                handleSelect={handleSelect}
                selected={selected}
                disabled={deletingCodes}
                key={code.password}
              />
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default CodeHistory;
