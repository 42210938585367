import { ColorRing } from 'react-loader-spinner';

const LoaderRing = (props) => {
    return (
        <ColorRing
            visible={true}
            height={props.height}
            width={props.width}
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={[
                '#1F60AE',
                '#5C79FF',
                '#455DC8',
                '#889DFF',
                '#5271FF',
            ]}
        />
    );
}

export default LoaderRing;