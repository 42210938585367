import './LogoGraph.scss';
import Logo from '../../assests/images/logo-dark.svg';

const LogoGraph = ({ style }) => {
    return (
        <div className='logo-graph' style={style}>
            <div className='elipse lg'>
                <div className='elipse md'>
                    <div className='elipse sm'>
                        <img src={Logo} alt="Logo" className='logo' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogoGraph;