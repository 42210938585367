import React, { useEffect, useState } from "react";
import {
  Accordion,
  Center,
  Text,
  Flex,
  Loader,
  TextInput,
  rem,
  CloseButton,
} from "@mantine/core";
import classes from "../../Kits/tabs/Orders.module.css";
import UseHttp from "../../../hooks/UseHttp";
import { useGeneral } from "../../../context/GeneralContext";
import { Auth } from "aws-amplify";
import Sidebar from "../components/AdminSideBar";
import InvoicesTable from "../../../components/invoice/table";
import { IoMdSearch } from "react-icons/io";

function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) => {
    const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
    return fullName.includes(query);
  });
}
const InvoicesList = () => {
  const [users, setUsers] = useState([]);
  const [invoices, setInvoices] = useState({});
  const [sortedData, setSortedData] = useState([]);

  const { setAlertModal } = useGeneral();

  const {
    isLoading: isLoadingUsers,
    error: getUsersError,
    requestData,
  } = UseHttp();

  const {
    isLoading: isLoadingState,
    error: errorState,
    requestData: changeInvoiceState,
  } = UseHttp();

  const {
    isLoading: isLoadingInvoices,
    error: invoicesError,
    requestData: requestInvoices,
  } = UseHttp();

  useEffect(() => {
    if (getUsersError) {
      console.log(getUsersError);
      setAlertModal({
        show: true,
        title: getUsersError.title,
        content: "There was an error in our server",
      });
    }
  }, [errorState, setAlertModal, getUsersError]);

  const fetchUsers = () => {
    Auth.currentSession().then((token) => {
      const requestConfig = {
        url: `${process.env.REACT_APP_NODE_API_URL}/api/users/admin/list?format=minimal`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };
      requestData(requestConfig, (response) => {
        setUsers(response);
        setSortedData(response);
      });
    });
  };

  const getBillingHistory = async (userId) => {
    Auth.currentSession().then((token) => {
      const requestConfig = {
        url: `${process.env.REACT_APP_NODE_API_URL}/api/invoices`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };
      requestInvoices(requestConfig, (response) => {
        setInvoices((prev) => {
          const newInvoices = { ...prev };
          newInvoices[userId] = response;
          return newInvoices;
        });
      });
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handlePayInvoice = (invoiceId) => {
    Auth.currentSession().then((token) => {
      const requestConfig = {
        method: "PATCH",
        url: `${process.env.REACT_APP_NODE_API_URL}/api/invoices/${invoiceId}/mark-as-paid`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
      };
      changeInvoiceState(requestConfig, (response) => {
        setInvoices((prev) => {
          const index = invoices[response.user].findIndex(
            (invoice) => invoice._id === response._id
          );
          const newInvoices = { ...prev };
          newInvoices[response.user][index] = response;
          return newInvoices;
        });
      });
    });
  };

  const [search, setSearch] = useState("");
  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(filterData(users, value));
  };
  const handleResetSearch = () => {
    setSearch("");
    setSortedData(filterData(users, ""));
  };

  const items = sortedData?.map((user, index) => (
    <Accordion.Item
      key={user._id}
      value={user._id}
      onClick={() => getBillingHistory(user._id)}
    >
      <Accordion.Control       
      >
        <Flex justify={"space-between"} align={"center"}>
          <Text>
            {user.firstName} {user.lastName}
          </Text>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel mx={20} onClick={(e) => e.stopPropagation()}>
        <InvoicesTable
          invoices={invoices[user._id]}
          isLoading={isLoadingInvoices}
          handlePayInvoice={handlePayInvoice}
        />
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Sidebar>
      {isLoadingUsers ? (
        <Center m={50}>
          <Loader color="blue" />
        </Center>
      ) : (
        <>
          {!users?.length ? (
            <Center m={50}>
              <Text>There are no users to display</Text>
            </Center>
          ) : (
            <>
              <TextInput
                m={30}
                flex={1}
                placeholder="Search by name"
                leftSection={
                  <IoMdSearch
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={1.5}
                  />
                }
                value={search}
                onChange={handleSearchChange}
                rightSection={<CloseButton onClick={handleResetSearch} />}
              />
              <Accordion m={30} classNames={classes}>
                {items}
              </Accordion>
            </>
          )}
        </>
      )}
    </Sidebar>
  );
};
export default InvoicesList;
