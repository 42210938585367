import { useMutationState, useQuery } from '@tanstack/react-query';
import { Card } from '../../../../designSystem/Card';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { getMeeting, getMeetingWithToken } from '../../../../api/bookings';
import logo from '../../../../assests/images/D-only-logo.png';
import style from './style.module.scss';
import LoaderRing from '../../../../components/LoaderRing';
import check from '../../../../assests/images/check.svg';
import { ReactSVG } from 'react-svg';

const defaultTimezone = 'America/Los_Angeles';

export const ConfirmationPage = () => {
  const { meetingId } = useParams();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token")

  const {
    data: booking,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['booking', meetingId ?? token],
    queryFn: (): any => meetingId ? getMeeting(meetingId) : getMeetingWithToken(token),
  });

  const startTime = toZonedTime(new Date(booking?.startTime * 1000), defaultTimezone);
  const endTime = toZonedTime(new Date((booking?.startTime * 1000) + 900000), defaultTimezone);

  const styleCheckIcon = (svg: SVGElement) => {
    svg.querySelector('path')?.setAttribute('stroke', '#979797');
  };

  return isLoading ? (
    <strong>Loading...</strong>
  ) : isError ? (
    <strong>Error...</strong>
  ) : (
    <section className={style.layout}>
      <Card className={style.card}>
        <header>
          <h1 className={style.heading}>
            <img
              src={logo}
              alt=''
            />
            Booking{' '}
            {booking.action === 'reschedule'
              ? 'Rescheduled'
              : 'Created'}
          </h1>
          <Card.Title className={style.cardTitle}>
            Well done! Your booking was{' '}
            {booking.action === 'reschedule'
              ? 'Rescheduled'
              : 'Created'}{' '}
            successfully!
          </Card.Title>
        </header>
        <article className={style.information}>
          <hr />
          <strong>Booking with: </strong>
          <span>{booking.user.firstName}</span>
          <hr />
          <strong>Agency: </strong>
          <span>{booking.user.company.name}</span>
          <hr />
          <strong>Location: </strong>
          <span>{booking.property.address}</span>
          <hr />
          <strong>Start: </strong>
          <span>{format(startTime, 'MM-dd-yyyy h:mm a')}</span>
          <hr />
          <strong>End: </strong>
          <span>{format(endTime, 'MM-dd-yyyy h:mm a')}</span>
        </article>
        <div className={style.notificationStatusGroup}>
          <p className={style.notificationStatus}>
            <ReactSVG
              beforeInjection={styleCheckIcon}
              src={check}
            />{' '}
            Sent{' '}
            to {booking.contact.phone}
          </p>
          <p className={style.notificationStatus}>
            <ReactSVG
              beforeInjection={styleCheckIcon}
              src={check}
            />{' '}
            Sent{' '}
            to {booking.contact.email}
          </p>
        </div>
      </Card>
      <p className={style.bottomText}>
        Do you want to make changes?
        <br />
        You can{' '}
        <Link
          className={style.link}
          to={`/reschedule/${booking._id}/${booking.user._id}`}
        >
          Reschedule Your Booking Here
        </Link>
      </p>
    </section>
  );
};
