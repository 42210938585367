import { Auth } from "aws-amplify";
import axios from "axios";
import {
  hasLength,
  isEmail,
  isNotEmpty,
  matchesField,
  useForm,
} from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Button,
  Title,
  Text,
  Image,
  Container,
  SimpleGrid,
  Select,
  Stepper,
  Stack,
  Group,
  Center,
  Space,
  Box,
  Progress,
  Modal,
  Flex,
} from "@mantine/core";
import logo from "../../assests/images/logo-dark.svg";
import { useState } from "react";
import classes from "./SignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { IMaskInput } from "react-imask";

function SignUp() {
  const [active, setActive] = useState(0);
  const [error, setError] = useState({ show: false, message: "" });
  const [success, setSuccess] = useState({ show: false, message: "" });
  const navigate = useNavigate();

  const websiteRegEx =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;

  const phoneRegEx = /^\+\d{1,3}[- ]?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;

  const requirements = [
    { re: /[0-9]/, label: "Includes number" },
    { re: /[a-z]/, label: "Includes lowercase letter" },
    { re: /[A-Z]/, label: "Includes uppercase letter" },
    { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
  ];

  const isValidPassword = (password) =>
    requirements.find((regex) => !regex.re.test(password)) === undefined;

  const form = useForm({
    initialValues: {
      companyName: "",
      website: "",
      units: "",
      firstName: "",
      lastName: "",
      jobTitle: "",
      phone: "",
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
    },

    validate: {
      companyName:
        active === 0 &&
        hasLength(
          { min: 1, max: 100 },
          "Company name must be 1-100 characters long"
        ),
      website: (value) =>
        active === 0 && value.length
          ? websiteRegEx.test(value)
            ? null
            : "Invalid URL"
          : null,
      units: active === 0 && isNotEmpty("Select number of units"),
      firstName:
        active === 1 &&
        hasLength({ min: 2, max: 100 }, "Name must be 2-100 characters long"),
      lastName:
        active === 1 &&
        hasLength(
          { min: 2, max: 100 },
          "Last name must be 2-100 characters long"
        ),
      jobTitle: active === 1 &&
      hasLength(
        { max: 100 },
        "Job title must be less than 100 characters long"
      ),
      phone: (value) =>
        active === 1 && (phoneRegEx.test(value) ? null : "Invalid phone"),
      code: active === 3 &&
      hasLength({ min:6, max: 6 }, "Confirmation Code must be 6 characters long"),
      email: active === 2 && isEmail("Invalid email"),
      password: (value) =>
        active === 2 && (isValidPassword(value) ? null : "Invalid password"),
      confirmPassword:
        active === 2 &&
        isNotEmpty("Confirm password") &&
        matchesField("password", "Passwords are not the same"),
    },
  });

  const sendConfirmationEmail = async (email) => {
    let url = process.env.REACT_APP_NODE_API_URL + "/api/users/request-code";
    await axios
      .post(url, { email })
      .then(async (response) => {
        setSuccess({ show: true, message: response?.data?.message });
        setActive(active + 1);
      })
      .catch((error) => {
        console.log(error.response);
        setError({ show: true, message: error?.response?.data?.message });
      });
  };

  const nextStep = async() => {
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 4 ? current + 1 : current;
    });
    if (active === 2 && !form.validate().hasErrors) {
      await sendConfirmationEmail(form.values.email);
    }
  };

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));


  function PasswordRequirement({ meets, label }) {
    return (
      <Text component="div" c={meets ? "teal" : "red"} mt={5} size="sm">
        <Center inline>
          {meets ? (
            <CiCircleCheck size="0.9rem" stroke={1.5} />
          ) : (
            <CiCircleRemove size="0.9rem" stroke={1.5} />
          )}
          <Box ml={7}>{label}</Box>
        </Center>
      </Text>
    );
  }

  function getStrength(password) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
  }

  const strength = getStrength(form.values.password);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(form.values.password)}
    />
  ));
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: "0ms" } }}
        value={
          form.values.password.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.validate().hasErrors) {
      return;
    }
    let url = process.env.REACT_APP_NODE_API_URL + "/api/users/register";
    await axios
      .post(url, {
        ...form.values,
        phone: form.values.phone.replace(/[^\d+]/g, ""),
      })
      .then((response) => {
        console.log(response);
        Auth.signIn(form.values.email, form.values.password);
      })
      .then(() => {
        nextStep();
      })
      .catch((error) => {
        console.log(error.response);
        setError({ show: true, message: error?.response?.data?.message });
      });
  };

  return (
    <Container fluid>
      <Flex justify={"space-around"} align={"center"} className={classes.content} gap={20}>
        <Container >

          <SimpleGrid dir="row" cols={2} mb={"xl"} w={"100%"}>
            <Title order={2}>
              <Image src={logo} alt="Logo" w={100} />
            </Title>
            <Text size="sm" ta={"end"}>
              Already have an account? <Link to={"/login"}>Log in</Link>
            </Text>
          </SimpleGrid>
          <Title className={classes.title} order={3}>
            Create your account
          </Title>
          <Space h="xl" />
          <form onSubmit={handleSubmit} className={classes.form}>
            <Stepper active={active} size="xs">
              <Stepper.Step label="Your company">
                <Title order={4} mt={20} mb={20}>
                  First, tell us about your company
                </Title>

                <TextInput
                  label="Company name"
                  size="md"
                  placeholder="Your company"
                  withAsterisk
                  {...form.getInputProps("companyName")}
                />
                <TextInput
                  label="Website"
                  placeholder="https://yourcomany.com"
                  size="md"
                  mt="md"
                  {...form.getInputProps("website")}
                />

                <Select
                  label={"Number of units"}
                  mt="md"
                  size="md"
                  placeholder="Select"
                  data={[
                    "0-1,000 units",
                    "1,000-10,000 units",
                    "10,000+ units",
                  ]}
                  withAsterisk
                  {...form.getInputProps("units")}
                />
              </Stepper.Step>
              <Stepper.Step label="Company representative">
                <Title order={4} mt={20} mb={20}>
                  Now tell us about yourself
                </Title>
                <SimpleGrid cols={2} mt="md">
                  <TextInput
                    withAsterisk
                    label="First name"
                    placeholder="Your name"
                    size="md"
                    {...form.getInputProps("firstName")}
                  />
                  <TextInput
                    withAsterisk
                    label="Last name"
                    placeholder="Your last name"
                    size="md"
                    {...form.getInputProps("lastName")}
                  />
                </SimpleGrid>
                <TextInput
                    label="Job title"
                    placeholder="Your position in the company"
                    size="md"
                    mt="md"
                    {...form.getInputProps("jobTitle")}
                  />
                <TextInput
                  withAsterisk
                  label="Phone number"
                  placeholder="Your contact number"
                  size="md"
                  mt="md"
                  component={IMaskInput}
                  mask="+0 (000) 000-0000"
                  {...form.getInputProps("phone")}
                />
              </Stepper.Step>
              <Stepper.Step label="Security">
                <TextInput
                  withAsterisk
                  type={"email"}
                  label="Email"
                  placeholder="name@company.com"
                  size="md"
                  mt="md"
                  {...form.getInputProps("email")}
                />
                <PasswordInput
                  withAsterisk
                  label="Password"
                  placeholder="Secure password"
                  size="md"
                  mt="md"
                  {...form.getInputProps("password")}
                />
                {form.values.password.length ? (
                  <>
                    <Group gap={5} grow mt="xs" mb="md">
                      {bars}
                    </Group>

                    <PasswordRequirement
                      label="Has at least 6 characters"
                      meets={form.values.password.length > 5}
                    />
                    {checks}
                  </>
                ) : null}
                <PasswordInput
                  withAsterisk
                  label="Confirm password"
                  placeholder="Repeat your password"
                  size="md"
                  mt="md"
                  {...form.getInputProps("confirmPassword")}
                />
              </Stepper.Step>
              <Stepper.Step label="Confirmation">
                  <TextInput
                    withAsterisk
                    type={"text"}
                    label="Confirmation code"
                    placeholder="Enter code"
                    size="md"
                    mt="md"
                    maxLength={6}
                    {...form.getInputProps("code")}
                  />
                </Stepper.Step>
              <Stepper.Completed>
                <Container my={50}>
                  <Stack align="center">
                    <Title ta={"center"}>Welcome!</Title>
                    <Space h="xl" />
                    <Container>
                      <Button
                        ta={"center"}
                        size="md"
                        onClick={() => navigate("/property/add")}
                      >
                        Create your first property
                      </Button>
                    </Container>
                    <Space h="xl" />

                    <Text ta="center">
                      If you have any further question please contact us!
                    </Text>
                    <Text ta="center">
                      Email: sales@delet.com or call 310-269-9814
                    </Text>
                  </Stack>
                </Container>
              </Stepper.Completed>
            </Stepper>
            <Group justify="flex-end" mt="xl">
              {active !== 0 && active !== 4 && (
                <Button variant="default" onClick={prevStep}>
                  Back
                </Button>
              )}
              {active === 3 && active !== 4 && (
                <Button type="submit">Sign up</Button>
              )}
              {active !== 3 && active !== 4 && (
                <Button onClick={nextStep}>Next step</Button>
              )}
            </Group>
          </form>
        </Container>
          <Container className={classes.image} fluid>
            <SimpleGrid cols={1}>
              <Title order={2} ta={"end"} c={"blue"}>
                <span className={classes.highlight}>
                  {" "}
                  Redefine property showing{" "}
                </span>
              </Title>
              <Image
                src={"https://delet.com/uploads/steps/how-it-work-step-3.svg"}
                fit="contain"
              />
            </SimpleGrid>
          </Container>
      </Flex>

      <Modal
        size="sm"
        title="Error Signing Up"
        opened={error.show}
        onClose={() => setError({ show: false, message: "" })}
        centered
      >
        {error.message}
      </Modal>
      <Modal
        size="sm"
        title="Signing Up success"
        opened={success.show}
        onClose={() => setSuccess({ show: false, message: "" })}
        centered
      >
        {success.message}
      </Modal>
    </Container>
  );
}

export default SignUp;
