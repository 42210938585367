import styled from "styled-components";

import Button from "../../../components/Button";
import * as ReactBootstrap from "react-bootstrap";
import { useProperty } from "../../../context/PropertyContext";

const Modal = styled(ReactBootstrap.Modal)`
  & .modal-dialog.modal-dialog-centered > .modal-content {
    display: flex;
    width: 560px;
    padding: 16px;
    flex-direction: column;
    gap: 32px;
  }
`;

/**
 * show: Bool
 * status: Bool
 * onCancel: function
 * onConfirm: function
 * address: String
 * unit: String
 */
const DoorConfirmation = ({ show, status, onCancel, onConfirm, loading }) => {
    const { property } = useProperty();
    const { address, unit } = property;

    return (
        <Modal centered show={show}>
            <div>
                <h4 className="h4">
                    {status ? "LOCK" : "UNLOCK"} Door Confirmation
                </h4>
            </div>
            <div>
                <span className="body">
                    Are you sure you want to {status ? "LOCK" : "UNLOCK"} the door at{" "}
                    {address}
                    {unit && unit !== "" && `, Unit ${unit}`}?
                </span>
            </div>
            <div className="d-flex justify-content-end gap-3">
                <Button 
                    variantType="secondary" 
                    onClick={onCancel} 
                    disabled={loading}
                >
                    Cancel
                </Button>
                <Button 
                    variantType="primary" 
                    onClick={onConfirm} 
                    disabled={loading}
                >
                    {status ? "Yes, Lock Door" : "Yes, Unlock Door"}
                </Button>
            </div>
        </Modal>
    );
};

export default DoorConfirmation;
