import { Box, Card, Group, ScrollArea, Text } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";

const OrderDetails = ({ order }) => {
  return (
    <Card withBorder w={"50%"}>
      <Text size="sm" fw={900} mb={10}>
        Details
      </Text>
      <Group justify="space-between">
        <ScrollArea h={100}>
          {order.user ? (
            <Text size="sm">
              <Text span fw={800}>
                Client:
              </Text>{" "}
              <Link to={`/admin/user/details/${order.user._id}`}>
                {order.user.firstName + " " + order.user.lastName}
              </Link>
            </Text>
          ) : null}
          <Text size="sm">
            <Text span fw={800}>
              Payment method:
            </Text>{" "}
            {order.paymentMethod}
          </Text>
          {order.notes ? (
            <Text size="sm">
              <Text span fw={800}>
                Notes:
              </Text>{" "}
              {order.notes}
            </Text>
          ) : null}
        </ScrollArea>

        <ScrollArea h={100}>
          {order.details?.map((el, index) => (
            <Box key={index}>
              <Text size="sm" tt={"capitalize"}>
                {el.serviceType} (x {el.amount}) - $ {el.price}
              </Text>
            </Box>
          ))}
        </ScrollArea>
      </Group>
    </Card>
  );
};

export default OrderDetails;
