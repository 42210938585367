import { useCallback, useState } from "react";
import axios from "axios";

const UseHttp = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);

  const requestData = useCallback(async (requestConfig, processData) => {
    let errorResponse = {
      title: 'Error',
      content: 'Something went wrong'
    }
    setIsLoading(true);
    setError(null)
    try {
      const response = await axios({
        method: requestConfig.method ? requestConfig.method : "GET",
        url: requestConfig.url,
        headers: requestConfig.headers ? requestConfig.headers : {},
        data: requestConfig.body ? requestConfig.body : null,
      });

      if (response.status === 200 || response.status === 201) {
        const data = response.data;

        processData(data);
      } else {
        setError({ ...errorResponse, code: response.status })
      }
    } catch (error) {
      if (error.response?.data?.title) {
        errorResponse.title = error.response.data.title;
      }

      if (error.response?.data?.message) {
        errorResponse.content = error.response.data.message;
      } else if (error?.message) {
        errorResponse.content = error.message
      }
      setError({ ...errorResponse, code: error?.response?.status || 500 })
    }

    setIsLoading(false);
  }, []);

  return { isLoading, error, requestData };
};

export default UseHttp;