import React from "react";
import OrderCard from ".//components/OrderCard";

const OrderCardsList = ({ orders, setCardProp, removeCard }) => {
  return (
    <div className="d-flex justify-content-between gap-3">
      {orders?.map((order, index) => (
        <div className="col">
            <OrderCard
              key={index}
              index={index}
              order={order}
              setCardProp={setCardProp}
              removeCard={removeCard}
            />
        </div>
      ))}
    </div>
  );
};

export default OrderCardsList;
