import "./DateRangeSelect.scss";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, forwardRef, useEffect } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { CloseButton, Form, InputGroup, Stack } from "react-bootstrap";

export const DateRangeSelect = ({
  startDate,
  endDate,
  handleDateRangeChange,
}) => {
  const [withPortal, setWithPortal] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Check the window width and set withPortal accordingly
      if (window.innerWidth < 768) {
        setWithPortal(true); // Set withPortal to true for smaller screens
      } else {
        setWithPortal(false); // Set withPortal to false for larger screens
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [date] = useState(new Date());

  const startYear = 1990;
  const currentYear = date.getFullYear();
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Input component
  const DatePickerInput = forwardRef(({ value, onClick }, ref) => {
    const dates = value.split(" - ");
    const range = value ? dates[0] + "     >     " + dates[1] : "";

    return (
      <InputGroup
        onClick={onClick}
        className="date-range-input-wrapper"
        size="sm"
      >
        <Form.Control
          value={range}
          readOnly
          htmlSize={25}
          as="input"
          placeholder="Start Date     >     End Date"
          className="date-range-input"
        />
        <InputGroup.Text className="date-range-input-icon">
          <IoCalendarOutline size={"1.4rem"} />
        </InputGroup.Text>
        <InputGroup.Text
          className="date-range-input-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleDateRangeChange(["", ""]);
          }}
        >
          <CloseButton className="clear-button" aria-label="Clear dates" />
        </InputGroup.Text>
      </InputGroup>
    );
  });
  const DatePickerContainer = ({ children }) => {
    return <div style={{ position: "relative" }}>{children}</div>;
  };
  const DatePickerHeader = ({ date, changeYear, changeMonth }) => (
    <Stack direction="horizontal" gap={3} style={{ margin: 10 }}>
      <Form.Select
        value={date.getFullYear(date)}
        className="select"
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>

      <Form.Select
        value={months[date.getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        className="select"
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    </Stack>
  );

  return (
    <div className="date-picker-wrapper">
      <DatePicker
        selected={startDate}
        onChange={handleDateRangeChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        shouldCloseOnSelect={false}
        calendarContainer={DatePickerContainer}
        customInput={<DatePickerInput />}
        renderCustomHeader={DatePickerHeader}
        formatWeekDay={(nameOfDay) => nameOfDay[0]}
        withPortal={withPortal}
      />
    </div>
  );
};
