import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { Button, CloseButton, Flex, Stack, TextInput } from "@mantine/core";
import SearchSelect from "../../../components/Selects/SearchSelect";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createInvoiceInfo,
  getUserInvoicesInfo,
} from "../../../api/invoiceInfo";
import Select from "react-select";

const initialValues = {
  street: "",
  city: "",
  state: "",
  zipCode: "",
  name: "",
  details: "",
};

const BillingInfoForm = ({ product, close }) => {
  const queryClient = useQueryClient();
  const [selectedInfo, setSelectedInfo] = useState(product.invoiceInfo);
  const form = useForm({
    validate: {},
  });

  const {
    data: invoicesInfo,
    isLoading: loadingInvoicesInfo,
    isError: errorGettingInfo,
  } = useQuery({
    queryKey: ["invoicesInfo"],
    queryFn: () => getUserInvoicesInfo(),
  });

  const {
    mutateAsync: createBI,
    isPending: creatingBI,
    isError: errorCreatingBI,
  } = useMutation({
    mutationFn: () => createInvoiceInfo(form.values, [product._id]),
    onSuccess: (data) => {
      queryClient.setQueryData(["invoicesInfo"], (invoicesInfoArr) => [
        ...invoicesInfo,
        data,
      ]);

      queryClient.setQueryData(['kits-from-order', product.order], (productsArr) => {
        const productIndex = productsArr.findIndex(prod => prod._id === product._id)
        productsArr[productIndex].invoiceInfo = data
        return productsArr
      });
      close();
    },
  });

  const handleSelect = (newInfo) => {
    setSelectedInfo(newInfo);
    form.setValues({
      street: newInfo.address?.street,
      city: newInfo.address?.city,
      state: newInfo.address?.state,
      zipCode: newInfo.address?.zipCode,
      name: newInfo.name,
      details: newInfo.details,
    });
  };

  const handleReset = () => {
    setSelectedInfo(null);
    form.setValues(initialValues);
  };

  return (
    <div className="pb-5">
      <Flex my={10} justify={"flex-end"}>
        {loadingInvoicesInfo ? (
          <strong>Getting existing invoice information...</strong>
        ) : errorGettingInfo ? (
          <strong>Error getting existing invoice information</strong>
        ) : (
          invoicesInfo.length > 0 && (
            <>
              <Select
                options={invoicesInfo}
                onChange={handleSelect}
                getOptionValue={(info) => info}
                getOptionLabel={(info) => info.name}
                value={selectedInfo}
                className="w-50"
              />
            </>
          )
        )}
        {selectedInfo && <CloseButton onClick={handleReset} />}
      </Flex>
      <Stack gap={"md"}>
        <form onSubmit={form.onSubmit(createBI)}>
          <TextInput
            label="Name"
            {...form.getInputProps("name")}
            disabled={selectedInfo}
            defaultValue={product.invoiceInfo?.name}
            required
          />

          <TextInput
            label="Street"
            {...form.getInputProps("street")}
            disabled={selectedInfo}
            defaultValue={product.invoiceInfo?.address?.street}
          />

          <TextInput
            label="Zip Code"
            {...form.getInputProps("zipCode")}
            disabled={selectedInfo}
            defaultValue={product.invoiceInfo?.address?.zipCode}
          />

          <TextInput
            label="City"
            {...form.getInputProps("city")}
            disabled={selectedInfo}
            defaultValue={product.invoiceInfo?.address?.city}
          />
          <TextInput
            label="State"
            {...form.getInputProps("state")}
            disabled={selectedInfo}
            defaultValue={product.invoiceInfo?.address?.state}
          />
          <TextInput
            label="Details"
            {...form.getInputProps("details")}
            disabled={selectedInfo}
            defaultValue={product.invoiceInfo?.details}
          />
          <Flex justify={"flex-end"} mt={20}>
            <Button type="submit" color="black">
              Submit
            </Button>
          </Flex>
        </form>
      </Stack>
    </div>
  );
};

export default BillingInfoForm;
