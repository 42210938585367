import { Modal } from 'react-bootstrap';
import LoaderRing from './LoaderRing';


const LoaderModal = ({ show = false }) => (
    <Modal show={show} className="d-flex justify-content-center">
        <Modal.Header >
            <Modal.Title>Loading...</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center pb-3">
            <LoaderRing />
        </Modal.Body>
    </Modal>
);

export default LoaderModal;