import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const useGaTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    
    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize(`${process.env.REACT_APP_GA}`);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            const title = location.pathname.split('/')[1] ? location.pathname.split('/')[1] : 'Delet';
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: title });
        }
    }, [initialized, location]);
};

export default useGaTracker;