import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

type GenericModalProps = {
  show: boolean;
  handleClose: () => void;
  title: string;
  body: React.ReactElement;
  hideCloseButton?: boolean;
}

const GenericModal = ({show, handleClose, title, body, hideCloseButton}: GenericModalProps) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      centered={true}
    >
      {title ? <Modal.Header closeButton={!hideCloseButton}>
        <Modal.Title className='text-secondary'>{title}</Modal.Title>
      </Modal.Header> : null}
      <Modal.Body>
        {body}
      </Modal.Body>
      
    </Modal>
  )
}

export default GenericModal