import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Center,
  Text,
  Flex,
  Loader,
  Title,
  Stack,
  Select,
  Modal,
  LoadingOverlay,
} from "@mantine/core";
import { GoDotFill } from "react-icons/go";
import classes from "../../Kits/tabs/Orders.module.css";
import moment from "moment";
import UseHttp from "../../../hooks/UseHttp";
import { useGeneral } from "../../../context/GeneralContext";
import { Auth } from "aws-amplify";
import Sidebar from "../components/AdminSideBar";
import { Link, NavLink } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";

const ShipmentsList = () => {
  const stateColors = {
    "waiting for payment information": "var(--mantine-color-blue-filled)",
    "assembling kits": "var(--mantine-color-blue-filled)",
    complete: "var(--mantine-color-green-filled)",
    paid: "var(--mantine-color-blue-filled)",
    cancelled: "var(--mantine-color-red-filled)",
  };

  const orderStates = ["requested", "initiated", "completed", "with issue"];

  const { setAlertModal } = useGeneral();

  const { isLoading, error, requestData: getOrders } = UseHttp();
  const {
    isLoading: isLoadingDetail,
    error: errorDetail,
    requestData: getOrder,
  } = UseHttp();
  const {
    isLoading: isLoadingState,
    error: errorState,
    requestData: changeShipmentState,
  } = UseHttp();
  const {
    isLoading: isLoadingKitState,
    error: errorKitState,
    requestData: changeKitState,
  } = UseHttp();

  useEffect(() => {
    if (error) {
      console.log(error);
      setAlertModal({
        show: true,
        title: error.title,
        content: "There was an error in our server",
      });
    }
    if (errorDetail) {
      console.log(errorDetail);
      setAlertModal({
        show: true,
        title: errorDetail.title,
        content: "There was an error please try again or reload",
      });
    }
    if (errorState) {
      console.log(errorDetail);
      setAlertModal({
        show: true,
        title: errorDetail.title,
        content: "There was an error when trying to update the state",
      });
    }
    if (errorKitState) {
      console.log(errorDetail);
      setAlertModal({
        show: true,
        title: errorDetail.title,
        content: "There was an error when trying to update the state",
      });
    }
  }, [error, errorDetail, errorState, errorKitState, setAlertModal]);

  const [shipments, setShipments] = useState([]);

  useEffect(() => {
    Auth.currentSession()
      .then((token) => {
        const requestConfig = {
          url: `${process.env.REACT_APP_NODE_API_URL}/api/shipments/admin/list`,
          headers: {
            Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
          },
        };
        getOrders(requestConfig, (response) => {
          setShipments(response);
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const [opened, { open, close }] = useDisclosure(false);

  const handleChangeState = (newState, shipment) => {
    const { state: currentState } = shipment;
    Auth.currentSession().then((token) => {
      const requestConfig = {
        method: "PATCH",
        url: `${process.env.REACT_APP_NODE_API_URL}/api/shipments/${shipment._id}/update-state`,
        headers: {
          Authorization: `Bearer ${token.getIdToken().getJwtToken()}`,
        },
        body: {
          currentState,
          newState,
        },
      };
      changeShipmentState(requestConfig, (response) => {
        const updatedShipments = [...shipments];
        const index = updatedShipments.findIndex(
          (el) => el._id === shipment._id
        );
        updatedShipments[index] = response;
        setShipments(updatedShipments);
      });
    });
  };

  const items = shipments?.map((shipment, index) => (
    <Accordion.Item key={shipment._id} value={shipment._id}>
      <Accordion.Control
        icon={<GoDotFill color={stateColors[shipment.state]} />}
      >
        <Flex justify={"space-between"} align={"center"}>
          <Text>Shipment {shipment.code}</Text>
          <Text>
            {shipment.user.firstName} {shipment.user.lastName}
          </Text>
          <Text c={"dimmed"} size="sm">
            {moment(shipment.createdAt).format("MMMM Do, YYYY")}
          </Text>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel mx={20} onClick={(e) => e.stopPropagation()}>
        {isLoadingDetail ? (
          <Center m={50}>
            <Loader color="blue" />
          </Center>
        ) : (
          <Flex my={10} justify={"space-between"} align={"center"}>
            <Card withBorder>
              <Text size="sm" fw={900} mb={10}>
                Details
              </Text>
              <Text size="sm">
                <Text span fw={800}>
                  Client:
                </Text>{" "}
                <Link
                  to={`/admin/user/details/${shipment.user._id}`}
                  target="_blank"
                >
                  {shipment.user.firstName + " " + shipment.user.lastName}
                </Link>
              </Text>

              <Text size="sm">
                <Text span fw={800}>
                  {shipment.kits.length} Kit{shipment.kits.length > 1 && "s"}
                </Text>
              </Text>

              <Text size="sm">
                <Text span fw={800}>
                  Tracking URL:
                </Text>{" "}
                <Link to={shipment.trackingUrl} target="_blank">
                  {shipment.trackingUrl}
                </Link>
              </Text>

              {shipment.notes && (
                <Text size="sm">
                  <Text span fw={800}>
                    Notes:
                  </Text>{" "}
                  <Text>{shipment.notes}</Text>
                </Text>
              )}
            </Card>
            {/* check */}
            <Stack>
              <LoadingOverlay
                visible={isLoadingState || isLoadingKitState}
                zIndex={1000}
                overlayProps={{ radius: "sm", blur: 2 }}
              />
              <Select
                label="Shipment status"
                data={orderStates}
                value={shipment.state}
                onChange={(value) => handleChangeState(value, shipment)}
              />
            </Stack>
          </Flex>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Sidebar>
      <Flex
        bg={"#eaeaeb"}
        align={"center"}
        px={"26px"}
        py={"20px"}
        gap={20}
      >
        <Title shipment={1} fw={900} lh={"45px"}>
          Shipments
        </Title>
        <Link
          to="/admin/shipment/create"
          size="sm"
          className="btn btn-primary btn-sm"
        >
          NEW
        </Link>
      </Flex>

      {isLoading ? (
        <Center m={50}>
          <Loader color="blue" />
        </Center>
      ) : (
        <>
          {!shipments?.length ? (
            <Center m={50}>
              <Text>
                There are no shipments to display{" "}
                <Link
                  to="/admin/shipment/create"
                  className="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                >
                  Create
                </Link>
              </Text>
            </Center>
          ) : (
            <Accordion m={30} classNames={classes}>
              {items}
            </Accordion>
          )}
        </>
      )}
    </Sidebar>
  );
};
export default ShipmentsList;
