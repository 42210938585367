import { ImgHTMLAttributes } from 'react';
import style from '../style.module.scss';

type Props = {
  src: string;
  alt: string;
} & Omit<ImgHTMLAttributes<HTMLImageElement>, 'alt' | 'src'>;

export const Image = ({ alt, src, className, ...props }: Props) => {
  return (
    <img
      className={`${style.img} ${className}`}
      alt={alt}
      src={src}
      {...props}
    />
  );
};
