import React, { FormEvent, useState } from 'react';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './step3/Step3';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getActiveProperties,
  getBuilding,
  getPublicProperty,
} from '../../../../api/properties';
import { AppButton } from '../../../../designSystem/AppButton';
import { StepNumberLabel } from './StepNumberLabel';
import style from './style.module.scss';
import check from '../../../../assests/images/check.svg';
import backArrow from '../../../../assests/images/PrevArrow.svg';
import nextArrow from '../../../../assests/images/NextArrow.svg';
import { ReactSVG } from 'react-svg';
import Alert from '../../../../components/Alert';

import useGaTracker from '../../../../hooks/useGaTracker';
import { createMeeting } from '../../../../api/bookings';

import { OnlyOnePropertySteps } from '../OnlyOnePropertySteps';

type bookingProps = {
  propertyId: string;
  localTimeRequested: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  privacyPolicy?: 'true' | 'false';
  idVerification: boolean;
  document: File;
  documentBack?: File;
  face?: File;
  verified?: boolean;
  agent: string
}

function StepsContainer() {
  useGaTracker();
  const [searchParams] = useSearchParams();
  const { propertyId, agentId } = useParams();
  const [inputValues, setInputValues] = useState<Partial<bookingProps>>({
    agent: agentId
  });

  const [alertModal, setAlertModal] = useState({
    show: false,
    title: '',
    content: '',
  });


  const navigate = useNavigate();
  const searchProperties = searchParams.get('properties');

  const [step, setStep] = useState(1);

  const {
    data: properties,
    isLoading: loadingProperties,
    isError: propertyError,
  } = useQuery({
    queryKey: propertyId
      ? ['property', propertyId]
      : ['properties', agentId],
    queryFn: async () => {
      const res = propertyId
        ? await getPublicProperty(propertyId)
        : await getActiveProperties(agentId, searchProperties);
      return res;
    },
  });

  const onNext = async () => {
    if (step === 3) {
      handleBook();
      return;
    }
    setStep(step + 1);
  };

  const handleBook = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex = new RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
    );
    if (
      !inputValues ||
      !inputValues.phone ||
      !inputValues.email ||
      !inputValues.firstName ||
      !inputValues.lastName ||
      !inputValues.localTimeRequested
    ) {
      setAlertModal({
        show: true,
        title: 'Alert',
        content: 'Please complete all the information',
      });
      return;
    }

    if (!phoneRegex.test(inputValues.phone)) {
      setAlertModal({
        show: true,
        title: 'Alert',
        content: 'Please enter a valid phone number',
      });
    } else if (!emailRegex.test(inputValues?.email)) {
      setAlertModal({
        show: true,
        title: 'Alert',
        content: 'Please enter a valid email address',
      });
    } else {
      meetingMutation.mutate(inputValues);
    }
  };
  const meetingMutation = useMutation({
    mutationFn: (booking: Partial<bookingProps>) => createMeeting(booking),
    onSuccess: (booking: any) => {
      navigate(`/book/confirmation/${booking.booking}`);
    },
  });

  const onPrev = () => {
    setStep(step - 1);
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInputValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const defineCircleContent = (labelStep: number) => {
    if (labelStep < step) {
      return (
        <img
          src={check}
          alt=''
        />
      );
    } else {
      return String(labelStep);
    }
  };

  const handleNextDisable = () => {
    if (!inputValues) return true
    if (step === 2 && !inputValues.localTimeRequested) {
      return true;

    } else if (step === 3) {
      if (!inputValues.email ||
        !inputValues.firstName ||
        !inputValues.lastName ||
        !inputValues.document ||
        !inputValues.phone ||
        inputValues.privacyPolicy !== "true") {
        return true;
      }

      if (selectedProperty.idVerification.active) {

        if (inputValues.verified === undefined || inputValues.verified === false) {
          return true;
        }
      }
    }
    return false;
  };

  const selectedProperty = !Array.isArray(properties)
    ? properties
    : properties.length === 1
      ? properties[0]
      : properties.find(property => property._id === inputValues?.propertyId) || null;

  return loadingProperties ? (
    <div>Loading...</div>
  ) : propertyError ? (
    <div>Error getting properties</div>
  ) : !Array.isArray(properties) ? (
    <OnlyOnePropertySteps property={properties} />
  ) : properties.length === 1 ? (
    <OnlyOnePropertySteps property={properties[0]} />
  ) : (
    <div className={style.layout}>
      <div className={`${style.stepLabels}`}>
        <StepNumberLabel
          className={`${step !== 1 && style.inactive} `}
          circleContent={defineCircleContent(1)}
          state={step === 1 ? 'selected' : step > 1 ? 'completed' : 'default'}
          title='Choose A Property'
        />
        <StepNumberLabel
          className={`${step !== 2 && style.inactive} `}
          circleContent={defineCircleContent(2)}
          state={step === 2 ? 'selected' : step > 2 ? 'completed' : 'default'}
          title='Choose Time'
        />
        <StepNumberLabel
          className={`${step !== 3 && style.inactive} `}
          circleContent={defineCircleContent(3)}
          state={step === 3 ? 'selected' : step > 3 ? 'completed' : 'default'}
          title='Identity Verifications'
        />
      </div>
      <form onSubmit={handleSubmit}>
        {step === 1 ? (
          <Step1
            selectedId={inputValues?.propertyId}
            currentStep={step}
            properties={properties}
            onChange={handleInputChange}
          />
        ) : step === 2 ? (
          <Step2
            className={style.container}
            currentStep={step}
            propertyId={inputValues.propertyId}
            onChange={handleInputChange} agent={undefined} />
        ) : (
          <Step3
            document={inputValues.document}
            documentBack={inputValues.documentBack}
            face={inputValues.face}
            className={style.container}
            currentStep={step}
            onChange={handleInputChange}
            idVerification={selectedProperty.idVerification}
            verified={inputValues.verified}
          />
        )}

        <div className={`${style.buttons} ${style.container}`}>
          <AppButton
            className={style.button}
            as='button'
            onClick={onPrev}
            disabled={step === 1}
            hierarchy='secondary'
            type='button'
          >
            <ReactSVG
              beforeInjection={(svg) => {
                if (step === 1) {
                  svg
                    .querySelector('path')
                    ?.setAttribute('stroke', '#00000033');
                }
              }}
              src={backArrow}
            />
            Go Back
          </AppButton>
          <AppButton
            disabled={handleNextDisable()}
            className={style.button}
            as='button'
            type='button'
            onClick={onNext}
          >
            <span className='d-flex align-items-center'>
              <ReactSVG
                beforeInjection={(svg) => {
                  if (handleNextDisable()) {
                    svg
                      .querySelector('path')
                      ?.setAttribute('stroke', '#00000033');
                  }
                }}
                src={nextArrow}
              />
              {step !== 3 ? 'Confirm and Continue' : 'Schedule My Tour'}
            </span>
          </AppButton>
        </div>
      </form>
      {alertModal.show && (
        <Alert
          show={alertModal.show}
          title={alertModal.title}
          content={alertModal.content}
          setShow={() => setAlertModal({ ...alertModal, show: false })}
          navigateTo={null}
        />
      )}
    </div>
  );
}

export default StepsContainer;
