import PropertyCard from "../PropertyCard/PropertyCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import style from "./style.module.scss";
import { useState } from "react";
import { DrawerModalContainer } from "../../../../../designSystem/DrawerModalContainer";
import { PropertyDetail } from "../../PropertyDetail";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function Step1({ currentStep, properties, onChange, selectedId }) {
  const [detailProperty, setDetailProperty] = useState(null);
  const onOpen = (property) => {
    setDetailProperty(property);
  };
  const onClose = (e) => {
    e?.stopPropagation();
    setDetailProperty(null);
  };

  return (
    <div className={` ${currentStep !== 1 ? "d-none" : ""} ${style.container}`}>
      <span className={style.companyName}>{properties.company?.name}</span>
      <Swiper
        slidesPerView={1}
        centerInsufficientSlides={true}
        className="py-1 w-100 mySwiper rounded-4 bg-white"
        navigation={true}
        pagination={true}
        modules={[Pagination, Navigation]}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        
      >
        {properties?.length ? (
          properties.map((property) => (
            <SwiperSlide>
              <div className="d-flex justify-content-center px-1 h-100 w-100">
                <PropertyCard
                  key={property.address}
                  onSeeDetails={onOpen}
                  selectedId={selectedId}
                  onChange={onChange}
                  property={property}
                />
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <PropertyCard
              onSeeDetails={onOpen}
              selectedId={selectedId}
              onChange={onChange}
              checked
              property={properties}
            />
          </SwiperSlide>
        )}
      </Swiper>

      <DrawerModalContainer isOpen={!!detailProperty} onClose={onClose}>
        {!!detailProperty ? <PropertyDetail property={detailProperty} /> : null}
      </DrawerModalContainer>
    </div>
  );
}

export default Step1;
