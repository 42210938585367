import React, { useState } from "react";
import moment from "moment";
import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Collapse,
  Group,
  Loader,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import {
  MdExpandLess,
  MdExpandMore,
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";

const Billing = ({invoices, isLoading, handlePayInvoice}) => {
  const [openedRows, setOpenedRows] = useState([]);
  const toggleRow = (index) => {
    const updatedRows = [...openedRows];
    updatedRows[index] = !updatedRows[index];
    setOpenedRows(updatedRows);
  };

  const expandAllRows = () => {
    const updatedRows = new Array(invoices.length).fill(true);
    setOpenedRows(updatedRows);
  };

  const collapseAllRows = () => {
    setOpenedRows(new Array(invoices.length).fill(false));
  };

  const handleDownload = (e, pdfPath) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = pdfPath;
    link.download = "invoice.pdf";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const rows = invoices?.map((element, index) => {
    return (
      <Table.Tbody key={element.id}>
      <Table.Tr>
        <Table.Td>
          {
            <ActionIcon
              c="black"
              onClick={() => toggleRow(index)}
              variant="transparent"
              aria-label="collapse"
            >
              {openedRows[index] ? <MdExpandLess /> : <MdExpandMore />}
            </ActionIcon>
          }
        </Table.Td>
        <Table.Td>
          {moment(element.paymentDate).format("MMMM Do, YYYY")}
        </Table.Td>
        <Table.Td>{moment(element.period).format("MMMM YYYY")}</Table.Td>

        <Table.Td>$ {(element.amount / 100).toFixed(2)}</Table.Td>
        <Table.Td> {element.paymentMethod}</Table.Td>

        <Table.Td>
          {element.paid ? (
            <Badge variant="light" color="green">
              Paid
            </Badge>
          ) : (
            <Badge color="red" variant="light">
              Not paid
            </Badge>
          )}
        </Table.Td>
        <Table.Td>
          <Button
            onClick={(e) => handleDownload(e, element.invoicePdf)}
            size="xs"
            variant="transparent"
            p={0}
          >
            Download
          </Button>
        </Table.Td>

        <Table.Td>
          <Button onClick={() => handlePayInvoice(element._id)} disabled={element.paid}>
            Collect payment
          </Button>
        </Table.Td>
      </Table.Tr>

      <Table.Tr display={!openedRows[index] && "none"}>
        <Table.Td colSpan={7}>
          <Collapse in={openedRows[index]} duration={400}>
            <Stack>
              <Group pl={95}>
                <Text size="sm">Kit 20:</Text>
                <Text size="sm">Address, Unit, ZIPCODE</Text>
              </Group>
              <Group pl={95}>
                <Text size="sm">Kit 20:</Text>
                <Text size="sm">Address, Unit, ZIPCODE</Text>
              </Group>
            </Stack>
          </Collapse>
        </Table.Td>


      </Table.Tr>
    </Table.Tbody>
    )
  });


  return (
    <>
      {isLoading ? (
        <Center h={100}>
          <Loader />
        </Center>
      ) : (
        <>
          <Title order={4}>Billing history</Title>
          <Table.ScrollContainer h={"60vh"} mr={25}>
            <Table
              striped
              highlightOnHover
              withRowBorders={false}
              verticalSpacing="lg"
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>
                    <ActionIcon
                      variant="transparent"
                      aria-label="expand-all"
                      color="black"
                      onClick={
                        openedRows.every((row) => row)
                          ? collapseAllRows
                          : expandAllRows
                      }
                    >
                      {openedRows.every((row) => row) ? (
                        <MdKeyboardDoubleArrowUp />
                      ) : (
                        <MdKeyboardDoubleArrowDown />
                      )}
                    </ActionIcon>
                  </Table.Th>
                  <Table.Th>Payment Date</Table.Th>
                  <Table.Th>Period</Table.Th>
                  <Table.Th>Amount</Table.Th>
                  <Table.Th>Payment Method</Table.Th>
                  <Table.Th>Status</Table.Th>
                  <Table.Th>Invoice</Table.Th>
                  <Table.Th>Mark as paid</Table.Th>
                </Table.Tr>
              </Table.Thead>
              {invoices?.length ? (
                rows
              ) : (
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td colSpan={8}>
                      <Center m={50}>
                        <Text>There are no records to display</Text>
                      </Center>
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              )}
            </Table>
          </Table.ScrollContainer>
        </>
      )}
    </>
  );
};

export default Billing;
